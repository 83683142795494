import {
  ADD_CHAT_RECOMMENDATION,
  CHAT_RECOMMENDATION_ERROR,
  DELETE_CHAT_RECOMMENDATION,
  EDIT_CHAT_RECOMMENDATION,
  FETCH_CHAT_RECOMMENDATION,
  GET_CHAT_RECOMMENDATION,
} from "common/constants/actions";

const initialState = {
  chat_recommendations: [],
  chat_recommendations_error: null,
  pending: false,
};

export default function Recommendations(state = initialState, action) {
  switch (action.type) {
    case GET_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case FETCH_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case ADD_CHAT_RECOMMENDATION:
      const currentRecommendations = state.chat_recommendations;
      currentRecommendations?.results?.unshift(action.payload);
      return {
        ...state,
        chat_recommendations: currentRecommendations,
        pending: false,
      };
    case EDIT_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case DELETE_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case CHAT_RECOMMENDATION_ERROR:
      return {
        ...state,
        chat_recommendations_error: action.payload,
        pending: false,
      };
    default:
      return state;
  }
}
