import React, { useEffect, useRef, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Icon, Message } from "semantic-ui-react";

import {
  CLEAR_CREATED_FILE_UPLOAD,
  SG_ADD_FILE_UPLOAD,
} from "common/constants/actions";

const FileUpload = ({
  organizationId,
  setShowReportId,
  setShowPage,
  AllPageOptions,
}) => {
  const dispatch = useDispatch();
  // fakeImage.image_url

  const setEditorRef = useRef(null);

  // const [employee, setEmployee] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { addToast } = useToasts();

  // const { get_employees } = useSelector(
  //     (state) => ({
  //         get_employees: state.employees,
  //     }),
  //     shallowEqual
  // );
  // useEffect(() => {
  //     if (get_employees) {
  //         setEmployee(get_employees[employee_id]);
  //         if (get_employees[employee_id]?.picture) {

  //         }
  //     }
  // }, [get_employees, employee_id]);
  // const event = new Date();
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  // console.log(event.toLocaleDateString(undefined, options));

  useEffect(() => {
    dispatch({
      type: CLEAR_CREATED_FILE_UPLOAD,
    });
  }, [dispatch]);

  const { get_file_uploads } = useSelector(
    (state) => ({
      get_file_uploads: state.fileupload,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (get_file_uploads?.created?.id) {
      setShowPage(AllPageOptions[1]);
      setShowReportId(get_file_uploads?.created?.id);
    }
  }, [get_file_uploads, setShowReportId, setShowPage, AllPageOptions]);

  // console.log(get_file_uploads?.created)

  const onClickSave = () => {
    const event = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    const TodayDate = event.toLocaleDateString(undefined, options);
    // console.log(image_file)
    if (organizationId) {
      // // update employee
      dispatch({
        type: SG_ADD_FILE_UPLOAD,
        payload: {
          name: `Uploaded on ${TodayDate}`,
          organization: organizationId,
          data_file: uploadedFiles,
          file_type: 3,
        },
      });
      // setOpen(false)
      setUploadedFiles(false);
      addToast("File Successfully Uploaded", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const handleDrop = (dropped) => {
    // console.log(dropped)
    if (dropped[0]?.name.split(".").pop() == "csv") {
      setUploadedFiles(dropped[0]);
    } else {
      setErrorMessage(`The file ${dropped[0]?.name} is not a CSV file`);
    }
  };

  // const onDrop = useCallback(acceptedFiles => {
  //     // Do something with the files
  //     // setUploadedFiles(acceptedFiles[0])
  // }, [])

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      // accept: 'image/jpeg, image/png',
      accept: "text/csv",
      // onDrop
    });

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // console.log('uploadedFiles', uploadedFiles)
  // console.log('acceptedFiles', acceptedFiles)
  // console.log("fileRejections", fileRejections[0]?.errors[0]?.message)
  // console.log(errorMessage)

  // fileRejections[0]?.errors
  useEffect(() => {
    setErrorMessage(fileRejections[0]?.errors[0]?.message);
  }, [fileRejections]);

  // useEffect(() => {
  //     setUploadedFiles(acceptedFiles[0])
  // }, [acceptedFiles]);

  return (
    <>
      <div aria-labelledby="max-width-dialog-title" style={{ paddingLeft: 30 }}>
        {errorMessage && (
          <Message
            negative
            onDismiss={() => setErrorMessage(false)}
            header="There was an Error!"
            content={errorMessage}
          />
        )}

        <div className="dialog">
          <P>Drag &amp; Drop your File here</P>

          <Dropzone
            onDrop={handleDrop}
            noKeyboard
            // getUploadParams={getUploadParams}
            // onChangeStatus={handleChangeStatus}
            // onSubmit={handleSubmit}
            maxFiles={1}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} file={uploadedFiles?.length > 0}>
                {uploadedFiles?.name ? (
                  <InnerBox onClick={() => setUploadedFiles([])}>
                    {" "}
                    {uploadedFiles?.name} <Icon name="cancel" />
                  </InnerBox>
                ) : (
                  <p>
                    {" "}
                    <Icon name="upload" /> Drag 'n' drop CSV files here, or
                    click to select CSV files to upload
                  </p>
                )}

                {<input {...getInputProps()} />}
              </Box>
            )}
          </Dropzone>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {uploadedFiles?.name && (
            <StyledButton onClick={() => onClickSave()} color="grey">
              Upload File
            </StyledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUpload;

const P = styled.p`
  font-size: 16px;
  font-family: "Barlow", sans-serif;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Info = styled.div`
  font-size: 14px;
  font-family: "Barlow", sans-serif;
`;

const Box = styled.div`
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  cursor: ${(props) => (props.file ? "default" : "pointer")};
  //   cursor:pointer;
  //   @media (max-width:768px){
  //     width:250px;
  //   }
`;
const InnerBox = styled.div`
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 15px;
  cursor: pointer;
  background-color: #dcdcdc;
`;

const Rotate = styled.div`
  position: absolute;
  bottom: 5px;
  right: -30px;
  width: 100px;
  cursor: pointer;
  color: #6a00ff;
`;
const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
`;
