import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown, Label, Pagination, Progress } from "semantic-ui-react";
import { shallowEqual, useSelector } from "react-redux";
import Context from "./FeedbackContext";
import TeamIcon from "assets/images/nav/team.svg";

var SentimentAnalysis = require("sentiment");

const scoring = {
  0: 10,
  1: 9,
  2: 8,
  3: 7,
  4: 6,
  5: 5,
  6: 4,
  7: 3,
  8: 2,
  9: 1,
  10: 0,
};

const Feedback = ({
  WhiteLabel,
  data,
  categories,
  structure,
  selected2,
  setTab,
  userEmp,
  is_french,
  language_hook,
}) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  var sentiment = new SentimentAnalysis();

  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual,
  );

  const reverseScore = (s, r) => {
    if (r) {
      return scoring[s];
    }

    return s;
  };

  const calculate_score = (s) => {
    let score = 0;
    let total = 0;
    s.questions.map((q) => {
      let reverse =
        structure.dimensions[q.id].factors[q.factor].questions[q.q]?.reverse;
      score += reverseScore(q.response, reverse);
      total += 1;

      return null;
    });

    return score / total;
  };

  const filter_subs = (s) => {
    let sub_id = s.categories.find((f) => f.priority == "primary").subfactor;

    if (sub_id) {
      let access = userEmp.employee_employee_category.category.find(
        (f) => f.value_id == sub_id,
      );

      if (access) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const filter_comments_by_id = (item, selected, i) => {
    let comment_id = structure.comments[selected]?.id;

    if (comment_id && item.id) {
      return item.id == comment_id;
    }

    return i == selected && (item.response || item.feedback);
  };

  const getFeedback = () => {
    let _data = [];
    if (filter_data) {
      filter_data[0]
        .filter((f) => filter_subs(f))
        .filter((f) => "comments" in f)
        .filter((f) => f?.comments?.length > 0)
        .map((item) => {
          //calculate the overall score for this user
          let score = calculate_score(item);
          let primary = categories.categories
            .find((f) => f.priority == "primary")
            .options.find(
              (f) =>
                f.id ==
                item.categories.find((x) => x.priority == "primary").response,
            );

          if (primary?.subfactors?.length > 0) {
            primary = primary.subfactors.find(
              (f) =>
                f.id ==
                item.categories.find((x) => x.priority == "primary").subfactor,
            ).name;
          } else {
            primary = primary.name;
          }

          let secondary = categories.categories
            .find((f) => f.priority == "secondary")
            .options.find(
              (f) =>
                f.id ==
                item.categories.find((x) => x.priority == "secondary").response,
            )?.name;

          item.comments
            .filter((f, i) => filter_comments_by_id(f, selected, i))
            .map((c) => {
              let sent = sentiment.analyze(c.response);
              let sent_score =
                sent.calculation.length > 0
                  ? sent.score / sent.calculation.length
                  : sent.score;
              let risk = 50 - score * 5 + (sent_score * -1 + 5) * 5;

              _data.push({
                text: c.response ? c.response : c.feedback,
                primary,
                secondary,
                risk: Math.floor(risk),
                sentiment: sent_score,
              });
            });
        });
      return _data.sort((a, b) => b.risk - a.risk).filter((f) => f.text);
    }
    return _data;
  };

  const combine_follow_up = (f) => {
    let _data = [];
    f.map((item) => {
      item.feedback.map((feedback, i) => {
        if (feedback.prompt_feedback && _data.length > 0) {
          _data[_data.length - 1]["feedback"]["follow_up_question"] =
            feedback.prompt_question;
          _data[_data.length - 1]["feedback"]["follow_up"] = feedback.feedback;
        } else {
          _data.push({
            feedback: feedback,
            categories: item.categories,
            follow_up: feedback.follow_up,
            follow_up_question: feedback.follow_up_question,
          });
        }
      });
    });

    return _data;
  };

  const getFeedbackContext = () => {
    return combine_follow_up(
      filter_data[0]
        .filter((f) => "feedback" in f)
        .filter((f) => f.feedback.length > 0),
    );
  };

  const [calculated, setCalculated] = useState(getFeedback());
  const [page, setPage] = useState(1);
  const [feedback, setFeedback] = useState(getFeedbackContext());

  useEffect(() => {
    setCalculated(getFeedback());
    setFeedback(getFeedbackContext());
  }, [filter_data]);

  useEffect(() => {
    if (feedback.length < 1) {
      setTab(2);
    }
  }, [feedback]);

  useEffect(() => {
    if (structure?.comments?.length > 0) {
      let _options = structure.comments.map((item, i) => {
        return {
          text: is_french ? item.fr : item.question,
          value: i,
        };
      });

      setOptions(_options);
      setSelected(_options[0].value);
    }
  }, [structure]);

  useEffect(() => {
    setCalculated(getFeedback());
  }, [selected]);

  const getSentiment = (sent) => {
    if (sent > 2) {
      return <Pillar>{language_hook("Positive")}</Pillar>;
    }
    if (sent < -2) {
      return <Pillar>{language_hook("Negative")}</Pillar>;
    }
    return <Label>{language_hook("Neutral")}</Label>;
  };

  const getRisk = (risk) => {
    if (risk > 70) {
      return (
        <>
          <Sentiment>
            {language_hook("High")} - {risk}%
          </Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#E5657A"} />
        </>
      );
    }
    if (risk > 50) {
      return (
        <>
          <Sentiment>
            {language_hook("Moderate")} - {risk}%
          </Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#F3AB3C"} />
        </>
      );
    }
    return (
      <>
        <Sentiment>
          {language_hook("Low")} - {risk}%
        </Sentiment>
        <StyledProgressBar percent={risk} size="tiny" color={"#66D686"} />
      </>
    );
  };

  return (
    <Container>
      {selected2 == 1 ? (
        <Context
          data={feedback}
          categories={categories}
          structure={structure}
        />
      ) : (
        <>
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Title>Question</Title>
            <Dropdown
              onChange={(e, t) => setSelected(t.value)}
              value={selected}
              selection
              options={options}
            />
          </div>
          <div style={{ width: "100%", marginTop: 20 }}>
            <Header>
              <TH width={50}>{language_hook("Feedback")}</TH>
              <TH width={20}>
                {is_french
                  ? categories?.categories.find((f) => f.priority == "primary")
                      .fr
                  : categories?.categories.find((f) => f.priority == "primary")
                      .name}
              </TH>

              <TH width={20}>{language_hook("Risk")}</TH>

              <TH width={10}>Sentiment</TH>
            </Header>

            {calculated.slice((page - 1) * 10, page * 10).map((item, i) => {
              return (
                <TR>
                  <TD width={50}>{item.text}</TD>

                  <TD width={20}>
                    <Team>
                      <Image src={TeamIcon} />
                      {item.primary}
                    </Team>
                  </TD>

                  <TD width={20}>{getRisk(item.risk)}</TD>

                  <TD width={10}>
                    <Pillar
                      color={
                        item.sentiment > 2
                          ? "#66D686"
                          : item.sentiment < -2
                            ? "#E66579"
                            : "white"
                      }
                    >
                      {item.sentiment > 2
                        ? language_hook("Positive")
                        : item.sentiment < -2
                          ? language_hook("Negative")
                          : language_hook("Neutral")}
                    </Pillar>
                  </TD>
                </TR>
              );
            })}
          </div>
          <div
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Pagination
              activePage={page}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              totalPages={Math.ceil(calculated.length / 10)}
              onPageChange={(e, { activePage }) => setPage(activePage)}
              size="mini"
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default Feedback;

const Container = styled.div`
  width: 100%;
  padding-left: 58px;
`;

const First = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-top: 1px solid black;
  height: 30px;
  align-items: center;
  padding-left: 5px;
`;

const Title = styled.div`
  margin-top: 20px;

  margin-bottom: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #414141;
`;
const Selection = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Barlow Semi Condensed", sans-serif;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  background-color: ${(props) => (props.selected ? "#2d50e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d50e2")};
  border: 1px solid #2d50e2;
`;
const Header = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  padding: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
`;

const TR = styled.div`
  width: 100%;
  padding: 18px 27px;
  font-family: "Poppins";
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 5px;
  align-items: center;
`;

const TD = styled.div`
  word-wrap: break-word;
  width: ${(props) => props.width + "%"};
  padding-right: 10px;
`;

const Image = styled.img`
  margin-right: 10px;
`;

const Team = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #7e7e7e;
  align-items: center;
`;

const Pillar = styled.div`
  width: 82px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.color == "white" ? "1px solid #DFDFDF" : "none")};
  color: ${(props) => (props.color == "white" ? "#6D6D6D" : "white")};
  border-radius: 100px;
  margin-left: 10px;
`;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color + " !important"};
    border-radius: 15px !important;
  }
  border-radius: 15px !important;
`;

const Sentiment = styled.div``;
const tagOptions = [
  {
    key: "Important",
    text: "Mental health and well-being",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Phsyical health and well-being",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Psychological safety",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Phsyical safety and Safe sport",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Self-determination",
    value: "Enhancement",
  },
];

const tagOptions2 = [
  {
    key: "Important",
    text: "Leadership",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Coaching",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Daily training environment",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Sport science and sport medicine",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Pathways and performance",
    value: "Enhancement",
  },
  {
    key: "Self-determination",
    text: "Athletes and international results",
    value: "Enhancement",
  },
];

const Items = [
  "DEI Overall",
  "Person Dimension",
  "Mental health and well-being",
  "Phsyical health and well-being",
  "Psychological safety",
  "Phsyical safety and Safe sport",
  "Self-determination",
  "Performance Dimension",
  "Leadership",
  "Coaching",
  "Daily training environment",
  "Sport science and sport medicine",
  "Pathways and performance",
  "Athletes and international results",
];
