import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ADD_GLOBAL_SCHEDULE,
  ALL_ERRORS,
  DELETE_GLOBAL_SCHEDULE,
  EDIT_GLOBAL_SCHEDULE,
  FETCH_GLOBAL_SCHEDULE,
  GET_GLOBAL_SCHEDULES,
  SG_ADD_GLOBAL_SCHEDULE,
  SG_DELETE_GLOBAL_SCHEDULE,
  SG_EDIT_GLOBAL_SCHEDULE,
  SG_FETCH_GLOBAL_SCHEDULE,
  SG_GET_GLOBAL_SCHEDULES,
  UPDATED_GLOBAL_SCHEDULES,
} from "common/constants/actions";

function* loadglobalSchedules(action) {
  try {
    const json = yield call(api.GET_GLOBAL_SCHEDULES, action.payload);
    yield put({ type: GET_GLOBAL_SCHEDULES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* globalScheduleLoad() {
  yield takeLatest(SG_GET_GLOBAL_SCHEDULES, loadglobalSchedules);
}

function* fetchglobalSchedules(action) {
  try {
    const json = yield call(api.FETCH_GLOBAL_SCHEDULE, action.payload);
    yield put({ type: FETCH_GLOBAL_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* globalScheduleFetch() {
  yield takeLatest(SG_FETCH_GLOBAL_SCHEDULE, fetchglobalSchedules);
}

function* updateglobalSchedules(action) {
  yield put({ type: UPDATED_GLOBAL_SCHEDULES });
  try {
    const json = yield call(api.EDIT_GLOBAL_SCHEDULE, action.payload);
    yield put({ type: EDIT_GLOBAL_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* globalScheduleUpdate() {
  yield takeEvery(SG_EDIT_GLOBAL_SCHEDULE, updateglobalSchedules);
}

function* addglobalSchedules(action) {
  yield put({ type: UPDATED_GLOBAL_SCHEDULES });
  try {
    const json = yield call(api.ADD_GLOBAL_SCHEDULE, action.payload);
    yield put({ type: ADD_GLOBAL_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* globalScheduleAdd() {
  yield takeLatest(SG_ADD_GLOBAL_SCHEDULE, addglobalSchedules);
}

function* deleteglobalSchedules(action) {
  try {
    const json = yield call(api.DELETE_GLOBAL_SCHEDULE, action.payload);
    yield put({ type: DELETE_GLOBAL_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* globalScheduleDelete() {
  yield takeLatest(SG_DELETE_GLOBAL_SCHEDULE, deleteglobalSchedules);
}

export default function* index() {
  yield all([
    globalScheduleLoad(),
    globalScheduleFetch(),
    globalScheduleUpdate(),
    globalScheduleAdd(),
    globalScheduleDelete(),
  ]);
}
