import { all, call, put, takeEvery } from "redux-saga/effects";

import * as api from "../common/constants/api";

import {
  CREATE_CHAT_ROOM,
  DELETE_CHAT_ROOM,
  FETCH_CHAT_ROOM,
  FETCH_FAILURE,
  GET_CHAT_ROOM,
  ROOM_LOADING,
  SG_CREATE_CHAT_ROOM,
  SG_DELETE_CHAT_ROOM,
  SG_FETCH_CHAT_ROOM,
  SG_GET_CHAT_ROOM,
} from "../common/constants/actions";

function* getProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.getChatRooms, action.payload);
    yield put({ type: GET_CHAT_ROOM, payload: json.data });
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* getSaga() {
  yield takeEvery(SG_GET_CHAT_ROOM, getProcess);
}

function* fetchProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.fetchChatRooms, action.payload);
    yield put({ type: FETCH_CHAT_ROOM, payload: json.data });
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* fetchSaga() {
  yield takeEvery(SG_FETCH_CHAT_ROOM, fetchProcess);
}

function* createProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.createChatRoom, action.payload);
    yield put({ type: CREATE_CHAT_ROOM, payload: json.data });
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* createSaga() {
  yield takeEvery(SG_CREATE_CHAT_ROOM, createProcess);
}

function* deleteProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.DELETE_CHAT_ROOM, action.payload);
    yield put({ type: DELETE_CHAT_ROOM, payload: action.payload });
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* deleteSaga() {
  yield takeEvery(SG_DELETE_CHAT_ROOM, deleteProcess);
}

export default function* index() {
  yield all([getSaga(), fetchSaga(), createSaga(), deleteSaga()]);
}
