import React from "react";
import styled from "styled-components";

const CustomMessagesList = {
  "The fields organization, email must make a unique set.": {
    cause: "The email id has already been used and cannot be used",
    userMessage: "This email id has already been used by another employee",
    nextSteps:
      "If you are looking to modify some information about the user, try updating the details",
  },
  // Add more custom messages here
};

export function CustomMessages({ message }) {
  if (CustomMessagesList.hasOwnProperty(message)) {
    const { userMessage, nextSteps } = CustomMessagesList[message];

    return (
      <ErrorMessageContainer>
        <p>{userMessage}</p>
        <p>{nextSteps}</p>
      </ErrorMessageContainer>
    );
  }

  return <ErrorMessageContainer>{message}</ErrorMessageContainer>;
}
const ErrorMessageContainer = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`;
