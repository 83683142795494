import React, { useContext, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Message } from "semantic-ui-react";
import { useLocation } from "react-router-dom";

import { DomainContext } from "common/layouts/LayoutContext";
import format from "date-fns/format";

import { SG_LOG_ERRORS } from "common/constants/actions";
import { CustomMessages } from "./Message";

export const BasicErrorMessage = () => {
  const dispatch = useDispatch();
  const Domain = useContext(DomainContext);
  const get_errors = useSelector((state) => state.errors, shallowEqual);
  const [submitError, setSubmitError] = useState(false);

  const [errorDetails, setErrorDetails] = useState(false);

  const usePrevLocation = (location) => {
    const prevLocRef = useRef(location);
    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);
    return prevLocRef.current;
  };
  // const titleRef = useRef();
  // console.log(titleRef)
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  useEffect(() => {
    if (get_errors?.errors?.request?.response) {
      setErrorDetails(get_errors?.errors?.request?.response);
    }
  }, [get_errors.errors]);

  useEffect(() => {
    if (!Domain?.debug) {
      if (errorDetails) {
        dispatch({
          type: SG_LOG_ERRORS,
          payload: {
            location: `Error in ${Domain.name} - ${Domain.url} - ${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}`,
            request: prevLocation?.pathname || "No details found",
            page: prevLocation?.pathname,
            description: errorDetails,
            extra_content: JSON.stringify(errorDetails),
          },
        });
      }
    }
  }, [dispatch, errorDetails, prevLocation, Domain]);

  useEffect(() => {
    if (get_errors?.errors?.request?.response) {
      const ErrorStatus = get_errors?.errors?.request?.status;
      if (ErrorStatus === 500) {
        setSubmitError([
          "Server Error! we have notified support this issue, please try again after closing this window",
        ]);
        return;
      }
      try {
        let RESP = JSON.parse(get_errors?.errors?.request?.response);

        setSubmitError(Object.values(RESP));
        return;
      } catch {
        console.log("could not log the error");
      }
    }
  }, [get_errors]);

  function ReLoadPAge() {
    window.location.reload();
  }

  if (submitError) {
    return (
      <Message onDismiss={ReLoadPAge} color="red">
        There was an error, <br />
        {submitError.map((se) => (
          <CustomMessages key={se} message={se} />
        ))}
      </Message>
    );
  }
  return null;
};

export default BasicErrorMessage;
