export const reverseScoring = {
  0: 10,
  1: 9,
  2: 8,
  3: 7,
  4: 6,
  5: 5,
  6: 4,
  7: 3,
  8: 2,
  9: 1,
  10: 0,
};

export const getGrade = (avgScore, reverse = false) => {
  const score = reverse ? 100 - avgScore : avgScore;

  if (score >= 90) {
    return "A+";
  }
  if (score >= 85) {
    return "A";
  }
  if (score >= 80) {
    return "A-";
  }
  if (score >= 77) {
    return "B+";
  }
  if (score >= 73) {
    return "B";
  }
  if (score >= 70) {
    return "B-";
  }
  if (score >= 67) {
    return "C+";
  }
  if (score >= 63) {
    return "C";
  }
  if (score >= 60) {
    return "C-";
  }
  if (score >= 50) {
    return "D";
  }

  return "F";
};

export const getAggregatedScore = (responseObj, factor, questions) => {
  const questionObj = questions?.factors
    ?.find((factorObj) => factorObj.id === factor)
    ?.questions.find((question) => question.id === responseObj.ques_order);

  return questionObj?.reverse
    ? reverseScoring[responseObj.response]
    : responseObj.response;
};

export const getGroupResponseId = (group, get_survey_structure, categoryId) => {
  const tertiaryGroup = get_survey_structure.survey_questions.find(
    (questionGroup) => {
      return questionGroup.id === categoryId;
    }
  );

  const groupResponseId = tertiaryGroup?.answers.find(
    (answer) => answer.name === group
  )?.id;
  return groupResponseId;
};

export const getAverageForQuestion = (
  question,
  group = null,
  primaryFilter,
  secondaryFilter,
  recentResponses,
  factorId,
  categoryId
) => {
  const primaryDataSet = recentResponses.find((data) => {
    return data.name === primaryFilter;
  });

  if (primaryDataSet) {
    let currentScores = [];

    primaryDataSet.responses.forEach((responseSet) => {
      let isValid = true;

      if (secondaryFilter) {
        const validRespondant =
          responseSet.response.categories.find(
            (c) => c.priority === "secondary"
          ).response === factorId;
        isValid = validRespondant;
      }

      if (group) {
        const groupResponseId = getGroupResponseId(group);
        const subValidator = responseSet.response.categories.find(
          (category) => category.id === categoryId
        );

        const isValidRespondant = subValidator.response === groupResponseId;

        if (
          isValidRespondant &&
          responseSet.response.questions.find(
            (f) => f.ques_order === question.id
          ).response &&
          isValid
        ) {
          currentScores.push(
            responseSet.response.questions.find(
              (f) => f.ques_order === question.id
            ).response
          );
        }
      } else {
        if (
          responseSet.response.questions.length > 0 &&
          responseSet.response.questions.find(
            (f) => f.ques_order === question.id
          ).response &&
          isValid
        ) {
          currentScores.push(
            responseSet.response.questions.find(
              (f) => f.ques_order === question.id
            ).response
          );
        }
      }
    });

    if (currentScores.length > 0) {
      const sum = currentScores.reduce((a, b) => a + b, 0);
      const avg = sum / currentScores.length || 0;
      return {
        avg: avg.toFixed(1),
        distribution: currentScores,
      };
    }
  }
  return {
    avg: 0,
    distribution: [],
  };
};

export const getAverageForGroup = (
  responses,
  group,
  categoryId,
  surveyStructure,
  factorId
) => {
  const groupResponseId = getGroupResponseId(
    group,
    surveyStructure,
    categoryId
  );

  if (responses) {
    const primaryDataSet = responses[0];

    if (primaryDataSet) {
      let currentScores = [];
      primaryDataSet.responses.forEach((responseSet) => {
        let isValid = true;

        if (isValid) {
          const subValidator = responseSet.response.categories.find(
            (category) => category.id === categoryId
          );

          const isValidRespondant = subValidator.response === groupResponseId;
          if (isValidRespondant) {
            responseSet.response.questions.forEach((questionResponse) => {
              if (questionResponse.factor === factorId - 1) {
                const score = getAggregatedScore(questionResponse, factorId);
                currentScores.push(score);
              }
            });
          }
        }
      });

      if (currentScores.length > 0) {
        const sum = currentScores.reduce((a, b) => a + b, 0);
        const avg = sum / currentScores.length || 0;
        return avg.toFixed(1);
      }
    }
  }
  return 0;
};

/**
 * Gets the avg for the specified factor.
 * This can be extended to get previous responses (for change)
 * @param {number} factor current factor to get avg.
 *
 * @returns the factor average (for all questions) used on factor averages.
 */
export const getFactorAverage = (factorIndex, responses, surveyQuestions) => {
  const dataSet = responses;
  if (dataSet) {
    let currentScores = [];
    dataSet.forEach((responseGroup) => {
      responseGroup.responses?.forEach((responseSet) => {
        responseSet.response.questions.forEach((questionResponses) => {
          if (factorIndex === questionResponses.factor) {
            const score = getAggregatedScore(
              questionResponses,
              Number(factorIndex),
              surveyQuestions
            );
            currentScores.push(score);
          }
        });
      });
    });

    if (currentScores.length > 0) {
      return getAvg(currentScores);
    }
  }
  return 0;
};

export const getFactorNValue = (factorIndex, responses, factorQuestions) => {
  const dataSet = responses;
  const numQuestions = factorQuestions?.factors[factorIndex]?.questions.length;
  let nCount = 0;
  if (dataSet) {
    dataSet.forEach((responseGroup) => {
      responseGroup.responses?.forEach((responseSet) => {
        responseSet.response.questions.forEach((questionResponses) => {
          if (factorIndex === questionResponses.factor) {
            nCount = nCount + 1;
          }
        });
      });
    });

    return nCount / numQuestions;
  }
  return nCount;
};

export const getAvg = (currentScores) => {
  const sum = currentScores.reduce((a, b) => a + b, 0);
  const avg = sum / currentScores.length || 0;

  return avg * 10;
};

export const getDeiAvg = (sections, responseData, questions) => {
  let factorAvg = [];
  sections.forEach((section) => {
    factorAvg.push(
      getFactorAverage(
        getFactorIndex(section.title, questions),
        responseData,
        questions
      )
    );
  });

  return (getAvg(factorAvg) / 10).toFixed(1);
};

/**
 *
 * @param {string} factor
 * returns the index for the given factor
 */

export const getFactorIndex = (factor, questions, surveyQuestions) => {
  if (questions) {
    return questions?.factors?.findIndex((factorObj) => {
      return factorObj.title.toLowerCase() === factor.toLowerCase();
    });
  } else if (surveyQuestions[0]) {
    return surveyQuestions[0]?.questions?.factors?.findIndex((factorObj) => {
      return factorObj.title.toLowerCase() === factor.toLowerCase();
    });
  }
};

/**
 * getTopRisks
 * retrieves a list of risks/strengths.
 *
 * @param {array} factors list of factors
 * @param {array} questions list of questions
 * @param {array} responses list of responses
 * @param {number} size number to indicate slice (or size) of returned array
 * @param {string} primary id of primary to filter group responses.
 * @param {bool} overviewReport boolean value to determine whether report is being generated by calendar or overview.
 *
 *
 * @returns {object} {risks: arrayOfRisks, strengths: arrayOfStrengths}
 */
export const getTopRisks = (
  structure,
  factors,
  responses,
  size,
  primary,
  overviewReport
) => {
  // First find the matching anchor
  const anchorList = overviewReport
    ? structure
    : structure.filter((set) => set.priority.toLowerCase() !== "primary");

  let risks = [];
  let strengths = [];
  let all = [];

  // Using the foundAnchor options, loop through the responses, and group
  // responses by matching anchors.
  anchorList.forEach((foundAnchor) => {
    const optionsResponses = foundAnchor.options.map((option) => {
      const groupId = option.id;
      // Group to represent current anchor option.
      const groupData = responses[0].responses.filter((respondant) => {
        // TODO: Add primary filter within this filter.
        const validRespondant = respondant.response.categories.find(
          (category) => category.response === groupId
        );
        return !!validRespondant;
      });

      return {
        anchorOption: groupId,
        anchorName: option.name,
        responses: groupData,
      };
    });

    // Empty array to store objects containing factor responses.
    let factorResponses = [];

    // Loop through each factor to calculate responses.
    factors.forEach((factor) => {
      // Loop through each of the questions for the above factor
      const questionData = factor.questions.map((question) => {
        // Find the responses for the matching response group
        const responsesSorted = optionsResponses.map(
          (matchingResponseGroup) => {
            // Empty array to store question distribution.
            let questionDistribution = [];
            // Loop through matching response groups to get the aggregated responses.
            matchingResponseGroup?.responses?.forEach((respondant) => {
              const response = respondant?.response?.questions.find(
                (q) => q.ques_order === question.id
              )?.response;
              questionDistribution.push(response);
            });

            let currentAverage = getArrayAverage(questionDistribution);

            return {
              group: matchingResponseGroup?.anchorOption,
              anchorName: matchingResponseGroup?.anchorName,
              distribution: questionDistribution,
              average: currentAverage,
            };
          }
        );

        return {
          question: question.q,
          reverse: question.reverse,
          data: responsesSorted.sort((a, b) => a.average - b.average),
        };
      });

      factorResponses.push({
        factor: factor.title,
        summary: questionData,
      });
    });

    // Take every averaged response and push it to the all array
    // this will allow us to easily sort the data, then slice it based on the
    // number of risks/strengths we are trying to retrieve.
    factorResponses.forEach((factor) => {
      factor.summary.forEach((responseSummary, i) => {
        responseSummary.data.forEach((group) => {
          all.push({
            factor: factor.factor,
            group: group.group,
            anchorName: group.anchorName,
            anchorType: foundAnchor.name,
            distribution: group.distribution,
            average: group.average,
            question: responseSummary.question,
          });
        });
      });
    });
  });

  // Wrapping the array with a [...arr] allows us to create a copy
  // without affecting the value when we sort.
  risks = [...all.sort((a, b) => a.average - b.average)];
  strengths = [...all.sort((a, b) => b.average - a.average)];

  return {
    risks: risks.slice(0, size),
    strengths: strengths.slice(0, size),
  };
};

export const getArrayAverage = (array) => {
  const n = array.length;
  if (n === 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => Number(a) + Number(b)) / n;

  return mean;
};

export const retrieveDemographics = (
  responseData,
  categories,
  secondaryFilter
) => {
  if (categories.length > 0) {
    return categories.map((cat) => {
      const groupStats = [];
      cat.options.forEach((option) => {
        let groupNum = 0;
        responseData.forEach((responseGroup) => {
          responseGroup.responses.forEach((response) => {
            const found = response?.response?.categories?.find((c) => {
              return c.response === option.id;
            });
            if (found) {
              groupNum += 1;
            }
          });
        });
        const groupStatsObj = {
          name: option.name,
          id: option.id,
          total: groupNum,
        };

        groupStats.push(groupStatsObj);
      });
      return {
        category: cat.name,
        categoryId: cat.id,
        data: groupStats,
      };
    });
  }
};

export const getDepScores = (data, factorId) => {
  let department = [];
  let departmentScores = data.map((responseGroup) => {
    if (department.includes(responseGroup.name)) {
      return null;
    }
    const currentDep = responseGroup.name;
    department.push(currentDep);

    return data.map((group) => {
      if (group.name === currentDep) {
        const primaryDataSet = group.responses;
        if (primaryDataSet) {
          let currentScores = [];
          primaryDataSet.forEach((responseSet) => {
            let isValid = true;
            if (isValid) {
              const isValidRespondant = true;
              if (isValidRespondant) {
                responseSet.response.questions.forEach((questionResponse) => {
                  if (questionResponse.factor === factorId - 1) {
                    const score = getAggregatedScore(
                      questionResponse,
                      factorId
                    );
                    currentScores.push(Number(score));
                  }
                });
              }
            }
          });

          if (currentScores.length > 0) {
            const sum = currentScores.reduce((a, b) => a + b, 0);
            const avg = sum / currentScores.length || 0;
            return Math.floor(avg * 10);
          }
        }
      }
    });
  });

  const filteredScores = departmentScores.filter((score) => score !== null);
  return filteredScores.map((group, i) => {
    return {
      group: department[i],
      score: getArrayAverage(group),
    };
  });
};
