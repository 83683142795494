import React from "react";
import styled from "styled-components";
import {
  fontSizes,
  inactiveColor,
  primaryColor,
} from "../../areas/analytics/styles/styleConfig";

const Tabs = (props) => {
  const { onSelect, selected, options, isOtp, showTabs } = props;
  const tabOptions =
    isOtp && !showTabs ? ["Profile", "Category Options"] : options;
  return (
    <Container>
      {tabOptions.map((item, i) => {
        return (
          <Tab key={i} selected={selected === i} onClick={() => onSelect(i)}>
            <TabText>{item}</TabText>
          </Tab>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1px;
  padding-bottom: 20px;
`;

const Tab = styled.div`
  margin-right: 50px;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${primaryColor}` : ""};
  font-style: normal;
  font-weight: 300;
  font-size: ${fontSizes.medium};
  line-height: 15px;
  cursor: pointer;
  flex-direction: row;
  color: ${(props) =>
    props.selected ? `${primaryColor}` : `${inactiveColor}`};
`;

const TabText = styled.div`
  margin-bottom: 5px;
`;

export default Tabs;
