import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Table } from "semantic-ui-react";

import format from "date-fns/format";
import { Link } from "react-router-dom";

import { SG_GET_SURVEY_COMPLIANCES } from "common/constants/actions";

const SurveyStatus = ({ organizationalId }) => {
  const dispatch = useDispatch();
  // const [activeIndex, setActiveIndex] = React.useState(-1);

  // useEffect(() => {
  //   dispatch({
  //     type: SG_GET_SURVEY_COMPLIANCES,
  //     payload: "limit=30",
  //   });
  // }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_COMPLIANCES,
      payload: `limit=30${
        organizationalId ? `&organization=${organizationalId}` : ""
      }`,
    });
  }, [dispatch, organizationalId]);

  const { get_survey_compliances } = useSelector(
    (state) => ({
      get_survey_compliances: state.surveycompliance.survey_compliances,
    }),
    shallowEqual,
  );

  // const handleClick = (e, titleProps) => {
  //   const { index } = titleProps;
  //   const newIndex = activeIndex === index ? -1 : index;

  //   setActiveIndex(newIndex);
  // };

  // console.log(surveyCompliances);
  return (
    <Container>
      <Title>Survey Status</Title>
      <Description>
        Check on the schedule and the status of survey's. Click to see the
        completion status of delivered survey's
      </Description>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Scheduled</Table.HeaderCell>
            <Table.HeaderCell>Close </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {get_survey_compliances.slice(0, 9).map((item, i) => {
            if (
              item.status_text === "In Session" ||
              item.status_text === "Scheduled"
            ) {
              return (
                <Table.Row key={i}>
                  <Table.Cell>
                    {item.status_text === "In Session" ? (
                      <Link to={"/app/team/team-survey-status"}>
                        {item.status_text}
                      </Link>
                    ) : (
                      item.status_text
                    )}
                  </Table.Cell>
                  <Table.Cell>{item.survey_type_text}</Table.Cell>
                  <Table.Cell>
                    {format(
                      new Date(item.scheduled_at.toLocaleString()),
                      "EEEE, MMM do h:mm a",
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {format(
                      new Date(item.close_at.toLocaleString()),
                      "EEEE, MMM do h:mm a",
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            }
            return null;
          })}
        </Table.Body>
      </Table>
    </Container>
  );
};

export default SurveyStatus;

const Container = styled.div`
  z-index: -1;
  margin-top: 50px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 20px;
  }
`;

const Description = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 22px;
  margin-top: 10px;
`;
