import React from "react";

function CategoryIcon() {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9978 1.10001H2.49775C1.77978 1.10001 1.19775 1.68204 1.19775 2.40001V2.80001C1.19775 3.51798 1.77978 4.10001 2.49775 4.10001H19.9978C20.7157 4.10001 21.2978 3.51798 21.2978 2.80001V2.40001C21.2978 1.68204 20.7157 1.10001 19.9978 1.10001Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
      <path
        d="M19.9977 6.60001H5.89766C5.17969 6.60001 4.59766 7.18204 4.59766 7.90001V8.3C4.59766 9.01798 5.17969 9.60001 5.89766 9.60001H19.9977C20.7156 9.60001 21.2977 9.01798 21.2977 8.3V7.90001C21.2977 7.18204 20.7156 6.60001 19.9977 6.60001Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
      <path
        d="M19.9977 12.1H12.1977C11.4797 12.1 10.8977 12.682 10.8977 13.4V13.8C10.8977 14.518 11.4797 15.1 12.1977 15.1H19.9977C20.7157 15.1 21.2977 14.518 21.2977 13.8V13.4C21.2977 12.682 20.7157 12.1 19.9977 12.1Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default CategoryIcon;
