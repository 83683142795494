import {
  ADD_AMBASSADOR,
  CLEAR_ALL,
  CLEAR_AMBASSADOR,
  DELETE_AMBASSADOR,
  EDIT_AMBASSADOR,
  FETCH_AMBASSADOR,
  GET_AMBASSADORS,
  UPDATE_AMBASSADOR_PIC,
  VALIDATE_AMBASSADOR,
} from "common/constants/actions";

const initialState = {
  ambassadors: [],
  ambassador_pics: [],
  validateEmail: [],
  pending: false,
};

export default function Ambassador(state = initialState, action) {
  switch (action.type) {
    case GET_AMBASSADORS:
      return {
        ...state,
        ambassadors: action.payload,
        pending: false,
      };
    case UPDATE_AMBASSADOR_PIC:
      return {
        ...state,
        ambassador_pics: action.payload,
        [action.payload.id]: {
          ...state[action.payload.id],
          picture: action.payload.picture,
        },
        pending: false,
      };
    case VALIDATE_AMBASSADOR:
      return {
        ...state,
        validateEmail: action.payload,
        pending: false,
      };

    case FETCH_AMBASSADOR:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_AMBASSADOR:
      // if (state.ambassadors.filter((an) => an.id === action.payload.id)[0]) {
      //   state = {
      //     ...state,
      //     ambassadors: state.ambassadors.filter(
      //       (emp) => emp.id !== action.payload.id
      //     ),
      //   };
      // }
      // console.log("reducer", action)
      return {
        ...state,
        [action.payload.id]: action.payload,
        // ambassadors: [...state.ambassadors, action.payload],
      };
    case DELETE_AMBASSADOR:
      return {
        ...state,
        ambassadors: state.ambassadors.filter(
          (row) => row.id !== action.payload,
        ),
      };

    case ADD_AMBASSADOR:
      return {
        ...state,
        created: action.payload,
        ambassadors: [...state.ambassadors, action.payload],
      };

    case CLEAR_AMBASSADOR:
      return {
        ...state,
        ambassadors: [],
        validateEmail: [],
        ambassador_pics: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ambassadors: [],
        validateEmail: [],
        ambassador_pics: [],
        pending: false,
      };

    default:
      return state;
  }
}
