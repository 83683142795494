import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  ALL_ERRORS,
  FETCH_MOOD,
  GET_EMOTIONS,
  GET_MOOD_ORG,
  GET_MOOD_ORG_REPORT,
  GET_MOOD_PER,
  GET_MOOD_TEAMS,
  GET_MOODS,
  LOAD_EMOTIONS,
  SG_FETCH_MOOD,
  SG_GET_MOOD_ORG,
  SG_GET_MOOD_ORG_REPORT,
  SG_GET_MOOD_PER,
  SG_GET_MOOD_TEAMS,
  SG_GET_MOODS,
} from "common/constants/actions";

import * as api from "common/constants/api";
import emotion_data from "common/data/emotions.json";

function* getMoods(action) {
  try {
    const json = yield call(api.GET_MOOD_REPORTS, action.payload);
    yield put({ type: GET_MOODS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMoods() {
  yield takeLatest(SG_GET_MOODS, getMoods);
}

function* getMoodTeams(action) {
  try {
    const json = yield call(api.GET_MOOD_REPORTS, action.payload);
    yield put({ type: GET_MOOD_TEAMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMoodTeams() {
  yield takeLatest(SG_GET_MOOD_TEAMS, getMoodTeams);
}

function* fetchCultureData(action) {
  try {
    const json = yield call(api.FETCH_MOOD_REPORT, action.payload);
    yield put({ type: FETCH_MOOD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* cultureDataFetch() {
  yield takeLatest(SG_FETCH_MOOD, fetchCultureData);
}

function* getEmotions(action) {
  try {
    // const json = yield call(api.GET_MOODS, action.payload);
    // yield put({ type: GET_EMOTIONS, payload: json.data });
    yield put({ type: GET_EMOTIONS, payload: emotion_data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadEmotions() {
  yield takeLatest(LOAD_EMOTIONS, getEmotions);
}

function* getMoodOrg(action) {
  try {
    const json = yield call(api.GET_MOOD_REPORTS, action.payload);
    yield put({ type: GET_MOOD_ORG, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMoodOrg() {
  yield takeLatest(SG_GET_MOOD_ORG, getMoodOrg);
}

function* getMoodOrgReport(action) {
  try {
    const json = yield call(api.GET_MOOD_ORG_REPORTS, action.payload);
    yield put({ type: GET_MOOD_ORG_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMoodOrgReport() {
  yield takeLatest(SG_GET_MOOD_ORG_REPORT, getMoodOrgReport);
}

function* getMoodPerReport(action) {
  try {
    yield put({ type: GET_MOOD_PER, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMoodPerReport() {
  yield takeLatest(SG_GET_MOOD_PER, getMoodPerReport);
}

export default function* index() {
  yield all([
    loadMoods(),
    loadMoodTeams(),
    loadEmotions(),
    loadMoodOrg(),
    loadMoodOrgReport(),
    loadMoodPerReport(),
  ]);
}
