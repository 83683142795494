import React from "react";
import styled from "styled-components";
import { filterColor, primaryColor } from "../../../styles/styleConfig";

export default function ConfirmButton(props) {
  const { buttonText, btnAlt, onClick } = props;

  return (
    <ButtonWrapper btnAlt={btnAlt} onClick={() => onClick()}>
      <ButtonText btnAlt={btnAlt}>{buttonText}</ButtonText>
    </ButtonWrapper>
  );
}

const ButtonWrapper = styled.div`
  width: 100%;
  height: 44px;
  background: ${(props) =>
    props.btnAlt ? filterColor.activeBackground : primaryColor};
  border-radius: 8px;
  margin-right: 16px;
  cursor: pointer;
`;

const ButtonText = styled.div`
  padding: 12px 24px;
  color: ${(props) => (props.btnAlt ? filterColor.activeText : "white")};
`;
