import styled from "styled-components";
import React from "react";
import { Dimmer, Loader, Select } from "semantic-ui-react";
import TeamIcon from "assets/images/nav/team.svg";
import { build_default_State } from "../../../common/calculations/analytics";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  SET_NAVIGATION,
  SET_SELECTED_FILTERS,
  SET_SELECTED_QUESTION,
  SET_SUB_NAV,
} from "common/constants/actions";
import Languages from "common/utils/language-utils/index";
import { useLanguageSetting } from "common/hooks/useLanguageSetting";
import { calculate_heat_data } from "../../../common/calculations/heatmap";

function heatMapColorforValue(value) {
  var h = (1.0 - value) * 240;
  return "hsl(" + h + ", 100%, 43%)";
}

function rgb(value) {
  let red = 245;
  let green = 47;
  let blue = 47;

  if (value < 5) {
    return (
      "rgb(" +
      Math.floor(red) +
      "," +
      Math.floor(green) +
      "," +
      Math.floor(blue) +
      ")"
    );
  }
  let remainder = value - 5;
  green = remainder > 2.143 ? 245 : green + remainder * 92.4;
  red =
    remainder > 4.286
      ? 47
      : remainder > 2.143
        ? red - (remainder - 2.143) * 92.4
        : red;
  blue = remainder - 4.286 > 0 ? blue + (remainder - 4.286) * 92.4 : blue;

  return (
    "rgb(" +
    Math.floor(red) +
    "," +
    Math.floor(green) +
    "," +
    Math.floor(blue) +
    ")"
  );
}

const SquareComponent = ({ width, color, opacity, score, onClick, type }) => {
  const [hover, setHover] = React.useState(false);

  const clicked = () => {
    if (type == "questions") {
      onClick();
    }
  };

  return (
    <Square
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      width={width}
      color={color}
      hover={hover}
      opacity={opacity}
      onClick={() => clicked()}
    >
      {score.toFixed(1) * 10}
    </Square>
  );
};

const MAX_WIDTH = "10%";

const create_option_list = (categories, questions, type, isFrench) => {
  let options = [];
  if (type == 2) {
    let dimensions = questions.dimensions.length;
    if (dimensions != 1) {
      questions.dimensions[1].priority = "questions";
      let title = isFrench
        ? questions.dimensions[1].fr
        : questions.dimensions[1].title;
      options.push({ text: title, value: questions.dimensions[1] });
    }
    questions.dimensions[0].priority = "questions";
    let title = isFrench
      ? questions.dimensions[0].fr
      : questions.dimensions[0].title;
    options.push({ text: title, value: questions.dimensions[0] });
  }

  categories.categories.map((item) => {
    if (type == 1 || (type == 2 && item.priority != "primary")) {
      let title = isFrench ? item.fr_name : item.name;
      options.push({ text: title, value: item });
    }
  });

  return options;
};

const configure = (structure) => {
  let ids = 0;
  let names = [{ title: "Overall DEI", level: 0, id: ids }];
  ids += 1;
  if (structure?.dimensions) {
    structure?.dimensions.map((item, i) => {
      names.push({ title: item.title, level: 1, id: ids, dimension: i });
      ids += 1;
      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
  }

  return names;
};

const HeatMap = ({
  data,
  categories,
  anchor,
  questions,
  nav,
  rule,
  data2,
  color,
}) => {
  const dispatch = useDispatch();
  const language_hook = new Languages(useLanguageSetting());
  const COLOR = "#3238a8";
  const [titles, setTitles] = React.useState([]);
  const [width, setWidth] = React.useState(3);
  const [loading, setLoading] = React.useState(true);
  const anchor_list = create_option_list(
    categories,
    questions,
    1,
    language_hook.is_french(),
  );
  const anchor_list2 = create_option_list(
    categories,
    questions,
    2,
    language_hook.is_french(),
  );
  const [horizontal, setHorizontal] = React.useState(
    anchor_list2.find((f) => f.value.priority == "questions").value,
  );
  const [vertical, setVertical] = React.useState(
    anchor_list.find((f) => f.value.priority == "primary").value,
  );

  const [calcluated, setCalculated] = React.useState(
    calculate_heat_data(
      data2,
      nav,
      categories,
      anchor,
      rule,
      questions,
      horizontal,
      vertical,
    ),
  );
  const selected = useSelector((state) => state.audit?.filters, shallowEqual);

  const sort_titles = (a, b, _calculated2) => {
    let avg_1 = average(
      _calculated2.map((item) => {
        return item.role_data.find((f) => f.id == a.id)?.score;
      }),
    );

    let avg_2 = average(
      _calculated2.map((item) => {
        return item.role_data.find((f) => f.id == b.id)?.score;
      }),
    );

    return avg_2 - avg_1;
  };

  const sort_titles_questions = (a, b, _calculated2) => {
    let avg_1 = average(
      _calculated2.map((item) => {
        return item.role_data.find((f) => parseInt(f.id) == a.id - 1)?.score;
      }),
    );

    let avg_2 = average(
      _calculated2.map((item) => {
        return item.role_data.find((f) => parseInt(f.id) == b.id - 1)?.score;
      }),
    );

    return avg_2 - avg_1;
  };

  const handle_navigation = (navigation, select) => {
    let structure = configure(questions);
    let _selected = structure.find(
      (f) => f.dimension == select.id && f.factor == select.factor,
    );

    dispatch({
      type: SET_SELECTED_QUESTION,
      payload: _selected,
    });

    dispatch({
      type: SET_SUB_NAV,
      payload: 3,
    });

    if (navigation.subOption) {
      onSelect({
        id: navigation.parent,
        option: navigation.subOption,
        subfactor: navigation.id,
        name: { name: navigation.title },
      });
    } else {
      onSelect({
        id: navigation.parent,
        option: navigation.id,
        subfactor: null,
        name: { name: navigation.title },
      });
    }
  };

  const onSelect = (s) => {
    let check = selected.filter(
      (f) =>
        f.id === s.id && f.subfactor === s.subfactor && f.option === s.option,
    );
    let _selected;

    if (check.length > 0) {
      _selected = selected;
    } else {
      _selected = [...selected, s];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);

    new_selected.map((item, i) => {
      if (_selected[item.id].selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }

      if (item.subfactor != null) {
        _selected[item.id][item.option].subfactors[item.subfactor].selected =
          true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  React.useEffect(() => {
    let isQuestion = horizontal.priority == "questions";
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 600);

    let _question = JSON.parse(JSON.stringify(questions));

    _question.dimensions[0].factors.sort((a, b) => a.id - b.id);

    if (_question.dimensions.length > 1) {
      _question.dimensions[1].factors.sort((a, b) => a.id - b.id);
    }

    let _calculated = calculate_heat_data(
      data2,
      nav,
      categories,
      anchor,
      rule,
      _question,
      horizontal,
      vertical,
    );
    setCalculated(_calculated.sort((a, b) => sorted(a, b)));

    if (isQuestion) {
      setWidth(_question.dimensions[horizontal.id - 1].factors.length);
      setTitles(
        _question.dimensions[horizontal.id - 1].factors.sort((a, b) =>
          sort_titles_questions(a, b, _calculated),
        ),
      );
    } else {
      setWidth(
        categories.categories.find((f) => f.id == horizontal.id).options.length,
      );
      setTitles(
        categories.categories
          .find((f) => f.id == horizontal.id)
          .options.sort((a, b) => sort_titles(a, b, _calculated)),
      );
    }
  }, [vertical, horizontal, nav]);

  const getColor = (score) => {
    let range = score > 5 ? 1 - (score - 5) / 5 : 1;
    return rgb(score);
  };

  const getOpacity = (score) => {
    if (score > 9) {
      return 0.1;
    }
    if (score > 7.5) {
      return 0.2;
    }
    if (score > 6) {
      return 0.6;
    }
    if (score > 5) {
      return 0.9;
    }
    if (score <= 5) {
      return 1;
    }
  };

  const average = (arr) => {
    let sum = 0;
    let n = 0;

    arr.map((item) => {
      if (item) {
        sum += item;
        n += 1;
      }
    });

    if (n) {
      return sum / n;
    }

    return 0;
  };

  const sorted = (a, b) => {
    let avg_1 = average(a.role_data.map((item) => item.score));
    let avg_2 = average(b.role_data.map((item) => item.score));

    return avg_2 - avg_1;
  };

  const getFrench = (item) => {
    return categories.categories
      .find((f) => f.id == item.parent)
      .options.find((f) => f.id == item.id).fr;
  };

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
      <OptionArea>
        <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
          <Label>{language_hook.fetch_words("Vertical Anchors")}</Label>
          <StyledSelect
            placeholder="Select Survey Type"
            options={anchor_list}
            value={vertical}
            id="anchors"
            onChange={(e, t) => setVertical(t.value)}
          />
        </div>

        <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
          <Label>{language_hook.fetch_words("Horizontal Anchors")}</Label>
          <StyledSelect
            placeholder="Select Survey Type"
            options={anchor_list2}
            value={horizontal}
            id="anchors"
            onChange={(e, t) => setHorizontal(t.value)}
          />
        </div>
      </OptionArea>
      <TopRow>
        <div style={{ width: "150px" }} />
        {titles.map((item) => {
          return (
            <TH width={width}>
              {horizontal.priority == "questions"
                ? language_hook.is_french()
                  ? item.fr
                  : item.title
                : language_hook.is_french()
                  ? item.fr
                  : item.name}
            </TH>
          );
        })}
      </TopRow>

      {calcluated.map((item) => {
        return (
          <Row>
            <Title>
              <Image src={TeamIcon} style={{ marginRight: 10 }} />
              {language_hook.is_french() ? getFrench(item) : item.title}
            </Title>
            {titles.map((t) => {
              let anchor_data =
                horizontal.priority == "questions"
                  ? item.role_data.find((f) => f.id == t.id - 1)
                  : item.role_data.find((f) => f.id == t.id);
              let select = {
                factor: parseInt(anchor_data?.id),
                id: horizontal.id - 1,
              };
              if (anchor_data) {
                return (
                  <SquareComponent
                    width={width}
                    color={getColor(anchor_data.score)}
                    opacity={1}
                    score={anchor_data.score}
                    type={horizontal.priority}
                    onClick={() => handle_navigation(item, select)}
                  />
                );
              }
              return (
                <SquareComponent
                  width={width}
                  color={"#476DFA"}
                  opacity={0}
                  score={0}
                />
              );
            })}
          </Row>
        );
      })}

      {/*<Legend>
				<LI>
					under 50%
					<Color opacity={1} color={'#476DFA'}/>
				</LI>
				<LI>
					50-60%
					<Color opacity={0.8} color={'#476DFA'}/>
				</LI>
				<LI>
					60-75%
					<Color opacity={0.6} color={'#476DFA'}/>
				</LI>
				<LI>
					75-90%
					<Color opacity={0.4} color={'#476DFA'}/>
				</LI>
				<LI>
					90-100%
					<Color opacity={0.3} color={'#476DFA'}/>
				</LI>
			</Legend>*/}
    </Container>
  );
};

export default HeatMap;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: width 1s;
`;

const Title = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 18px;
  margin-right: 5px;
  /* identical to box height */

  color: #7e7e7e;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Square = styled.div`
  height: 50px;
  width: ${(props) => "calc((100% - 150px) / " + props.width + ")"};
  max-width: ${MAX_WIDTH};
  background-color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};
  cursor: pointer;
  margin-right: 0px;
  position: relative;

  border: ${(props) => (props.hover ? "1px solid #476DFA" : "")};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TopRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => "calc((100% - 100px) / " + props.width + ")"};
  max-width: ${MAX_WIDTH};
  justify-content: center;
  display: flex;
  text-align: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 18px;
  /* identical to box height */

  color: #7e7e7e;
`;

const Legend = styled.div`
  display: flex;
  margin-top: 40px;
  padding-left: 100px;
`;

const LI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  height: 22px;
  width: 100px;
  position: relative;
  color: white;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`;

const Color = styled.div`
  background-color: ${(props) => props.color};

  border-radius: 5px;
  opacity: ${(props) => props.opacity};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Image = styled.img``;

const SquareContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Popped = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  display: ${(props) => (props.hover ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  background: #303030;
  border-radius: 10px;
  color: white;

  position: absolute;
  top: -40px;
`;
