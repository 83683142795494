import {
  ADD_TEAM_ACCESS,
  CLEAR_ALL,
  CLEAR_TEAM_ACCESS,
  DELETE_TEAM_ACCESS,
  EDIT_TEAM_ACCESS,
  FETCH_TEAM_ACCESS,
  GET_TEAM_ACCESSS,
  RESET_CREATED_TEAM,
} from "common/constants/actions";

const initialState = {
  team_access: [],
  pending: false,
  response: null,
  created: [],
};

export default function TeamAccess(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM_ACCESSS:
      return {
        ...state,
        team_access: action.payload,
        pending: false,
      };

    case FETCH_TEAM_ACCESS:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_TEAM_ACCESS:
      // if (state.team_access.filter((an) => an.id === action.payload.id)[0]) {
      //   state = {
      //     ...state,
      //     team_access: state.team_access.filter(
      //       (rw) => rw.id !== action.payload.id
      //     ),
      //   };
      // }
      return {
        ...state,
        team_access: [...state.team_access, action.payload],
      };

    case DELETE_TEAM_ACCESS:
      return {
        ...state,
        team_access: state.team_access.filter(
          (row) => row.id !== action.payload.id,
        ),
      };
    case RESET_CREATED_TEAM:
      return {
        ...state,
        created: [],
      };

    case ADD_TEAM_ACCESS:
      return {
        ...state,
        created: action.payload,
        team_access: [...state.team_access, action.payload],
        response: action.payload.response,
      };

    case CLEAR_TEAM_ACCESS:
      return {
        ...state,
        team_access: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        team_access: [],
      };

    default:
      return state;
  }
}
