import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  ALL_ERRORS,
  CLEAR_PRE_VALIDATE_EMAIL,
  PRE_VALIDATE_EMAIL,
  SG_PRE_VALIDATE_EMAIL,
  SG_VALIDATE_EMAIL,
  SG_VALIDATE_EMAIL_LIST,
  SG_VALIDATE_TOKEN,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_LIST,
  VALIDATE_EMAIL_PENDING,
  VALIDATE_TOKEN,
} from "common/constants/actions";

import * as api from "common/constants/api";

function* validatePreCheckEmail(action) {
  yield put({ type: CLEAR_PRE_VALIDATE_EMAIL });
  try {
    const json = yield call(api.PRE_VALIDATE_EMAIL, action.payload);
    yield put({ type: PRE_VALIDATE_EMAIL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getPreCheckValidation() {
  yield takeLatest(SG_PRE_VALIDATE_EMAIL, validatePreCheckEmail);
}

//  this uses post and in authenticated
function* getData(action) {
  try {
    const json = yield call(api.VALIDATE_EMAIL, action.payload);
    yield put({ type: VALIDATE_EMAIL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadData() {
  yield takeEvery(SG_VALIDATE_EMAIL, getData);
}

// this uses get
function* getDataList(action) {
  try {
    yield put({ type: VALIDATE_EMAIL_PENDING });
    const json = yield call(api.VALIDATE_EMPLOYEE, action.payload);
    yield put({ type: VALIDATE_EMAIL_LIST, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadDataList() {
  yield takeEvery(SG_VALIDATE_EMAIL_LIST, getDataList);
}

function* validateToken(action) {
  try {
    const json = yield call(api.VALIDATE_TOKEN, action.payload);
    yield put({ type: VALIDATE_TOKEN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getTokenValidation() {
  yield takeLatest(SG_VALIDATE_TOKEN, validateToken);
}

export default function* index() {
  yield all([
    getPreCheckValidation(),
    loadData(),
    loadDataList(),
    getTokenValidation(),
  ]);
}
