// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Dimmer, Icon, Loader } from "semantic-ui-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

// Components
import PDF from "common/components/dei-pdf";
import NavBar2 from "common/components/NavBar2";

// Hooks
import useGetSurveyData from "common/hooks/useGetSurveyData";

// Actions
import {
  SG_CREATE_PDF_TEMPLATE,
  SG_GET_PDF_TEMPLATES,
} from "common/constants/actions";
import { SURVEY_INFO } from "common/constants/surveys";

const PDFReport = () => {
  /********* Declarations **********/
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [pdfTemplate, setPdfTemplate] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [startDate, setStartDate] = useState(new Date("2022/02/08"));
  const [endDate, setEndDate] = useState(new Date());
  const [options, setOptions] = useState({
    startDate: startDate,
    endDate: endDate,
    sports: [],
  });
  const [category_access, setAccess] = useState([]);

  /********* Selectors **********/
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual,
  );

  /********* Hooks **********/
  const {
    get_survey_structure,
    get_employee,
    get_employee_categories,
    get_auth,
  } = useGetSurveyData({
    surveyType: SURVEY_INFO.edi.question_type,
    auditTypeId: SURVEY_INFO.edi.type,
  });

  useEffect(() => {
    if (get_employee_categories.length > 0) {
      setAccess(
        get_employee_categories.find(
          (f) => f.employee === Number(get_auth.employee_id),
        ).category,
      );
    }
  }, [get_auth, get_employee_categories]);

  /********* Functions & Routines **********/
  const resetDefaultsBtnClicked = () => {
    setStartDate(new Date("2022/02/08"));
    setEndDate(new Date());
    setOptions({
      startDate: new Date("2022/02/08"),
      endDate: new Date(),
      sports: [],
    });
  };

  const handleStartDateSelect = (date) => {
    setStartDate(date);
    setOptions({ ...options, startDate: date });
  };

  const handleEndDateSelect = (date) => {
    setEndDate(date);
    setOptions({ ...options, endDate: date });
  };

  /********* Dispatch Calls **********/
  // Retrieve PDF Templates (if they exist).
  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_TEMPLATES,
    });
  }, [dispatch]);

  // Create a PDF Template (if one does not exist).
  useEffect(() => {
    if (pdfTemplate.length === 0) {
      dispatch({
        type: SG_CREATE_PDF_TEMPLATE,
        payload: { name: "ver 1.0" },
      });
    }
  }, [pdfTemplate, dispatch]);

  /********* useEffect Hooks **********/
  useEffect(() => {
    setPdfTemplate(pdfConfig);
  }, [pdfConfig]);

  // Create array of select options based on primary categories
  useEffect(() => {
    if (!selectOptions && get_survey_structure?.[0]) {
      const options = get_survey_structure?.[0]?.categories
        .find((category) => category.priority.toLowerCase() === "primary")
        ?.options.map((catOption) => {
          return {
            key: catOption.name,
            text: catOption.name,
            value: catOption.name,
          };
        });
      setSelectOptions(options);
      setLoading(false);
    }
  }, [get_survey_structure, selectOptions]);

  /********* Render Functions **********/
  const SelectDate = React.forwardRef((props, ref) => (
    <SelectContainer
      ref={ref}
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      <Icon
        name="calendar alternate"
        size={"small"}
        style={{ color: "#6a00ff", marginRight: 8 }}
      />{" "}
      {props.value}
    </SelectContainer>
  ));

  if (loading || !pdfConfig[0] || !get_employee) {
    return (
      <LoadingWrapper>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </LoadingWrapper>
    );
  }

  const filter = (
    <FilterWrapper>
      <FilterLabel>Select your filters: </FilterLabel>
      <NavBar2
        structure={get_survey_structure[0]}
        mployee_categories={category_access}
        isAdmin={
          get_employee[get_auth.employee_id]?.account_type_text === "Admin"
        }
      />
    </FilterWrapper>
  );

  return (
    <Page>
      <PageHeader>PDF Report</PageHeader>
      <PageSubtitle>Customize your PDF report options</PageSubtitle>
      <OptionsSection>
        <SelectionSection>
          <SelectorLabel>Choose your Date Range: </SelectorLabel>
          <DateRangeWrapper>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDateSelect(date)}
              selectsStart
              showYearDropdown
              startDate={startDate}
              endDate={endDate}
              customInput={<SelectDate />}
            />
          </DateRangeWrapper>
          <DateRangeToLabel>to</DateRangeToLabel>
          <DateRangeWrapper>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDateSelect(date)}
              selectsEnd
              showYearDropdown
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<SelectDate />}
            />
          </DateRangeWrapper>
        </SelectionSection>
      </OptionsSection>
      <ButtonsSection>
        <ResetButtonWrapper>
          <Button onClick={() => resetDefaultsBtnClicked()}>Reset</Button>
        </ResetButtonWrapper>
        <GenerateButtonWrapper>
          {get_survey_structure?.[0] && pdfConfig?.length > 0 && options && (
            <PDF
              coverSubtitle={"Generate PDF Report"}
              anchor={anchor}
              config={pdfConfig}
              options={options}
              overviewReport={true}
              buttonEnabled={true}
            />
          )}
        </GenerateButtonWrapper>
      </ButtonsSection>
    </Page>
  );
};

/********** Styles ***********************/
const LoadingWrapper = styled.div`
  padding: 300px;
`;

const SelectContainer = styled.div`
  width: 150px;
  height: 30px;
  border-radius: 8px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  position: relative;
  z-index: 1;
  margin-right: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: 10px;

  @media (max-width: 768px) {
    padding-left: 10px;
    width: 150px;
    font-size: 13px;
  }
`;

const Page = styled.div`
  font-family: "Red Hat Display", sans-serif;
  padding: 10px 0px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const PageHeader = styled.div`
  padding: 35px 0px;
  font-size: 32px;
  font-weight: 700;
`;

const PageSubtitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 60px;
`;

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ResetButtonWrapper = styled.div`
  padding-right: 15px;
`;

const GenerateButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: 35px;
`;

const SelectionSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  line-height: 35px;
`;

const DropdownWrapper = styled.div`
  width: 350px;
  z-index: 9;
`;

const AnchorWrapper = styled.div`
  width: 150px;
  margin-top: 14px;
`;

const SelectorLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding-right: 8px;
`;

const DateRangeWrapper = styled.div`
  z-index: 99;
`;

const DateRangeToLabel = styled.div`
  padding-right: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 35px;
  vertical-align: middle;
  margin-bottom: 35px;
`;

const FilterLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding-right: 8px;
  line-height: 35px;
`;

export default PDFReport;
