import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dimmer, Divider, Input, Loader, Pagination } from "semantic-ui-react";
import TableList from "areas/team-management/components/ArchivedTableList";
import {
  SG_GET_SURVEY_STRUCTURES,
  SG_INACTIVE_EMPLOYEES,
} from "common/constants/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { StyledButton } from "common/components/button/StyledButton";
import { useNavigate } from "react-router-dom";
import { PageContent, Title } from "common/styles/page";

const MemberDirectory = () => {
  const dispatch = useDispatch();

  const [memberList, setMemberList] = useState([]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [selectTeam, setSelectTeam] = useState(false);

  const [organization, setOrganization] = useState({});

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual,
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual,
  );

  const {
    get_organizations,
    get_employees,
    get_auth,
    get_selectedOrg,
    employees_pending,
    get_employee_categories,
  } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      get_employees: state.employees,
      get_organizations: state.organizations,
      employees_pending: state.employees.pending,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch({
      type: SG_INACTIVE_EMPLOYEES,
    });
  }, [dispatch]);

  useEffect(() => {
    // dispatch({ type: SG_GET_MOODS });
    if (Number(AmbassadorId) > 0) {
      // console.log("Ambassador Lead teams", AmbassadorId)
      const OrgId = get_selectedOrg?.organization?.id;
      if (OrgId) {
        dispatch({
          type: SG_GET_SURVEY_STRUCTURES,
          payload: `organization=${OrgId}`,
        });
        // dispatch({
        //     type: SG_GET_EMPLOYEE_CATEGORIES,
        //     payload: `organization=${OrgId}`,
        // });
      }
    } else {
      // console.log("Lead teams")
      dispatch({ type: SG_GET_SURVEY_STRUCTURES });
      // dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
    }
  }, [dispatch, AmbassadorId, get_selectedOrg]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganization(get_organizations[get_selectedOrg?.organization?.id]);
    } else {
      setOrganization(get_organizations[get_auth?.organization_id]);
    }
  }, [get_organizations, get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    setMemberList(
      get_employees?.inactive.sort((a, b) => (a.id > b.id ? 1 : -1)),
    );
  }, [get_employees]);

  // console.log(employees_pending)

  useEffect(() => {
    // if (members.length > 0) {
    setLoading(employees_pending);
    if (employees_pending === false) {
      const timer1 = setTimeout(() => {
        // console.log("I am here", loading)
        // if (setLoading) {
        setLoading(false);
        // }
      }, 1000);
      return () => clearTimeout(timer1);
    }
    // }
  }, [employees_pending]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  function SearchMembers(e) {
    setSearch(e);
    let emailLst = [];
    let firstList = [];
    let lastList = [];
    if (e.length > 0) {
      setPage(1);
      emailLst = memberList.filter((item) => item?.email?.includes(e));
      firstList = memberList.filter((item) => item?.first_name?.includes(e));
      lastList = memberList.filter((item) => item?.last_name?.includes(e));
      const _MemberList = emailLst.concat(firstList, lastList);
      setMemberList(_MemberList);
    } else {
      setMemberList(memberList);
    }
  }

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      <PageContent>
        <TopTitle>
          <Title>Archived Members</Title>
        </TopTitle>

        <TopSection>
          <StyledButton
            color="blue"
            label="View Active"
            width="140px"
            onClick={() => navigate(`/core/members/list`)}
          />
        </TopSection>

        <Divider />

        <SearchContainer>
          <Input
            icon="search"
            iconPosition="left"
            placeholder="Search..."
            size="small"
            style={{ width: "70%" }}
            value={search}
            onChange={(e) => SearchMembers(e.target.value)}
          />
        </SearchContainer>
        <TableList
          members={memberList}
          employee_categories={get_employee_categories}
        />
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: 10,
          }}
        >
          <Pagination
            activePage={page}
            onPageChange={handlePaginationChange}
            boundaryRange={1}
            size="mini"
            firstItem={null}
            lastItem={null}
            siblingRange={0}
            totalPages={Math.ceil(memberList?.length / 10)}
          />
        </div>
      </PageContent>
      <Dimmer active={loading}>
        <LoadingContainer>
          <p>Loading page</p>
          <Loader size="medium">Loading</Loader>
        </LoadingContainer>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default MemberDirectory;

const Container = styled.div`
  padding-left: 38px;
  margin-bottom: 50px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LoadingContainer = styled.div`
  min-height: 40vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TopTitle = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  margin-top: -20px;
`;

/* const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`; */

const TopSection = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  padding-right: 10px;
`;
