import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  GET_INDUSTRY_BENCHMARK,
  SG_GET_INDUSTRY_BENCHMARK,
} from "common/constants/actions";

function* loadIndustryBenchmark(action) {
  try {
    const json = yield call(api.GET_INDUSTRY_BENCHMARK, action.payload);

    yield put({ type: GET_INDUSTRY_BENCHMARK, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* industryBenchmarkLoad() {
  yield takeLatest(SG_GET_INDUSTRY_BENCHMARK, loadIndustryBenchmark);
}

export default function* index() {
  yield all([industryBenchmarkLoad()]);
}
