import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SG_GET_DEBRIEF_SCHEDULES } from "common/constants/actions";

import format from "date-fns/format";
import { add, endOfMonth, startOfMonth, sub } from "date-fns";

import SubFactor from "./calendar/Subfactor";
import Category from "./calendar/Category";

const CalendarList = ({
  organizationId,
  survey_structure,
  groupList,
  // employee_category,
  primaryCategory,
  subfactorFound,
  surveyType,
  userAdmin,
  userEmployeeCategoryList,
  language_hook,
}) => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(
    endOfMonth(
      add(new Date(), {
        months: 11,
      }),
    ),
  );
  const [monthList, setMonthList] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(1);

  const [surveySchedule, setSurveySchedule] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const { get_debrief_schedules } = useSelector(
    (state) => ({
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual,
  );

  // page_size
  useEffect(() => {
    if (organizationId) {
      //
      const Start = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      const End = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page_size=1000&type_of=${surveyType}&organization=${organizationId}&start_date=${Start}&end_date=${End}`,
      });
    }
  }, [dispatch, organizationId, surveyType, startDate, endDate]);

  useEffect(() => {
    setSurveySchedule(get_debrief_schedules?.debrief_schedule.results);
  }, [get_debrief_schedules]);

  function dateRange(startD) {
    const formatDates = [];
    for (var d = 0; d <= 11; d++) {
      const result = add(startD, {
        months: d,
      });
      if (result) {
        formatDates.push(format(new Date(result), "MMM-yy"));
      }
    }
    return formatDates;
  }

  useEffect(() => {
    setPageLoading(true);
    const timer1 = setTimeout(() => {
      setPageLoading(false);
    }, 1000);
    return () => clearTimeout(timer1);
  }, [startDate, endDate]);

  function nextMonth() {
    setStartDate(
      startOfMonth(
        add(startDate, {
          months: 1,
        }),
      ),
    );
    setEndDate(
      endOfMonth(
        add(endDate, {
          months: 1,
        }),
      ),
    );
  }

  function prevMonth() {
    setEndDate(
      endOfMonth(
        sub(endDate, {
          months: 1,
        }),
      ),
    );
    setStartDate(
      startOfMonth(
        sub(startDate, {
          months: 1,
        }),
      ),
    );
  }

  useEffect(() => {
    setMonthList(dateRange(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    const value = format(new Date(), "MMM-yy");
    if (Object.keys(monthList).find((key) => monthList[key] === value)) {
      setCurrentMonth(
        Number(Object.keys(monthList).find((key) => monthList[key] === value)) +
          1,
      );
    } else {
      setCurrentMonth(0);
    }
  }, [monthList]);

  return (
    <>
      <RightItems>
        <Button.Group>
          <Button icon="left chevron" onClick={prevMonth} />

          <Button icon="right chevron" onClick={nextMonth} />
        </Button.Group>
      </RightItems>

      <Container>
        {/*  */}
        {subfactorFound ? (
          <SubFactor
            primaryCategory={primaryCategory} // to show subfactors
            groupList={groupList}
            surveySchedule={surveySchedule}
            monthList={monthList}
            currentMonth={currentMonth}
            pageLoading={pageLoading}
            userAdmin={userAdmin}
            userEmployeeCategoryList={userEmployeeCategoryList}
            language_hook={language_hook}
            survey_structure={survey_structure}
          />
        ) : (
          <Category
            surveySchedule={surveySchedule}
            primaryCategory={primaryCategory}
            monthList={monthList}
            currentMonth={currentMonth}
            pageLoading={pageLoading}
            userAdmin={userAdmin}
            userEmployeeCategoryList={userEmployeeCategoryList}
          />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  font-family: "Poppins";
  display: flex;
  position: relative;
  margin-top: 10px;
`;

const RightItems = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: right;
`;

export default CalendarList;
