import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Dimmer, Loader } from "semantic-ui-react";
import { shallowEqual, useSelector } from "react-redux";
import LegendIcon from "common/components/icons/LegendIcon";

const get_buckets = (data) => {
  let response = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.map((i) => {
    response[Math.round(i)] += 1;
  });
  return response;
};

const sum_distribution = (arr) => {
  let _distribution = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arr.map((item) => {
    item.map((s, i) => (_distribution[i] += s));
  });
  return _distribution;
};

const getAverage = (arr) => {
  let total = 0;
  let n = 0;
  arr.map((item, i) => {
    total += item * i;
    n += item;
  });

  return total / n;
};

const getAverage2 = (arr) => {
  let total = 0;
  let n = 0;
  arr.map((item, i) => {
    total += item;
    n += 1;
  });

  return total / n;
};

const clipLength = (s) => {
  if (s.length > 17) {
    return s.slice(0, 15) + "...";
  }
  return s;
};

const Distribution = ({ labels, data, question, is_french, language_hook }) => {
  const [chart, setChart] = useState(data3.options);
  const [series, setData] = useState(data3.series);
  const [loading, setLoading] = useState(true);
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual,
  );

  useEffect(() => {
    setLoading(true);
    let _series;
    let dimension = "dimension" in question ? question.dimension : null;
    let factor = "factor" in question ? question.factor : null;

    if (question.id == 0) {
      _series = Object.keys(data.overall).map((item, i) => {
        let name = is_french
          ? labels.find((f) => f.id == item)?.fr
            ? labels.find((f) => f.id == item)?.fr
            : labels.find((f) => f.id == item)?.name
          : labels.find((f) => f.id == item)?.name;
        return {
          name,
          data: getAverage2(data.overall[item].responses).toFixed(1),
        };
      });
    }

    if (question.level == 1) {
      let filtered = data.summary.filter(
        (f) => Number(f.dimension) == dimension,
      );
      let secondary = [...new Set(filtered.map((i) => i.secondary))];

      let distributions = secondary.map((i) => {
        return filtered
          .filter((f) => f.secondary == i)
          .map((x) => x.distribution);
      });

      _series = distributions.map((item, i) => {
        let f_id = secondary[i];
        let name = is_french
          ? labels.find((f) => f.id == f_id)?.fr
            ? labels.find((f) => f.id == f_id)?.fr
            : labels.find((f) => f.id == f_id)?.name
          : labels.find((f) => f.id == f_id)?.name;
        return {
          name,
          data: getAverage(sum_distribution(item)).toFixed(1),
        };
      });
    }
    if (question.level == 2) {
      _series = data.summary
        .filter(
          (f) => Number(f.dimension) == dimension && Number(f.factor) == factor,
        )
        .map((item, i) => {
          let f_id = item.secondary;
          let name = is_french
            ? labels.find((f) => f.id == f_id)?.fr
              ? labels.find((f) => f.id == f_id)?.fr
              : labels.find((f) => f.id == f_id)?.name
            : labels.find((f) => f.id == f_id)?.name;
          return {
            name: name,
            data: item.average.toFixed(1),
          };
        });
    }

    _series = _series.sort((a, b) => a.data - b.data);

    setData([
      {
        name: is_french ? "Moyenne" : "Average Scores",
        data: _series.map((i) => i.data),
      },
    ]);
    let _chart = chart;
    _chart.colors = ["#476DFA", "#c9c9c9"];
    _chart.xaxis.categories = _series.map((i) => i.name);

    if (is_french) {
      _chart.yaxis.title.text = "Moyenne";
    }

    setChart(_chart);
    setTimeout(() => setLoading(false), 300);
  }, [data, question, labels]);

  return (
    <Container>
      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <div style={{ width: "100%", padding: 30, marginBottom: -65 }}>
          <Title>
            <LegendIcon />
            {language_hook("Average_anchor")}
          </Title>
          <Description>{language_hook("Average_anchor2")}</Description>
          <Chart options={chart} series={series} type="bar" height={350} />
        </div>
      )}
    </Container>
  );
};

export default Distribution;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;
const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-left: 25px;

  color: #c0c0c0;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3260a8"],
    xaxis: {
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      decimalsInFloat: 1,
      title: {
        text: "Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
