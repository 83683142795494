import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  FETCH_EMPLOYEE_ROLE,
  GET_EMPLOYEE_ROLES,
  SG_FETCH_EMPLOYEE_ROLE,
  SG_GET_EMPLOYEE_ROLES,
} from "common/constants/actions";

function* loademployeeRoles(action) {
  try {
    const json = yield call(api.GET_EMPLOYEE_ROLES, action.payload);
    yield put({ type: GET_EMPLOYEE_ROLES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* employeeRoleLoad() {
  yield takeLatest(SG_GET_EMPLOYEE_ROLES, loademployeeRoles);
}

function* fetchemployeeRoles(action) {
  try {
    const json = yield call(api.FETCH_EMPLOYEE_ROLE, action.payload);
    yield put({ type: FETCH_EMPLOYEE_ROLE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* employeeRoleFetch() {
  yield takeLatest(SG_FETCH_EMPLOYEE_ROLE, fetchemployeeRoles);
}

export default function* index() {
  yield all([employeeRoleLoad(), employeeRoleFetch()]);
}
