import React from "react";
import styled from "styled-components";

const MandatoryLinks = ({ Domain }) => {
  const today = new Date();
  return (
    <>
      {/* {Domain.allowRegister &&
            <Banner>
                To know more about us <a href={Domain.homePage} target="_blank" rel="noreferrer">click here</a>
            </Banner>} */}
      <Banner>
        To learn more about how we collect, use, disclose, transfer, and store
        your information, please visit our{" "}
        <a
          href="https://portal.nulogic.co/terms.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </Banner>
      <LinkContainer>
        <p>Copyright © {today.getFullYear()} nuwaveco.com</p>
        <a
          href="https://portal.nulogic.co/terms.html"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
      </LinkContainer>
    </>
  );
};

export default MandatoryLinks;

const Banner = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`;

const LinkContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;
