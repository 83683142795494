import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  CREATE_PDF_SAVED_COMMENTS,
  DELETE_PDF_SAVED_COMMENTS,
  GET_PDF_SAVED_COMMENTS,
  SG_CREATE_PDF_SAVED_COMMENTS,
  SG_DELETE_PDF_SAVED_COMMENTS,
  SG_GET_PDF_SAVED_COMMENTS,
  SG_UPDATE_PDF_SAVED_COMMENTS,
  UPDATE_PDF_SAVED_COMMENTS,
} from "common/constants/actions";

function* createPDFComments(action) {
  try {
    const json = yield call(api.CREATE_PDF_SAVED_COMMENTS, action.payload);
    yield put({ type: CREATE_PDF_SAVED_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfCommentsCreate() {
  yield takeLatest(SG_CREATE_PDF_SAVED_COMMENTS, createPDFComments);
}

function* getAllPdfComment() {
  try {
    const json = yield call(api.GET_PDF_SAVED_COMMENTS);
    yield put({ type: GET_PDF_SAVED_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfGetCommentsLoad() {
  yield takeLatest(SG_GET_PDF_SAVED_COMMENTS, getAllPdfComment);
}

function* updatePDFComments(action) {
  try {
    const json = yield call(api.UPDATE_PDF_SAVED_COMMENTS, action.payload);
    yield put({ type: UPDATE_PDF_SAVED_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfCommentsUpdate() {
  yield takeLatest(SG_UPDATE_PDF_SAVED_COMMENTS, updatePDFComments);
}

function* deletePDFComments(action) {
  try {
    const json = yield call(api.DELETE_PDF_SAVED_COMMENTS, action.payload);
    yield put({ type: DELETE_PDF_SAVED_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfCommentsDelete() {
  yield takeLatest(SG_DELETE_PDF_SAVED_COMMENTS, deletePDFComments);
}

export default function* index() {
  yield all([
    pdfCommentsCreate(),
    pdfGetCommentsLoad(),
    pdfCommentsUpdate(),
    pdfCommentsDelete(),
  ]);
}
