import React, { useContext, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import Pic2 from "assets/images/empty/wrong.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SG_LOG_ERRORS } from "common/constants/actions";

import { DomainContext } from "common/layouts/LayoutContext";
import format from "date-fns/format";

function ErrorFallback({ error, resetErrorBoundary }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const Domain = useContext(DomainContext);

  const get_errors = useSelector((state) => state.errors, shallowEqual);

  const safety = () => {
    navigate(-1);
    resetErrorBoundary();
  };

  const [errorDetails, setErrorDetails] = useState(false);

  const usePrevLocation = (location) => {
    const prevLocRef = useRef(location);
    useEffect(() => {
      prevLocRef.current = location;
    }, [location]);
    return prevLocRef.current;
  };

  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  useEffect(() => {
    if (get_errors?.errors?.request?.response) {
      setErrorDetails(get_errors?.errors?.request?.response);
    }
  }, [get_errors.errors]);

  useEffect(() => {
    if (!Domain?.debug) {
      if (errorDetails) {
        dispatch({
          type: SG_LOG_ERRORS,
          payload: {
            location: `Error in ${Domain.name} - ${Domain.url} - ${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}`,
            request: prevLocation?.pathname || "No details found",
            page: prevLocation?.pathname,
            description: errorDetails,
            extra_content: JSON.stringify(errorDetails),
          },
        });
      }
    }
  }, [dispatch, errorDetails, prevLocation, Domain]);

  return (
    <Container>
      <Image src={Pic2} />
      <Title>Oh Dang... I think we broke something.</Title>
      <Text>
        You can try to load this again by refreshing your browser. If this error
        persists, don't hesistate to contact our support team, they have already
        been notified.{" "}
      </Text>
      <Button onClick={() => safety()}>Bring me to safety</Button>
    </Container>
  );
}

const ErrorWrapper = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorWrapper;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  min-height: 400px;
  margin-top: 50px;
`;

const Text = styled.div`
  margin-top: 30px;
  text-align: center;
  max-width: 400px;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #666d79;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: #2a3039;
`;

const Image = styled.img`
  width: 150px;
`;

const Button = styled.div`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #2d70e2;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: Raleway;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 30px;
`;
