import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Message } from "semantic-ui-react";

import { ErrorMessages } from "../data/ErrorMessage";

const UserErrorMessages = () => {
  const { addToast } = useToasts();
  const [registerError, setRegisterError] = React.useState(false);

  const [oneToast, setOneToast] = React.useState(0);
  const { get_error } = useSelector(
    (state) => ({
      get_error: state.errors,
    }),
    shallowEqual,
  );

  //   console.log(get_error?.errors?.data?.non_field_errors?.[0]);
  // console.log(get_error)

  React.useEffect(() => {
    // setOneToast(0) non_field_errors
    if (get_error?.errors?.data) {
      if (
        ErrorMessages.find(
          ({ name }) => name == get_error?.errors?.data?.non_field_errors?.[0],
        )
      ) {
        setRegisterError(
          ErrorMessages.find(
            ({ name }) =>
              name == get_error?.errors?.data?.non_field_errors?.[0],
          )?.userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(
              ({ name }) =>
                name == get_error?.errors?.data?.non_field_errors?.[0],
            )?.toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }

        return;
      }

      if (
        ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
      ) {
        setRegisterError(
          ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
            ?.userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
              ?.toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }

        return;
      }

      if (
        ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
      ) {
        setRegisterError(
          ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
            ?.userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(({ name }) => name == get_error?.errors?.data[0])
              ?.toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }

        return;
      }

      if (Array.isArray(get_error?.errors?.data)) {
        // console.log(get_error?.errors);

        setRegisterError(
          ErrorMessages.find(
            ({ name }) => name == Object.keys(get_error?.errors?.data)[0],
          )?.userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(
              ({ name }) => name == Object.keys(get_error?.errors?.data)[0],
            )?.toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }
        return;
      }

      if (
        ErrorMessages.find(
          ({ name }) => name == get_error?.errors?.data?.detail,
        )
      ) {
        setRegisterError(
          ErrorMessages.find(
            ({ name }) => name == get_error?.errors?.data?.detail,
          ).userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(
              ({ name }) => name == get_error?.errors?.data?.detail,
            ).toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }
        return;
      }

      if (
        ErrorMessages.find(
          ({ name }) => name == Object.keys(get_error?.errors?.data)?.[0],
        )
      ) {
        setRegisterError(
          ErrorMessages.find(
            ({ name }) => name == Object.keys(get_error?.errors?.data)?.[0],
          ).userMessage,
        );
        if (oneToast === 0) {
          setOneToast(1);
          addToast(
            ErrorMessages.find(
              ({ name }) => name == Object.keys(get_error?.errors?.data)?.[0],
            ).toastMessage,
            { appearance: "error", autoDismiss: true },
          );
        }
        return;
      }

      if (get_error?.errors?.data) {
        setRegisterError(JSON.stringify(get_error?.errors?.data));
        if (oneToast === 0) {
          setOneToast(1);
          addToast("Please login", { appearance: "error", autoDismiss: true });
        }
      }
    } else {
      setRegisterError(false);
    }
  }, [get_error, addToast, oneToast]);
  return (
    <div>
      {registerError && (
        <Message
          error
          onDismiss={() => setRegisterError(false)}
          // header={props.message}
          content={registerError}
        />
      )}
    </div>
  );
};

export default UserErrorMessages;
