
export const pathList = [
  {
    name: "Teamtool", path: "app",
    description: "Add a description at routes/routeMap.js file"
  },
  {
    name: "Audit", path: "audit",
    description: "Add a description at routes/routeMap.js file"
  },
  {
    name: "EDI", path: "edi",
    description: "Add a description at routes/routeMap.js file"
  },
  {
    name: "OTP", path: "otp",
    description: "Add a description at routes/routeMap.js file"
  },
];


