import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  DELETE_SURVEY_TOKEN,
  GET_SURVEY_TOKENS,
  SG_DELETE_SURVEY_TOKEN,
  SG_GET_SURVEY_TOKENS,
} from "common/constants/actions";

function* loadTokens(action) {
  // console.log(action.payload)
  try {
    const json = yield call(api.GET_SURVEY_TOKENS, action.payload);
    yield put({ type: GET_SURVEY_TOKENS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* tokenLoad() {
  yield takeLatest(SG_GET_SURVEY_TOKENS, loadTokens);
}

function* deleteToken(action) {
  try {
    const json = yield call(api.DELETE_SURVEY_TOKEN, action.payload);
    yield put({ type: DELETE_SURVEY_TOKEN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* tokenDelete() {
  yield takeLatest(SG_DELETE_SURVEY_TOKEN, deleteToken);
}

export default function* index() {
  yield all([tokenLoad(), tokenDelete()]);
}
