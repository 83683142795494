import { all, put, takeLatest } from "redux-saga/effects";

import {
  ALL_ERRORS,
  GET_MEMBER_FILTERS,
  SET_MEMBER_FILTERS,
  SET_MEMBER_SEARCH_FILTERS,
  SG_GET_MEMBER_FILTERS,
  SG_SET_MEMBER_FILTERS,
  SG_SET_MEMBER_SEARCH_FILTERS,
} from "common/constants/actions";

function* getMemberFilters(action) {
  try {
    yield put({ type: GET_MEMBER_FILTERS, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadMemberFilters() {
  yield takeLatest(SG_GET_MEMBER_FILTERS, getMemberFilters);
}

function* setMemberFilters(action) {
  try {
    yield put({ type: SET_MEMBER_FILTERS, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateMemberFilters() {
  yield takeLatest(SG_SET_MEMBER_FILTERS, setMemberFilters);
}

function* setMemberSearchFilters(action) {
  try {
    yield put({ type: SET_MEMBER_SEARCH_FILTERS, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateMemberSearchFilters() {
  yield takeLatest(SG_SET_MEMBER_SEARCH_FILTERS, setMemberSearchFilters);
}

export default function* index() {
  yield all([
    loadMemberFilters(),
    updateMemberFilters(),
    updateMemberSearchFilters(),
  ]);
}
