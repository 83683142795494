import React from "react";

function SaveIcon() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4583 15.375H3.54167C3.16594 15.375 2.80561 15.2257 2.53993 14.9601C2.27426 14.6944 2.125 14.3341 2.125 13.9583V4.04167C2.125 3.66594 2.27426 3.30561 2.53993 3.03993C2.80561 2.77426 3.16594 2.625 3.54167 2.625H11.3333L14.875 6.16667V13.9583C14.875 14.3341 14.7257 14.6944 14.4601 14.9601C14.1944 15.2257 13.8341 15.375 13.4583 15.375Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0418 15.375V9.70834H4.9585V15.375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9585 2.625V6.16667H10.6252"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SaveIcon;
