import {
  AUTO_LOGIN_TO_ML,
  AUTO_LOGIN_TO_ML_ERROR,
  CHAT_GPT_PENDING,
  CLEAR_ALL,
  CLEAR_CHAT_GPT,
  GET_CHAT_GPT,
  POST_CHAT_GPT,
  POST_ML_SERVER_V1,
  POST_ML_SERVER_V1_XL,
} from "common/constants/actions";

const initialState = {
  chat_gpt: [],
  chat_gpt_ml: [],
  chat_gpt_ml_xl: [],
  chat_gpt_error: false,
  token: false,
  pending: false,
};

export default function ChatGpt(state = initialState, action) {
  switch (action.type) {
    case CHAT_GPT_PENDING:
      return {
        ...state,
        pending: true,
      };

    case POST_CHAT_GPT:
      return {
        ...state,
        chat_gpt: action.payload,
        pending: false,
      };
    case AUTO_LOGIN_TO_ML:
      return {
        ...state,
        token: action.payload,
        // pending: false
      };
    case AUTO_LOGIN_TO_ML_ERROR:
      return {
        ...state,
        chat_gpt_error: action.payload,
        pending: false,
      };
    case POST_ML_SERVER_V1:
      return {
        ...state,
        chat_gpt_ml: action.payload,
        pending: false,
      };
    case POST_ML_SERVER_V1_XL:
      return {
        ...state,
        chat_gpt_ml_xl: action.payload,
        pending: false,
      };
    case GET_CHAT_GPT:
      return {
        ...state,
        chat_gpt: action.payload,
        pending: false,
      };

    case CLEAR_CHAT_GPT:
      return {
        ...state,
        chat_gpt: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        chat_gpt: [],
      };

    default:
      return state;
  }
}
