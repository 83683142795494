import styled from "styled-components";
import React from "react";

import Crystal from "assets/images/nav/crystalball.svg";

const ModelCard = ({ color, type, title, onClick }) => {
  return (
    <Container onClick={() => onClick()}>
      <TD width={60}>
        <Title>
          <Image src={Crystal} />
          {title}
        </Title>
      </TD>

      <TD width={15}>
        <Button>{type}</Button>
      </TD>

      <TD width={15}>
        <Score>60%</Score>
      </TD>

      <TD width={10}>
        <ElipsesButton />
      </TD>
    </Container>
  );
};

export default ModelCard;

const Container = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  padding: 18px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;

  color: #414141;
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  padding-left: 24px;
  color: #7e7e7e;
`;

const Button = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  padding: 3px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;

  color: white;
  background-color: #476dfa;
  width: 110px;
`;

const Image = styled.img`
  margin-right: 10px;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  padding-left: 20px;
`;

const Score = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #6d6d6d;
`;

function ElipsesButton(fill) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 30C23.732 30 30 23.732 30 16C30 8.268 23.732 2 16 2C8.268 2 2 8.268 2 16C2 23.732 8.268 30 16 30ZM10 18C9.46957 18 8.96086 17.7893 8.58579 17.4142C8.21071 17.0391 8 16.5304 8 16C8 15.4696 8.21071 14.9609 8.58579 14.5858C8.96086 14.2107 9.46957 14 10 14C10.5304 14 11.0391 14.2107 11.4142 14.5858C11.7893 14.9609 12 15.4696 12 16C12 16.5304 11.7893 17.0391 11.4142 17.4142C11.0391 17.7893 10.5304 18 10 18ZM18 16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18C15.4696 18 14.9609 17.7893 14.5858 17.4142C14.2107 17.0391 14 16.5304 14 16C14 15.4696 14.2107 14.9609 14.5858 14.5858C14.9609 14.2107 15.4696 14 16 14C16.5304 14 17.0391 14.2107 17.4142 14.5858C17.7893 14.9609 18 15.4696 18 16ZM22 18C21.4696 18 20.9609 17.7893 20.5858 17.4142C20.2107 17.0391 20 16.5304 20 16C20 15.4696 20.2107 14.9609 20.5858 14.5858C20.9609 14.2107 21.4696 14 22 14C22.5304 14 23.0391 14.2107 23.4142 14.5858C23.7893 14.9609 24 15.4696 24 16C24 16.5304 23.7893 17.0391 23.4142 17.4142C23.0391 17.7893 22.5304 18 22 18Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}
