import {
  CLEAR_ALL,
  CLEAR_AM,
  GET_AM_EMPLOYEE,
  GET_AM_ORGANIZATION,
  GET_AM_TEAM,
  ROUTE_PATH,
  SET_AM_MENU,
} from "common/constants/actions";

const initialState = {
  organization: [],
  routePath: false,
  team: [],
  employee: [],
  role: 5,
  account: 5,
  pending: false,
  top_menu: [],
};

export default function SelectedOrg(state = initialState, action) {
  switch (action.type) {
    case GET_AM_ORGANIZATION:
      return {
        ...state,
        organization: action.payload,
        pending: false,
      };
    case SET_AM_MENU:
      return {
        ...state,
        top_menu: action.payload,
        pending: false,
      };
    case ROUTE_PATH:
      return {
        ...state,
        routePath: action.payload,
        pending: false,
      };
    case GET_AM_TEAM:
      return {
        ...state,
        team: action.payload,
        pending: false,
      };
    case GET_AM_EMPLOYEE:
      return {
        ...state,
        employee: action.payload,
        pending: false,
      };
    case CLEAR_AM:
      return {
        ...state,
        price: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        price: [],
      };

    default:
      return state;
  }
}
