import { Navigate } from "react-router-dom";
import HomeLayout from "common/layouts/HomeLayout";
import AuthLayout from "common/layouts/AuthLayout";
import ForgotPassword from "areas/authentication/components/ForgotPassword";
import UpgradePassword from "areas/authentication/components/UpgradePassword";
import Login from "areas/authentication/components/Login";
import Validate from "areas/authentication/components/Validate";
import Logout from "areas/authentication/components/Logout";
import ResetPassword from "areas/authentication/components/ResetPassword";
import ChangePassword from "areas/authentication/components/ChangePassword";
import SelectOrg from "areas/authentication/components/SelectOrg";
import PasswordMessage from "areas/authentication/components/PasswordMessage";
import Lost from "areas/404/components";
import UnSubcribed from "areas/404/components/NotSubscribed";
import NoAccess from "areas/404/components/NoAccess";
import Members from "areas/team-management/MembersRouter";
import MemberSettings from "areas/settings/components/UserSettingsPage";
import Register from "areas/authentication/components/Register";
import RegisterToken from "areas/authentication/components/RegisterToken";
import AmbassadorToken from "areas/authentication/components/AmbassadorRegisterToken";
import ValidateEmail from "areas/authentication/components/validate/Validate";
import NoLoginRole from "areas/authentication/components/validate/Validate";
import Ambassador from "areas/ambassador/components/index";
import RoutesEdi from "routes/Edi";
import AmbassadorLogin from "areas/ambassador/components/login";
import Dashboard from "../areas/dashboard/components/Dashboard";

const routes = [
  {
    path: "app",
    element: <AuthLayout />,
    children: [
      {
        path: "edi/*",
        element: <RoutesEdi />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: false,
      },
      {
        path: "404",
        element: <Lost />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "unsubscribed",
        element: <UnSubcribed />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "access-denied",
        element: <NoAccess />,
        role: 21,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      // { path: '*', element: <Navigate to="/404" /> }
    ],
  },
  {
    path: "/settings",
    element: <AuthLayout />,
    children: [
      {
        path: "change-password",
        element: <ChangePassword />,
        role: 11,
        visible: false,
        authentication: true,
        team: false,
        paid: false,
      },
      {
        path: "select-organization",
        element: <SelectOrg />,
        role: 21,
        visible: false,
        authentication: true,
        team: false,
        paid: false,
      },
      // {
      //   path: "profile",
      //   element: <Profile />,
      //   role: 5,
      //   visible: true,
      //   authentication: true,
      //   team: false,
      // },
      {
        path: "member", // profile page
        element: <MemberSettings />,
        role: 11,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ],
  },
  {
    path: "/ambassador",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <Ambassador />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "ambassador-select",
        element: <AmbassadorLogin />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
    ],
  },
  {
    path: "/core",
    element: <AuthLayout />,
    children: [
      {
        path: "members/*",
        element: <Members />,
        role: 9,
        visible: true,
        authentication: true,
        team: false,
        paid: true,
      },
    ],
  },

  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        path: "",
        element: <Login />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      // {
      //   path: "roi-report/:token",
      //   element: <MarketingReport />,
      //   role: 11,
      //   visible: true,
      //   authentication: false,
      //   team: false,
      //   paid: false,
      // },
      // {
      //   path: "roi-report/",
      //   element: <MarketingReport />,
      //   role: 11,
      //   visible: true,
      //   authentication: false,
      //   team: false,
      //   paid: false,
      // },
      {
        path: "404",
        element: <Lost />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },

      {
        path: "ambassador-token/:token",
        element: <AmbassadorToken />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "validate-email",
        element: <ValidateEmail />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "validate-survey-email",
        element: <NoLoginRole />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },

      { path: "/", element: <Navigate to="/account/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/experimental",
    element: <AuthLayout />,
    children: [],
  },
  {
    path: "account",
    element: <HomeLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "upgrade-password",
        element: <UpgradePassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "logout",
        element: <Logout />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "register",
        element: <Register />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "register-token/:token",
        element: <RegisterToken />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      {
        path: "password-message",
        element: <PasswordMessage />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },

      {
        path: "validate-account",
        element: <Validate />,
        role: 11,
        visible: true,
        authentication: false,
        team: false,
        paid: false,
      },
      // { path: 'login', element: <LoginView /> },
      // { path: 'register', element: <RegisterView /> },
      // { path: 'logout', element: <Logout /> },
      // { path: 'forgot-password', element: <ForgotPassword /> },
      // { path: 'reset-password', element: <ResetPassword /> },
      // { path: '404', element: <NotFoundView /> },
      // { path: '/', element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
