import React from "react";

function UploadGroupIcon() {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21H2C1.73478 21 1.48043 20.8946 1.29289 20.7071C1.10536 20.5196 1 20.2652 1 20V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V11M14.75 21V14.5"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.25L13.25 15.5L14.75 14L16.25 15.5L17 16.25"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7H13M5 11H9"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default UploadGroupIcon;
