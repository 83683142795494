import React from "react";
import { hexToRGBA } from "../../../common/utils/ColorUtils";

const Dial = ({
  strokeWidth = 3,
  borderPercentage = 25,
  borderColor = "#2D70E2",
}) => {
  const radius = 50;
  const innerRadius = radius * 0.8;
  const borderCircleRadius = radius + strokeWidth;
  const circleCircumference = 2 * Math.PI * borderCircleRadius;

  const borderLength =
    (borderPercentage / 100) * 270 * ((circleCircumference - 20) / 360);

  const getCirclePosition = (angle: number, radius: number) => {
    const x = 50 + radius * Math.cos((angle - 90) * (Math.PI / 180));
    const y = 50 + radius * Math.sin((angle - 90) * (Math.PI / 180));
    return { x, y };
  };
  const circleAngle = 225 + ((borderPercentage + 2) / 100) * 266;
  const circlePosition = getCirclePosition(
    circleAngle,
    borderCircleRadius - strokeWidth / 2,
  );

  return (
    <svg width="80%" height="80%" viewBox="-10 -10 120 120" fill="none">
      <circle cx="50" cy="50" r={radius} fill={borderColor} opacity={0.1} />

      <path
        d="M50 50 L50 0 A50 50 0 0 1 101 50 Z"
        fill="#ffffff"
        transform="rotate(134.5, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={borderCircleRadius - strokeWidth / 2}
        fill="none"
        stroke="#ffffff"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={`${borderLength} ${
          circleCircumference - borderLength
        }`}
        transform="rotate(135, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={borderCircleRadius - strokeWidth / 2}
        fill="none"
        stroke={borderColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={`${borderLength} ${
          circleCircumference - borderLength
        }`}
        transform="rotate(135, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={innerRadius}
        fill="#ffffff"
        filter={`drop-shadow(0px 8px 20px ${hexToRGBA(borderColor, 0.08)})`}
      />
      <text
        x="50"
        y="50"
        text-anchor="middle"
        alignment-baseline="central"
        font-size="20"
        font-weight="bold"
        fill="#000"
        font-family="Raleway, sans-serif"
      >
        {borderPercentage}
      </text>
      <circle
        cx={circlePosition.x}
        cy={circlePosition.y}
        r="4"
        fill="none"
        stroke={borderColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Dial;
