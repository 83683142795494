import styled from "styled-components";
import React from "react";
import Matrix from "assets/images/nav/Matrix.svg";

const VIF = () => {
  return (
    <Container>
      <TableTitle>
        <Image src={Matrix} />
        Variance Inflation Factor Matrix
      </TableTitle>
      <Sub>
        Based on company and industry norms, there is an opportunity to reduce
        the turn over rate of this organization by up to 30%
      </Sub>
    </Container>
  );
};

export default VIF;

const Container = styled.div`
  width: 100%;
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 20px;
  color: #7e7e7e;
  max-width: 500px;
  padding-left: 33px;
`;

const Image = styled.img`
  margin-right: 10px;
  width: 20px;
`;
