import React from "react";
import styled from "styled-components";
import Down from "assets/images/UI/down-small.svg";
import Up from "assets/images/UI/up-small.svg";
import Dial from "./Dial";

type ContainerProps = Pick<ScoreProps, "bgColor" | "addMargin">;
type ScoreProps = {
  title?: string;
  change: number;
  score: number;
  color?: string;
  bgColor?: string;
  addMargin?: boolean;
};

const ScoreContainer = ({
  change,
  score,
  color,
  bgColor,
  addMargin,
}: ScoreProps) => {
  return (
    <Container bgColor={bgColor} addMargin={addMargin}>
      <Title>Overall Score</Title>
      <Bottom>
        <ChangeMetrics>
          <Change
            color={
              change > 0
                ? "#1D9A7D"
                : change && !isNaN(change)
                  ? "#F15062"
                  : "#000"
            }
          >
            {(change && (
              <>
                <Pic src={change < 0 ? Down : Up} /> {change > 0 ? "+" : ""}
                {change} %
              </>
            )) || <>--</>}
          </Change>
          <Sub>vs last assessment</Sub>
        </ChangeMetrics>

        <DialContainer>
          <Dial borderPercentage={score} borderColor={color} />
        </DialContainer>
      </Bottom>
    </Container>
  );
};

export default ScoreContainer;

const Container = styled.div`
  padding: 24px 5px 24px 24px;
  background-color: #f8faff;
  width: calc(33% - 20px);
  margin: ${(props: ContainerProps) =>
    props.addMargin ? "0 10px 10px 0" : "0"};
  max-height: 155px;
  min-height: 155px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2a3039;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Change = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.color};
`;

const Sub = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #666d79;
`;

const Pic = styled.img`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
`;

const ChangeMetrics = styled.div`
  width: 50%;
  margin-right: 30px;
`;

const DialContainer = styled.div`
  width: 100px;
`;
