import React from "react";

function ButtonTrashIcon() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 4.25H3.54167H14.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4587 4.24999V14.1667C13.4587 14.5424 13.3094 14.9027 13.0437 15.1684C12.778 15.4341 12.4177 15.5833 12.042 15.5833H4.95866C4.58294 15.5833 4.2226 15.4341 3.95692 15.1684C3.69125 14.9027 3.54199 14.5424 3.54199 14.1667V4.24999M5.66699 4.24999V2.83332C5.66699 2.4576 5.81625 2.09726 6.08192 1.83159C6.3476 1.56591 6.70794 1.41666 7.08366 1.41666H9.91699C10.2927 1.41666 10.653 1.56591 10.9187 1.83159C11.1844 2.09726 11.3337 2.4576 11.3337 2.83332V4.24999"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08301 7.79166V12.0417"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91699 7.79166V12.0417"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ButtonTrashIcon;
