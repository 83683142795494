import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal } from "semantic-ui-react";

import format from "date-fns/format";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SG_ADD_EMPLOYEE_LOG, UPDATE_EMPLOYEE } from "common/constants/actions";
import {
  StyledCancelButton,
  StyledReactivateButton,
} from "common/components/button/StyledButton";

const ArchivedTableList = ({ members, categories }) => {
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [deactiveReason, setDeactiveReason] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { get_employee, get_auth } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      get_employee: state.employees,
    }),
    shallowEqual,
  );

  const activateEmployee = () => {
    // Active status = 1
    dispatch({
      type: UPDATE_EMPLOYEE,
      payload: {
        id: selectedUser.id,
        status: 1,
      },
    });
    // SG_ADD_EMPLOYEE_LOG
    dispatch({
      type: SG_ADD_EMPLOYEE_LOG,
      payload: {
        employee: selectedUser.id,
        type_of_log: 2,
        description: {
          Archived: deactiveReason,
        },
      },
    });
    setSelectedUser();
    setShowArchiveModal(false);
    navigate("/core/members/list");
  };

  useEffect(() => {
    if (get_employee && get_auth) {
      const found = get_employee?.userEmp?.id === Number(get_auth?.employee_id);

      if (found) {
        setCurrentUser(get_employee?.userEmp);
      }
    }
  }, [get_employee, get_auth]);

  return (
    <>
      <div
        style={{
          marginTop: 30,
        }}
      >
        {members && members.length > 0 && (
          <TableContainer>
            <TopTableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Email</TableHeading>
              <TableHeading>Updated</TableHeading>
              <TableHeading>Actions</TableHeading>
            </TopTableRow>
            {members &&
              members.map((item, i) => {
                if (item.email) {
                  const dateUpdated = format(
                    new Date(item?.updated_at),
                    "yyyy-MM-dd",
                  );
                  let teamString = "N/A";
                  let popupString = "";
                  const empCategories = categories?.find(
                    (cat) => cat.employee === item.id,
                  );
                  if (empCategories?.category.length > 0) {
                    const primaryCategories = empCategories.category.filter(
                      (cat) => cat.priority === "primary",
                    );

                    if (primaryCategories) {
                      teamString =
                        primaryCategories.length > 1
                          ? `${primaryCategories[0]} +${
                              primaryCategories.length - 1
                            }`
                          : primaryCategories[0];

                      primaryCategories.forEach((cat, i) => {
                        if (i > 0) {
                          if (popupString.length > 0) {
                            popupString = popupString + ", " + cat.value;
                          } else {
                            popupString = cat.value;
                          }
                        }
                      });
                    }
                  }
                  return (
                    <TableRow key={i}>
                      <TableContentName>
                        {item?.first_name
                          ? item?.first_name + " " + item?.last_name
                          : "N/A"}
                      </TableContentName>
                      <TableContent>
                        {item?.email ? item?.email : "N/A"}
                      </TableContent>
                      <TableContent>{dateUpdated}</TableContent>
                      <TableContent>
                        {currentUser?.account_type === 5 && (
                          <Modal
                            onClose={() => {
                              setSelectedUser();
                              setShowArchiveModal(false);
                            }}
                            onOpen={() => {
                              setSelectedUser(item);
                              setShowArchiveModal(true);
                            }}
                            size="tiny"
                            open={showArchiveModal}
                            trigger={<StyledReactivateButton />}
                          >
                            <ModalContents>
                              <ModalHeader>
                                Are you sure you want to re-activate this user?
                              </ModalHeader>
                              <ModalBody>
                                <InputContainer>
                                  <RowData
                                    value={deactiveReason}
                                    placeholder="Re-Activate Reason"
                                    onChange={(s, t) => {
                                      setDeactiveReason(s.target.value);
                                    }}
                                  />
                                </InputContainer>
                                <ModalButtons>
                                  <StyledCancelButton
                                    onClick={() => {
                                      setSelectedUser();
                                      setShowArchiveModal(false);
                                    }}
                                  />
                                  <StyledReactivateButton
                                    onClick={() => activateEmployee(item.id)}
                                  />
                                </ModalButtons>
                              </ModalBody>
                            </ModalContents>
                          </Modal>
                        )}
                      </TableContent>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={i}>
                      <TableContent>N/A</TableContent>
                      <TableContent>N/A</TableContent>
                      <TableContent>N/A</TableContent>
                      <TableContent></TableContent>
                    </TableRow>
                  );
                }
              })}
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default ArchivedTableList;

const TableContainer = styled.div`
  width: 100%;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;

const TableContent = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContentName = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
`;

const ModalContents = styled.div`
  background-color: white;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
`;

const ModalHeader = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  display: flex;

  color: #414141;
`;

const ModalBody = styled.div`
  padding-top: 5px;
`;

const InputContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowData = styled.input`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 376px;
  height: 48px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 99px;
`;
