import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Divider, Icon, Loader, Message } from "semantic-ui-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CLEAR_ERRORS, REGISTER_USER } from "common/constants/actions";
import styled from "styled-components";
import Input from "common/components/input/Input";
import GoogleAuth from "./GoogleAuth";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { DomainContext } from "common/layouts/LayoutContext";
import MandatoryLinks from "./MandatoryLinks";

// const email_validation =
//   /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [checked, setChecked] = useState(false);
  // const { register, handleSubmit, control, setValue, watch, errors } =
  //   useForm();

  const { setPathPageView, setIdentity } = useTrackingCode();

  const Domain = useContext(DomainContext);
  const [formStep, setFormStep] = useState(0);

  const [valueChanged, setValueChanged] = useState(0);
  const [formValue, setFormValue] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [pristine, setPristine] = useState(true);

  const [valueChanged2, setValueChanged2] = useState(0);
  const [formValue2, setFormValue2] = useState({});
  const [formErrors2, setFormErrors2] = useState({});
  const [pristine2, setPristine2] = useState(true);

  const [msgResp, setMsgResp] = useState(false);
  // const [preValidation, setPreValidation] = useState(false);
  // const [domain, setDomain] = useState(window.location.hostname);

  setPathPageView("/trial");

  // const onSubmit = (data) => {
  //   // console.log({ ...data, terms: checked });
  //   dispatch({
  //     type: REGISTER_USER,
  //     payload: {
  //       ...data,
  //       company_name: formValue?.organization,
  //       // no_of_employees: empNos,
  //       enable_login: true,
  //       sub_domain: Domain.id
  //     },
  //   });
  //   setIdentity(data.email);
  //   setPathPageView("/registration");
  // };

  useEffect(() => {}, []);
  const {
    get_auth,
    // get_error,
    // get_pre_email_validation,
  } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      // get_pre_email_validation: state.validateEmail.preValidateEmail,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const timer1 = setTimeout(() => {
      // if (get_auth.register_org?.next_steps === 1) {
      //   navigate("/account/validate-account");
      // }
      if (get_auth?.token) {
        navigate("/app/dashboard");
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [navigate, get_auth]);

  useEffect(() => {
    setMsgResp(false);
    if (get_auth?.register_org?.message) {
      setMsgResp(get_auth?.register_org?.message);
    }
  }, [get_auth]);

  const onOrgSubmit = () => {
    if (!valueChanged) {
      // console.log("pristine")
      setPristine(false);
      return;
    }
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    setFormStep(1);
    // setPristine(true);
  };

  function ChangeFormValue(name, value) {
    const _FormValue = formValue;
    _FormValue[name] = value;
    setFormValue(_FormValue);
    setValueChanged(Math.random);
  }

  const onOrgSubmit2 = () => {
    if (!valueChanged2) {
      // console.log("pristine")
      setPristine2(false);
      return;
    }
    if (formErrors2?.errors > 0) {
      setPristine2(false);
      return;
    }

    dispatch({
      type: CLEAR_ERRORS,
    });

    setPristine2(true);
    dispatch({
      type: REGISTER_USER,
      payload: {
        first_name: formValue2?.first_name,
        last_name: formValue2?.last_name,
        email: formValue2?.email,
        password: formValue2?.password,
        password2: formValue2?.password2,
        company_name: formValue?.organization,
        // no_of_employees: empNos,
        enable_login: true,
        sub_domain: Domain.id,
      },
    });
    setIdentity(formValue2.email);
    setPathPageView("/registration");
  };

  function ChangeFormValue2(name, value) {
    const _FormValue2 = formValue2;
    _FormValue2[name] = value;
    setFormValue2(_FormValue2);
    setValueChanged2(Math.random);
  }

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;

    if (!formValue?.organization) {
      ErrorList["organization"] = {
        error: true,
        message: "Please add Organization",
      };
      totalErrors += 1;
    }
    if (!formValue?.terms) {
      ErrorList["terms"] = {
        error: true,
        message: "Please agree to terms and conditions",
      };
      totalErrors += 1;
    }

    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [formValue, pristine, valueChanged]);

  useEffect(() => {
    const ErrorList = {};
    let totalErrors = 0;
    // console.log("error checking form 2")
    if (!formValue2?.first_name) {
      // console.log("error checking form 2 first name")
      ErrorList["first_name"] = {
        error: true,
        message: "Please enter first name",
      };
      totalErrors += 1;
    }
    if (!formValue2?.last_name) {
      ErrorList["last_name"] = {
        error: true,
        message: "Please enter last name",
      };
      totalErrors += 1;
    }
    if (!formValue2?.email) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter email",
      };
      totalErrors += 1;
    }
    if (formValue2?.email) {
      // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if ( re.test(email) ) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValue2?.email)
      ) {
        ErrorList["email"] = {
          error: true,
          message: "Invalid email address",
        };
        totalErrors += 1;
      }
    }
    if (!formValue2?.password) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter password of min 8 characters",
      };
      totalErrors += 1;
    }
    // The min length of password is 8 characters
    if (formValue2?.password) {
      if (formValue2?.password.length < 8) {
        ErrorList["password"] = {
          error: true,
          message: "Please enter password of min 8 characters",
        };
        totalErrors += 1;
      }
    }

    if (formValue2?.password !== formValue2?.password2) {
      ErrorList["password2"] = {
        error: true,
        message: "Passwords do not match",
      };
      totalErrors += 1;
    }

    ErrorList["errors"] = totalErrors;

    setFormErrors2(ErrorList);
  }, [formValue2, pristine2, valueChanged2]);

  return (
    <Container>
      {Domain.allowRegister ? (
        <FormContainer>
          <IMG src={Domain.fullImage} />
          <Title top={true}>Welcome to {Domain.name}</Title>
          {formStep === 0 && (
            <Box>
              {/* close */}
              <P>Let's create your account!</P>
              {formErrors.organization?.error && !pristine && (
                <Message
                  error
                  // header={props.message}
                  content={formErrors.organization?.message}
                />
              )}

              <Input
                name="company_name"
                placeholder="Organization Name"
                onChange={(e) => ChangeFormValue("organization", e)}
                defaultValue={formValue?.organization}
              />
              {formErrors.terms?.error && !pristine && (
                <Message
                  error
                  // header={props.message}
                  content={formErrors.terms?.message}
                />
              )}
              <Checkbox
                onChange={() => ChangeFormValue("terms", !formValue?.terms)}
                checked={formValue?.terms}
                name="terms"
                label={{
                  children: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={"https://portal.nulogic.co/terms.html"}
                    >
                      I agree to the terms and conditions
                    </a>
                  ),
                }}
                style={{ borderColor: "black" }}
              />

              <Button type="submit" top={true} onClick={onOrgSubmit}>
                Next
              </Button>
            </Box>
          )}
          {formStep === 1 && (
            <Box>
              <CloseBox onClick={() => setFormStep(0)}>
                Back <Icon name="close" />
              </CloseBox>
              {msgResp && (
                <div>
                  <Loader />
                  <Message
                    success
                    // header='Action Forbidden'
                    content={msgResp}
                  />
                </div>
              )}
              <Button type="submit" top={true} onClick={() => setFormStep(2)}>
                Register with Email
              </Button>
              {Domain.showGoogleSso && (
                <>
                  <Divider horizontal>Or</Divider>
                  <GoogleAuth organizationName={formValue?.organization} />
                </>
              )}
            </Box>
          )}
          {formStep === 2 && (
            <>
              <CloseBox onClick={() => setFormStep(1)}>
                Back <Icon name="close" />
              </CloseBox>
              <Box>
                {msgResp && (
                  <div>
                    <Loader />
                    <Message
                      success
                      // header='Action Forbidden'
                      content={msgResp}
                    />
                  </div>
                )}

                <label>Organization</label>
                <Title>{formValue?.organization}</Title>

                <SpacedContainer>
                  <RowsContainer>
                    <Input
                      name="first_name"
                      placeholder="First name"
                      onChange={(e) => ChangeFormValue2("first_name", e)}
                      defaultValue={formValue2?.first_name}
                    />

                    {formErrors2?.first_name?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.first_name?.message}
                      </ErrorBanner>
                    )}
                  </RowsContainer>

                  <RowsContainer>
                    <Input
                      name="last_name"
                      placeholder="Last name"
                      onChange={(e) => ChangeFormValue2("last_name", e)}
                      defaultValue={formValue2?.last_name}
                    />
                    {formErrors2?.last_name?.error && !pristine2 && (
                      <ErrorBanner>
                        {formErrors2?.last_name?.message}
                      </ErrorBanner>
                    )}
                  </RowsContainer>
                </SpacedContainer>

                <Input
                  name="email"
                  placeholder="Email"
                  onChange={(e) => ChangeFormValue2("email", e)}
                  defaultValue={formValue2?.email}
                />
                {formErrors2?.email?.error && !pristine2 && (
                  <ErrorBanner>{formErrors2?.email?.message}</ErrorBanner>
                )}

                <Input
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => ChangeFormValue2("password", e)}
                  defaultValue={formValue2?.password}
                />

                {formErrors2?.password?.error && !pristine2 && (
                  <ErrorBanner>{formErrors2?.password?.message}</ErrorBanner>
                )}

                <Input
                  name="password2"
                  placeholder="Please enter Password Again"
                  type="password"
                  onChange={(e) => ChangeFormValue2("password2", e)}
                  defaultValue={formValue2?.password2}
                />

                {formErrors2?.password2?.error && !pristine2 && (
                  <ErrorBanner>{formErrors2?.password2?.message}</ErrorBanner>
                )}

                <Button onClick={onOrgSubmit2}>Get Started Now</Button>
              </Box>
              {/* // </form> */}
            </>
          )}
          <Box>
            <Bottom>
              <div>
                <Link to="/account/login">Already registered? Sign in</Link>
              </div>
            </Bottom>
            <MandatoryLinks Domain={Domain} />
          </Box>
        </FormContainer>
      ) : (
        <p>{Domain.notRegisterText}</p>
      )}
    </Container>
  );
};

export default Register;

const Title = styled.span`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 20px;
  font-family: "Barlow", sans-serif;
  margin-top: ${(props) => (props.top ? "30px" : "0px")};
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  margin-top: ${(props) => (props.top ? "30px" : "0px")};

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
const IMG = styled.img`
  width: 160px;
`;
const CloseBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  margin-bottom: 50px;
  color: #4634eb;
  font-size: 14px;
`;

const ErrorBanner = styled.div`
  color: red;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: -10px;
  width: 100%;
`;

const P = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;
  font-weight: bold;

  a {
    color: #2d50e2;
  }
`;

const FormContainer = styled.div`
  width: 50%;
  padding: 100px 30px 0 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 20px;
  }
`;

const SpacedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: center;
`;

const RowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
`;
