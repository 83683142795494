import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { DomainContext } from "common/layouts/LayoutContext";

import styled from "styled-components";

import { CLEAR_ALL, SG_RESET_PASSWORD } from "common/constants/actions";

import Input from "./InputLogin";

// import Background from "assets/images/login.jpg";
import UserErrorMessages from "./UserErrorMessages";

import { Message } from "semantic-ui-react";

const UpgradePassword = () => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  const Domain = useContext(DomainContext);

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [token, setToken] = useState(false);

  const [errorResp, setErrorResp] = useState(false);
  const [msgResp, setMsgResp] = useState(false);

  const { get_auth, get_error } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_error: state.errors,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setToken(get_auth?.pass_key);
  }, [get_auth]);

  useEffect(() => {
    setMsgResp(false);
    setErrorResp(get_auth?.forgot_password?.data?.error);
    if (get_auth.forgot_password?.data?.message?.length > 0) {
      setMsgResp(get_auth.forgot_password?.data?.message);
    }
  }, [get_auth]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;

    if (!token) {
      ErrorList["token"] = {
        error: true,
        message: "no token found",
      };
      totalErrors += 1;
    } else {
      if (password1?.length === 0) {
        ErrorList["password1"] = {
          error: true,
          message: "Please enter a password",
        };
        totalErrors += 1;
      }

      if (password1 !== password2) {
        ErrorList["password2"] = {
          error: true,
          message: "Passwords dont match",
        };
        totalErrors += 1;
      }

      if (password2?.length === 0) {
        ErrorList["password2"] = {
          error: true,
          message: "Please enter password again",
        };
        totalErrors += 1;
      }
    }
    ErrorList["errors"] = totalErrors;

    setFormErrors(ErrorList);
  }, [password1, password2, pristine, token]);

  function password1Value(e) {
    if (e) {
      setPassword1(e);
    }
  }

  function password2Value(e) {
    if (e) {
      setPassword2(e);
    }
  }

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    dispatch({
      type: CLEAR_ALL,
    });

    dispatch({
      type: SG_RESET_PASSWORD,
      payload: {
        new_password: password1,
        token: token,
      },
    });
    setPristine(true);
  };

  return (
    <Container>
      <Box>
        <Banner>
          <IMG src={Domain.image} />
        </Banner>
        <Title>Password Change</Title>
        <Description>Please update your password</Description>
        {get_error?.errors?.data?.status && (
          <Label basic color="red" pointing="below">
            {get_error?.errors?.statusText}
          </Label>
        )}

        {formErrors.token?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.token?.message}
          />
        )}

        {msgResp && (
          <Message
            error={errorResp}
            // header={props.message}
            content={msgResp}
          />
        )}
        {/* registerError */}
        <UserErrorMessages />

        <Input
          placeholder="Password"
          name="new_password"
          type="password"
          id="password1"
          value={password1}
          onChange={password1Value}
        />
        {formErrors.password1?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.password1?.message}
          />
        )}
        <Input
          placeholder="Same Password Again"
          name="password2"
          type="password"
          id="password2"
          value={password2}
          onChange={password2Value}
        />
        {formErrors.password2?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.password2?.message}
          />
        )}

        <Button onClick={onSubmit} id="change">
          Update Password
        </Button>
        <Bottom>
          <div>
            <Link to={"/account/login"}>Login</Link>
          </div>
          <div></div>
        </Bottom>
      </Box>
    </Container>
  );
};

export default UpgradePassword;

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 160px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 50px;
  width: 100%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
    font-weight: bold;
  }
`;
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    padding: 0;
    width: 90%;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;
