import {
  CLEAR_ALL,
  SIGNUP_DEPARTMENT,
  SIGNUP_EMPLOYEE,
  SIGNUP_HOME,
  SIGNUP_TEAM,
} from "common/constants/actions";

const initialState = {
  step: { id: 1, page: "Home" },
  data: [],
  department: [],
  team: [],
  employee: [],
  pending: false,
};

export default function signupStepsReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNUP_HOME:
      return {
        ...state,
        status: action.payload,
        errors: action.payload,
        showError: action.payload,
      };

    case SIGNUP_DEPARTMENT:
      return {
        ...state,
        step: { id: 2, page: "Department" },
        data: action.payload,
        department: action.payload,
        pending: false,
      };

    case SIGNUP_TEAM:
      return {
        ...state,
        step: { id: 4, page: "Team" },
        data: action.payload,
        team: action.payload,
        pending: false,
      };

    case SIGNUP_EMPLOYEE:
      return {
        ...state,
        step: { id: 6, page: "Employee" },
        data: action.payload,
        employee: action.payload,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        id: [],
        data: [],
        page: [],
        pending: false,
      };
    default:
      return state;
  }
}
