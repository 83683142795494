import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  EMAIL_DELIVERY_STATUS,
  FETCH_EMAIL_DELIVERY_STATUS,
  SG_EMAIL_DELIVERY_STATUS,
  SG_FETCH_EMAIL_DELIVERY_STATUS,
} from "common/constants/actions";

function* loadTokens(action) {
  // console.log(action.payload)
  try {
    const json = yield call(api.GET_EMAIL_DELIVERY_STATUS, action.payload);
    yield put({ type: EMAIL_DELIVERY_STATUS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* tokenLoad() {
  yield takeLatest(SG_EMAIL_DELIVERY_STATUS, loadTokens);
}

function* fetchToken(action) {
  try {
    const json = yield call(api.GET_EMAIL_DELIVERY_STATUS, action.payload);
    yield put({ type: FETCH_EMAIL_DELIVERY_STATUS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* tokenFetch() {
  yield takeLatest(SG_FETCH_EMAIL_DELIVERY_STATUS, fetchToken);
}

export default function* index() {
  yield all([tokenLoad(), tokenFetch()]);
}
