import React, { useContext, useEffect, useState } from "react";
import { Message } from "semantic-ui-react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Input from "./InputLogin";
import { ValidateEmail } from "common/utils/EmailUtils";
import { SG_FORGOT_PASSWORD } from "common/constants/actions";
import Select from "react-select";

import UserErrorMessages from "./UserErrorMessages";
import { DomainContext } from "common/layouts/LayoutContext";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  // let [searchParams, setSearchParams] = useSearchParams();
  // let logo = searchParams.get("org");
  const Domain = useContext(DomainContext);

  const [email, setEmail] = useState("");
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(false);

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual,
  );

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter a email",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email",
        };
        totalErrors += 1;
      }
    }

    if (orgList?.length > 0) {
      if (!selectedOrg.OrganizationID) {
        ErrorList["organization"] = {
          error: true,
          message: "Please select an Organization",
        };
        totalErrors += 1;
      }
    }

    ErrorList["errors"] = totalErrors;

    setFormErrors(ErrorList);
  }, [email, selectedOrg, orgList, pristine]);

  useEffect(() => {
    if (get_auth?.forgot_password) {
      setOrgList(get_auth?.forgot_password?.organization_list);
    }
  }, [get_auth]);

  function emailValue(e) {
    // console.log(e)
    setEmail(e);
  }

  useEffect(() => {
    if (get_auth?.forgot_password?.email) {
      setEmail(get_auth?.forgot_password?.email);
    }
  }, [get_auth?.forgot_password]);

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    dispatch({
      type: SG_FORGOT_PASSWORD,
      payload: {
        email: email,
        organization_id: selectedOrg?.OrganizationID,
      },
    });
    ResetForm();
  };

  function ResetForm() {
    setEmail("");
    setPristine(true);
    setFormErrors([]);
  }

  return (
    <Container>
      <Box>
        <Banner>
          <IMG src={Domain.image} />
        </Banner>
        <UserErrorMessages />

        {get_auth?.forgot_password?.message && (
          <Message
            success
            // header={props.message}
            content={get_auth?.forgot_password?.message}
          />
        )}

        <Title>Reset your password</Title>
        <Description>
          Please enter the email you use to login and we'll send instructions on
          how to reset your password to that email
        </Description>
        <Input
          placeholder="Email"
          name="email"
          type="email"
          id="email"
          value={email}
          onChange={emailValue}
        />
        {formErrors.email?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.email?.message}
          />
        )}

        {get_auth?.forgot_password?.next_steps === 1 && orgList?.length > 0 && (
          <>
            <Label>Select an Organization</Label>
            <Select
              name="organization"
              options={orgList}
              onChange={setSelectedOrg}
              getOptionLabel={(option) => `${option.Name} (${option.ID})`}
              getOptionValue={(option) => `${option.OrganizationID}`}
              value={selectedOrg}
            />
            {formErrors?.organization?.error && !pristine && (
              <Message
                error
                // header={props.message}
                content={formErrors?.organization?.message}
              />
            )}
          </>
        )}

        <Button onClick={onSubmit}>Forgot Password</Button>

        <Bottom>
          <div>
            <Link to={"/account/login"}>Login</Link>
          </div>
        </Bottom>
      </Box>
    </Container>
  );
};

export default ForgotPassword;

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;
const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;
const IMG = styled.img`
  width: 160px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
  width: 100%;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const P = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
  }
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;
