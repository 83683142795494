import {
  CLEAR_ALL,
  CLEAR_EMAIL_DELIVERY_STATUS,
  EMAIL_DELIVERY_STATUS,
  FETCH_EMAIL_DELIVERY_STATUS,
} from "common/constants/actions";

const initialState = {
  email_status: [],
  message_status: [],
  pending: false,
};

export default function emailDeliveryStatusReducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_DELIVERY_STATUS:
      return {
        ...state,
        email_status: action.payload,
        pending: false,
      };

    case FETCH_EMAIL_DELIVERY_STATUS:
      return { ...state, message_status: action.payload, pending: false };

    case CLEAR_EMAIL_DELIVERY_STATUS:
      return {
        ...state,
        email_status: [],
        message_status: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        email_status: [],
        message_status: [],
        pending: false,
      };
    default:
      return state;
  }
}
