import {
  CLEAR_ALL,
  CLEAR_ENGAGEMENT_DATA,
  ENGAGEMENT_DATA_PENDING,
  GET_ENGAGEMENT_DATA,
} from "common/constants/actions";

const initialState = {
  engagement_data: [],
  pending: false,
};

export default function EngagementData(state = initialState, action) {
  switch (action.type) {
    case ENGAGEMENT_DATA_PENDING:
      return {
        ...state,
        engagement_data: action.payload,
        pending: true,
      };
    case GET_ENGAGEMENT_DATA:
      return {
        ...state,
        engagement_data: action.payload,
        pending: false,
      };

    case CLEAR_ENGAGEMENT_DATA:
      return {
        ...state,
        engagement_data: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        engagement_data: [],
      };

    default:
      return state;
  }
}
