export const primaryColor = "#2D70E2";
export const primaryBg = "#EBF1FD";
export const secondaryColor = "#1D9A7D";
export const hoverBg = "#F8FAFF";
export const titleColor = "#2A3039";

export const inactiveColor = "#666D79";
export const poorColor = "#F15062";

export const neutralColor = "#DFDFDF";
export const filterColor = {
  text: inactiveColor,
  activeText: primaryColor,
  activeBackground: "#E3EEFF",
  background: "#F8FAFF",
};

export const fontSizes = {
  xsmall: "12px",
  small: "14px",
  medium: "16px",
  regular: "18px",
  large: "22px",
  xlarge: "24px",
};
