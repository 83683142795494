import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

const get_average = (data) => {
  let total = 0;
  let n = 0;

  data.map((i) => {
    total += i.average * i.n;
    n += i.n;
  });

  if (n == 0) {
    return "";
  }

  return total / n;
};

const average_responses = (resp) => {
  let total = 0;
  let n = 0;
  resp.map((i) => {
    total += i.response;
    n += 1;
  });

  if (n == 0) {
    return "";
  }

  return total / n;
};

const get_question_list = (q, questions) => {
  return questions.dimensions[q.dimension].factors[q.factor].questions;
};

const structured = (data, q) => {
  let responses = data.filter(
    (f) => f.dimension == q.dimension && f.section == q.factor,
  );

  return responses;
};

const QuestionSelect = ({
  question,
  info,
  refresh,
  questions,
  last_data,
  selectedQ,
  setSelected,
}) => {
  const [q, setQ] = useState(get_question_list(question, questions));
  const [data, setData] = useState(structured(info, question));

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  useEffect(() => {
    setData(structured(info, question));
    setQ(get_question_list(question, questions));
  }, [info, question]);

  const get_last_data = (q) => {
    return last_data
      .map((item) =>
        item.questions.filter(
          (f) =>
            f.id == selected.dimension &&
            f.factor == selected.factor &&
            f.q == q,
        ),
      )
      .flat();
  };

  return (
    <TableContainer>
      <Title>Pillar Question Analysis</Title>
      <Scale color={"181, 181, 181"}>
        <TH width={52.7} />
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <Num>{i}</Num>
        ))}
        <Num2 />
      </Scale>
      {q.map((item, i) => (
        <TableRow
          item={item}
          idx={i}
          data={data.filter((f) => Number(f.question == i))}
          color={"rgba(181, 181, 181,1)"}
          last={get_last_data(i)}
          onClick={() => setSelected(i)}
        />
      ))}
    </TableContainer>
  );
};

export default QuestionSelect;

function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
  );
}

const get_stdev = (data) => {
  let dataSet = [];
  data.map((item) => {
    item.distribution.map((x, i) => {
      for (let l = 0; l < x; l++) {
        dataSet.push(i);
      }
    });
  });
  return getStandardDeviation(dataSet);
};

const getWidth = (average, stdev) => {
  if (average - stdev < 0) {
    return average + stdev;
  } else if (average + stdev > 10) {
    return 10 - average + stdev;
  }

  return stdev * 2;
};

const getOffset = (average, stdev) => {
  if (average - stdev < 0) {
    return 0;
  }

  return average - stdev;
};

const TableRow = ({ item, data, color, idx, last, onClick }) => {
  const sortedData = data.sort((a, b) => a.average - b.average);
  const [open, setOpen] = React.useState(false);
  const stdev = get_stdev(data);
  const average = get_average(data);
  const last_average = average_responses(last);
  const diff = ((average - last_average) * 10).toFixed(1);

  return (
    <TR highlight={item.reverse} onClick={() => onClick()}>
      <TD
        width={40}
        borderRight={true}
        bold={item == "Average"}
        noBottom={open}
        top={idx === 0}
      >
        {item.q}
      </TD>
      <TD width={15} center={true} bold={item == "Average"} top={idx === 0}>
        {average.toFixed(1)}{" "}
        {last_average ? (
          <span
            style={{ color: diff > 0 ? "green" : "red", marginLeft: 5 }}
          >{`(${diff > 0 ? "+" : ""} ${diff}%)`}</span>
        ) : (
          ""
        )}
      </TD>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
        <TD width={4.09} dash={true} noBottom={i == 10} top={idx === 0}></TD>
      ))}

      <BarArea>
        <div
          style={{
            width: "100%",
            position: "relative",
            height: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <STDBAR
            width={getWidth(average, stdev)}
            left={getOffset(average, stdev)}
          />
          <Bar left={average} color={color} />
        </div>
      </BarArea>
    </TR>
  );
};

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  padding: 25px;
`;

const LabelContainer = styled.div`
  cursor: pointer;
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 12px;
  overflow: hidden;

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const Scale = styled.div`
  margin-bottom: 5px;
  display: flex;
`;

const First = styled.div`
  background-color: ${(props) => "rgba(" + props.color + ",1)"};
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-top: 1px solid black;
  height: 30px;
  align-items: center;
  padding-left: 5px;
`;

const TTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
`;

const TR = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  background-color: ${(props) => (props.highlight ? "#F5F5F5" : "white")};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  }
`;

const BarContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(55% - 2px);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 0px;
`;

const BarArea = styled.div`
  width: 42%;
  position: absolute;
  left: 54.5%;
  height: 100%;
  align-items: center;
  display: flex;
`;

const Bar = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  left: ${(props) => "calc(" + props.left * 10 + "% - 6px)"};
  top: calc(50% - 6px);
  background-color: white;
`;

const STDBAR = styled.div`
  height: 12px;
  width: ${(props) => props.width * 10 + "%"};
  background-color: #e66579;
  opacity: 1;
  left: ${(props) => "calc(" + props.left * 10 + "%)"};
  position: absolute;
  border-radius: 10px;
`;

const Bar2 = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: ${(props) => props.color};
  position: relative;
  border-radius: 10px;
`;

const Num = styled.div`
  width: 4.09%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #7e7e7e;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Num2 = styled.div`
  width: 2%;

  position: relative;
  display: flex;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.dash ? "0px " : "10px 5px")};
  border-right: ${(props) =>
    props.borderRight ? "1px solid #DFDFDF" : "none"};
  font-weight: ${(props) => (props.bold ? "bold" : 400)};
  border-left: ${(props) => (props.dash ? "1px dashed #DFDFDF " : "none")};
  border-bottom: ${(props) => (!props.noBottom ? "1px solid #DFDFDF" : "none")};
  border-top: ${(props) => (props.top ? "1px solid #DFDFDF" : "none")};
  box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const Details = styled.div`
  width: 100%;
  max-height: ${(props) => (props.open ? "1000px" : 0)};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const Score = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const Expand = styled.div`
  font-size: 10px;
  text-transform: uppercase;
`;

const Highlight = styled.div`
  margin-top: 20px;
  font-style: italic;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  max-width: 600px;
  color: #7e7e7e;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  margin-bottom: 20px;
  color: #414141;
`;
