function hexToRGBA(hex: string, alpha = 1) {
  // Ensure the hex color code starts with a '#'
  if (hex.charAt(0) !== "#") {
    throw new Error("Invalid hex color code format");
  }

  // Remove the '#' from the hex color code
  const cleanHex = hex.slice(1);

  // Check if the hex color code has a valid length (either 3 or 6 characters)
  if (cleanHex.length !== 3 && cleanHex.length !== 6) {
    throw new Error("Invalid hex color code length");
  }

  // Expand the 3-character hex color code to a 6-character one if necessary
  const finalHex =
    cleanHex.length === 3
      ? cleanHex
        .split("")
        .map((char) => char + char)
        .join("")
      : cleanHex;

  // Extract the red, green, and blue values from the hex color code
  const r = parseInt(finalHex.slice(0, 2), 16);
  const g = parseInt(finalHex.slice(2, 4), 16);
  const b = parseInt(finalHex.slice(4, 6), 16);

  // Return the RGBA color value as a string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export {
  hexToRGBA,
}
