import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import Chart from "react-apexcharts";
import QuestionDrop from "./QuestionDrop/index";
import { shallowEqual, useSelector } from "react-redux";
import { calculate_history_tab } from "../../data_navigation/get_historical";
import Empty from "../../../../common/components/Empty";

const Items = [
  "DEI Overall",
  "Person Dimension",
  "Mental health and well-being",
  "Phsyical health and well-being",
  "Psychological safety",
  "Phsyical safety and Safe sport",
  "Self-determination",
  "Performance Dimension",
  "Leadership",
  "Coaching",
  "Daily training environment",
  "Sport science and sport medicine",
  "Pathways and performance",
  "Athletes and international results",
];

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const frenchMonthNames = [
  "Jan",
  "Fév",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sep",
  "Oct",
  "Nov",
  "Déc",
];

const History = ({
  WhiteLabel,
  structure,
  rule,
  nav,
  is_french,
  language_hook,
}) => {
  const [question, setQuestion] = useState({ id: 0 });
  const [options, setOptions] = useState(data3.options);

  const [options2, setOptions2] = useState(data2.options);
  const [series, setSeries] = useState(data3.series);
  const [series2, setSeries2] = useState(data2.series);
  const [effect, setEffect] = useState([]);
  const [loading, setLoading] = useState(true);

  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual,
  );

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);
  const raw = useSelector((state) => state.audit?.raw_data, shallowEqual);
  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual,
  );
  const dates = useSelector((state) => state.audit?.dates, shallowEqual);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

  const getMonth = (month) => {
    let offset = month.getTimezoneOffset();
    let new_date = new Date(
      month.getTime() + offset * 60 * 1000 + 24 * 60 * 60 * 1000,
    ).getMonth();

    return new_date;
  };

  useEffect(() => {
    update();
  }, [question, filter_data, selected, anchor]);

  const update = () => {
    setLoading(true);
    let _options = options;

    let _options2 = options2;
    let color = "#476DFA";
    let chartColor = (_options.colors = [color, "#b5b5b5"]);
    let chartColor2 = (_options2.colors = [color]);
    let calculated_data = calculate_history_tab(
      filter_data,
      raw,
      selected,
      categories,
      dates,
      anchor,
      rule,
      structure,
      nav,
    );

    if (Object.keys(calculated_data).length > 0) {
      let chart1 = calculated_data.chart1.sort((a, b) => a.current - b.current);

      _options.xaxis.categories = chart1.map((item) =>
        is_french ? (item.fr ? item.fr : item.name) : item.name,
      );

      if (is_french) {
        _options.yaxis.title.text = "Moyenne";
        _options2.yaxis.title.text = "Moyenne";
        _options2.xaxis.title.text = "Date de l'audit";
      }

      // _options.annotations =  {
      // 	  yaxis: [
      // 	    {
      // 	      y: calculated_data.chart1[0].average,
      // 	      borderColor: '#476DFA',
      // 		      label: {
      // 		        borderColor: '#476DFA',
      // 		        orientation: 'horizontal',
      // 		        text: WhiteLabel?.norm ? WhiteLabel?.norm : 'Organization Average'
      // 		      }
      // 	    }
      // 	  ]
      // 	}

      let _series = [
        {
          name: is_french ? "moyennes actuelles" : "current averages",
          data: chart1.map((item) => Math.floor(item.current * 10) / 10),
        },
        {
          name: is_french ? "moyennes historiques" : "long term averages",
          data: chart1.map((item) => Math.floor(item.historical * 10) / 10),
        },
      ];

      let filtered_chart_data = [
        { data: calculated_data.chart2[0].data.filter((f) => f.y) },
      ];
      let months = filtered_chart_data[0].data.map((item) =>
        is_french
          ? frenchMonthNames[getMonth(item.x)]
          : monthNames[getMonth(item.x)],
      );
      filtered_chart_data[0].data = filtered_chart_data[0].data.map(
        (item) => item.y,
      );

      setSeries(_series);
      setSeries2(filtered_chart_data);
      setEffect(calculated_data.ES);

      _options2.xaxis.categories = months;
      setOptions2(_options2);
    }

    setOptions(_options);

    setTimeout(() => setLoading(false), 300);
  };

  if (series[0].data.length == 0) {
    return <Empty />;
  }

  return (
    <Container>
      <QuestionDrop
        color={WhiteLabel.highlight}
        structure={structure}
        selected={question}
        setSelected={setQuestion}
      />
      <SquareContainer>
        <Square width={100}>
          <Title>{language_hook("Score by category")}</Title>
          <Description>{language_hook("Chart1")}</Description>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Chart
              options={options}
              series={series}
              type="bar"
              height={"100%"}
              style={{ marginTop: -30 }}
            />
          )}
        </Square>

        <Square width={effect?.length > 0 ? 70 : 100}>
          <Title>{language_hook("Trends")}</Title>
          <Description>{language_hook("Trends1")}</Description>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Chart
              options={options2}
              series={series2}
              type="line"
              height={"100%"}
            />
          )}
        </Square>

        <Square width={effect?.length > 0 ? 30 : 0} padding={0} scroll={true}>
          <div style={{ padding: "24px 24px 0px 24px" }}>
            <Title>Smart insights</Title>
            <Description>
              Critical factors that are most impacted and in need of attention
            </Description>
          </div>
          <Labels>
            {effect
              ? effect.length > 0
                ? effect.map((item, i) => {
                    let size = Math.abs(Math.max(...item.ES));
                    let efs = Math.max(...item.ES);
                    let direction =
                      efs > -0.2 && efs < 0.2
                        ? ""
                        : efs > 0
                          ? "Positive "
                          : "Negative ";
                    let magnitude =
                      size < 0.2
                        ? "No "
                        : size < 0.5
                          ? "Small "
                          : size < 0.8
                            ? "Moderate "
                            : size < 1.3
                              ? "Large "
                              : "Very Large ";

                    return (
                      <Label
                        color={
                          direction
                            ? direction == "Positive "
                              ? "66, 135, 245"
                              : "245, 24, 65"
                            : "135, 135, 135"
                        }
                        title={item.name}
                        text={magnitude + "Effect"}
                        icon={
                          direction
                            ? direction == "Positive "
                              ? "plus"
                              : "exclamation"
                            : "balance scale"
                        }
                        key={i}
                        last={i == effect.length - 1}
                      />
                    );
                  })
                : ""
              : ""}
          </Labels>
        </Square>
      </SquareContainer>
    </Container>
  );
};

export default History;

const Label = (props) => {
  return (
    <LabelContainer last={props.last}>
      <LabelTitle>{props.title}</LabelTitle>
      <LabelText>{props.text}</LabelText>
    </LabelContainer>
  );
};

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4px;
  border-radius: 2px;
  width: 100%;
  border-top: 1px solid #d7dce6;
  border-bottom: ${(props) => (props.last ? "1px solid #D7DCE6" : "")};
  padding: 13px 0px 13px 24px;
`;

const LabelTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #a5a5a5;
`;

const LabelText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Container = styled.div`
  padding-top: 19px;
  padding-left: 58px;
`;

const First = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-top: 1px solid black;
  height: 30px;
  align-items: center;
  padding-left: 5px;
`;

const SquareContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Options = styled.div`
  width: 200px;
  height: 350px;
  margin-left: 20px;
  padding: 10px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  margin-left: 25px;

  color: #414141;
`;

const Square = styled.div`
  width: ${(props) => "calc(" + props.width + "% - 10px)"};
  height: 350px;
  padding: ${(props) => (props.padding === 0 ? 0 : "24px")};
  margin-bottom: 10px;
  margin-right: 10px;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow-y: ${(props) => (props.scroll ? "scroll" : "none")};
  &::-webkit-scrollbar {
    width: 3px;
  }
`;

const Num = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-left: 25px;

  color: #c0c0c0;
`;

const Region = styled.div`
  margin-top: 10px;
  line-height: 1;
  margin-bottom: 3px;
`;

const data2 = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["orange", "grey"],
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [5, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    xaxis: {
      type: "category",
      title: {
        text: "Survey Date",
        style: {
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 1,
    },
  },
};

const data3 = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6],
    },
    {
      name: "Category Norms",
      data: [8.6, 8.0, 7.3, 8.5],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    colors: ["#5167DD", "#D7DCE6"],
    fill: {
      type: ["solid", "pattern"],
      opacity: 1,
      pattern: {
        style: [
          "verticalLines",
          "slantedLines",
          "verticalLines",
          "horizontalLines",
        ], // string or array of strings
      },
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        colors: ["white", "black"],
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Coaches",
        "Technical Leaders",
        "Sport Scientists",
        "Athletes",
      ],
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 0,
    },
  },
};

const data = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6],
    },
  ],
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#6a00ff", "#3078ff"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
    },
    yaxis: {
      show: false,
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 0,
    },
  },
};

const tagOptions = [
  {
    key: "Important",
    text: "Mental health and well-being",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Phsyical health and well-being",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Psychological safety",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Phsyical safety and Safe sport",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Self-determination",
    value: "Enhancement",
  },
];

const tagOptions2 = [
  {
    key: "Important",
    text: "Leadership",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Coaching",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Daily training environment",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Sport science and sport medicine",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Pathways and performance",
    value: "Enhancement",
  },
  {
    key: "Self-determination",
    text: "Athletes and international results",
    value: "Enhancement",
  },
];
