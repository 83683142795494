import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const DownloadButton = (props) => {
  const { btnAction, showControl, setPdfGenerating, overviewReport } = props;
  const [generateClicked, setGenerateClicked] = useState(false);

  const handleGenerateButtonClicked = () => {
    setPdfGenerating(true);
    btnAction();
    setGenerateClicked(true);
  };

  const generateButton = (
    <PDFGenerateWrapper overviewReport={overviewReport}>
      <Button
        disabled={!showControl}
        onClick={() => handleGenerateButtonClicked()}
      >
        Build PDF
      </Button>
    </PDFGenerateWrapper>
  );

  if (!showControl && !overviewReport) {
    return (
      <NoDataDiv overviewReport={overviewReport}>Not Enough Data</NoDataDiv>
    );
  }

  return generateClicked ? null : generateButton;
};

const NoDataDiv = styled.div`
  width: 110px;
  position: ${(props) => (props.overviewReport ? "relative" : "absolute")};
  left: 0;
  bottom: ${(props) => (props.overviewReport ? "0" : "25px")};
  line-height: 0px;
`;

const PDFGenerateWrapper = styled.div`
  width: 110px;
  position: ${(props) => (props.overviewReport ? "relative" : "absolute")};
  left: 0;
  top: 0;
`;

DownloadButton.propTypes = {
  btnReady: PropTypes.bool,
  btnAction: PropTypes.func,
};

export default DownloadButton;
