import styled from "styled-components";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import Heatmap from "./Heatmap";
import LinkedRow from "./LinkedRow";
import { calculate_data } from "../../../common/calculations/analytics";
import Chart from "react-apexcharts";
import ChartLoading from "common/components/analytics/ChartLoading";
import Empty from "common/components/Empty";

const Grid = ({
  data,
  nav,
  refresh,
  structure,
  WhiteLabel,
  setSub,
  rule,
  questions,
  pdf,
  overviewReport = false,
  selected,
}) => {
  const [select, setSelected] = React.useState(0);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const [calculated, setCalculated] = React.useState([]);
  const [render, setRender] = React.useState(false);
  const [chart, setChart] = React.useState(data3.options);
  const [series, setData] = React.useState(data3.series);
  const [loading, setLoading] = React.useState(true);

  const get_survey_structure = useSelector(
    (state) => state.surveystructure.survey_structure,
    shallowEqual,
  );
  const pdfAnchor = get_survey_structure[0].categories.find(
    (f) => f.priority.toLowerCase() === "primary",
  ).id;

  const checkSubFactors = (id, option) => {
    if (structure.categories[id].subfactors.length > 0) {
      let factor = structure.categories[id].subfactors.filter(
        (f) => f.parent == option,
      );
      if (factor.length > 0) {
        return true;
      }
    }

    return false;
  };

  const handleBarClick = (
    { clientX, clientY },
    chartContext,
    { dataPointIndex },
  ) => {
    setSelected(dataPointIndex);
  };

  const clipLength = (s) => {
    if (s.length > 17) {
      return s.slice(0, 15) + "...";
    }
    return s;
  };

  React.useEffect(() => {
    setLoading(true);
    let _series = calculated.map((item, i) => {
      return {
        name: clipLength(item.title.name),
        data: item.summary[0].toFixed(1),
      };
    });

    setData([{ name: "Average Scores", data: _series.map((i) => i.data) }]);
    let _chart = chart;
    _chart.colors = [WhiteLabel.highlight, "#c9c9c9"];
    _chart.xaxis.categories = _series.map((i) => i.name);

    _chart.chart.events = {
      dataPointSelection: handleBarClick,
    };

    _chart.colors = [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex == select) {
          return "#66D686";
        } else {
          return "#2C4FE2";
        }
      },
    ];

    setChart(_chart);
    setTimeout(() => setLoading(false), 300);
  }, [calculated, select]);

  React.useEffect(() => {
    setSelected(0);
  }, [calculated]);

  React.useEffect(() => {
    setSelected(0);
  }, [anchor]);

  React.useEffect(() => {
    if (anchor) {
      setRender(!render);
      setCalculated(
        calculate_data(data, nav, structure, anchor, rule, questions).sort(
          (a, b) => {
            let _a = parseInt(a.summary[0]) ? parseInt(a.summary[0]) : 0;
            let _b = parseInt(b.summary[0]) ? parseInt(b.summary[0]) : 0;
            return _b - _a;
          },
        ),
      );
    }
  }, [nav, refresh, anchor, data]);

  if (calculated.length == 0 || calculated[0]?.summary[0] == 0) {
    return <Empty />;
  }

  const getDiff = () => {
    if (calculated[select].summary_diff[0] > 0) {
      return (
        <Diff style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 5, color: "green" }}>
            ({(calculated[select].summary_diff[0] * 10).toFixed(1)}%)
          </div>
        </Diff>
      );
    } else if (calculated[select].summary_diff[0] < 0) {
      return (
        <Diff style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginLeft: 5, color: "red" }}>
            ({(calculated[select].summary_diff[0] * 10).toFixed(1)}%)
          </div>
        </Diff>
      );
    }
  };

  return (
    <BigC>
      <First color={WhiteLabel.highlight} className="title-bar">
        <Title>nuLogic Metrics</Title>
      </First>
      <Main>
        {selected == 1 ? (
          <Container pdf={pdf}>
            <LTitle style={{ marginLeft: 32 }}>Overall Scores</LTitle>
            <Text>
              Scores from the most recent assessment for each anchor factor
            </Text>
            {loading ? (
              <ChartLoading />
            ) : (
              <Chart options={chart} series={series} type="bar" height={400} />
            )}
          </Container>
        ) : (
          <Heatmap
            questions={questions}
            categories={structure}
            nav={nav}
            rule={rule}
            data2={data}
            anchor={anchor}
            color={WhiteLabel.highlight}
          />
        )}

        {selected == 1 ? (
          <Legend className="legend-container">
            <Card>
              <CardTop>
                <LTitle>Legend</LTitle>
                <TeamTitle>
                  <Circle color={"#09E1C0"} />
                  {calculated[select].title.name}
                </TeamTitle>
                <Score>
                  <ROW>
                    {calculated[select].summary[0].toFixed(1)}
                    {getDiff()}
                  </ROW>

                  <Sub>Overall Score</Sub>
                </Score>
              </CardTop>
              <Title2>Factor Ranking</Title2>
              {loading ? (
                <div style={{ height: 250 }}>
                  <ChartLoading />
                </div>
              ) : (
                <LinkedRow data={calculated[select]} color={"orange"} />
              )}
            </Card>
          </Legend>
        ) : (
          ""
        )}
      </Main>
    </BigC>
  );
};

export default Grid;

const LTitle = styled.div`
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* identical to box height */

  color: #414141;
`;

const Positioned = styled.div`
  position: absolute;
`;

const Circle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const BigC = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-left: 59px;
  font-family: "Inter", sans-serif;
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

const Container = styled.div`
  width: 700px;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 500px;
`;

const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;

  color: #414141;
`;
const TeamTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: #7e7e7e;
  display: flex;
  align-items: center;
`;

const Title2 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 730px);
  margin-left: 30px;
  transition: all 500ms;
  height: 100%;
`;

const First = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 20px;
  color: #414141;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 10px;
  margin-top: 19px;
  font-family: "Poppins", sans-serif;
`;

const Card = styled.div`
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  height: 500px;
`;

const CardTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Score = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 22px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Sub = styled.div`
  font-size: 10px;
  font-weight: 400;
`;

const Text = styled.div`
  font-size: 14px;
  margin-top: 5px;
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-left: 32px;
`;

const Diff = styled.span`
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const ROW = styled.div`
  display: flex;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: "100%",
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        colors: {
          backgroundBarOpacity: 1,
        },
      },
    },
    colors: ["#476DFA"],
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: true,
        offsetY: 5,
        minHeight: 110,
      },
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      min: 0,
      max: 10,
      decimalsInFloat: 0,
      title: {
        text: "Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
