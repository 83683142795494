import { format } from "date-fns";
import styled from "styled-components";

const OverviewCard = ({ sportName, completed, SportFound }) => {
  const DateinFormat = format(
    new Date(SportFound?.scheduled_for),
    "dd-MMM-yyyy",
  );
  if (completed) {
    return (
      <Card>
        <OT>Completed</OT>
        <Description>
          {sportName} completed on {DateinFormat}
        </Description>
      </Card>
    );
  }
  return (
    <Card>
      <OT>Scheduled</OT>
      <Description>
        {sportName} scheduled for {DateinFormat}
      </Description>
    </Card>
  );
};

export default OverviewCard;

const Card = styled.div`
  width: 300px;
  padding: 10px;
`;

const OT = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Barlow", sans-serif;
`;

const Description = styled.div`
  font-size: 12px;
`;
