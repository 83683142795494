import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SG_GET_EMPLOYEE_CATEGORIES, SG_GET_SAFE_EMPLOYEE_LIST } from "common/constants/actions";

/**
 *
 * @param {auditTypeId: int, surveyType: int } params
 *
 * @param allCategories (Boolean)
 *  Determines whether or not to use `employee=${employeeId}`
 *  or `organization=${OrgId}`
 *
 * @returns
 */
function useGetOrgData(params) {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [filteredEmployeesSafe, setFilteredEmployeesSafe] = useState(null);
  const [isOTP, setIsOTP] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();

  const {
    employeeId,
    get_auth,
    get_selectedOrg,
    get_employee,
    get_organizations,
    get_employee_categories,
    employeesListSafe,
    get_employees,
  } = useSelector(
    (state) => ({
      employeeId: Number(state.auth?.employee_id),
      get_errors: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_employee_categories: state.employee_category.employee_category,
      employeesListSafe: state.employees.employeesListSafe,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const org = useSelector((state) => {
    return state.organizations;
  });

  useEffect(() => {
    if (get_organizations) {
      const isOTP = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;
      setIsOTP(isOTP);
    }
  }, [get_organizations]);

  useEffect(() => {
    if (params?.orgCategories && org) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `organization=${org}`,
      });
    }
  }, [org, params?.orgCategories]);

  useEffect(() => {
    setEmployeeCategories(get_employee_categories);
  }, [get_employee_categories]);

  useEffect(() => {
    if (
      !params?.orgCategories &&
      employeeId &&
      get_employee_categories.length === 0
    ) {
      // // I believe this statement can be removed, as there appears to be no
      // // implementation using this particular dispatch.
      // dispatch({
      //   type: SG_GET_EMPLOYEE_CATEGORIES,
      //   payload: `employee=${employeeId}`,
      // });
    }

    if (org && !organizationData) {
      setOrganizationData(org[organizationId || org?.organization?.id]);
    }
  }, [
    organizationId,
    dispatch,
    employeeId,
    ambassadorId,
    org,
    get_employee_categories.length,
    organizationData,
  ]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SAFE_EMPLOYEE_LIST,
    });
  }, [dispatch]);

  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth]);

  useEffect(() => {
    if (employeesListSafe?.length > 0 && organizationId) {
      const filteredEmployees = employeesListSafe.filter(
        (emp) => Number(emp.organization) === organizationId
      );

      setFilteredEmployeesSafe(filteredEmployees);
    }
  }, [employeesListSafe, organizationId]);

  return {
    get_auth,
    get_organizations,
    get_employee,
    get_selectedOrg,
    organizationId,
    organizationData,
    ambassadorId,
    employeesListSafe: filteredEmployeesSafe,
    get_employee_categories,
    isOTP,
    employeeCategories,
    get_employees,
  };
}

export default useGetOrgData;
