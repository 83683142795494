

export const feedback =[
	{
		q:0,
		factor:0,
		id:0,
		question:'People feel safe sharing ideas, feedback, and opinions, even if they differ from others.',
		feedback:['This is a strength of our team right now. I think most people are feeling comfortable in the environment to say something when they see something.',
					'Being new to the team I don’t feel like it is my place to say things to other people, I am not comfortable with my teammates yet. The older players kind of what it their way anyways.']
	},
	{
		q:3,
		factor:4,
		id:0,
		question:'People receive the 1-on-1 support they need to grow. ',
		feedback:['I like how the coaching staff has set up weekly times scheduled for us to meet with them. This gives us a time that we can ask questions and talk about ourselves.',
					'The coaches tell us the door is always open but when it comes time to get together, I find it hard to schedule a meeting or the staff isn’t available. It seems like more of a bother than anything else.']
	},
	{
		q:1,
		factor:1,
		id:0,
		question:'Well-being is often compromised in in service of performance.',
		feedback:['The therapy folks and the coaches have a good system to support us when it comes to injuries and things like that. It feels collaborative.',
					'There have been a few times where I felt like it was just better to not say anything and practice because I didn’t want to bring it up to anyone and cause problems.']
	},
	{
		q:4,
		factor:2,
		id:0,
		question:'Our cohesion is a strength in competitive, pressure situations.',
		feedback:['Our team seems to come together and trust each other when the going gets a little tough. We stick together. This might be one of our biggest strengths as a team last season.',
					'When we play games and we face adversity, everyone starts to do their own thing or just shut down completely. Sometimes I don’t see the point when it gets like that.']
	},
	{
		q:2,
		factor:2,
		id:1,
		question:'People rarely get the feedback they need to improve',
		feedback:['I like the routine at the beginning of the week when we debrief the weekend and talk about things we need to improve as a individuals and as a team.',
					'It always seems like coaches are too busy to give us feedback to improve. That time seems to be reserved more for the players that get to play a lot.']
	},
	{
		q:3,
		factor:3,
		id:1,
		question:'When expectations or standards aren’t met, it is addressed ',
		feedback:['The older players on the team have a good way of communicating this to the rest of us. This is something that we focus on as a team and most are receptive to it.',
					'I find this one confusing because our expectations and standards are always clear with everything so it is hard to address it, but it is a problem.']
	},
	{
		q:0,
		factor:5,
		id:1,
		question:'Communication is candid and transparent.',
		feedback:['the players only chat is what makes me score this high for our culture. People are able to communicate and ask questions and someone always answers and helps the other players.',
					'The messages sometimes contradict each other. We get one thing one day and something else the next. It is hard to keep track about what we are doing and how we are supposed to do it.']
	}
]


export const feedback2 = [
	{
		q:0,
		factor:5,
		id:0,
		question:'I feel like I’m a part of our company culture.',
		feedback:['It always feels like we have a collaborative approach to our culture and we all have a hand in creating it. This starts with the recruiting and hiring process that seems to include a focus on adding people that fit what we are trying to accomplish.',
					'2.	There are too many disconnects at different levels of leadership to score this question high and I don’t feel like I can be the one to change this. ']
	},
	{
		q:0,
		factor:2,
		id:0,
		question:'This work environment allows for spontaneous collaboration to occur. ',
		feedback:['I find this one to be a definite strength of our team! Being back in the office is helping this process and allows us to collaborate more frequently and efficiently.',
					'This has suffered since we have been in this hybrid model and schedules are all over the place. It’s hard to get a rhythm as we try to figure out these new work flows. ']
	},
	{
		q:3,
		factor:0,
		id:0,
		question:'Ours is a culture where communication is candid and transparent.',
		feedback:['The team of team approach to our company structure has really helped this area. We have trust within our close working groups so people feel comfortable to always speak candidly. ',
					'Often times messages contradict each other. The messages come down and get communicated a certain way one week, and then another the next week. Confusion ensues! ']
	},
	{
		q:0,
		factor:1,
		id:1,
		question:'Our leaders prioritize the growth and development of others.',
		feedback:['This is an obvious priority here. We have a great system in place to allow for growth and autonomy in this space. It is really encouraged and acted upon! ',
					'This is something we always talk about but it seems like everyone is too busy with their work to even think about growing or developing our roles.']
	},
	{
		q:1,
		factor:0,
		id:1,
		question:'When expectations or standards aren’t met, it is addressed ',
		feedback:['There is a lot of trust built up amongst the group and we are comfortable holding each other to the standards that we have set.',
					'The more senior employees tend to “get away” with much more than the the newer ones. People are hesitant to tell it like it is and jeopardize relationships.']
	},
	{
		q:0,
		factor:2,
		id:1,
		question:'It’s encouraged to be bold and take risks when solving problems here.',
		feedback:['This is one of the main reasons I love working here, this area is extremely supported by the leadership.',
					'A lot of uncertainty by many about how the leaders are going to react based on decisions made by many of us. It is causing us to be paralyzed because no one wants to make a mistake.']
	},
	{
		q:0,
		factor:3,
		id:1,
		question:'When changes are being implemented, the impact of those changes on individuals is considered.',
		feedback:['Since we are a small, tight-knit team, there is always lots of opportunity to provide feedback about the decisions that are being made. This is a huge strength of ours and why we have been able to be successful. ',
					'We are operating in such a fast pace environment that decisions are getting made often times without a lot of consideration of how to impacts the downward work flow. This is how it has been ever since I can remember.']
	}
]

export const feedback3 = [
	{
		q:0,
		factor:2,
		id:0,
		question:'This work environment allows for spontaneous collaboration to occur. ',
		feedback:['I find this one to be a definite strength of our team! Being back in the office is helping this process and allows us to collaborate more frequently and efficiently.',
					'This has suffered since we have been in this hybrid model and schedules are all over the place. It’s hard to get a rhythm as we try to figure out these new work flows. ']
	},
	{
		q:3,
		factor:0,
		id:0,
		question:'Ours is a culture where communication is candid and transparent.',
		feedback:['The team of team approach to our company structure has really helped this area. We have trust within our close working groups so people feel comfortable to always speak candidly. ',
					'Often times messages contradict each other. The messages come down and get communicated a certain way one week, and then another the next week. Confusion ensues! ']
	},
	{
		q:0,
		factor:1,
		id:1,
		question:'Our leaders prioritize the growth and development of others.',
		feedback:['This is an obvious priority here. We have a great system in place to allow for growth and autonomy in this space. It is really encouraged and acted upon! ',
					'This is something we always talk about but it seems like everyone is too busy with their work to even think about growing or developing our roles.']
	},
	{
		q:1,
		factor:0,
		id:1,
		question:'When expectations or standards aren’t met, it is addressed ',
		feedback:['There is a lot of trust built up amongst the group and we are comfortable holding each other to the standards that we have set.',
					'The more senior employees tend to “get away” with much more than the the newer ones. People are hesitant to tell it like it is and jeopardize relationships.']
	},
	{
		q:0,
		factor:2,
		id:1,
		question:'It’s encouraged to be bold and take risks when solving problems here.',
		feedback:['This is one of the main reasons I love working here, this area is extremely supported by the leadership.',
					'A lot of uncertainty by many about how the leaders are going to react based on decisions made by many of us. It is causing us to be paralyzed because no one wants to make a mistake.']
	},
	{
		q:0,
		factor:3,
		id:1,
		question:'When changes are being implemented, the impact of those changes on individuals is considered.',
		feedback:['Since we are a small, tight-knit team, there is always lots of opportunity to provide feedback about the decisions that are being made. This is a huge strength of ours and why we have been able to be successful. ',
					'We are operating in such a fast pace environment that decisions are getting made often times without a lot of consideration of how to impacts the downward work flow. This is how it has been ever since I can remember.']
	},
	{
		q:0,
		factor:3,
		id:1,
		question:'When changes are being implemented, the impact of those changes on individuals is considered.',
		feedback:['Since we are a small, tight-knit team, there is always lots of opportunity to provide feedback about the decisions that are being made. This is a huge strength of ours and why we have been able to be successful. ',
					'We are operating in such a fast pace environment that decisions are getting made often times without a lot of consideration of how to impacts the downward work flow. This is how it has been ever since I can remember.']
	}
]