import Resulta from "assets/images/nav/resulta.svg";
import IL from "assets/images/otp/otp.png";
import Nu from "assets/images/nav/nuwave.png";

import Blades from "assets/images/nav/blades.png";

export const white_label_data = {
  OTP: {
    image: IL,
    background: "rgba(0, 0, 0,0.9)",
    highlight: "rgb(227, 176, 31)",
    color2: "#b5b5b5",
    color3: "#c97c0c",
    nav_background: true,
    overview: 1,
    progress_color: "rgb(227, 176, 31)",
    chart_color: "#E3B01F",
  },
  Resulta: {
    image: Resulta,
    background: "#434343",
    highlight: "#ff6b00",
    color2: "#ff6b00",
    color3: "#ff6b00",
    nav_background: false,
    overview: 0,
    progress_color: "#8497B0",
    chart_color: "#ff6b00",
  },
  Nuwave: {
    image: Nu,
    background: "rgba(0, 0, 0,0.9)",
    highlight: "#009370",
    color2: "#009370",
    color3: "#009370",
    nav_background: false,
    overview: 0,
    progress_color: "#8497B0",
    chart_color: "#009370",
  },
  Blades: {
    image: Blades,
    background: "#1B0861",
    highlight: "#FECD07",
    color2: "#FECD07",
    color3: "#FECD07",
    nav_background: false,
    overview: 0,
    progress_color: "#8497B0",
    chart_color: "#009370",
  },
};
