import React from "react";
import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import Table from "../components/Table";
import Footer from "../components/Footer";
import Side from "../components/Side";

// Create styles
const styles = StyleSheet.create({
  date: {
    fontSize: 20,
    fontWeight: 700,
  },
  title2: {
    marginRight: 20,
    fontSize: 14,
    fontWeight: 500,
  },
  score: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: "black",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontWeight: 700,
    fontSize: 20,
  },
  score2: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: "black",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontWeight: 700,
    fontSize: 14,
  },
  page: {
    padding: 60,
    width: "100%",
    position: "relative",
  },
});

const tableTemplate2 = {
  rows: [
    { title: "Location", width: "25%" },
    { title: "Responses", width: "25%" },
    { title: "Total Requests", width: "25%" },
    { title: "Engagement", width: "25%" },
  ],
  data: [
    ["New York", 12, 20, "60%"],
    ["New York", 12, 20, "60%"],
    ["New York", 12, 20, "60%"],
    ["New York", 12, 20, "60%"],
    ["New York", 12, 20, "60%"],
  ],
};

const Engagement = () => {
  return (
    <Page style={styles.page}>
      <Footer />
      <Side />
      <View
        style={{ flexDirection: "row", alignItems: "center", marginBottom: 10 }}
      >
        <Text style={styles.title2}>Data collection date:</Text>
        <Text style={styles.date}>Jan 5th, 2022</Text>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }}
      >
        <View style={styles.score}>
          <Text>76%</Text>
        </View>

        <View style={{ marginLeft: 20, width: "80%" }}>
          <Text style={styles.title2}>Engagement rate</Text>
          <Text style={{ fontSize: 12 }}>
            Beef drumstick capicola, pork chop frankfurter tail rump picanha
            fatback pork. Tongue tenderloin bresaola beef ribs, ground round
          </Text>
        </View>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
      >
        <View style={styles.score}>
          <Text>67th</Text>
        </View>

        <View style={{ marginLeft: 20, width: "80%" }}>
          <Text style={styles.title2}>Engagement standard</Text>
          <Text style={{ fontSize: 12 }}>
            Beef drumstick capicola, pork chop frankfurter tail rump picanha
            fatback pork. Tongue tenderloin bresaola beef ribs, ground round
          </Text>
        </View>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
      >
        <View style={styles.score2}>
          <Text>Medium</Text>
        </View>

        <View style={{ marginLeft: 20, width: "80%" }}>
          <Text style={styles.title2}>Response Quality</Text>
          <Text style={{ fontSize: 12 }}>
            Beef drumstick capicola, pork chop frankfurter tail rump picanha
            fatback pork. Tongue tenderloin bresaola beef ribs, ground round
          </Text>
        </View>
      </View>

      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
      >
        <View style={styles.score2}>
          <Text>Mixed</Text>
        </View>

        <View style={{ marginLeft: 20, width: "80%" }}>
          <Text style={styles.title2}>Response Consensus</Text>
          <Text style={{ fontSize: 12 }}>
            Beef drumstick capicola, pork chop frankfurter tail rump picanha
            fatback pork. Tongue tenderloin bresaola beef ribs, ground round
          </Text>
        </View>
      </View>

      <Text
        style={{
          fontSize: "10",
          fontWeight: 700,
          marginBottom: 5,
          marginTop: 50,
        }}
      >
        Engagement Summary
      </Text>
      <Table data={tableTemplate2} />
    </Page>
  );
};

export default Engagement;
