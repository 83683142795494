import React from "react";

function RefreshIcon() {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1998 0.279972C13.7298 0.279972 16.6298 2.88997 17.1198 6.27997H19.1998L15.6998 10.28L12.1998 6.27997H14.5198C14.2961 5.30108 13.7471 4.42695 12.9625 3.80034C12.1778 3.17373 11.2039 2.83165 10.1998 2.82997C8.7498 2.82997 7.4698 3.53997 6.6598 4.60997L4.9498 2.65997C5.60432 1.91153 6.41149 1.31189 7.31704 0.901372C8.22259 0.490854 9.20554 0.278972 10.1998 0.279972ZM9.7998 13.72C6.2798 13.72 3.3698 11.11 2.8798 7.71997H0.799805L4.2998 3.71997C5.4698 5.04997 6.6298 6.38997 7.7998 7.71997H5.4798C5.70352 8.69887 6.25252 9.57299 7.03715 10.1996C7.82178 10.8262 8.79567 11.1683 9.7998 11.17C11.2498 11.17 12.5298 10.46 13.3398 9.38997L15.0498 11.34C14.3959 12.0892 13.5889 12.6893 12.6832 13.0999C11.7775 13.5105 10.7942 13.7219 9.7998 13.72Z"
        fill="#5167DD"
      />
    </svg>
  );
}

export default RefreshIcon;
