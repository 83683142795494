import React from "react";
import styled, { keyframes } from "styled-components";

const LoadingComponent = () => {
  return (
    <Container>
      <Title>Generating your report...</Title>
      {/* <IMG src={ReportImage}/> */}
      <ProcessingAnimation />
    </Container>
  );
};

export default LoadingComponent;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
`;

const Title = styled.div`
  color: #666d79;
  font-family: Raleway;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
`;

const IMG = styled.img`
  width: 700px;
`;

// Define the rotation keyframes for the spinner
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #2d70e2;
  width: 80px;
  height: 80px;
  animation: ${rotate} 2s linear infinite;
`;

const ProcessingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Message = styled.p`
  font-size: 1.2em;
  text-align: center;
  margin-top: 16px;
  font-family: Raleway;
`;

const ProcessingAnimation = () => {
  return (
    <ProcessingContainer>
      <Spinner />
      <Message>
        This process might take up to 5-10 minutes. We'll email you when this
        report is ready.
      </Message>
    </ProcessingContainer>
  );
};
