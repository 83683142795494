/**
 * @file Personas.js
 * @description This file contains the list of personas, which are used to
 *             determine which options to set for a user.
 *  Legend:
 * - pdf_enabled: Whether or not the user can generate a PDF report
 * - otp_view: Whether or not the user will have the OTP view for PDF generation
 * - edit_users: Whether or not the user can edit other users
 * - access_datalytics: Whether or not the user can access the Data Analytics page
 * - access_subfactor: Whether or not the user can access the Subfactors data
 * - schedule_survey: Whether or not the user can schedule a survey
 * - is_admin: Whether or not the user is an admin
 * - enable_login: Whether or not the user can login
 * - is_cwf: Whether or not the user is a CWF user
 * - read_feedback: Whether or not the user can read feedback
 * - participate_survey: Whether or not the user can participate in a survey
 *
 */
export const PERSONA_OPTIONS = {
  pdf_enabled: "pdf_enabled",
  otp_view: "otp_view",
  edit_users: "edit_users",
  access_datalytics: "access_datalytics",
  access_subfactor: "access_subfactor",
  schedule_survey: "schedule_survey",
  is_admin: "is_admin",
  enable_login: "enable_login",
  is_cwf: "is_cwf",
  read_feedback: "read_feedback",
  participate_survey: "participate_survey",
};

export const PERSONAS = [
  {
    id: 1,
    text: "OTP Admin",
    value: "OTP Admin",
    options: [
      PERSONA_OPTIONS.otp_view,
      PERSONA_OPTIONS.access_datalytics,
      PERSONA_OPTIONS.enable_login,
      PERSONA_OPTIONS.pdf_enabled,
      PERSONA_OPTIONS.edit_users,
      PERSONA_OPTIONS.schedule_survey,
    ],
  },
  {
    id: 2,
    text: "CWF",
    value: "CWF",
    options: [
      PERSONA_OPTIONS.otp_view,
      PERSONA_OPTIONS.is_cwf,
      PERSONA_OPTIONS.access_datalytics,
      PERSONA_OPTIONS.access_subfactor,
      PERSONA_OPTIONS.schedule_survey,
      PERSONA_OPTIONS.enable_login,
      PERSONA_OPTIONS.pdf_enabled,
      PERSONA_OPTIONS.edit_users,
      PERSONA_OPTIONS.read_feedback,
    ],
  },
  {
    id: 3,
    text: "Culture Leader",
    value: "Culture Leader",
    options: [
      PERSONA_OPTIONS.access_datalytics,
      PERSONA_OPTIONS.access_subfactor,
      PERSONA_OPTIONS.enable_login,
      PERSONA_OPTIONS.participate_survey,
    ],
  },
  {
    id: 4,
    text: "Participant",
    value: "Participant",
    options: [PERSONA_OPTIONS.participate_survey],
  },
];
