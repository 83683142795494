import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import PDFReport from "areas/analytics/components/feedback-audit/DEIPDFReport";
import Audit from "areas/analytics/components/feedback-audit/Audit";
import DataScience from "areas/data-science/components/DataScience";
import TeamSummary from "areas/group-analytics/components/GroupAnalytics";
import Recommendations from "areas/recommendations/components/Recommendations";
import Copilot from "areas/copilot/components/Copilot";
import Outcomes from "areas/outcomes/components/Outcome";
import Feedback from "areas/analytics/components/feedback-audit/Feedback";
import Lost from "areas/404/components";
import Analytics from "areas/dashboard/components/Dashboard";
import Assessment from "areas/assessment-schedule/components/Assessment";

function RoutesTeamTool() {
  const navigate = useNavigate();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);

  const { get_auth, get_organizations, get_selectedOrg, get_employees } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
        get_employees: state.employees,
      }),
      shallowEqual,
    );

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual,
  );
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual,
  );

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees[get_auth.employee_id]);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth, EmployeeId, get_employees]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  useEffect(() => {
    const Found = organization?.services_enabled
      ?.filter((srv) => srv?.enabled === true)
      .map((sr) => sr.name);

    setValidRoute(Found);
  }, [organization]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!validRoute?.includes("EDI")) {
        return navigate("/app/unsubscribed");
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization, validRoute, navigate]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    if (validRoute) {
      if (validRoute?.includes("Teamtool")) {
        if (employee?.account_type > 7) {
          if (employee?.role > 7) {
            return navigate("/app/access-denied");
          }
        }
      }
    }
  }, [employee, validRoute, navigate]);

  return (
    <>
      {validRoute && (
        <Routes>
          <Route path="/" element={<Assessment />} />
          <Route path="report" element={<Audit />} />
          <Route path="pdf" element={<PDFReport />} />
          <Route path={"data_science"} element={<DataScience />} />
          <Route path={"group"} element={<TeamSummary />} />
          <Route path={"rec"} element={<Recommendations />} />
          <Route path={"rec/:id"} element={<Recommendations />} />
          <Route path={"outcomes"} element={<Outcomes />} />
          <Route path={"feedback"} element={<Feedback />} />
          <Route path={"AI"} element={<Copilot />} />
          <Route path={"analytics"} element={<Analytics />} />
          <Route path={"*"} element={<Lost />} />
        </Routes>
      )}
    </>
  );
}

export default RoutesTeamTool;
