import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Checkbox, Dropdown, Icon } from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";
import FilterIcon from "assets/images/nav/clarity_filter-grid-circle-line.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SG_SET_MEMBER_FILTERS } from "common/constants/actions";
import { PERMISSION_TEXT } from "common/constants/permission-labels";

const Filter = (props) => {
  const [open, setOpen] = useState(false);
  const [account, setAccount] = useState(false);
  const [role, setRole] = useState(false);
  const [status, setStatus] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredPermissions, setFilteredPermissions] = useState([]);
  const [missingCategoriesFilter, setMissingCategoriesFilter] = useState(false);
  const [filteredCategoriesId, setFilteredCategoriesId] = useState([]);

  const [filters, setFilters] = useState([]);
  const dispatch = useDispatch();

  const ACCOUNT_PERMISSIONS = [
    {
      label: PERMISSION_TEXT.SURVEY_PARTICIPATION.TITLE,
      property: "survey_status",
    },
    { label: PERMISSION_TEXT.LOGIN_TO_PORTAL.TITLE, property: "enable_login" },
    {
      label: PERMISSION_TEXT.DATA_ANALYTICS.TITLE,
      property: "access_data_analytics",
    },
    {
      label: PERMISSION_TEXT.SUBFACTOR_DATA.TITLE,
      property: "access_subfactor_data",
    },
    { label: PERMISSION_TEXT.READ_FEEDBACK.TITLE, property: "read_feedback" },
    { label: PERMISSION_TEXT.EDIT_USERS.TITLE, property: "survey_add_users" },
    {
      label: PERMISSION_TEXT.SCHEDULE_SURVEYS.TITLE,
      property: "survey_schedule",
    },
    { label: PERMISSION_TEXT.ADMIN_ACCOUNT.TITLE, property: "account_type" },
  ];

  const handleClickAway = () => {
    setOpen(false);
  };

  const memberFilters = useSelector(
    (state) => state.member_filters,
    shallowEqual,
  );

  // Check if arrays are equal to determine if dispatch is required.
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = arr1.length; i--; ) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  };

  const reset = () => {
    setFilters([]);
    setFilteredCategories([]);
    setFilteredPermissions([]);
    setFilteredCategoriesId([]);

    props.resetMemberList();
    dispatch({
      type: SG_SET_MEMBER_FILTERS,
      payload: {
        filteredCategories: [],
        filteredCategoriesId: [],
        filteredPermissions: [],
        missingCategoriesFilter: false,
      },
    });
  };

  useEffect(() => {
    if (
      memberFilters.filteredCategoriesId.length > 0 &&
      filteredCategoriesId.length === 0
    ) {
      setFilteredCategoriesId([...memberFilters.filteredCategoriesId]);
    }
    if (memberFilters.missingCategoriesFilter !== missingCategoriesFilter) {
      setMissingCategoriesFilter(memberFilters.missingCategoriesFilter);
    }
    if (
      memberFilters.filteredPermissions.length > 0 &&
      filteredPermissions.length === 0
    ) {
      setFilteredPermissions([...memberFilters.filteredPermissions]);
    }
    if (
      memberFilters.filteredCategories.length > 0 &&
      filteredCategories.length === 0
    ) {
      setFilteredCategories([...memberFilters.filteredCategories]);
    }
  }, [memberFilters]);

  useEffect(() => {
    if (filteredPermissions.length === 0 && filteredCategoriesId.length === 0) {
      props.setFilterCategories([]);
      props.setFilterPermissions([]);
    } else {
      props.setFilterCategories([...filteredCategoriesId]);
      props.setFilterPermissions([...filteredPermissions]);

      if (
        memberFilters.missingCategoriesFilter !== missingCategoriesFilter ||
        !arraysEqual(
          memberFilters.filteredCategoriesId,
          filteredCategoriesId,
        ) ||
        !arraysEqual(memberFilters.filteredPermissions, filteredPermissions) ||
        !arraysEqual(memberFilters.filteredCategories, filteredCategories)
      ) {
        dispatch({
          type: SG_SET_MEMBER_FILTERS,
          payload: {
            filteredCategories: [...filteredCategories],
            filteredCategoriesId: [...filteredCategoriesId],
            filteredPermissions: [...filteredPermissions],
            missingCategoriesFilter: missingCategoriesFilter,
          },
        });
      }
    }
  }, [filteredCategoriesId, filteredPermissions]);

  const checkedPermission = (perm) => {
    let currentPermissions = [...filteredPermissions];
    if (currentPermissions.includes(perm)) {
      currentPermissions = currentPermissions.filter((perms) => perms !== perm);
      setFilteredPermissions(currentPermissions);
    } else {
      currentPermissions.push(perm);
      setFilteredPermissions(currentPermissions);
    }
  };

  const checkedCategory = (cat) => {
    let selectedCopy = [...filteredCategoriesId];
    const catSelected = cat?.options?.filter((c) => {
      selectedCopy.forEach((s) => {
        // remove any of the ids from selectedCopy that exist in currentCat?.options
        if (s === c.id) {
          selectedCopy = selectedCopy.filter((sc) => {
            return sc !== s;
          });
          return true;
        }
      });

      return cat.value.includes(c.value);
    });
    const catIds = catSelected.map((c) => {
      return c.id;
    });
    selectedCopy = [...selectedCopy, ...catIds];

    setFilteredCategories(cat.value);
    setFilteredCategoriesId(selectedCopy);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container>
        <div
          onClick={() => setOpen(!open)}
          style={{
            width: 115,
            height: 35,
            display: "flex",
            alignItems: "center",
          }}
        >
          Filters
          <Icon
            name={open ? "chevron up" : "chevron down"}
            style={{ position: "absolute", right: 10, top: 7 }}
          />
        </div>

        <FilterContainer open={open}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "20px 20px 0px 20px",
            }}
          >
            <Image src={FilterIcon} />
            <div style={{ position: "absolute", left: 45 }}>Filter Members</div>
            <Icon
              name={"close"}
              style={{ position: "absolute", right: 20 }}
              onClick={() => handleClickAway()}
            />
            <Reset onClick={() => reset()}>Reset Filters</Reset>
          </div>
          <Filters>
            <Fill>
              {props.categories.map((cat, i) => {
                if (!cat?.category) return;
                return (
                  <CategoryList key={i}>
                    <Title>{cat?.category}</Title>
                    <Dropdown
                      fluid
                      selection
                      search
                      multiple
                      options={cat?.options}
                      value={filteredCategories}
                      onChange={(_e, t) => checkedCategory(t)}
                      onAddItem={(_e, t) => checkedCategory(t)}
                      size={"small"}
                    />
                  </CategoryList>
                );
              })}
            </Fill>

            <Fill>
              <Title>Permissions</Title>
              {ACCOUNT_PERMISSIONS.map((perm, i) => {
                return (
                  <Checkbox
                    key={i}
                    checked={filteredPermissions.includes(perm.property)}
                    onClick={() => checkedPermission(perm.property)}
                    label={perm.label}
                    style={{
                      color: "#7E7E7E",
                      marginTop: 8,
                      fontWeight: 400,
                      fontSize: 12,
                    }}
                  />
                );
              })}

              <FilterRow>
                <Title>Missing Data</Title>
                <Checkbox
                  checked={missingCategoriesFilter}
                  onClick={() => {
                    setMissingCategoriesFilter(!missingCategoriesFilter);
                    props.setMissingCategoriesFilter(!missingCategoriesFilter);
                  }}
                  label="Categories Missing"
                  style={{
                    color: "#7E7E7E",
                    marginTop: 8,
                    fontWeight: 400,
                    fontSize: 12,
                  }}
                />
              </FilterRow>
            </Fill>
          </Filters>
        </FilterContainer>
      </Container>
    </ClickAwayListener>
  );
};

export default Filter;

const Container = styled.div`
  width: 110px;
  height: 35px;
  border: 1px solid #e9e9e9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  cursor: pointer;
  z-index: 999;
  color: #414141;
  &:hover {
    background-color: #eeeeee;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const FilterContainer = styled.div`
  width: 550px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #eeeeee;
  z-index: 30;
  border: 1px solid #cccccc;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 #ececec;
  padding: ${(props) => (props.open ? "0px" : 0)};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  font-size: 14px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  border-top: 1px solid #dfdfdf;
  font-weight: 500;
  padding: 5px;
  gap: 20px;
`;
const Fill = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  color: #4a4a4a;
  padding-left: 20px;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const Reset = styled.div`
  position: absolute;
  right: 60px;
  top: 17px;
  font-weight: 500;
  height: 25px;
  width: 95px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  align-items: center;
  padding-top: 2px;
  padding-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  cursor: pointer;
  z-index: 999;
  color: #414141;
  &:hover {
    background-color: #dddddd;
  }
`;

const CategoryList = styled.div`
  flex-direction: row;
`;

const Image = styled.img`
  height: 20px;
  margin-right: 10px;
`;

const FilterRow = styled.div`
  flex-direction: column;
  padding-top: 30px;
`;
