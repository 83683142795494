import {
  CLEAR_ALL,
  FETCH_LEADERSHIP_DATA,
  GET_LEADERSHIP_DATA,
  GET_LEADERSHIP_DATA_ORG,
  GET_LEADERSHIP_DATA_TEAMS,
} from "common/constants/actions";

const initialState = {
  leadership: [],
  org: [],
  teams: [],
  pending: false,
};

export default function leadershipReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LEADERSHIP_DATA:
      return {
        ...state,
        leadership: action.payload,
        pending: false,
      };
    case GET_LEADERSHIP_DATA_TEAMS:
      return {
        ...state,
        teams: action.payload,
        pending: false,
      };
    case GET_LEADERSHIP_DATA_ORG:
      return {
        ...state,
        org: action.payload,
        pending: false,
      };
    case FETCH_LEADERSHIP_DATA:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        leadership: [],
        org: [],
        teams: [],
        pending: false,
      };
    default:
      return state;
  }
}
