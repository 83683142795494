import {
  ADD_TEAM,
  CLEAR_ALL,
  CLEAR_TEAM,
  DELETE_TEAM,
  EDIT_TEAM,
  FETCH_TEAM,
  GET_TEAMS,
  RESET_CREATED_TEAM,
} from "common/constants/actions";

const initialState = {
  teams: [],
  pending: false,
  response: null,
  created: [],
};

export default function Teams(state = initialState, action) {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: action.payload,
        pending: false,
      };

    case FETCH_TEAM:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_TEAM:
      if (state.teams.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          teams: state.teams.filter((rw) => rw.id !== action.payload.id),
        };
      }
      return {
        ...state,
        teams: [...state.teams, action.payload],
      };

    case DELETE_TEAM:
      return {
        ...state,
        teams: state.teams.filter((row) => row.id !== action.payload.id),
      };
    case RESET_CREATED_TEAM:
      return {
        ...state,
        created: [],
      };

    case ADD_TEAM:
      return {
        ...state,
        created: action.payload,
        teams: [...state.teams, action.payload],
        response: action.payload.response,
      };

    case CLEAR_TEAM:
      return {
        ...state,
        teams: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        teams: [],
      };

    default:
      return state;
  }
}
