import React from "react";

function PlusIcon(fill: string | undefined) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4248 0.70166L6.42487 11.1931"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.6707 5.94751L1.17922 5.94748"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PlusIcon;
