import {
  ADD_SURVEY_COMPLIANCE,
  CLEAR_ALL,
  CLEAR_SURVEY_COMPLIANCE,
  DELETE_SURVEY_COMPLIANCE,
  EDIT_SURVEY_COMPLIANCE,
  FETCH_SURVEY_COMPLIANCE,
  GET_SURVEY_COMPLIANCES,
} from "common/constants/actions";

const initialState = {
  survey_compliances: [],
  pending: false,
};

export default function SurveyCompliance(state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_COMPLIANCES:
      return {
        ...state,
        survey_compliances: action.payload,
        pending: false,
      };

    case FETCH_SURVEY_COMPLIANCE:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_SURVEY_COMPLIANCE:
      const schedule = state.survey_compliances.filter(
        (row) => row.id !== action.payload.id,
      );
      schedule.push(action.payload);
      return { ...state, survey_compliances: schedule };

    case DELETE_SURVEY_COMPLIANCE:
      return {
        ...state,
        survey_compliances: state.survey_compliances.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_SURVEY_COMPLIANCE:
      return {
        ...state,
        survey_compliances: [...state.survey_compliances, action.payload],
      };

    case CLEAR_SURVEY_COMPLIANCE:
      return {
        ...state,
        survey_compliances: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        survey_compliances: [],
      };

    default:
      return state;
  }
}
