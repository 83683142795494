import styled from "styled-components";
import React from "react";

import { shallowEqual, useSelector } from "react-redux";

import Tabled from "./Table1";
import Table2 from "./Table";
import Individual from "./Individual";
import Distribution from "./DistributionV2";

import RawData from "./RawData";
import {
  calculate_table_data,
  get_labels,
} from "../../../../common/calculations/analytics";

import QuestionDrop from "./QuestionDrop/index";
import Empty from "../../../../common/components/Empty";

const data = {
  title: "Person dimension bar graphs",
  color: "3, 135, 239",
  people: [
    "Average",
    "Technical Leaders",
    "Coaches",
    "Sport Science Providers",
    "Athletes",
  ],
  categories: [
    { title: "Mental Health and Well Being" },
    { title: "Physical Health and Well Being" },
    { title: "Psychological Safety" },
    { title: "Physical Safety and Safe Sport" },
  ],
};

const panes = [
  {
    menuItem: "Comprehensive",
    render: ({ data, labels, WhiteLabel, structure, raw }) => (
      <Comprehensive
        data={data}
        labels={labels}
        WhiteLabel={WhiteLabel}
        structure={structure}
        raw={raw}
      />
    ),
  },
  {
    menuItem: "Raw Data",
    render: ({ data, labels, WhiteLabel, structure }) => (
      <RawData
        data={data}
        labels={labels}
        WhiteLabel={WhiteLabel}
        structure={structure}
      />
    ),
  },
];

const Comprehensive = ({
  data,
  nav,
  labels,
  WhiteLabel,
  structure,
  raw,
  is_french,
  language_hook,
}) => {
  const question = useSelector((state) => state.audit?.selected, shallowEqual);
  return (
    <Container>
      <QuestionDrop
        color={WhiteLabel.highlight}
        structure={structure}
        selected={question}
      />

      <Distribution
        data={data}
        labels={labels}
        question={question}
        is_french={is_french}
        language_hook={language_hook}
      />

      {question.id == 0 ? (
        <Tabled
          data={data}
          labels={labels}
          color={WhiteLabel.highlight}
          structure={structure}
          is_french={is_french}
          language_hook={language_hook}
        />
      ) : question.dimension == 0 && question.level == 1 ? (
        <Table2
          type={0}
          data={data}
          labels={labels}
          color={WhiteLabel.highlight}
          is_french={is_french}
          language_hook={language_hook}
        />
      ) : question.dimension == 1 && question.level == 1 ? (
        <Table2
          type={1}
          data={data}
          labels={labels}
          color={WhiteLabel.highlight}
          is_french={is_french}
          language_hook={language_hook}
        />
      ) : (
        <Individual
          question={question}
          questions={structure}
          data={data.responses}
          q={question.id}
          raw={raw}
          color={WhiteLabel.highlight}
          is_french={is_french}
          language_hook={language_hook}
          WhiteLabel={WhiteLabel}
        />
      )}
    </Container>
  );
};

const Dimension = ({
  data,
  nav,
  WhiteLabel,
  structure,
  categories,
  rule,
  selected,
  is_french,
  language_hook,
}) => {
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const [calculated, setCalculated] = React.useState(
    calculate_table_data(data, nav, categories, anchor, rule, structure),
  );
  const [labels, setLabels] = React.useState(
    get_labels(nav, categories, anchor),
  );

  const [render, setRender] = React.useState(false);

  React.useEffect(() => {
    // setRender(!render)
    setCalculated(
      calculate_table_data(data, nav, categories, anchor, rule, structure),
    );
    setLabels(get_labels(nav, categories, anchor));
  }, [nav, anchor]);

  if (calculated.responses.length == 0) {
    return <Empty />;
  }

  return selected === 1 ? (
    <Comprehensive
      data={calculated}
      labels={labels}
      WhiteLabel={WhiteLabel}
      structure={structure}
      raw={data}
      is_french={is_french}
      language_hook={language_hook}
    />
  ) : (
    <RawData
      data={calculated}
      labels={labels}
      WhiteLabel={WhiteLabel}
      structure={structure}
      is_french={is_french}
      language_hook={language_hook}
    />
  );
};

export default Dimension;

const Container = styled.div`
  padding-left: 58px;
  padding-top: 19px;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
  color: ${(props) => "rgb(" + props.color + ")"};
`;

const Table = (props) => {
  return (
    <TableContainer>
      {props.title == "Person Dimension" ? (
        <First color={props.color}>{props.title}</First>
      ) : (
        <TTitle>{props.title}</TTitle>
      )}

      <Scale color={props.color}>
        <TH width={26.82} />
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <Num>{i}</Num>
        ))}
        <Num2 />
      </Scale>
      {props.info.people.map((item, i) => {
        return (
          <TR onClick={() => props.setSelected(3)}>
            <TD width={20} borderRight={true} bold={item == "Average"}>
              {item}
            </TD>
            <TD width={10} center={true} bold={item == "Average"}>
              8
            </TD>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              <TD width={6.36} dash={true} noBottom={i == 10}></TD>
            ))}

            <Bar
              width={8 * 6.36}
              color={item == "Average" ? props.color : "140, 140, 140"}
            />
          </TR>
        );
      })}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const Scale = styled.div`
  background-color: ${(props) => "rgba(" + props.color + ",0.4)"};
  display: flex;
`;

const First = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: black;
  border-top: 1px solid black;
  height: 30px;
  align-items: center;
  padding-left: 5px;
`;

const TTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding-left: 5px;
`;

const TR = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  position: relative;
`;

const Bar = styled.div`
  width: ${(props) => props.width + "%"};
  height: 15px;
  position: absolute;
  left: calc(30% - 2px);
  top: 7.5px;
  background-color: ${(props) => "rgb(" + props.color + ")"};
`;

const Num = styled.div`
  width: 6.36%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Num2 = styled.div`
  width: 3.18%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  display: flex;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.dash ? "0px " : "3px 6px")};
  border-right: ${(props) => (props.borderRight ? "1px solid black" : "none")};
  font-weight: ${(props) => (props.bold ? "bold" : 400)};
  border-left: ${(props) => (props.dash ? "1px dashed black " : "none")};
  border-bottom: ${(props) => (!props.noBottom ? "1px solid black" : "none")};
  box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
`;

const tagOptions = [
  {
    key: "Important",
    text: "Mental health and well-being",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Phsyical health and well-being",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Psychological safety",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Phsyical safety and Safe sport",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Self-determination",
    value: "Enhancement",
  },
];

const tagOptions2 = [
  {
    key: "Important",
    text: "Leadership",
    value: "Important",
  },
  {
    key: "Announcement",
    text: "Coaching",
    value: "Announcement",
  },
  {
    key: "Psychological safety",
    text: "Daily training environment",
    value: "Cannot Fix",
  },
  {
    key: "Phsyical safety and Safe sport",
    text: "Sport science and sport medicine",
    value: "News",
  },
  {
    key: "Self-determination",
    text: "Pathways and performance",
    value: "Enhancement",
  },
  {
    key: "Self-determination",
    text: "Athletes and international results",
    value: "Enhancement",
  },
];

const Items = [
  "DEI Overall",
  "Person Dimension",
  "Mental health and well-being",
  "Phsyical health and well-being",
  "Psychological safety",
  "Phsyical safety and Safe sport",
  "Self-determination",
  "Performance Dimension",
  "Leadership",
  "Coaching",
  "Daily training environment",
  "Sport science and sport medicine",
  "Pathways and performance",
  "Athletes and international results",
];
