import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ADD_TEAMS_IN_SCHEDULE,
  ALL_ERRORS,
  DELETE_TEAMS_IN_SCHEDULE,
  EDIT_TEAMS_IN_SCHEDULE,
  FETCH_TEAMS_IN_SCHEDULE,
  GET_TEAMS_IN_SCHEDULES,
  SG_ADD_TEAMS_IN_SCHEDULE,
  SG_DELETE_TEAMS_IN_SCHEDULE,
  SG_EDIT_TEAMS_IN_SCHEDULE,
  SG_FETCH_TEAMS_IN_SCHEDULE,
  SG_GET_TEAMS_IN_SCHEDULES,
} from "common/constants/actions";

function* loadteams(action) {
  //   yield put({ type: GET_teamS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_TEAMS_IN_SCHEDULES, action.payload);
    yield put({ type: GET_TEAMS_IN_SCHEDULES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamLoad() {
  yield takeLatest(SG_GET_TEAMS_IN_SCHEDULES, loadteams);
}

function* fetchteams(action) {
  try {
    const json = yield call(api.FETCH_TEAMS_IN_SCHEDULE, action.payload);
    yield put({ type: FETCH_TEAMS_IN_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamFetch() {
  yield takeLatest(SG_FETCH_TEAMS_IN_SCHEDULE, fetchteams);
}

function* updateteams(action) {
  try {
    const json = yield call(api.EDIT_TEAMS_IN_SCHEDULE, action.payload);
    yield put({ type: EDIT_TEAMS_IN_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamUpdate() {
  yield takeLatest(SG_EDIT_TEAMS_IN_SCHEDULE, updateteams);
}

function* addteams(action) {
  try {
    const json = yield call(api.ADD_TEAMS_IN_SCHEDULE, action.payload);
    yield put({ type: ADD_TEAMS_IN_SCHEDULE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamAdd() {
  yield takeEvery(SG_ADD_TEAMS_IN_SCHEDULE, addteams);
}

function* deleteteams(action) {
  try {
    yield call(api.DELETE_TEAMS_IN_SCHEDULE, action.payload);
    yield put({ type: DELETE_TEAMS_IN_SCHEDULE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* teamDelete() {
  yield takeEvery(SG_DELETE_TEAMS_IN_SCHEDULE, deleteteams);
}

export default function* index() {
  yield all([teamLoad(), teamFetch(), teamUpdate(), teamAdd(), teamDelete()]);
}
