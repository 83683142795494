import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Modal,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import Avatar from "./Avatar";

import SelectOrgRow from "./SelectOrgRow";

import Input from "./Input";
import { useToasts } from "react-toast-notifications";
import { Controller, useForm } from "react-hook-form";

import {
  SG_FETCH_USER_EMPLOYEE,
  SG_GET_EMPLOYEE_CATEGORIES,
  UPDATE_EMPLOYEE,
} from "common/constants/actions";

import "react-datepicker/dist/react-datepicker.css";

import ImageAvtar from "./ImageAvatar";
import { pathList } from "routes/routeMap";
import { PERMISSION_TEXT } from "common/constants/permission-labels";

const MemberSettings = () => {
  const dispatch = useDispatch();

  // const [authEmployeeId, setAuthEmployeeId] = useState(false);
  const [authEmployee, setAuthEmployee] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [auditEnabled, setAuditEnabled] = useState(false);
  const [employeeCategory, setEmployeeCategory] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState(false);
  const [secondaryCategory, setSecondaryCategory] = useState(false);
  const [appList, setAppList] = useState([]);
  const [fullName, setFullName] = useState("");

  const [dos, setDos] = useState(false);
  const { addToast } = useToasts();
  const { handleSubmit, control, setValue, errors } = useForm();
  const employee_id = useSelector((state) => state.auth.employee_id);
  const [loading, setLoading] = useState(false);

  // const [reLoad, setReload] = useState(false)
  const [loadingText, setLoadingText] = React.useState("Loading");

  useEffect(() => {
    // if the response is ok 200
    if (employee_id) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: { id: employee_id },
      });
      dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
    }
  }, [employee_id, dispatch]);

  const {
    get_employee,
    get_auth,
    organization_id,
    get_organization,
    get_employee_categories,
  } = useSelector(
    (state) => ({
      get_employee: state.employees,
      get_auth: state.auth,
      organization_id: state.auth.organization_id,
      get_organization: state.organizations,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (get_employee) {
      setAuthEmployee(get_employee.userEmp);
      setOrganization(get_organization?.[organization_id]);
      setDos(Date.parse(get_employee[get_auth?.employee_id]?.join_date));
      // if (get_employee[get_auth?.employee_id]?.dob) {
      //   setDatabaseDate(new Date(get_employee[get_auth?.employee_id]?.dob));
      // }
    }
  }, [get_employee, get_auth, organization_id, get_organization]);

  useEffect(() => {
    const _appsEnabled = [];
    pathList?.map((rt) => {
      const Found = organization?.services_enabled?.filter(
        (srv) => srv?.name === rt.name && srv?.enabled === true,
      )?.[0];

      _appsEnabled.push({
        name: rt.name,
        enabled: Found ? true : false,
        description: rt.description,
      });

      return null;
    });
    setAppList(_appsEnabled);
  }, [organization]);

  // console.log(appList)

  useEffect(() => {
    const _Category = get_employee_categories?.filter(
      (ec) => ec.employee === Number(get_auth?.employee_id),
    )?.[0]?.category;
    setEmployeeCategory(
      _Category
        ?.filter((ct) => ct?.priority === "primary")
        .map((ct) => ct.value),
    );
    setPrimaryCategory(_Category?.filter((ct) => ct?.priority === "primary"));
    setSecondaryCategory(
      _Category?.filter((ct) => ct?.priority === "secondary"),
    );
  }, [get_employee_categories, get_auth]);

  // console.log(employeeCategory)
  // console.log(secondaryCategory)

  const onSubmit = (data) => {
    if (authEmployee) {
      // update employee
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          ...data,
          id: authEmployee.id,
        },
      });

      setLoading(true);
      setLoadingText("Profile Updated");
      setNameOpen(false);

      authEmployee.first_name = data.first_name;
      authEmployee.last_name = data.last_name;
      setFullName(FullName());
    }
  };

  useEffect(() => {
    if (organization?.services_enabled) {
      setAuditEnabled(
        organization?.services_enabled.filter(
          (se) => se?.name.toLowerCase() === "audit",
        )?.[0]?.enabled,
      );
    }
  }, [organization]);

  useEffect(() => {
    if (authEmployee) {
      setValue("first_name", authEmployee?.first_name);
      setValue("last_name", authEmployee?.last_name);
    }
    setFullName(FullName());
  }, [authEmployee, setValue]);

  const [open, setOpen] = useState(false);
  const [nameOpen, setNameOpen] = useState(false);
  const [onClickSaveImg, setOnClickSaveImg] = useState(false);

  const FullName = () => {
    if (authEmployee?.first_name) {
      return `${authEmployee.first_name} ${authEmployee.last_name}`;
    }
    return authEmployee?.email?.split("@")[0];
  };

  return (
    <Container>
      <FormSection1>
        <Avatar
          picture={authEmployee?.picture ? authEmployee.picture : false}
          onClick={() => setOpen(true)}
          loading={loading}
        />
        <Modal
          closeIcon
          size={"small"}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
        >
          <Modal.Header>Profile Image</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <ImageAvtar
                employee_id={employee_id}
                setOpen={setOpen}
                onClickSaveImg={onClickSaveImg}
              />
            </Modal.Description>
          </Modal.Content>
        </Modal>
        <Title>{fullName}</Title>
        <Icon
          onClick={() => setNameOpen(true)}
          style={{ margin: "20px", cursor: "pointer", color: "#888888" }}
          name="edit"
        />
      </FormSection1>
      <FormContainer>
        <FormSection>
          <Table basic="very" celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ width: "200px" }}>Email</Table.Cell>
                <Table.Cell style={{ width: "400px" }}>
                  <Header.Content>{authEmployee?.email}</Header.Content>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Password</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    <Link to="/settings/change-password">Change Password</Link>
                  </Header.Content>
                </Table.Cell>
              </Table.Row>

              {primaryCategory &&
                primaryCategory.map((pc, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{pc.name}</Table.Cell>
                      <Table.Cell>
                        <Header.Content>{pc.value}</Header.Content>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              {secondaryCategory &&
                secondaryCategory.map((sc, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>{sc.name}</Table.Cell>
                      <Table.Cell>
                        <Header.Content>{sc.value}</Header.Content>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}

              <Table.Row>
                <Table.Cell>Organization</Table.Cell>
                <Table.Cell>
                  <Header.Content>{organization?.name}</Header.Content>
                </Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>
                  <Header.Content>Current Organization</Header.Content>
                </Table.Cell>
                <Table.Cell>
                  <SelectOrgRow />
                </Table.Cell>
              </Table.Row>
              {authEmployee?.account_type_text === "Admin" && (
                <Table.Row>
                  <Table.Cell>
                    <Header.Content>Admin</Header.Content>
                  </Table.Cell>
                  <Table.Cell>
                    <Header.Content>Yes</Header.Content>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          {authEmployee?.account_type_text !== "Admin" ? (
            <>
              <Heading>Permissions</Heading>

              <Table basic="very" celled style={{ width: "500px" }}>
                <Table.Body>
                  {authEmployee?.survey_status && (
                    <Table.Row>
                      <Table.Cell style={{ width: "200px" }}>
                        {PERMISSION_TEXT.SURVEY_PARTICIPATION.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {authEmployee?.access_data_analytics && (
                    <Table.Row>
                      <Table.Cell>
                        {PERMISSION_TEXT.DATA_ANALYTICS.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {authEmployee?.access_subfactor_data && (
                    <Table.Row>
                      <Table.Cell>
                        {PERMISSION_TEXT.SUBFACTOR_DATA.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {authEmployee?.read_feedback && (
                    <Table.Row>
                      <Table.Cell>
                        {PERMISSION_TEXT.READ_FEEDBACK.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {authEmployee?.survey_add_users && (
                    <Table.Row>
                      <Table.Cell>
                        {PERMISSION_TEXT.EDIT_USERS.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}

                  {authEmployee?.access_data_analytics && (
                    <Table.Row>
                      <Table.Cell>
                        {PERMISSION_TEXT.SCHEDULE_SURVEYS.TITLE}
                      </Table.Cell>
                      <Table.Cell>
                        <Icon color="green" name="checkmark" size="large" />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </>
          ) : (
            <></>
          )}

          <Modal
            closeIcon
            size={"small"}
            onClose={() => setNameOpen(false)}
            onOpen={() => setNameOpen(true)}
            open={nameOpen}
            // trigger={<Button>Show Modal</Button>}
          >
            <Modal.Header>Update Profile</Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                <Form
                  loading={false}
                  onSubmit={handleSubmit(onSubmit)}
                  error
                  style={{ width: "100%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Controller
                      as={Input}
                      name="first_name"
                      placeholder="First name"
                      type="text"
                      control={control}
                      defaultValue={authEmployee?.first_name}
                      rules={{
                        required: "Please enter first name",
                      }}
                      error={errors.first_name ? errors.first_name.message : ""}
                      half={true}
                    />

                    <Controller
                      as={Input}
                      name="last_name"
                      placeholder="Last name"
                      type="text"
                      control={control}
                      defaultValue={authEmployee?.last_name}
                      rules={{
                        required: "Please enter last name",
                      }}
                      error={errors.last_name ? errors.last_name.message : ""}
                      half={true}
                    />
                  </div>
                  <StyleButton>Save</StyleButton>
                </Form>
              </Modal.Description>
            </Modal.Content>
          </Modal>
          <Dimmer.Dimmable blurring dimmed={loading}>
            <Dimmer active={loading} onClickOutside={() => setLoading(false)}>
              {loadingText === "Loading" && <Loader />}{" "}
              <Header as="h2" icon inverted>
                {loadingText}
              </Header>
              {loadingText !== "Loading" && (
                <p>Click anywhere to dismiss message</p>
              )}
            </Dimmer>
          </Dimmer.Dimmable>
        </FormSection>
      </FormContainer>
    </Container>
  );
};

export default MemberSettings;

const Container = styled.div`
  margin-left: 50px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 20px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 30px;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 50%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;
