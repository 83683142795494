import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CLEAR_ERRORS, LOGIN_REQUEST } from "common/constants/actions";

import { DomainContext } from "common/layouts/LayoutContext";

import Input from "./InputLogin";
import UpgradePassword from "./UpgradePassword";
import { ValidateEmail } from "common/utils/EmailUtils";

import UserErrorMessages from "./UserErrorMessages";

import { Message } from "semantic-ui-react";

const LoginForm = (props) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [orgList, setOrgList] = useState([]);
  // const [selectedOrg, setSelectedOrg] = useState(false)

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [userAuth, setUserAuth] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);

  const Domain = useContext(DomainContext);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual,
  );

  useEffect(() => {
    setUserAuth(get_auth);
    if (get_auth?.next_steps === 1) {
      setOrgList(get_auth?.organization_list);
    }
  }, [get_auth]);

  useEffect(() => {
    setPasswordReset(get_auth?.reset_password);
  }, [get_auth?.reset_password]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;
    if (password?.length === 0) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter a password",
      };
      totalErrors += 1;
    }
    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email",
      };
      totalErrors += 1;
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email",
        };
        totalErrors += 1;
      }
    }
    ErrorList["errors"] = totalErrors;
    setFormErrors(ErrorList);
  }, [password, email, orgList, pristine]);

  let { from } = {
    // from: { pathname: "/app/dashboard" },
    from: { pathname: "/settings/select-organization" },
  }; // new

  // if (userAuth?.token) {
  //   return <Navigate to={from} />;
  // }
  if (userAuth?.token) {
    return <Navigate to={from} />;
  }

  function emailValue(e) {
    if (e) {
      setEmail(e);
    }
  }

  function passwordValue(e) {
    if (e) {
      setPassword(e);
    }
  }

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    // dispatch({
    //   type: CLEAR_ALL,
    // });
    dispatch({
      type: CLEAR_ERRORS,
    });

    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: email,
        password: password,
      },
    });
    // setPristine(true);
  };

  if (userAuth?.next_steps === 2) {
    return <UpgradePassword />;
  }

  // console.log(passwordReset)
  return (
    <Container>
      <FormContainer>
        <Box>
          <IMG src={Domain.fullImage} style={{ paddingBottom: "25px" }} />
          <Title>{Domain.loginText}</Title>

          {/* registerError */}
          <UserErrorMessages />
          {formErrors.length > 0 && !pristine && (
            <Message
              negative
              //   onDismiss={clearErrors}
              header="We need these details!"
              list={formErrors}
            />
          )}

          {passwordReset?.message && (
            <Message
              success
              // header={props.message}
              content={passwordReset?.message}
            />
          )}
          <Input
            placeholder="Email"
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={emailValue}
          />
          {formErrors.email?.error && !pristine && (
            <Message
              error
              // header={props.message}
              content={formErrors.email?.message}
            />
          )}

          <Input
            placeholder="Password"
            name="password"
            type="password"
            id="password"
            value={password}
            // defaultValue={password}
            onChange={passwordValue}
          />
          {formErrors.password?.error && !pristine && (
            <Message
              error
              // header={props.message}
              content={formErrors.password?.message}
            />
          )}

          <Button onClick={onSubmit} id="sign-in">
            Sign In
          </Button>

          <Bottom>
            <div>
              <Link to="/account/forgot-password">Forgot Password</Link>
            </div>
            {/*             <div>
              {Domain.allowRegister ?
                <>{Domain.registerText}  <Link to="/account/register">Sign Up</Link></>
                :
                <p>{Domain.notRegisterText}</p>}
            </div>
 */}{" "}
          </Bottom>

          {/* <MandatoryLinks Domain={Domain} /> */}
        </Box>
      </FormContainer>
    </Container>
  );
};

export default LoginForm;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2d50e2;
  color: white;
  font-size: 18px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 160px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
    font-weight: bold;
  }
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  height: 100%;
  padding: 50px 30px 0 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 50px;
  }
`;
