export const primaryColor = "#2D70E2";
export const secondaryColor = "#1D9A7D";
export const tertiaryColor = "#27CDA7";
export const gradientPrimary = "#3F86FE";

export const inactiveColor = "#666D79";
export const labelColor = {
 text: "#1D9A7D",
 background: "#F3FDFA",
};
export const iconColor = {
 good: secondaryColor,
 goodBg: "#EAFBF7",
};

export const unreadColor = "#FE3E85";
export const neutralColor = "#DFDFDF";
export const filterColor = {
 text: inactiveColor,
 activeText: primaryColor,
 activeBackground: "#E3EEFF",
 background: "#F8FAFF",
};

export const scoresComponent = {
  activeBackground: "#F8FAFF",
  active: `linear-gradient(270deg, #3F86FE 0%, rgba(63, 134, 254, 0.3) 100%); !important`,
  inactive: `linear-gradient(270deg, rgba(102, 109, 121, 0.5) 0%, rgba(102, 109, 121, 0.2) 100%); !important`,
};

export const defaultText = "#2A3039";
export const barContainerBg = "#EBF1FD";

export const fontSizes = {
 xsmall: "12px",
 small: "14px",
 medium: "16px",
 regular: "18px",
 large: "22px",
 xlarge: "24px",
};

export const sectionTabs = ["Overview", "Predictions"];

export const factorInsightsDescriptions = [
  {
    factor: "Communication",
    description:
      "Scores in this category have a significant effect on this cohorts risk assessment",
  },
  {
    factor: "Problem Solving",
    description:
      "Scores in this category have a significant effect on this cohorts risk assessment",
  },
  {
    factor: "Values",
    description:
      "Scores in this category have a significant effect on this cohorts risk assessment",
  },
  {
    factor: "Accountability",
    description:
      "Scores in this category have a significant effect on this cohorts risk assessment",
  },
  {
    factor: "Autonomy",
    description:
      "Scores in this category have a significant effect on this cohorts risk assessment",
  },
];