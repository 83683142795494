import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ADD_AMBASSADOR,
  ALL_ERRORS,
  DELETE_AMBASSADOR,
  EDIT_AMBASSADOR,
  FETCH_AMBASSADOR,
  GET_AMBASSADORS,
  SG_ADD_AMBASSADOR,
  SG_DELETE_AMBASSADOR,
  SG_EDIT_AMBASSADOR,
  SG_FETCH_AMBASSADOR,
  SG_GET_AMBASSADORS,
  SG_UPDATE_AMBASSADOR_PIC,
  SG_VALIDATE_AMBASSADOR,
  UPDATE_AMBASSADOR_PIC,
  VALIDATE_AMBASSADOR,
} from "common/constants/actions";

function* loadambassadors(action) {
  try {
    const json = yield call(api.GET_AMBASSADORS, action.payload);

    yield put({ type: GET_AMBASSADORS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorLoad() {
  yield takeLatest(SG_GET_AMBASSADORS, loadambassadors);
}

function* validateAmbassador(action) {
  try {
    const json = yield call(api.VALIDATE_AMBASSADOR, action.payload);
    yield put({ type: VALIDATE_AMBASSADOR, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorValidate() {
  yield takeLatest(SG_VALIDATE_AMBASSADOR, validateAmbassador);
}

function* fetchambassadors(action) {
  try {
    const json = yield call(api.FETCH_AMBASSADOR, action.payload);
    yield put({ type: FETCH_AMBASSADOR, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorFetch() {
  yield takeLatest(SG_FETCH_AMBASSADOR, fetchambassadors);
}

function* updateambassadors(action) {
  try {
    const json = yield call(api.EDIT_AMBASSADOR, action.payload);
    yield put({ type: EDIT_AMBASSADOR, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorUpdate() {
  yield takeLatest(SG_EDIT_AMBASSADOR, updateambassadors);
}

function* updateambassadorpic(action) {
  try {
    const json = yield call(api.ADD_AMBASSADOR_PIC, action.payload);
    yield put({ type: UPDATE_AMBASSADOR_PIC, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorPicUpdate() {
  yield takeLatest(SG_UPDATE_AMBASSADOR_PIC, updateambassadorpic);
}

function* addambassadors(action) {
  try {
    const json = yield call(api.ADD_AMBASSADOR, action.payload);
    yield put({ type: ADD_AMBASSADOR, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorAdd() {
  yield takeLatest(SG_ADD_AMBASSADOR, addambassadors);
}

function* deleteambassadors(action) {
  try {
    yield call(api.DELETE_AMBASSADOR, action.payload);
    yield put({ type: DELETE_AMBASSADOR, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* ambassadorDelete() {
  yield takeLatest(SG_DELETE_AMBASSADOR, deleteambassadors);
}

export default function* index() {
  yield all([
    ambassadorLoad(),
    ambassadorFetch(),
    ambassadorUpdate(),
    ambassadorPicUpdate(),
    ambassadorAdd(),
    ambassadorDelete(),
    ambassadorValidate(),
  ]);
}
