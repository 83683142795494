import {
  SG_FETCH_USER_EMPLOYEE,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
} from "common/constants/actions";
// Libraries
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function useGetUserSettings(params) {
  const dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState();
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [accessSubfactor, setAccessSubfactor] = useState();
  const [otpView, setOtpView] = useState(false);
  const [primaryCategories, setPrimaryCategories] = useState();
  const [isOtp, setIsOtp] = useState(false);
  const [otpDualView, setOtpDualView] = useState(false);
  const [empCategoryList, setEmpCategoryList] = useState();
  const {
    get_auth,
    get_employee_user_category,
    get_employee_org_category,
    get_employee_categories,
    get_employees,
    get_survey_structure,
    get_organizations,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_employees: state.employees,
      get_survey_structure: state.surveystructure.survey_structure,
      get_organizations: state.organizations,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (params?.useOrg && get_employee_org_category?.length > 0) {
        const currentCategories = get_employee_org_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id),
        );
        setEmployeeCategories(currentCategories);
      } else if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id),
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [
    get_employee_user_category,
    get_auth?.employee_id,
    get_employee_user_category,
  ]);

  useEffect(() => {
    if (!currentUser && !get_employee_user_category?.length > 0) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: {
          id: Number(get_auth?.employee_id),
        },
      });

      if (params?.useOrg && !get_employee_org_category?.length > 0) {
        dispatch({
          type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
          payload: `organization=${get_auth?.organization_id}`,
        });
      } else {
        dispatch({
          type: SG_GET_EMPLOYEE_USER_CATEGORIES,
          payload: `employee=${get_auth?.employee_id}`,
        });
      }
    }
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (get_organizations) {
      const isOTPType = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP",
      )?.enabled;

      setIsOtp(isOTPType);
    }
  }, [get_organizations]);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    setAccessSubfactor(false);
    if (get_employees) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employees?.userEmp);
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employees.userEmp?.access_subfactor_data === 1) {
        setAccessSubfactor(true);
      }

      if (get_employees.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employees.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employees]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary",
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  // If isOtp, check to see if account is OTP view.
  useEffect(() => {
    if (
      employeeCategories &&
      employeeCategories?.category &&
      get_survey_structure.length > 0 &&
      isOtp
    ) {
      let otpView = true;
      const primaryList = get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary",
      )?.[0]?.options;

      const empCategories = employeeCategories?.category.map((ci) => ci?.value);

      setEmpCategoryList(empCategories);

      primaryList.forEach((li) => {
        if (!empCategories.includes(li.name)) {
          otpView = false;
        }
      });

      if (otpView === true && empCategories.length > primaryList.length) {
        setOtpDualView(true);
      }
      setOtpView(otpView);
    }
  }, [employeeCategories, isOtp, get_survey_structure]);

  return {
    isAdmin,
    canEdit,
    filteredCategories,
    currentUser,
    accessSubfactor,
    otpView,
    primaryCategories,
    isOtp,
    otpDualView,
    empCategoryList,
    get_employee_user_category,
  };
}

export default useGetUserSettings;
