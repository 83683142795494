function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}
const reducer = (a, b) => a + b.response;
const reducer2 = (a, b) => a + b;

const checkSubfactor = (anchor, categories) => {
  let primary = categories.categories.find((f) => f.priority == "primary");
  if ("subfactors" in primary.options[0]) {
    let subfactors = [];
    primary.options.map((item) => {
      item.subfactors.map((s) => subfactors.push(s.id));
    });
    return subfactors.includes(anchor);
  }

  return false;
};

export const get_historical = (
  data,
  factor,
  question,
  role,
  dates,
  anchor,
  categories
) => {
  if (Object.keys(factor).length < 4) {
    return [];
  }

  //remove the first data point from data because its a duplicate of the most recent responses
  let responses = data
    .filter((f, i) => i != 0)
    .map((item) => {
      //individual time point
      return item
        .filter((f) => {
          if (checkSubfactor(role, categories)) {
            return f.categories.find((f) => f.id == anchor)?.subfactor == role;
          }
          return f.categories.find((f) => f.id == anchor)?.response == role;
        })
        .filter(
          (f) =>
            f.questions.find(
              (f) =>
                f.id == factor.dimension &&
                f.factor == factor.factor &&
                f.q == question
            ) != undefined
        )
        .map((i) => {
          return i.questions.filter(
            (f) =>
              f.id == factor.dimension &&
              f.factor == factor.factor &&
              f.q == question
          )[0].response;
        });
    });
  //Process dates and averages for each datepoint
  return [
    {
      data: responses
        .filter((f, i) => dates[i])
        .map((date, i) => {
          let total = 0;
          let x = 0;
          date.map((val) => {
            total += val;
            x += 1;
          });
          return {
            x: dates[i],
            y: total ? Math.floor((total / x) * 10) / 10 : null,
          };
        }),
    },
  ];
};

const effect_size_calculation = (categories, data, anchor) => {
  //Determine tertiary category structure

  let tertiary = categories.categories.filter((f) => f.priority == "tertiary");

  let tertiary_ids = tertiary.map((item) => item.id);
  if (tertiary.length > 0) {
    let responses = data
      .filter((f) => f.questions.length > 0)
      .map((i) => {
        return {
          response: i.questions.reduce(reducer, 0) / i.questions.length,

          categories: i.categories.filter((f) => tertiary_ids.includes(f.id)),
        };
      });
    let ES = tertiary.map((category) => {
      let idx = category.id;
      let standard_deviation = getStandardDeviation(
        responses.map((i) => i.response)
      );
      return {
        name: category.name,
        ES: category.options.map((option, i) => {
          let total1 = 0;
          let count1 = 0;

          responses
            .filter(
              (f) =>
                f.categories.filter((d) => d.id == idx)[0]?.response ==
                option.id
            )
            .map((item) => {
              total1 += item.response;
              count1 += 1;
            });

          let total2 = 0;
          let count2 = 0;
          responses
            .filter(
              (f) =>
                f.categories.filter((d) => d.id == idx)[0]?.response !=
                option.id
            )
            .map((item) => {
              total2 += item.response;
              count2 += 1;
            });

          if (count1 == 0 || count2 == 0 || standard_deviation == 0) {
            return 0;
          }

          return (total1 / count1 - total2 / count2) / standard_deviation;
        }),
      };
    });

    return ES;
  }
};

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const findName = (key, item, categories) => {
  return categories.options
    .find((f) => f.id == key)
    .subfactors.find((f) => f.id == item).name;
};

const getRolesFromNav = (roles, nav) => {
  let primary = Object.keys(nav).find((f) => nav[f].priority == "primary");
  let subfactors = [];

  Object.keys(nav[primary])
    .filter((f) => !["priority", "selected"].includes(f))
    .map((key) => {
      let factor = nav[primary][key];
      if (factor.sub) {
        Object.keys(factor.subfactors)
          .filter((f) => factor.subfactors[f].selected)
          .map((item) => {
            subfactors.push({
              parent: key,
              subID: factor.subfactors[item].id,
              name: findName(key, item, roles),
            });
          });
      }
    });

  return subfactors;
};

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    if(!isNaN(item)){
      total += item;
      n += 1;
    }
   
  });
  return total / n;
};

const get_norm = (data, selection, questions) => {
  let all_resp = data
    .filter((x, i) => i != 0)
    .map((r) => r.responses)
    .flat();
  const reducer_reverse = (a, b) => {
    let resp = check_reverse_score(b, questions);
    return a + resp;
  };

  let filter_data;
  if (selection.id == 0) {
    filter_data = all_resp;
  } else if (selection.level == 1) {
    filter_data = all_resp.map((x) => {
      return {
        ...x,

        questions: x.questions.filter((f) => f.id == selection.dimension),
      };
    });
  } else {
    filter_data = all_resp.map((x) => {
      return {
        ...x,

        questions: x.questions.filter(
          (f) => f.id == selection.dimension && f.factor == selection.factor
        ),
      };
    });
  }

  let summary = filter_data?.map(
    (val) => val?.questions?.reduce(reducer_reverse, 0) / val?.questions?.length
  );
  if (summary) {
    return average(summary);
  }
  return null;
};

export const calculate_history_tab = (
  filtered,
  raw,
  selection,
  categories,
  dates,
  anchor,
  rule,
  questions,
  nav
) => {
  if (Object.keys(selection).length < 1) {
    return {};
  }

  questions.dimensions[0].factors = questions.dimensions[0].factors.sort(
    (a, b) => a.id - b.id
  );

  let roles = categories.categories.find((f) => f.id == anchor);
  let subfactors = [];
  if (roles.priority == "primary") {
    subfactors = getRolesFromNav(roles, nav);
  }

  //take subfactors here if present and replace the roles map below
  let current, historical;
  //Sort calculation based on overview, dimension, or specific factor
  if (selection.id == 0) {
    current = filtered[0];
    historical = filtered.flat();
  } else if (selection.level == 1) {
    let new_filtered = filtered.map((i) => {
      return i.map((x) => {
        return {
          ...x,

          questions: x.questions.filter((f) => f.id == selection.dimension),
        };
      });
    });
    current = new_filtered[0];
    historical = new_filtered.flat();
  } else {
    let new_filtered = filtered.map((i) => {
      return i.map((x) => {
        return {
          ...x,
          questions: x.questions.filter(
            (f) => f.id == selection.dimension && f.factor == selection.factor
          ),
        };
      });
    });
    current = new_filtered[0];
    historical = new_filtered.flat();
  }

  const reducer_reverse = (a, b) => {
    let resp = check_reverse_score(b, questions);
    return a + resp;
  };

  let chart1;

  let overall_norm = get_norm(raw, selection, questions);

  if (subfactors.length > 0) {
    chart1 = subfactors.map((item, i) => {
      let data = current.filter(
        (f) => f.categories.find((f) => f.id == anchor).subfactor == item.subID
      );

      //Rule of 3, only allow data above 2 entries to be passed
      if (data.length > rule) {
        let summary = data.map(
          (val) =>
            val.questions.reduce(reducer_reverse, 0) / val.questions.length
        );

        let summary_hist = historical
          .filter(
            (f) =>
              f.categories.find((f) => f.id == anchor)?.subfactor == item.subID
          )

          .map(
            (val) =>
              val.questions.reduce(reducer_reverse, 0) / val.questions.length
          );

        return {
          current: summary.reduce(reducer2) / summary.length,
          historical: summary_hist.reduce(reducer2) / summary_hist.length,
          name: item.name,
          average: overall_norm,
        };
      }
    });
  } else {
    chart1 = roles.options.map((item, i) => {
      let data = current.filter(
        (f) => f.categories.find((f) => f.id == anchor)?.response == item.id
      );

      //Rule of 3, only allow data above 2 entries to be passed
      if (data.length > rule) {
        let summary = data.map(
          (val) =>
            val.questions.reduce(reducer_reverse, 0) / val.questions.length
        );



        let summary_hist = historical
          .filter(
            (f) => f.categories.find((f) => f.id == anchor)?.response == item.id
          )

          .map(
            (val) =>
              val.questions.reduce(reducer_reverse, 0) / val.questions.length
          );

        return {
          current: average(summary),
          historical: average(summary_hist),
          name: item.name,
          fr: item.fr,
          average: overall_norm,
        };
      }
    });
  }

  const checkFactor = (factor) => {

    return questions.dimensions[factor.id]?.factors?.length > factor?.factor;
  };

  let curr_history = filtered
    .filter((f, i) => i != 0)
    .filter((f, i) => dates[i])
    .map((date, i) => {
      let _data = date;
      if (selection.level == 1) {
        _data = _data.map((x) => {
          return {
            ...x,

            questions: x.questions.filter(
              (f) => f.id == selection.dimension && checkFactor(f)
            ),
          };
        });
      } else if (selection.id != 0) {
        _data = _data.map((x) => {
          return {
            ...x,
            questions: x.questions.filter(
              (f) => f.id == selection.dimension && f.factor == selection.factor
            ),
          };
        });
      } else {
        _data = _data.map((x) => {
          return {
            ...x,

            questions: x.questions.filter((f) => checkFactor(f)),
          };
        });
      }

      let summary = _data.map((val) => {
        return val.questions.reduce(reducer_reverse, 0) / val.questions.length;
      });

      return {
        x: dates[i],

        y:
          summary.length > 0
            ?average(summary).toFixed(1)
            : null,
      };
    });

  let ES = effect_size_calculation(categories, current, anchor);

  return {
    chart1: chart1.filter((item) => item !== undefined),
    chart2: [{ data: curr_history }],
    ES,
  };
};
