import {
  ADD_GLOBAL_SCHEDULE_FREQUENCY,
  CLEAR_ALL,
  CLEAR_GLOBAL_SCHEDULE_FREQUENCY,
  DELETE_GLOBAL_SCHEDULE_FREQUENCY,
  EDIT_GLOBAL_SCHEDULE_FREQUENCY,
  FETCH_GLOBAL_SCHEDULE_FREQUENCY,
  GET_GLOBAL_SCHEDULE_FREQUENCIES,
} from "common/constants/actions";

const initialState = {
  global_schedule_frequencies: [],
  pending: false,
  updated: false,
};

export default function surveyScheduleFrequencyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GLOBAL_SCHEDULE_FREQUENCIES:
      return {
        ...state,
        global_schedule_frequencies: action.payload,
        pending: false,
        updated: false,
      };

    case FETCH_GLOBAL_SCHEDULE_FREQUENCY:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        updated: false,
      };

    case EDIT_GLOBAL_SCHEDULE_FREQUENCY:
      const schedule = state.global_schedule_frequencies.filter(
        (row) => row.id !== action.payload.id,
      );
      schedule.push(action.payload);
      return {
        ...state,
        global_schedule_frequencies: schedule,
        pending: false,
        updated: false,
      };

    case DELETE_GLOBAL_SCHEDULE_FREQUENCY:
      return {
        ...state,
        global_schedule_frequencies: state.global_schedule_frequencies.filter(
          (row) => row.id !== action.payload.id,
        ),
        pending: false,
        updated: false,
      };

    case ADD_GLOBAL_SCHEDULE_FREQUENCY:
      return {
        ...state,
        global_schedule_frequencies: [
          ...state.global_schedule_frequencies,
          action.payload,
        ],
        pending: false,
        updated: false,
      };

    case CLEAR_GLOBAL_SCHEDULE_FREQUENCY:
      return {
        ...state,
        global_schedule_frequencies: [],
        pending: false,
        updated: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        global_schedule_frequencies: [],
        pending: false,
        updated: false,
      };

    default:
      return state;
  }
}
