import {
  ADD_DEBRIEF_EMAIL,
  CLEAR_ALL,
  CLEAR_DEBRIEF_EMAIL,
  DEBRIEF_EMAIL_PENDING,
  DELETE_DEBRIEF_EMAIL,
  FETCH_DEBRIEF_EMAIL,
  GET_DEBRIEF_EMAIL,
  UPDATE_DEBRIEF_EMAIL,
} from "common/constants/actions";

const initialState = {
  debrief_email: [],
  pending: false,
  created: [],
};

export default function DebriefResponse(state = initialState, action) {
  switch (action.type) {
    case DEBRIEF_EMAIL_PENDING:
      return {
        ...state,
        pending: true,
      };

    case GET_DEBRIEF_EMAIL:
      return {
        ...state,
        debrief_email: action.payload,
        pending: false,
      };

    case FETCH_DEBRIEF_EMAIL:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case UPDATE_DEBRIEF_EMAIL:
      return {
        ...state,
        debrief_email: [...state.debrief_email, action.payload],
        pending: false,
      };

    case DELETE_DEBRIEF_EMAIL:
      return {
        ...state,
        debrief_email: state.debrief_email.filter(
          (row) => row.id !== action.payload.id,
        ),
        pending: false,
      };

    case ADD_DEBRIEF_EMAIL:
      return {
        ...state,
        created: action.payload,
        debrief_email: [...state.debrief_email, action.payload],
        pending: false,
      };

    case CLEAR_DEBRIEF_EMAIL:
      return {
        ...state,
        debrief_email: [],
        created: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_email: [],
        created: [],
        pending: false,
      };

    default:
      return state;
  }
}
