import React from "react";

import { Icon, Popup, Table } from "semantic-ui-react";

export function ValidateCheckRow({
  k,
  item,
  head,
  validEmaiIds,
  categoryLabel,
  fileHeaders,
  validFieldNames,
  createIfNotFound,
}) {
  let Foundval = false;

  if (fileHeaders?.includes(head)) {
    let _options = validFieldNames?.[head]?.options;
    Foundval = _options?.find((op) => op.name === item[head])?.name;
  }

  if (head === "email") {
    if (!item?.email) {
      return (
        <Table.Cell key={k} negative>
          <Popup
            content="This field cannot be empty"
            trigger={<Icon name="attention" />}
          />
          {item[head]}
        </Table.Cell>
      );
    }
    const Found = validEmaiIds.includes(item?.email);
    if (Found) {
      return (
        <Table.Cell key={k} positive>
          <Popup
            content="Found"
            trigger={<Icon color="green" name="checkmark" />}
          />
          {item[head]}{" "}
        </Table.Cell>
      );
    } else {
      if (createIfNotFound) {
        return (
          <Table.Cell key={k} warning>
            <Popup
              content="Not Found will be added"
              trigger={<Icon name="warning" />}
            />
            {item[head]}
          </Table.Cell>
        );
      }
      return (
        <Table.Cell key={k} negative>
          <Popup
            content="No Found cant add"
            trigger={<Icon name="attention" />}
          />
          {item[head]}
        </Table.Cell>
      );
    }
  }
  if (head === "first_name" || head === "last_name") {
    return (
      <Table.Cell positive>
        <Popup
          content="Found"
          trigger={<Icon color="green" name="checkmark" />}
        />
        {item[head]}{" "}
      </Table.Cell>
    );
  }
  if (fileHeaders.includes(head)) {
    if (Foundval) {
      return (
        <Table.Cell positive>
          <Popup
            content="Found"
            trigger={<Icon color="green" name="checkmark" />}
          />
          {item[head]}{" "}
        </Table.Cell>
      );
    } else {
      return (
        <Table.Cell negative>
          <Popup content="No valid value" trigger={<Icon name="attention" />} />
          {item[head]}
        </Table.Cell>
      );
    }
  }
  return <Table.Cell key={k}>{item[head]}</Table.Cell>;
}

export default ValidateCheckRow;
