import styled from "styled-components";
import React from "react";

const TData = [
  {
    title: "DEI Overall",
    color: "0, 0, 0",
    categories: [
      "Overall",
      "Coaches",
      "Technical Staff",
      "Athletes",
      "Sport Science",
    ],
  },
];

const top_labels = ["Average", "N"];
const top_labels_french = ["Moyenne", "N"];
const colored = [];

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const SingleTable = ({
  data,
  labels,
  color,
  count,
  language_hook,
  is_french,
}) => {
  return (
    <Container>
      <TR>
        <TH width={25} borderLeft={false} borderRight={true} color={color}>
          {language_hook("DEI Overall")}
        </TH>

        {top_labels.map((item, i) => {
          return (
            <TH
              width={75 / top_labels.length}
              borderRight={i == top_labels.length - 1 ? false : true}
              center={true}
              color={color}
            >
              {is_french ? top_labels_french[i] : item}
            </TH>
          );
        })}
      </TR>

      {Object.keys(data).map((item, i) => {
        return (
          <TableRow
            title={labels.find((f) => f.id == item)}
            data={data[item]}
            count={count}
            is_french={is_french}
          />
        );
      })}
    </Container>
  );
};

const Table = ({
  data,
  labels,
  color,
  structure,
  is_french,
  language_hook,
}) => {
  let count = 0;

  structure.dimensions.map((item) => {
    item.factors.map((f) => {
      count += f.questions.length;
    });
  });

  return (
    <SingleTable
      data={data.overall}
      labels={labels}
      color={color}
      count={count}
      is_french={is_french}
      language_hook={language_hook}
    />
  );
};

export default Table;

const TableRow = (props) => {
  return (
    <TR>
      <TD width={25} borderLeft={false} borderRight={true}>
        <Name>{props.is_french ? props.title.fr : props.title?.name}</Name>
      </TD>
      {top_labels.map((item, i) => {
        return (
          <TD2
            width={75 / top_labels.length}
            borderRight={i == top_labels.length - 1 ? false : true}
            center={true}
            bold={i == 0}
            color={
              colored.includes(i) ? "rgba(" + props.color + ",0.3)" : "white"
            }
          >
            {i == 0
              ? Math.floor((props.data.total / props.data.n) * 10) / 10
              : props.data.n2}
          </TD2>
        );
      })}
    </TR>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
`;
const TR = styled.div`
  width: 100%;
  padding: 14px;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 3px;
`;

const TH = styled.div`
  color: black;
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const Name = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;
`;

const TD2 = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  padding: 3px 6px;
  justify-content: center;
  background-color: ${(props) => props.color};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #7e7e7e;
`;
