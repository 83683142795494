import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Message, Modal } from "semantic-ui-react";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import Select from "react-select";

import { SortedTimezone } from "common/constants/timezone";

import {
  PULL_ORGANIZATION,
  SG_ADD_DEBRIEF_SCHEDULE,
  SG_FETCH_DEBRIEF_SCHEDULE,
} from "common/constants/actions";

const AddSchedule = ({
  briefId,
  primaryCategory,
  secondaryCategory,
  setFetchAgain,
  trigger,
  open,
  setOpen,
  surveyQuestions,
  surveyStructure,
  primarySecondaryName,
  surveyType,
  surveyInfo,
  setAlertMessage,
}) => {
  const dispatch = useDispatch();

  const [dateValue, setDateValue] = useState(new Date());

  const [briefType, setBriefType] = useState(false);
  const [pristine, setPristine] = useState(true);

  const [recheckQuestionList, setRecheckQuestionList] = useState(false);

  const [organizationId, setOrganizationId] = useState(false);
  const [organization, setOrganization] = useState(false);

  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState(false);

  const [formErrors, setFormErrors] = useState([]);
  const [debriefSchedule, setDebriefSchedule] = useState(false);

  const [cultureAuditQues, setCultureAuditQues] = useState(0);

  const [surveySeleStructure, setSurveySeleStructure] = useState(false);
  const [surveySeleQuestion, setSurveySeleQuestion] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [canEdit, setCanEdit] = useState();
  const [endDate, setEndDate] = useState(addDays(new Date(), 21));
  const [primaryOptions, setPrimaryOptions] = useState(null);
  const [timeZone, setTimeZone] = useState({
    value: "America/Halifax",
    label: "America/Halifax",
    text: "America/Halifax",
  });

  useEffect(() => {
    setBriefType(debriefSchedule?.type_of);
  }, [debriefSchedule]);

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual,
  );

  const {
    get_errors,
    get_auth,
    get_selectedOrg,
    get_organizations,
    get_debrief_schedules,
    get_employee_category,
    get_employee,
  } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_employee: state.employees,
      get_employee_category: state.employee_category.employee_category,
      get_auth: state.auth,
      get_selectedOrg: state.selectedOrg,
      get_organizations: state.organizations,
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (get_auth && get_employee) {
      const found = get_employee?.employees.find((emp) => {
        return emp.id === Number(get_auth?.employee_id);
      });

      if (found) {
        setCurrentUser(found);
      }

      if (found?.survey_add_users === 1) {
        setCanEdit(true);
      }
    }
  }, [get_employee, get_auth]);

  useEffect(() => {
    if (briefId) {
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: { id: briefId },
      });
    }
  }, [dispatch, briefId]);

  useEffect(() => {
    setDebriefSchedule(get_debrief_schedules?.[briefId]);
  }, [get_debrief_schedules, briefId]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch({ type: SG_GET_MOODS });
    if (Number(AmbassadorId) > 0) {
      if (organizationId) {
        dispatch({
          type: PULL_ORGANIZATION,
          payload: { id: organizationId },
        });
        return;
      }
    }
  }, [dispatch, AmbassadorId, organizationId]);

  useEffect(() => {
    setOrganization(get_organizations?.[organizationId]);
  }, [get_organizations, organizationId]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (organization) {
      setTimeZone({
        value: organization?.time_zone,
        label: organization?.time_zone,
        text: organization?.time_zone,
      });
    }
  }, [organization]);

  function UpdateTimezone(e) {
    setTimeZone({
      value: e.value,
      label: e.value,
      text: e.value,
    });
  }

  useEffect(() => {
    if (
      !currentUser?.account_type === 5 &&
      currentUser?.survey_add_users === 1
    ) {
      const filterCat = get_employee_category
        ?.find((c) => {
          return c.employee === currentUser?.id;
        })
        ?.category.filter((c) => c.priority === "primary")
        .map((c) => c.value);

      const filteredOptions = primaryCategory.filter((o) => {
        return filterCat.includes(o.value);
      });

      setPrimaryOptions(filteredOptions);
    }
  }, [primaryCategory]);

  // console.log(debriefSchedule)
  // console.log(primaryCategory)

  useEffect(() => {
    if (debriefSchedule) {
      // HoursList
      if (debriefSchedule?.scheduled_for) {
        setDateValue(new Date(debriefSchedule?.scheduled_for));
      }
      setBriefType(debriefSchedule?.type_of);
      // setTypeList(debriefSchedule?.additional_type);
      setSelectedPrimary(
        primaryCategory.find((hl) => hl.name_id === debriefSchedule?.name_id),
      );

      if (debriefSchedule?.secondary) {
        setSelectedSecondary(
          secondaryCategory.filter((hl) =>
            debriefSchedule?.secondary.includes(hl.value),
          ),
        );
      }

      // setSelectedNoOfHours(
      //   HoursList.find((hl) => hl.value === debriefSchedule?.close_in_minutes)
      // );
      setTimeZone({
        value: debriefSchedule?.time_zone,
        label: debriefSchedule?.time_zone,
        text: debriefSchedule?.time_zone,
      });
    }
  }, [debriefSchedule, primaryCategory, secondaryCategory]);

  function CheckThis() {
    const Vals = ["Athlete", "Coach"];
    setSelectedSecondary(
      secondaryCategory.filter((hl) => Vals.includes(hl.value)),
    );
  }

  const onSubmit = () => {
    if (formErrors.length > 0) {
      setPristine(false);
      return;
    }

    if (!briefId) {
      dispatch({
        type: SG_ADD_DEBRIEF_SCHEDULE,
        payload: {
          user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type_of: surveyType,
          multiple_list: selectedPrimary.map((sp) => [sp.name, sp.name_id]),
          secondary: selectedSecondary
            ? selectedSecondary.map((sp) => sp.value)
            : null,
          scheduled_for: dateValue,
          time_zone: timeZone.value,
          close_at: endDate,
          organization: organizationId,
          brief_question: surveySeleQuestion?.id,
          survey_structure: surveySeleStructure?.id,
        },
      });
      setFetchAgain(Math.random());
    }
    setOpen(false);
    setPristine(true);
    setAlertMessage({ no: 1, text: "Successfully Added New Schedule" });
    ResetForm();
  };

  function ResetForm() {
    setSelectedPrimary([]);
    setDateValue(new Date());
    setSelectedSecondary(false);
  }

  function CloseForm() {
    ResetForm();
    setOpen(false);
    setRecheckQuestionList(true);
  }

  useEffect(() => {
    setCultureAuditQues(
      organization?.services_enabled &&
        organization?.services_enabled.filter(
          (sc) => sc?.name === surveyInfo?.name,
        )?.[0]?.question_sort_order,
    );
  }, [organization, recheckQuestionList, surveyInfo]);

  // default sort order of survey question for culture 6
  useEffect(() => {
    setSurveySeleQuestion(surveyQuestions[0]);
    if (cultureAuditQues > 0) {
      if (surveyQuestions) {
        setSurveySeleQuestion(
          surveyQuestions.filter(
            (sq) => sq.sort_order === cultureAuditQues,
          )?.[0],
        );
      }
    }
  }, [surveyQuestions, organizationId, recheckQuestionList, cultureAuditQues]);

  useEffect(() => {
    if (surveyStructure?.length === 1) {
      setSurveySeleStructure(surveyStructure[0]);
    }
  }, [surveyStructure, recheckQuestionList]);

  useEffect(() => {
    if (recheckQuestionList) {
      const timer1 = setTimeout(() => {
        setRecheckQuestionList(false);
      }, 2000);
      return () => clearTimeout(timer1);
    }
  }, [surveySeleQuestion, surveySeleStructure, recheckQuestionList]);

  useEffect(() => {
    const ErrorList = [];
    if (selectedPrimary?.length === 0) {
      ErrorList.push("Select a Primary");
    }
    if (!dateValue) {
      ErrorList.push("Select a Date");
    }
    if (!endDate) {
      ErrorList.push("Closing and Closing Date");
    }
    if (!surveySeleQuestion?.id) {
      ErrorList.push("Select a Survey Question");
    }
    if (!surveySeleStructure?.id) {
      ErrorList.push("Select a Select Structure");
    }
    setFormErrors(ErrorList);
  }, [
    briefType,
    selectedPrimary,
    dateValue,
    endDate,
    surveySeleQuestion,
    surveySeleStructure,
    recheckQuestionList,
  ]);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    setEndDate(addDays(dateValue, 1));
  }, [dateValue]);

  return (
    <Modal
      onClose={CloseForm}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      size="tiny"
      closeIcon
    >
      <Modal.Header>
        <Title>Schedule an Audit Assessment</Title>
      </Modal.Header>
      <Modal.Content>
        <div>
          {formErrors.length > 0 && !pristine && (
            <Message
              negative
              header="We need these details!"
              list={formErrors}
            />
          )}

          <FormContainer>
            <Label style={{ cursor: "pointer" }} onClick={CheckThis}>
              {primarySecondaryName?.primary}
            </Label>

            <Select
              placeholder={`Select ${primarySecondaryName?.primary}`}
              onChange={(e) => setSelectedPrimary(e)}
              value={selectedPrimary}
              isMulti
              options={primaryOptions || primaryCategory}
            />
          </FormContainer>
          {secondaryCategory && (
            <FormContainer>
              <Label>{primarySecondaryName?.secondary}</Label>

              <Select
                placeholder={`Leave Blank for all ${primarySecondaryName?.secondary}`}
                onChange={(e) => setSelectedSecondary(e)}
                value={selectedSecondary}
                isMulti
                options={secondaryCategory}
              />
            </FormContainer>
          )}

          <FormContainer>
            <Label>Time Zone</Label>
            <Select
              placeholder="Select your timezone"
              onChange={(e) => UpdateTimezone(e)}
              value={timeZone}
              options={SortedTimezone()}
            />
          </FormContainer>
          <FormContainer>
            <Label>Start Assessment</Label>
            <DateTimePicker onChange={setDateValue} value={dateValue} />
          </FormContainer>
          <FormContainer>
            <Label>Close Assessment</Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect
              minDate={endDate}
              maxDate={addDays(dateValue, 90)}
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </FormContainer>

          {!surveySeleQuestion?.id && (
            <FormContainer>
              <Label>Select Survey Question</Label>
              {/* <Input placeholder='# of hours' /> */}

              <Select
                placeholder="Select Question"
                onChange={(e) => setSurveySeleQuestion(e)}
                value={surveySeleQuestion}
                options={surveyQuestions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </FormContainer>
          )}
          {surveyStructure?.length !== 1 && (
            <FormContainer>
              <Label>Select Category</Label>
              {/* <Input placeholder='# of hours' /> */}

              <Select
                placeholder="Select Category"
                onChange={(e) => setSurveySeleStructure(e)}
                value={surveySeleStructure}
                options={surveyStructure}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </FormContainer>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <StyledButton
          color={"#2d50e2"}
          disable={formErrors.length > 0 && !pristine}
          onClick={onSubmit}
        >
          {briefId ? "update" : "Schedule"}
        </StyledButton>
      </Modal.Actions>
    </Modal>
  );
};

AddSchedule.propTypes = {
  briefId: PropTypes.number,
  primaryCategory: PropTypes.array,
  setFetchAgain: PropTypes.func,
  trigger: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  surveyQuestions: PropTypes.array,
  surveyStructure: PropTypes.array,
  primarySecondaryName: PropTypes.object,
  surveyType: PropTypes.number,
  surveyInfo: PropTypes.object,
};

const HoursList = [
  { value: 30, label: "Half Hour (30 mins) - testing only" },
  { value: 60, label: "One Hour (60 mins) - testing only" },
  { value: 1440, label: "One Day (24 hrs)" },
  { value: 4320, label: "Three Days" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Week" },
  { value: 30240, label: "3 Week" },
  { value: 40320, label: "4 Week" },
];

/******** Styles ****************************************************/

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
`;

const FormContainer = styled.div`
  max-width: 300px;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 0px 30px;
  height: 40px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

export default AddSchedule;
