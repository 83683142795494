import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Search } from "../Insights/difference_search";
import {
  SET_NAVIGATION,
  SET_SELECTED_FILTERS,
  SET_SELECTED_QUESTION,
  SET_SUB_NAV,
} from "common/constants/actions";
import { build_default_State } from "../../../../../common/calculations/analytics";
import { Dimmer, Loader, Pagination } from "semantic-ui-react";

const configure = (structure) => {
  let ids = 0;
  let names = [{ title: "Overall DEI", level: 0, id: ids }];
  ids += 1;
  if (structure?.dimensions) {
    structure?.dimensions.map((item, i) => {
      names.push({ title: item.title, level: 1, id: ids, dimension: i });
      ids += 1;
      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
  }

  return names;
};

const Insights = ({ questions, change, insights }) => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.audit?.filters, shallowEqual);
  const pillar = useSelector((state) => state.audit?.selected, shallowEqual);
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState(true);
  const [changes, setChanges] = useState(change);
  const [demographic, setDemographics] = useState(false);
  const [layer, setLayers] = useState(1);
  const [loading, setLoading] = useState(true);

  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual,
  );

  const filter_last_data = useSelector(
    (state) => state.audit?.filtered_last_data,
    shallowEqual,
  );

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual,
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    if (filter_data.length > 0) {
      setLoading(true);

      setData(
        Search(
          filter_data,
          categories,
          questions,
          checked,
          demographic,
          layer,
          filter_last_data,
        ).filter((f) => f.d == pillar.dimension && f.factor == pillar.factor),
      );
      setTimeout(() => {
        setLoading(false);
      }, [400]);
    }
  }, []);

  useEffect(() => {
    if (filter_data.length > 0 && Object.keys(categories).length > 0) {
      setLoading(true);
      let search_data = Search(
        filter_data,
        categories,
        questions,
        checked,
        demographic,
        layer,
        filter_last_data,
      ).filter((f) => f.d == pillar.dimension && f.factor == pillar.factor);
      if (changes) {
        search_data = search_data
          .filter((f) => f.hasOwnProperty("change"))
          .filter((f) => f.change)
          .sort((a, b) => a.change - b.change);
      }
      setData(search_data);
      setTimeout(() => {
        setLoading(false);
      }, [400]);
    }
  }, [checked, filter_data, layer, demographic, changes, categories]);

  const handle_navigation = (n1, n2, select) => {
    let structure = configure(questions);
    let _selected = structure.find(
      (f) => f.dimension == select.id && f.factor == select.factor,
    );

    dispatch({
      type: SET_SELECTED_QUESTION,
      payload: _selected,
    });

    dispatch({
      type: SET_SUB_NAV,
      payload: 3,
    });

    onSelect(n1, n2);
  };

  const onSelect = (s1, s2) => {
    let check = selected.filter(
      (f) =>
        (f.id === s1.id &&
          f.subfactor === s1?.subfactor &&
          f.option === s1.option) ||
        (f.id === s2.id &&
          f.subfactor === s2?.subfactor &&
          f.option === s2.option),
    );
    let _selected;

    if (check.length > 0) {
      _selected = selected;
    } else {
      if (s2.option) {
        _selected = [...selected, s1, s2];
      } else {
        _selected = [...selected, s1];
      }
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);

    new_selected.map((item, i) => {
      if (_selected[item.id]?.selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }

      if (item.subfactor != null) {
        _selected[item.id][item.option].subfactors[item.subfactor].selected =
          true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  return (
    <Container>
      {/*	<div style={{width:'100%',display:'flex',justifyContent:'space-between',paddingRight:30,marginBottom:30}}>
			<Title>Insights Scan</Title>
			<div style={{display:'flex'}}>
				<Checkbox toggle checked={changes} onChange={(e,d)=>setChanges(d.checked)} label='Change metrics' style={{marginRight:30}}/>
				<Checkbox toggle checked={checked} onChange={(e,d)=>setChecked(d.checked)} label='Pillar data' style={{marginRight:30}}/>
				<Checkbox toggle checked={layer==2} onChange={(e,d)=>setLayers(d.checked?2:1)} label='2 Layers' style={{marginRight:30}}/>
				<Checkbox toggle checked={demographic} onChange={(e,d)=>setDemographics(d.checked)} label='Demographics'/>
			</div>
			</div>*/}

      <Dimmer.Dimmable blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        {data.slice((page - 1) * 10, 10 * page).map((item) => {
          let select = { factor: item.factor, id: item.d };
          let n1 = {
            option: item.option1.id,
            id: item.category1,
            name: item.option1,
            subfactor: null,
          };
          let n2 = {
            option: item?.option2?.id,
            id: item?.category2,
            name: item?.option2,
            subfactor: null,
          };
          return (
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  width: "30%",
                }}
              >
                {/*							<Warning color={getColor(item.diff)}>
								<Icon name={'warning'} style={{margin:0,marginTop:-4}}/>
							</Warning>*/}
                <Sub>
                  {
                    categories.categories.find((f) => f.id == item.category1)
                      .name
                  }
                </Sub>
                <Option>{item.option1.name}</Option>
              </div>
              {item.option2 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "30%",
                  }}
                >
                  <Sub>
                    {
                      categories.categories.find((f) => f.id == item.category2)
                        .name
                    }
                  </Sub>
                  <Option>{item.option2.name}</Option>
                </div>
              ) : (
                ""
              )}

              {/*<Col width={20}>
							{item.factor!==null?<Pillar>{questions.dimensions.find(f=>f.id-1==item.d).factors.find(f=>f.id-1==item.factor).title}</Pillar>:""}
						</Col>*/}

              <Col width={40}>
                {/*{getFlags(item).map((item=><Flag data={item}/>))}*/}
                <Score>
                  {changes
                    ? (item.change > 0 ? "+" : "") +
                      (item.change * 10).toFixed(1)
                    : item.diff.toFixed(1)}
                  %{" "}
                  {changes
                    ? "since last survey"
                    : item.diff < 0
                      ? "below norm"
                      : "above norm"}
                </Score>
              </Col>
            </Row>
          );
        })}
      </Dimmer.Dimmable>

      <div
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          display: "flex",
          marginTop: 10,
        }}
      >
        <Pagination
          activePage={page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          totalPages={Math.ceil(data.length / 10)}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          size="mini"
        />
      </div>
    </Container>
  );
};

export default Insights;

const Warning = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
  font-family: "Poppins";
  margin-top: 15px;
`;

const Row = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Option = styled.div`
  font-size: 14px;
`;

const Sub = styled.div`
  font-size: 8px;
`;

const Score = styled.div`
  color: #6d6d6d;
`;

const Pillar = styled.div`
  font-weight: 400;
  font-size: 8px;
  background-color: #476dfa;
  color: white;
  border-radius: 100px;
  padding: 0px 10px;
  width: 150px;
  display: flex;
  justify-content: center;
`;

const Col = styled.div`
  width: ${(props) => props.width + "%"};
  padding-left: 5px;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;

  color: #414141;
`;
