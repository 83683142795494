import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";

const QuickFilters = (props) => {
  const { onSelect, categories, selectedCategories } = props;

  return (
    <QuickFilterContainer>
      {categories?.map((c) => {
        return (
          <QuickFilterButton
            isSelected={selectedCategories?.includes(c)}
            onClick={() => onSelect(c)}
          >
            <QuickFilterText>{c}</QuickFilterText>
          </QuickFilterButton>
        );
      })}
    </QuickFilterContainer>
  );
};

const CategorizedDropdown = (props) => {
  const {
    filterOptions,
    isMulti,
    value,
    placeholder,
    onSelectLabel,
    hideFilter,
    hideLabel,
  } = props;
  const [open, setOpen] = useState(false);
  const [quickFilters, setQuickFilters] = useState([]);
  const [search, setSearch] = useState(false);
  const [selected, setSelected] = useState(value);
  const [listedOptions, setListedOptions] = useState([]);
  const [quickFilterOptions, setQuickFilterOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();

  useEffect(() => {
    const listToFilter = search.length > 0 ? listedOptions : filterOptions;
    if (quickFilters.length > 0) {
      const newList = listToFilter?.filter((l) =>
        quickFilters?.includes(l.value.type),
      );
      setCategoryOptions(quickFilters);
      setListedOptions(newList);
    } else {
      setCategoryOptions(quickFilterOptions);

      setListedOptions(listToFilter);
    }
  }, [quickFilters, search]);

  useEffect(() => {
    setCategoryOptions(quickFilterOptions);
  }, [quickFilterOptions]);

  useEffect(() => {
    const quickOps = [];
    filterOptions?.forEach((o) => {
      if (!quickOps.includes(o.value.type)) {
        quickOps.push(o.value.type);
      }
    });

    setQuickFilterOptions(quickOps);
    setListedOptions(filterOptions);
  }, [filterOptions]);

  const selectFilter = (filter) => {
    const selectedFilters = [...quickFilters];
    const isSelectedIndex = selectedFilters.findIndex((f) => f === filter);

    if (isSelectedIndex > -1) {
      selectedFilters.splice(isSelectedIndex, 1);
    } else {
      selectedFilters.push(filter);
    }

    setQuickFilters(selectedFilters);
  };

  const onSelect = (s) => {
    setSelected(s);
    onSelectLabel(s);
    setOpen(false);
  };

  const onSearchChange = (s) => {
    setSearch(s.target.value);
    const listToFilter =
      quickFilters.length > 0 ? listedOptions : filterOptions;
    if (s.target.value.length > 0) {
      const filteredList = listToFilter.filter((f) =>
        f.text.toLowerCase().includes(s.target.value.toLowerCase()),
      );

      setListedOptions(filteredList);
    } else {
      setListedOptions(filterOptions);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setOpen(false);
      setSelected(listedOptions[0]);
      onSelectLabel(listedOptions[0]);
    }

    if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <DropdownContainer>
        {(!open && (
          <DropdownSelect onClick={() => setOpen(true)}>
            {(selected && value && (
              <SelectedLabel>{selected.text}</SelectedLabel>
            )) || <PlaceholderLabel>{placeholder}</PlaceholderLabel>}
            <IconWrapper>
              <Icon name="caret down" size="small" color="grey" />
            </IconWrapper>
          </DropdownSelect>
        )) || (
          <SelectContainer>
            <SearchSection>
              <SearchWrap>
                <Icon name="search" style={{ fontSize: 12 }} color="grey" />
              </SearchWrap>
              <SearchBox
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(s) => onSearchChange(s)}
              ></SearchBox>
              <IconWrapper>
                <Icon name="caret up" size="small" color="grey" />
              </IconWrapper>
            </SearchSection>

            {!hideFilter && (
              <>
                <Line />
                <QuickFilterWrapper>
                  <QuickFilterHeader>I'm searching for ...</QuickFilterHeader>

                  <QuickFilters
                    categories={quickFilterOptions}
                    selectedCategories={quickFilters}
                    onSelect={(s) => selectFilter(s)}
                  />
                </QuickFilterWrapper>
              </>
            )}

            {categoryOptions.map((cat) => {
              return (
                <CategoryWrapper hideLabel={hideLabel}>
                  {!hideLabel && <CategoryLabel>{cat}</CategoryLabel>}

                  {listedOptions
                    .filter((lo) => {
                      return lo.value.type === cat;
                    })
                    .map((item) => (
                      <LabelWrapper onClick={() => onSelect(item)}>
                        <SelectLabel>{item.text}</SelectLabel>
                        {selected?.text === item.text && (
                          <Icon
                            name="check"
                            size="small"
                            color="grey"
                            style={{ paddingLeft: 10, marginTop: 10 }}
                          />
                        )}
                      </LabelWrapper>
                    ))}
                </CategoryWrapper>
              );
            })}
          </SelectContainer>
        )}
      </DropdownContainer>
    </ClickAwayListener>
  );
};

const SearchSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
`;

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  margin-top: 0px;
  margin-bottom: 15px;
`;

const SearchWrap = styled.div`
  margin-left: 10px;
  margin-top: 3px;
`;

const QuickFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 7px;
`;

const QuickFilterHeader = styled.div`
  font-family: "Poppins", sans-serif;
  padding-left: 8px;
  text-transform: uppercase;
  font-size: 10px;
  color: grey;
  margin-bottom: 5px;
`;

const SearchBox = styled.input`
  border-style: none;
  background-color: white;
  z-index: 9;
  position: relative;
  border-top: 0.5px;
  padding-left: 15px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  :focus {
    outline: none;
  }
`;

const CategoryWrapper = styled.div`
  background-color: white;
  z-index: 9;
  position: relative;
  padding-top: ${(props) => (props.hideLabel ? "0px" : "10px")};
  text-transform: capitalize;
  padding-left: 7px;
`;

const CategoryLabel = styled.div`
  padding-left: 8px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 10px;
  color: grey;
`;

const LabelWrapper = styled.div`
  display: flex;
  padding-left: 15px;
  background-color: white;
  z-index: 9;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 100%;
`;

const PlaceholderLabel = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 4px;
  color: #9e9fa1;
  font-weight: 500;
  width: 275px;
  padding-left: 15px;
`;

const SelectLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #414141;
`;

const DropdownContainer = styled.div`
  width: 100%;
  min-height: 30px;
  max-height: 350px;
  background-color: "red";
  border-radius: 20px;
  border-width: 1px;
`;

const SelectedLabel = styled.div`
  cursor: pointer;
  width: 275px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding-top: 4px;
  padding-left: 15px;
  align-self: center;
  color: #9e9fa1;
  font-weight: 500;
`;

const QuickFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  z-index: 9;
  position: relative;
`;

const QuickFilterButton = styled.div`
  background-color: ${(props) => (props.isSelected ? "#476dfa" : "white")};
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  margin: 2px 3px;
`;

const QuickFilterText = styled.div`
  cursor: pointer;
  text-transform: capitalize;
  font-size: 8px;
  color: #6d6d6d;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-weight: 500;
`;

const DropdownSelect = styled.div`
  display: flex;
  height: 35px;
  cursor: pointer;
  justify-content: space-between;
  border-color: #9e9fa1;
  border-width: 1px;
  border-radius: 20px;
  border-style: solid;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  border-color: #9e9fa1;
  overflow: auto;
  border-width: 1px;
  min-width: 275px;
  max-width: 350px;
  border-radius: 20px;
  max-height: 350px;
  border-style: solid;
  background-color: white;
  z-index: 999;
  ::-webkit-scrollbar {
    width: 0.5em;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 20px;
  }
`;

export default CategorizedDropdown;
