import React from "react";

function DeleteIcon(fill: string | undefined) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5.30951C4.5 5.03337 4.72386 4.80951 5 4.80951H14.1667C14.4428 4.80951 14.6667 5.03337 14.6667 5.30951C14.6667 5.58565 14.4428 5.80951 14.1667 5.80951H5C4.72386 5.80951 4.5 5.58565 4.5 5.30951Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.77344 4.65475C7.77344 4.37861 7.9973 4.15475 8.27344 4.15475H10.8925C11.1686 4.15475 11.3925 4.37861 11.3925 4.65475C11.3925 4.9309 11.1686 5.15475 10.8925 5.15475H8.27344C7.9973 5.15475 7.77344 4.9309 7.77344 4.65475Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6543 7.42856C5.93044 7.42856 6.1543 7.65242 6.1543 7.92856V14.6309H13.0114V7.92923L13.5114 7.92856L14.0114 7.92863V15.6309H5.1543V7.92856C5.1543 7.65242 5.37815 7.42856 5.6543 7.42856ZM13.5114 7.92856L14.0114 7.92863C14.0114 7.65249 13.7876 7.42856 13.5114 7.42856C13.2353 7.42856 13.0114 7.65309 13.0114 7.92923L13.5114 7.92856Z"
        fill={fill}
      />
    </svg>
  );
}

export default DeleteIcon;
