import {
  CLEAR_ALL,
  CLEAR_EMPLOYEE_REPORTS,
  GET_EMPLOYEE_REPORTS,
} from "common/constants/actions";

const initialState = {
  employee_report: [],
  pending: false,
};

export default function EmployeeReport(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_REPORTS:
      return {
        ...state,
        employee_report: action.payload,
        pending: false,
      };

    //   case FETCH_EMPLOYEE_REPORTS:
    //     return {
    //         ...state,
    //         [action.payload.id]: action.payload,
    //         pending: false
    //     };

    case CLEAR_EMPLOYEE_REPORTS:
      return {
        ...state,
        employee_report: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        employee_report: [],
      };

    default:
      return state;
  }
}
