import Logo from "./assets/images/logo_blue_main.png";

// import OtpLogo from "assets/images/otp/Logo.png";
export const SL ="";

// // google auth api
// export const GOOGLE_CLIENT_ID=''
// export const GOOGLE_SCOPE = ""

export const Domain = {
  id: 1,
  name: "Nulogic",
  url: "app.nulogic.co",
  image: Logo,
};

export const Environment = "Production";
// both the same for now
export const SentryDSN = "https://ea470cb24553de59bf27d459c7cfb366@o4507131261353984.ingest.us.sentry.io/4507136744030208";
export const SentryDSN_STG = "https://ea470cb24553de59bf27d459c7cfb366@o4507131261353984.ingest.us.sentry.io/4507136744030208";

export const serverUrl = "https://survey.nulogic.co";
// export const returnUrl = "https://app.nulogic.co/account/login";
export const returnUrl = "https://www.nuwaveco.com/";
// export const returnUrl = "https://www.nulogic.co";

// go auth login
export const goAuthApiServer = "https://auth.nulogic.co";
// django api
export const serverApi = "https://api.nulogic.co"
// ml api
export const mlProductionApi = "https://ml.nulogic.co"
// chat api
export const chatProductionApi = "https://ai-websocket.nulogic.co"
// websocket chat
export const wsProductionApi = "wss://ai-websocket.nulogic.co"
// // production
export const currentApi = serverApi;
export const curGoApi = goAuthApiServer;
export const mlApi = mlProductionApi
export const chatApi = chatProductionApi;
export const wsApi = wsProductionApi;
// 

export const config = {
    loginapi: `${curGoApi}`,
    signupapi: `${currentApi}/api/signup`,
    appapi: `${currentApi}/api/organization`,
    mlapi: `${mlApi}`,
    website: `${currentApi}/api/web`,
    chatApi, 
    wsApi,  
  };
export default config;

