import React from "react";
import styled from "styled-components";
import TeamCard from "./TeamCard";

const TeamTable = ({ members, data, setSelectedTeam }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
      }}
    >
      {data && data?.length > 0 && (
        <Table>
          <Headers>
            <TH width={40}>Name</TH>
            <TH width={30}># of members</TH>
            <TH width={30}>Team type</TH>
          </Headers>
          {data.map((item, i) => {
            return (
              <TeamCard
                data={item}
                id={i}
                members={members}
                key={i}
                setSelectedTeam={setSelectedTeam}
              />
            );
          })}
        </Table>
      )}
    </div>
  );
};

export default TeamTable;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
