
export const PERMISSION_TEXT = {
  SURVEY_PARTICIPATION: {
    TITLE: "Participate in Survey",
    DESC: "Member will be included in surveys scheduled for their location"
  },
  LOGIN_TO_PORTAL: {
    TITLE: "Login to Portal",
    DESC: "Member can log in to the nuLogic portal"
  },
  DATA_ANALYTICS: {
    TITLE: "Access Data Analytics",
    DESC: "Member has access to data analytics"
  },
  SUBFACTOR_DATA: {
    TITLE: "Access Subfactor Data",
    DESC: "Member has access to subfactor data"
  },
  READ_FEEDBACK: {
    TITLE: "Can Read Feedback",
    DESC: "Member has the ability to read feedback"
  },
  EDIT_USERS: {
    TITLE: "Can Edit Users",
    DESC: "Member can manage users for specified groups (invite, edit, archive)"
  },
  SCHEDULE_SURVEYS: {
    TITLE: "Can Schedule Surveys",
    DESC: "Member can schedule and manage surveys for specified groups"
  },
  ADMIN_ACCOUNT: {
    TITLE: "Account Administrator",
    DESC: "Has access to all reports, results, settings, user management and team management for the entire organization"
  }
};
