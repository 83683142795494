import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Message, Modal } from "semantic-ui-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { useForm, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications";

import {
  SAVE_ORGANIZATION,
  SG_VALIDATE_ORGANIZATION,
} from "common/constants/actions";

const Create = ({ UpdateList }) => {
  const [open, setOpen] = useState(false);
  const { addToast } = useToasts();
  const apiDispatch = useDispatch();
  // const { register, handleSubmit, errors, watch } = useForm();

  const [errorMessage, setErrorMessage] = useState(false);
  const [orgName, setOrgName] = useState("");

  const { get_error, get_auth, get_organizations } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
    }),
    shallowEqual,
  );

  // SG_SEARCH_EMPLOYEES
  // console.log(get_ambassador.validateEmail)
  // console.log(errorMessage)
  // const watchName = watch("name", false);

  useEffect(() => {
    setErrorMessage(false);
    // console.log(watchEmail)
    if (orgName.length > 3) {
      apiDispatch({
        type: SG_VALIDATE_ORGANIZATION,
        payload: `name=${orgName}&ambassador=${Number(get_auth?.ambassador_id)}`,
      });
    }

    // setEmailId(watchEmail)
  }, [orgName, apiDispatch, get_auth]);

  // console.log(Number(get_auth?.ambassador_id))

  useEffect(() => {
    if (
      get_organizations?.validation?.code !== 200 &&
      get_organizations.validation.length > 0
    ) {
      setErrorMessage(`${get_organizations?.validation?.message}`);
      // setInviteText("Already Invited")
    } else if (get_organizations?.validation?.code === 200) {
      // setInviteText("Invite")
      setErrorMessage(false);
      // setInviteDisabled(false)
    }
  }, [get_organizations?.validation]);

  const onSubmit = () => {
    if (orgName.length < 3) {
      setErrorMessage(
        "Please have alteast 3 characters in the organization name",
      );
      return;
    }
    apiDispatch({
      type: SAVE_ORGANIZATION,
      payload: {
        name: orgName,
        ambassador: Number(get_auth?.ambassador_id),
      },
    });
    setOpen(false);

    addToast(`Organization successfully added`, {
      appearance: "success",
      autoDismiss: true,
    });

    UpdateList();
    setOrgName("");
    setErrorMessage("");
    // apiDispatch({
    //   type: LOAD_ORGANIZATIONS
    // });
  };

  // useEffect(() => {
  //   console.log(errorMessage)
  // }, [errorMessage])

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size={"small"}
      trigger={<StyledButton>Create Organization</StyledButton>}
    >
      <Form className="ui form segment" onSubmit={onSubmit}>
        <Modal.Header>Create a new organization</Modal.Header>
        {errorMessage && (
          <Message
            negative
            onDismiss={() => setErrorMessage(false)}
            header="Unable to Create"
            content={errorMessage}
          />
        )}
        <div style={{ padding: 20 }}>
          <Description>
            As an nuLogic ambassador you can create an unlimited number of
            organizations under your umbrella. Innerlogic staff must however
            activate the organization. Please contact your customer service
            representative to ensure that the proper information is setup for
            this organizaiton in order for the full functionality to be in
            order. Please create this organization first and then contact your
            service representative with the proper information.
          </Description>
          <div className="one field">
            <Label>Organization name</Label>
            <Input
              placeholder="Organization Name"
              name="name"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              type="text"
            />
          </div>

          {errorMessage && (
            <Message
              color={
                Number(get_organizations?.validation?.code === 200)
                  ? "green"
                  : "red"
              }
            >
              {errorMessage}
            </Message>
          )}
        </div>
        <Modal.Actions>
          <Button color="grey" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Create"
            labelPosition="right"
            icon="checkmark"
            type="submit"
            positive
          />
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default Create;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Description = styled.div`
  margin-bottom: 20px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  line-height: 1.3;
`;

const Label = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
`;
