import {
  ADD_DEPARTMENT,
  CLEAR_ALL,
  CLEAR_DEPARTMENT,
  DELETE_DEPARTMENT,
  EDIT_DEPARTMENT,
  FETCH_DEPARTMENT,
  GET_DEPARTMENTS,
} from "common/constants/actions";

const initialState = {
  departments: [],
  pending: false,
  created: [],
};

export default function departmentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        pending: false,
      };
    case FETCH_DEPARTMENT:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_DEPARTMENT:
      if (state.departments.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          departments: state.departments.filter(
            (rw) => rw.id !== action.payload.id,
          ),
        };
      }
      return {
        ...state,
        departments: [...state.departments, action.payload],
      };

    case DELETE_DEPARTMENT:
      return {
        ...state,
        departments: state.departments.filter(
          (row) => row.id !== action.payload.id,
        ),
      };
    case ADD_DEPARTMENT:
      return {
        ...state,
        created: action.payload,
        departments: [...state.departments, action.payload],
      };
    case CLEAR_DEPARTMENT:
      return {
        ...state,
        departments: [],
      };
    case CLEAR_ALL:
      return {
        ...state,
        departments: [],
      };
    default:
      return state;
  }
}
