import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Loader, Select } from "semantic-ui-react";
import { useDispatch } from "react-redux";

import noData from "assets/images/empty/empty.png";
// Hooks
import useGetSurveyData from "common/hooks/useGetSurveyData";
import useGetSurveyUtils from "common/hooks/useGetSurveyUtils";
import Model from "assets/images/nav/fa6-solid_layer-group.svg";
import Bar from "common/components/analytics/Bar";
import { useLocation } from "react-router-dom";

import ChartLoading from "common/components/analytics/ChartLoading";
import CategorizedDropdown from "common/components/analytics/CategorizedDropdown";
import { erf } from "mathjs";

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const create_option_list = (categories, questions, type, showPersonality) => {
  let options = [];
  if (type === 1) {
    options.push({
      text: "Overview",
      value: { text: "Overview", type: "overview", id: options.length },
    });
  }
  categories.categories.forEach((item, i) => {
    if (type === 1 || type === 2) {
      options.push({
        text: item.name,
        value: { text: item, type: "categories", id: i },
      });
    }
  });

  if (type === 2) {
    if (showPersonality) {
      options.push({
        text: "Personality",
        value: { type: "personality", id: null, text: "Personality" },
      });
    }

    options.push({
      text: "Records",
      value: { type: "records", id: null, text: "Records" },
    });
  }

  return options;
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id,
  );
};

const filterInitialCategories = (horizontal, data) => {
  const selectedCategory = horizontal?.type?.id;
  const categoryResponse = horizontal?.value;
  const filteredResponses = data.filter((dataSet) => {
    return dataSet.categories.find(
      (catResponses) =>
        catResponses.id === selectedCategory &&
        catResponses.response === categoryResponse,
    );
  });

  return filteredResponses;
};

const retrieveCategoryAverages = (
  filteredResponses,
  unitOfAnalysis,
  records,
  data,
) => {
  let values = [];
  if (unitOfAnalysis.type === "categories") {
    unitOfAnalysis.text.options.forEach((op) => {
      const matchingResponses = filteredResponses.filter((fr) => {
        return fr.categories.find(
          (r) => r.id === unitOfAnalysis.text.id && r.response === op.id,
        );
      });

      const totalResponses = filteredResponses.filter((fr) => {
        return fr.categories.find(
          (r) => r.id === unitOfAnalysis.text.id && r.response != null,
        );
      });

      const normResponses = data.filter((fr) => {
        return fr.categories.find(
          (r) => r.id === unitOfAnalysis.text.id && r.response === op.id,
        );
      });

      values.push({
        name: op.name,
        id: op.id,
        norm:
          (normResponses.length > 0 &&
            Math.round((normResponses.length / totalResponses.length) * 100)) ||
          0,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(
              (matchingResponses.length / totalResponses.length) * 100,
            )) ||
          0,
      });
    });
  } else if (unitOfAnalysis.type === "personality") {
    let personality_factors = [
      "Extraversion",
      "Conscientiousness",
      "Agreeableness",
      "Neuroticism",
      "Openess",
    ];

    personality_factors.forEach((p, i) => {
      const dataArr = filteredResponses
        .filter((rd) => rd?.survey_personality)
        .map((item) => {
          return calculate_personality(item.survey_personality, i);
        });
      const normArr = data
        .filter((rd) => rd?.survey_personality)
        .map((item) => {
          return calculate_personality(item.survey_personality, i);
        });

      let filteredSum = 0;
      dataArr.forEach((v) => {
        filteredSum += v;
      });

      let normSum = 0;
      normArr.forEach((v) => {
        normSum += v;
      });
      if (dataArr.length > 0 || normArr.length > 0) {
        values.push({
          name: p,
          id: i,
          norm:
            (normArr.length > 0 && Math.round(normSum / normArr.length)) || 0,
          percent:
            (dataArr.length > 0 && Math.round(filteredSum / dataArr.length)) ||
            0,
        });
      }
    });
  } else if (unitOfAnalysis.type === "records") {
    const numericalRegex = /[$|€|£]?(\d*\s?[\,?\d{3,}]*\.?\d+)/;
    let hasRecords = false;
    let isSalary = false;
    filteredResponses.forEach((item) => {
      if (item.employee_records.length > 0) {
        hasRecords = true;
      }
    });
    if (records.length > 0 && hasRecords) {
      records.forEach((r) => {
        const dataArr = filteredResponses.map((item) => {
          let recordVal;
          item.employee_records.forEach((recordObj) => {
            if (recordObj?.[r.name]) {
              recordVal = recordObj?.[r.name];
            }
          });
          if (recordVal) {
            let cleanedVal = recordVal.toString().match(numericalRegex);
            cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
            cleanedVal = cleanedVal.replace(/,/g, "");
            return Number(cleanedVal);
          }
        });

        const normArr = data.map((item) => {
          let recordVal;
          item.employee_records.forEach((recordObj) => {
            if (recordObj?.[r.name]) {
              recordVal = recordObj?.[r.name];
            }
          });
          if (recordVal) {
            let cleanedVal = recordVal.toString().match(numericalRegex);
            if (cleanedVal.input.match(/[$|€|£]/)) {
              isSalary = true;
            }
            if (cleanedVal.input.match(/€/)) {
              cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
              cleanedVal = Number(cleanedVal) * 1.35;
            } else {
              cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
              cleanedVal = cleanedVal.replace(/,/g, "");
            }
            return Number(cleanedVal);
          }
        });

        // if (isSalary) {
        let filteredSum = 0;
        let normSum = 0;
        dataArr.sort((a, b) => a > b && -1);
        normArr.sort((a, b) => a > b && -1);
        const companyHigh = dataArr[0];
        const normHigh = normArr[0];
        dataArr.forEach((v) => {
          if (v) {
            filteredSum += v;
          }
        });
        normArr.forEach((v) => {
          if (v) {
            normSum += v;
          }
        });

        values.push({
          name: r.name,
          id: r.id,
          companyHigh,
          norm:
            (normArr.length > 0 && Math.round(normSum / normArr.length)) || 0,
          percent:
            (dataArr.length > 0 && Math.round(filteredSum / dataArr.length)) ||
            0,
        });
        // }
        // else {
        //   dataArr.sort((a, b) => a > b && -1);
        //   normArr.sort((a, b) => a > b && -1);
        //   const companyHigh = dataArr[0];
        //   const normHigh = normArr[0];
        //   const relativePerc = dataArr.map((d) => {
        //     return (d / companyHigh) * 10;
        //   });

        //   const normRelativePerc = normArr.map((d) => {
        //     return (d / normHigh) * 10;
        //   });

        //   let filteredSum = 0;
        //   relativePerc.forEach((v) => {
        //     if (v) {
        //       filteredSum += v;
        //     }
        //   });
        //   let normSum = 0;
        //   normRelativePerc.forEach((v) => {
        //     if (v) {
        //       normSum += v;
        //     }
        //   });

        //   values.push({
        //     name: r.name,
        //     id: r.id,
        //     norm:
        //       (normArr.length > 0 &&
        //         Math.round((normSum / normArr.length) * 10)) ||
        //       0,
        //     percent:
        //       (dataArr.length > 0 &&
        //         Math.round((filteredSum / dataArr.length) * 10)) ||
        //       0,
        //   });
        // }
      });
    }
  }
  values.sort((a, b) => a.percent > b.percent && -1);
  return values;
};

const TeamSummary = ({ data, records, showPersonality }) => {
  const dispatch = useDispatch();
  const [horizontal, setHorizontal] = useState();
  const [vertical, setVertical] = useState();
  const [anchor_list, setAnchorList] = useState();
  const [anchor_list2, setAnchorList2] = useState();
  const [variableList, setVariableList] = useState();
  const [hideVariableList, setHideVariableList] = useState(false);
  const [analysisVariable, setAnalysisVariable] = useState();
  const [calculated, setCalculated] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const isDEI = location.pathname.includes("edi");

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({
    surveyType: isDEI ? 7 : 6,
    auditTypeId: isDEI ? 4 : 3,
  });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    surveyType: isDEI ? 7 : 6,
    ambassadorId,
    get_employee,
  });

  useEffect(() => {
    if (vertical?.text?.options) {
      const options = vertical?.text?.options.map((op) => {
        return {
          text: op.name,
          value: { text: op.name, value: op.id, type: vertical?.text },
        };
      });
      setVariableList(options);
      setHorizontal(options[0].value);
      setHideVariableList(false);
    }
    if (vertical?.text === "Overview") {
      setHideVariableList(true);
    }
  }, [vertical]);

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1,
        showPersonality,
      );
      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2,
        showPersonality,
      );
      setAnchorList2(anchor_list2);
    }
  }, [get_survey_structure, questionStructure]);

  useEffect(() => {
    if (
      analysisVariable &&
      (horizontal || vertical?.text === "Overview") &&
      vertical &&
      records &&
      data &&
      data.length > 0
    ) {
      setLoading(true);
      const filteredResponses =
        vertical?.text === "Overview"
          ? data
          : filterInitialCategories(horizontal, data);
      const calculatedData = retrieveCategoryAverages(
        filteredResponses,
        analysisVariable,
        records,
        data,
      );

      if (calculatedData.length > 0) {
        setCalculated(calculatedData);
      }

      setTimeout(() => {
        setLoading(false);
      }, 400);
    }
  }, [analysisVariable, horizontal, vertical, records, data]);

  if (!get_survey_structure[0] || !data) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Summary Analysis</Title>
      <OptionArea>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label>Category</Label>
          <div
            style={{
              width: 275,
            }}
          >
            <CategorizedDropdown
              placeholder="Select a Category"
              filterOptions={anchor_list}
              hideLabel
              hideFilter
              value={vertical}
              id="anchors"
              onSelectLabel={(t) => setVertical(t.value)}
            />
          </div>
        </div>
        {!hideVariableList && (
          <div
            style={{
              marginLeft: 30,
              display: "flex",
              alignItems: "center",
              width: 275,
            }}
          >
            <Label>Variable</Label>

            <div
              style={{
                width: 275,
              }}
            >
              <CategorizedDropdown
                placeholder="Select a Variable"
                filterOptions={variableList}
                hideLabel
                hideFilter
                value={horizontal}
                id="anchors"
                onSelectLabel={(t) => setHorizontal(t.value)}
              />
            </div>
          </div>
        )}
      </OptionArea>

      <Cards>
        <Card>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Factor Breakdown
          </TableTitle>
          <Sub>Summary data for the category variable that is selected.</Sub>
          <Content>
            <Top>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Label>Unit of Analysis</Label>
                <div style={{ width: 275 }}>
                  <CategorizedDropdown
                    placeholder="Select Survey Type"
                    filterOptions={anchor_list2}
                    hideLabel
                    hideFilter
                    value={analysisVariable}
                    id="anchors"
                    onSelectLabel={(t) => setAnalysisVariable(t.value)}
                  />
                </div>
              </div>
              {((!horizontal && vertical?.text !== "Overview") ||
                !vertical ||
                !calculated) &&
              !loading ? (
                <NoDataContainer>
                  <ImageContainer src={noData} />
                  <Text>No data for this group</Text>
                </NoDataContainer>
              ) : loading && horizontal && vertical ? (
                <ChartLoading />
              ) : (
                <Legend>
                  {/* <LegendItem>
                      <Bar1 /> <LText>Team norms</LText>
                    </LegendItem> */}
                  <LegendItem>
                    <Bar1 /> <LText>Team norms</LText>
                  </LegendItem>
                </Legend>
              )}
            </Top>
            <BarArea>
              {calculated && !loading && (
                <>
                  {calculated.map((d) => {
                    return (
                      <Bar
                        companyHigh={d?.companyHigh}
                        score={[d.percent, d.norm]}
                        title={d.name}
                      />
                    );
                  })}
                </>
              )}
            </BarArea>
          </Content>
        </Card>

        {/*    <Card2 width={"380px"}>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Culture Score
          </TableTitle>
          {calculated && (
            <>
              <Sub>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus
                vitae faucibus nisl venenatis, ut etiam proin neque.
              </Sub>
              <Chart
                options={data2.options}
                series={data2.series}
                type="radialBar"
                height={"100%"}
                style={{ marginTop: 0 }}
              />
            </>
          )}
        </Card2>*/}
      </Cards>
    </Container>
  );
};

export default TeamSummary;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const ImageContainer = styled.img`
  height: 140px;
  margin-top: 50px;
`;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Poppins";
  margin-top: 10px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Card = styled.div`
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 50px 20px;
  margin-right: 20px;
`;

const Card2 = styled.div`
  width: ${(props) => props.width};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 50px 20px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-left: 33px;
`;

const Image = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const Content = styled.div`
  padding-left: 25px;
`;

const BarArea = styled.div`
  margin-top: 40px;
`;

const Bar1 = styled.div`
  width: 20px;
  height: 2px;
  border-bottom: 2px dashed #414141;
`;

const Bar2 = styled.div`
  width: 20px;
  height: 2px;
  border-bottom: 2px solid #414141;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 70px;
`;

const LText = styled.div`
  margin-left: 5px;
  margin-right: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const data2 = {
  series: [80],
  options: {
    chart: {
      height: 400,
      type: "radialBar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "35%",
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "17px",
            fontFamily: "Poppins, sans-serif",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#414141",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ["#476DFA"],
    },
    stroke: {
      lineCap: "round",
    },
  },
};
