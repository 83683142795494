import React, { useContext, useEffect, useState } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SG_VALIDATE_EMAIL } from "common/constants/actions";

import styled from "styled-components";
import { DomainContext } from "common/layouts/LayoutContext";

const Register = () => {
  const dispatch = useDispatch();
  const Domain = useContext(DomainContext);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const [validationToken, setValidationToken] = useState(false);

  const { validate_email, get_error } = useSelector(
    (state) => ({
      validate_email: state.validateEmail.validateEmail,
      get_error: state.errors.msg,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (token) {
      dispatch({
        type: SG_VALIDATE_EMAIL,
        payload: { token: token },
      });
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (!validate_email?.error) {
      setValidationToken(true);
    }
  }, [validate_email]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Banner>
        <IMG src={Domain.image} />
      </Banner>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title>Email validation</Title>

        {validationToken ? (
          <P>{validate_email.message}</P>
        ) : (
          <Error>{validate_email.message}</Error>
        )}
        {!token && <Error>No Token found</Error>}

        <Error>{get_error?.data?.token && "Invalid Token"}</Error>

        {validationToken && (
          <>
            <Link to="/app/dashboard">
              <Button type="submit">Back to Home page</Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 40px;
  font-family: "Red Hat Display", sans-serif;
`;
const Button = styled.button`
  width: 200px;
  height: 55px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const IMG = styled.img`
  width: 150px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 50px;
  margin-top: 100px;
`;

const P = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
`;

const Error = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 24px;
  color: red;
  margin-bottom: 20px;
  line-height: 1.4;
`;
