const colors2 = [
  "#0387EF",
  "#FFC600",
  "#02AE5B",
  "#FF0000",
  "#fcba03",
  "#2b704d",
  "#472aa3",
  "#a3852a",
  "#a32a89",
  "#2a8ba3",
  "#a1a32a",
  "#2aa330",
];

function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const check_for_subfactors = (nav) => {
  let check = false;

  //find the primary nav structure
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority == "primary")];
  Object.keys(prime)
    .filter((f) => f != "selected")
    .map((item, i) => {
      let items = prime[item];

      if (items.subfactors) {
        Object.keys(items.subfactors).map((x) => {
          if (items.subfactors[x].selected) {
            check = true;
          }
        });
      }
    });

  return check;
};

const getTitle = (idx, nav, structure, option, anchor) => {
  if (anchor === -1) {
    return { name: idx };
  }
  if (anchor) {
    let option_name = structure.categories
      .find((f) => f.id == anchor)
      .options.find((f) => f.id == idx);
    return option_name;
  }
  if (!check_for_subfactors(nav)) {
    return structure.categories.filter((f) => f.priority == "primary")[0]
      .options[idx];
  }
  let structures = structure.categories.filter(
    (f) => f.priority == "primary"
  )[0];

  return structure.categories
    .find((f) => f.priority == "primary")
    .options.find((f) => f.id == option)
    .subfactors.find((f) => f.id == idx);
};

const getColor = (idx, structure, anchor) => {
  if (anchor === -1) {
    if (idx === "Non-BIPOC") {
      return "#D96176";
    }
    return "#615BF0";
  }
  return structure.categories
    .find((f) => f.id == anchor)
    .options.find((f) => f.id == idx)?.color;
};

const get_ranking = (r) => {
 // This is where the calculations are performed for the averages on the main overview page
 // All responses are added to a single array and then averaged
// This means that the averages can be slightly skewed by culture leaders for OTP
// Since the length of the survey is longer in each factor for them.

  let summary = [];
  r.map((item) => {
    item.map((cat) => {
      let exists = summary.findIndex(
        (f) => f.id == cat.id && f.factor == cat.factor
      );
      if (exists >= 0) {
        let data = summary[exists];
        data.response.push(...cat.response);
        summary[exists] = data;
      } else {
        let data = {
          id: cat.id,
          factor: cat.factor,
          response: [...cat.response],
        };
        summary.push(data);
      }
    });
  });


  return summary.map((item) => {
    return { ...item, response: average(item.response) };
  });
};

const get_ranked_diff = (current, last) => {
  return current.map((item) => {
    item["diff"] =
      item.response -
      last.find((f) => f.id == item.id && f.factor == item.factor)?.response;
    return item;
  });
};

const get_last_n = (data) => {
  let total = 0;
  Object.keys(data).map((item) => {
    total += data[item].length;
  });

  return total;
};

const structure_data = (
  data,
  nav,
  ranked,
  structure,
  anchor,
  last,
  last_ranked,
  rule
) => {
  let response = [];

  Object.keys(data).map((k, i) => {
    let title = getTitle(k, nav, structure, null, anchor);

    let summary = overall(data[k]);

    let summary_last = overall(last[k]);
    let ranking = get_ranking(ranked[k]);
    let last_ranking = get_ranking(last_ranked[k]);


    let ranked_diff =
      last_ranking.length === 0
        ? ranking
        : get_ranked_diff(ranking, last_ranking);

    let summary_diff = [
      summary[0] - summary_last[0],
      summary[1] - summary_last[1],
    ];

    let breakdown = [];
    Object.keys(data[k]).map((sub) => {
      let subTitle = structure.categories[1].options[sub];
      let average = average_cat(data[k], sub);
      breakdown.push({ title: subTitle, average });
    });
    let raw = generate_raw(data[k], structure, rule,anchor);
    let n = get_total_data(data[k])


    let stdev = [
      getStandardDeviation(raw.map((i) => i.x)),
      getStandardDeviation(raw.map((i) => i.y)),
    ];

    let color = getColor(k, structure, anchor);
    let _id = anchor;
    let navigation = { option: k, id: _id, subfactor: null };
    let last_n = get_last_n(last[k]);

    response.push({
      title,
      summary,
      breakdown,
      raw,
      stdev,
      color,
      ranking: ranked_diff,
      navigation,
      summary_last,
      last_ranking,
      summary_diff,
      last_n,
      n
    });
  });

  return response;
};

const structure_sub_data = (
  data,
  nav,
  ranked,
  structure,
  last,
  last_ranked,
  rule
) => {
  let response = [];

  Object.keys(data).map((k, i) => {

    let title = getTitle(data[k].sub, nav, structure, data[k].primary);

    let summary = overall(data[k].data);


    let summary_last = overall(last[k].data);
    let ranking = get_ranking(ranked[k]);

    let last_ranking = get_ranking(last_ranked[k]);
    let ranked_diff = get_ranked_diff(ranking, last_ranking);
    let summary_diff = [
      summary[0] - summary_last[0],
      summary[1] - summary_last[1],
    ];

    let breakdown = [];
    Object.keys(data[k].data).map((sub) => {
      let subTitle = structure.categories[1].options[sub];
      let average = average_cat(data[k].data, sub);
      breakdown.push({ title: subTitle, average });
    });

    let raw = generate_raw(data[k].data, structure,rule);

    let stdev = [
      getStandardDeviation(raw.map((i) => i.x)),
      getStandardDeviation(raw.map((i) => i.y)),
    ];

    let _id = structure.categories.find((f) => f.priority == "primary").id;
    let navigation = {
      option: data[k].primary,
      id: _id,
      subfactor: data[k].sub,
    };

    let last_n = get_last_n(last[k].data);
    let n = get_total_data(data[k].data)

    response.push({
      title,
      summary,
      breakdown,
      raw,
      stdev,
      color: colors2[randomIntFromInterval(0, colors2.length - 1)],
      navigation,
      ranking: ranked_diff,
      summary_last,
      last_ranking,
      summary_diff,
      last_n,
      n
    });
  });
  return response;
};

const get_total_data = (data) =>{
  let n = 0
  Object.keys(data).map((item)=>{
    n+=data[item].length
  })
  return n
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    "" +
    parseInt(result[1], 16) +
    "," +
    parseInt(result[2], 16) +
    "," +
    parseInt(result[3], 16)
  );
}

const getRoleColor = (idx, structure, anchor) => {
  let color = structure?.categories
  ?.find((f) => f.priority === "secondary")
  ?.options?.find((f) => f.id === idx)?.color

  if (!color) {
    color = structure?.categories?.find((c) => c?.options?.find((o) => o?.id === idx))?.options?.find(option => option?.id === idx)?.color
  }

  if (!color) {
    // Default to black if no color is found
    color = "#000000"
  }

  return hexToRgb(color);
};

const generate_raw = (data, structure,rule, anchor) => {
  let raw = [];
  Object.keys(data).map((item, i) => {
    if(data[item].length>rule){
      data[item].map((coord) => {
        let color = getRoleColor(item, structure, anchor);
        raw.push({
          x: coord[0],
          y: coord[1],
          color: "rgba(" + color + ",0.3)",
        });
      });
    }

  });

  // if (raw.length > 150) {
  //   raw = raw.filter((f) => randomIntFromInterval(1, 20) < 2);
  // }

  return raw;
};

export const overall = (data) => {
  let average = [0, 0];
  let n = 0;
  Object.keys(data).map((k, i) => {
    data[k].map((item) => {
      average[0] += item[0]
      average[1] += item[1]
      n += 1;
    });
  });

  return [average[0] / n, average[1] / n];
};

export const average_cat = (data, cat) => {
  let average = [0, 0];
  let n = 0;

  data[cat].map((item) => {
    average[0] += item[0];
    average[1] += item[1];
    n += 1;
  });

  return [average[0] / n, average[1] / n];
};

const average = (array) => {
  let total = 0;
  let n = 0;

  array.map((item) => {
    if(!isNaN(item)){
      total += item;
      n += 1;
    }

  });

  return total / n;
};

const get_average = (array) => {
  let total = 0;
  let n = 0;

  array.map((item) => {
    if(!isNaN(item)){
      total += item;
      n += 1;
    }

  });

  return total / n;
};

const get_buckets = (data) => {
  let response = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.map((i) => {
    response[Math.round(i)] += 1;
  });
  return response;
};

const sum_distribution = (arr) => {
  let _distribution = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arr.map((item) => {
    item.map((s, i) => (_distribution[i] += s));
  });
  return _distribution;
};

const check_all_selected = (structure, item, filters) => {
  let filter_length = filters[item.id]?.length;
  let structure_length = structure.categories.find((f) => f.id == item.id)
    ?.options.length;
  return filter_length == structure_length;
};

export const calculate_table_data = (
  data,
  nav,
  structure,
  anchor,
  rule,
  questions
) => {
  let primary = [];
  let subfactor = [];
  let filters = [];
  //find the primary nav structure
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority === "primary")];
  remove_NA_data(data);


  Object.keys(prime)
    .filter((f) => f !== "selected" || f !== "priority")
    .map((item, i) => {
      let items = prime[item];

      if (items.selected) {
        primary.push(item);
      }
      let subs = [false];
      if (items.subfactors) {
        if (Object.keys(items.subfactors).length > 0) {
          subs = Object.keys(items.subfactors).map(
            (x) => items.subfactors[x].selected
          );
        }
      }
      if (subs.includes(true)) {
        Object.keys(items.subfactors).map((s) => {
          let sub = items.subfactors[s];
          if (sub.selected) {
            subfactor.push({
              primary: item,
              sub: s,
            });
          }
        });
      }
    });

  Object.keys(nav)
    .filter((f) => f !== 0)
    .map((i) => {
      filters[i] = Object.keys(nav[i])
        .filter((f) => f !== "selected")
        .filter((f) => nav[i][f].selected)
        .map((x) => x);
    });

  let filter_data = data[0]?.responses
    ?.filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;

      x.categories
        .filter((d) => d.priority !== "primary")
        .map((item, i) => {
          try {
            let all_selected = check_all_selected(structure, item, filters);
            if (
              !all_selected
            ) {
              checked = checked
                ? filters[item.id].includes(item.response)
                : false;
            }
          } catch (err) {
            checked = false;
          }
        });

      return checked;
    });


  if (subfactor.length > 0) {
    filter_data = filter_data?.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(
          f.categories.find((cat) => cat.priority === "primary").subfactor
        );
    });
  } else {
    filter_data = filter_data?.filter((f) => {
      return primary.includes(
        f.categories.find((cat) => cat.priority === "primary").response
      );
    });
  }



  let anchor_priority = structure.categories.find(
    (f) => f.id == anchor
  ).priority;
  //Begin to summary the question data in to the proper tables
  let response = {};
  let respondent_count ={}
  filter_data?.map((item, i) => {
    let secondary;
    let primary;
    let subfactor_parent = null;
    if (subfactor.length > 0 && anchor_priority === "primary") {
      secondary = item.categories.find((f) => f.id == anchor).subfactor;
      subfactor_parent = item.categories.find((f) => f.id == anchor).response;
    } else {
      secondary = item.categories.find((f) => f.id == anchor)?.response;
    }

    if (!secondary) {
      return;
    }
    if(!(secondary in respondent_count)){
      respondent_count[secondary] = 1
    }else{
      respondent_count[secondary]+=1
    }


    item.questions.map((q, r) => {
      if (!(q.id in response)) {
        response[q.id] = {};
      }
      if (!(q.factor in response[q.id])) {
        response[q.id][q.factor] = {};
      }
      if (!(q.q in response[q.id][q.factor])) {
        response[q.id][q.factor][q.q] = {};
      }
      if (!(secondary in response[q.id][q.factor][q.q])) {
        response[q.id][q.factor][q.q][secondary] = {
          total: 0,
          n: 0,
          responses: [],
          adjusted_total: 0,
          adjusted_responses: [],
          subfactor_parent,
          n_respondent:0
        };
      }
      let resp = check_reverse_score(q, questions);

      response[q.id][q.factor][q.q][secondary].adjusted_total += resp;
      response[q.id][q.factor][q.q][secondary].adjusted_responses.push(resp);
      response[q.id][q.factor][q.q][secondary].total += q.response;
      response[q.id][q.factor][q.q][secondary].n += 1;
      response[q.id][q.factor][q.q][secondary].responses.push(q.response);
    });


  });


  let processed_responses = [];
  Object.keys(response).map((item, i) => {
    let section = response[item];
    Object.keys(section).map((s) => {
      let question = section[s];
      Object.keys(question).map((q) => {
        let role = question[q];
        Object.keys(role).map((r) => {
          let role_data = role[r];
          let average = get_average(role_data.responses)
          let stdev =
            Math.floor(getStandardDeviation(role_data.responses) * 10) / 10;
          let label;
          if (subfactor.length > 0 && anchor_priority == "primary") {
            label = label = structure.categories
              .find((f) => f.id == anchor)
              .options.find((f) => f.id == role_data.subfactor_parent)
              .subfactors.find((f) => f.id == r);
          } else {
            label = structure.categories
              .find((f) => f.id == anchor)
              .options.find((f) => f.id == r);
          }
          let distribution = get_buckets(role_data.responses);
          let factors = { dimension: i, factor: s, x: 0, y: 0 };
          // let norm = calculate_norms(data,r,average,factors,q)
          processed_responses.push({
            average,
            label,
            distribution,
            role: r,
            question: q,
            section: s,
            dimension: i,
            stdev,
            n: role_data.n,
          });
        });
      });
    });
  });

  //summarize data in person and performance
  let summary = {};
  Object.keys(response).map((item, i) => {
    summary[item] = {};
    Object.keys(response[item]).map((f) => {
      summary[item][f] = {};

      Object.keys(response[item][f]).map((q) => {
        Object.keys(response[item][f][q]).map((s) => {
          if (!(s in summary[item][f])) {
            summary[item][f][s] = { total: 0, n: 0, responses: [],raw:[] };
          }
          summary[item][f][s].total += response[item][f][q][s].adjusted_total;
          summary[item][f][s].n += response[item][f][q][s].n;
          summary[item][f][s].raw.push(...response[item][f][q][s].adjusted_responses)
          summary[item][f][s].responses.push(
            get_buckets(response[item][f][q][s].adjusted_responses)
          );
        });
      });
    });
  });




  let processed_summary = [];
  Object.keys(summary).map((item, i) => {
    let dimension = summary[item];
    Object.keys(dimension).map((f) => {
      let factor = dimension[f];
      Object.keys(factor).map((s) => {
        let secondary = factor[s];
        let average = get_average(secondary.raw)

        let n =
          secondary.n /
          questions.dimensions[item]?.factors[f]?.questions.length;

        let distribution = sum_distribution(secondary.responses);
        processed_summary.push({
          dimension: item,
          factor: f,
          secondary: s,
          average,
          distribution,
          n: respondent_count[s],
        });
      });
    });
  });

  //summarize data in overall culture
  let overall = {};
  Object.keys(response).map((item, i) => {
    Object.keys(response[item]).map((f) => {
      Object.keys(response[item][f]).map((q) => {
        Object.keys(response[item][f][q]).map((s) => {
          if (!(s in overall)) {
            overall[s] = { total: 0, n: 0, responses: [], n1: 0 };
          }
          overall[s].total += response[item][f][q][s].adjusted_total;
          overall[s].n += response[item][f][q][s].n;
          overall[s].n1 = response[item][f][q][s].n;
          overall[s].n2 = respondent_count[s]
          //need to reverse the appropriate responses
          overall[s].responses.push(
            average(response[item][f][q][s].adjusted_responses)
          );
        });
      });
    });
  });




  //filter based on the rule of 3

  processed_summary = processed_summary.filter((f) => f.n > rule);
  processed_responses = processed_responses.filter((f) => f.n > rule);
  const asArray = Object.entries(overall);
  const filtered = asArray.filter(
    ([key, value]) => value.n2 > rule
  );

  const filtered_overall = Object.fromEntries(filtered);

  return {
    responses: processed_responses,
    summary: processed_summary,
    overall: filtered_overall,
  };
};

export const get_labels = (nav, structure, anchor) => {
  let _nav = nav[anchor];

  if (_nav.priority == "primary") {
    if (
      Object.keys(_nav)
        .filter((f) => f != "priority" && f != "selected")
        .map((i) => _nav[i].sub)
        .includes(true)
    ) {
      let labels = [];
      Object.keys(_nav)
        .filter((f) => f != "priority" && f != "selected")
        .map((i) => {
          if (_nav[i].sub) {
            Object.keys(_nav[i].subfactors)
              .filter((f) => _nav[i].subfactors[f].selected)
              .map((k) => {
                labels.push(
                  structure.categories
                    .find((f) => f.id == anchor)
                    .options.find((f) => f.id == i)
                    .subfactors.find((f) => f.id == k)
                );
              });
          }
        });
      return labels;
    }
  }

  return structure.categories.find((f) => f.id == anchor).options;
};

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;

  return response;
};

const get_BIPOC = (filtered_data, structure, rule, questions) => {
  let _data = {};
  let ranked = {};

  let e = structure.categories.find(
    (f) => f.name === "Ethnicity" && f.priority != "primary"
  );
  let e_id = e.id;
  let selection = e.options.find((d) => d.name == "White").id;

  let _filtered_data = filtered_data
    .filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === e_id).response === selection
    )
    .filter((f) => f.questions.length > 0);

  if (_filtered_data.length > rule) {
    _data["Non-BIPOC"] = {};
    ranked["Non-BIPOC"] = [];
    _filtered_data.map((r) => {
      let summary = condensed(r, questions);
      try {
        let secondary = r.categories.find(
          (f) => f.priority === "secondary"
        ).response;
        if (!(secondary in _data["Non-BIPOC"])) {
          _data["Non-BIPOC"][secondary] = [];
        }
        let x = [0, 0];
        let y = [0, 0];
        if (r.questions.length > 0) {
        }
        r.questions.map((d) => {
          let resp = check_reverse_score(d, questions);

          if (d.id === 0) {
            x[0] += resp;
            x[1] += 1;
          } else {
            y[0] += resp;
            y[1] += 1;
          }
        });
        ranked["Non-BIPOC"].push(summary);

        _data["Non-BIPOC"][secondary].push([x[0] / x[1], y[0] / y[1]]);
      } catch (err) {
        console.log(err);
      }
    });
  }

  _filtered_data = filtered_data
    .filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === e_id).response !== selection
    )
    .filter((f) => f.questions.length > 0);

  if (_filtered_data.length > rule) {
    _data["BIPOC"] = {};
    ranked["BIPOC"] = [];
    _filtered_data.map((r) => {
      let summary = condensed(r, questions);
      try {
        let secondary = r.categories.find(
          (f) => f.priority === "secondary"
        ).response;
        if (!(secondary in _data["BIPOC"])) {
          _data["BIPOC"][secondary] = [];
        }
        let x = [0, 0];
        let y = [0, 0];
        if (r.questions.length > 0) {
        }
        r.questions.map((d) => {
          let resp = check_reverse_score(d, questions);

          if (d.id === 0) {
            x[0] += resp;
            x[1] += 1;
          } else {
            y[0] += resp;
            y[1] += 1;
          }
        });
        ranked["BIPOC"].push(summary);

        _data["BIPOC"][secondary].push([x[0] / x[1], y[0] / y[1]]);
      } catch (err) {
        console.log(err);
      }
    });
  }

  return [_data, ranked];
};

const filter_anchor = (data, anchor, item) => {
  return data?.filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === anchor)?.response === item.id
    )
    .filter((f) => f.questions.length > 0);
};

const get_anchor_data = (
  structure,
  anchor,
  filtered_data,
  rule,
  questions,
  filtered_last,
  showPrimary = false
) => {
  //get the list of the categories options and map through

  if (anchor === -1) {
    return get_BIPOC(filtered_data, structure, rule, questions);
  }
  let anchor_list = structure.categories.find((f) => f.id === anchor)?.options;
  let _data = {};
  let ranked = {};
  let _last_data = {};
  let last_ranked = {};
  let primaryDataMap = {};
  let lastPrimaryDataMap = {};


  if (anchor_list) {
    anchor_list.map((item, i) => {
      _data[item.id] = {};
      primaryDataMap[item.id] = [];
      lastPrimaryDataMap[item.id] = [];
      ranked[item.id] = [];

      _last_data[item.id] = {};

      last_ranked[item.id] = [];

      let _filtered_data = filter_anchor(filtered_data, anchor, item);
      let _filtered_last = filter_anchor(filtered_last, anchor, item);

      if (_filtered_data.length > rule) {
        //Loop through each response, and categorize them based on the secondary anchor
        _filtered_data.map((r, i) => {
          let summary = condensed(r, questions);
          try {
            let secondary = r.categories.find(
              (f) => f.priority === "secondary"
            ).response;

            let primary;
            if (!showPrimary) {
              primary = r.categories.find(
                (f) => f.priority === "primary"
              ).response;
            }

            if (!(secondary in _data[item.id])) {
              _data[item.id][secondary] = [];
            }
            let x = [0, 0];
            let y = [0, 0];
            if (r.questions.length > 0) {
            }
            r.questions.map((d) => {
              let resp = check_reverse_score(d, questions);

              if (d.id === 0) {
                x[0] += resp;
                x[1] += 1;
              } else {
                y[0] += resp;
                y[1] += 1;
              }
            });
            ranked[item.id].push(summary);

            let pillar_averages = get_pillar_averages(r, questions);

            if (!showPrimary) {
              primaryDataMap[item.id][i] = primary;
              _data[item.id][secondary].push(pillar_averages);
            } else {
              _data[item.id][secondary].push(pillar_averages);
            }
          } catch (err) {
            console.log(err);
          }
        });

        _filtered_last?.map((r) => {
          let summary = condensed(r, questions);
          try {
            let secondary = r.categories.find(
              (f) => f.priority === "secondary"
            ).response;

            let primary;
            if (showPrimary) {
              primary = r.categories.find(
                (f) => f.priority === "primary"
              ).response;
            }

            if (!(secondary in _last_data[item.id])) {
              _last_data[item.id][secondary] = [];
            }
            let x = [0, 0];
            let y = [0, 0];
            if (r.questions.length > 0) {
            }
            r.questions.map((d) => {
              let resp = check_reverse_score(d, questions);

              if (d.id === 0) {
                x[0] += resp;
                x[1] += 1;
              } else {
                y[0] += resp;
                y[1] += 1;
              }
            });
            last_ranked[item.id].push(summary);

            let pillar_averages = get_pillar_averages(r, questions);


            if (!showPrimary) {
              lastPrimaryDataMap[item.id][i] = primary;
              _last_data[item.id][secondary].push(pillar_averages);
            } else {
              _last_data[item.id][secondary].push(pillar_averages);
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    });
  }

  return [
    _data,
    ranked,
    _last_data,
    last_ranked,
    lastPrimaryDataMap,
    primaryDataMap,
  ];

};

const getScore = (arr) => {
  return average(arr.flat(2));
};

const remove_NA_data = (data) => {
  return data.map((item) => {
    let _item = item;
    _item.responses = item.responses.map((resp) => {
      let _resp = resp;
      _resp.questions = resp.questions.filter((f) => f.response != "N/A");
      return _resp;
    });
    if (_item.last) {
      _item.last = item.last.map((resp) => {
        let _resp = resp;
        _resp.questions = resp.questions.filter((f) => f.response != "N/A");
        return _resp;
      });
    }

    return _item;
  });
};

const filter_data_nav = (data, filters, structure) => {

  return data?.filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;
      try {
        x.categories
          .filter((d) => d.priority != "primary")
          .map((item, i) => {
            let all_selected = check_all_selected(structure, item, filters);
            if (
              !all_selected
            ) {
              checked = checked
                ? filters[item.id].includes(item.response)
                : false;
            }
          });
      } catch (err) {
        checked = false;
      }

      return checked;
    });
};

const filter_primary = (data, primary) => {
  return data?.filter((f) => {
    return primary.includes(
      f.categories.find((d) => d.priority == "primary").response
    );
  });
};

const get_pillar_averages = (data, questions) => {
  let _data = [];
  questions.dimensions.map(d=>{
    let d_data = []
    d.factors.map(f=>{
      d_data.push(average(data.questions.filter(x=>x.id==d.id-1 && x.factor==f.id-1).map(q=>check_reverse_score(q,questions))))
    })
    _data.push(average(d_data))
  })
  return _data
}

export const calculate_data = (
  data,
  nav,
  structure,
  anchor,
  rule,
  questions,
  showPrimary = false
) => {
  let primary = [];
  let subfactor = [];
  let filters = {};


  remove_NA_data(data);
  //find the primary nav structure
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority === "primary")];
  Object.keys(prime)
    .filter((f) => f !== "selected" || f !== "priority")
    .map((item, i) => {
      let items = prime[item];

      if (items.selected) {
        primary.push(item);
      }
      let subs = [false];
      if (items.subfactors) {
        if (Object.keys(items.subfactors).length > 0) {
          subs = Object.keys(items.subfactors).map(
            (x) => items.subfactors[x].selected
          );
        }
      }
      if (subs.includes(true)) {
        Object.keys(items.subfactors).map((s) => {
          let sub = items.subfactors[s];
          if (sub.selected) {
            subfactor.push({
              primary: item,
              sub: s,
            });
          }
        });
      }
    });


  Object.keys(nav)
    .filter((f) => f != 0)
    .map((i) => {
      filters[i] = Object.keys(nav[i])
        .filter((f) => f != "selected")
        .filter((f) => nav[i][f].selected)
        .map((x) => x);
    });

  //Filter the responses from the most recent data based on the navigation filters
  let filter_data = filter_data_nav(data[0]?.responses, filters, structure)
  //Do the same filtering with the historical data if any
  let filter_last_data = data[0]?.last

  let _data = {};

  let ranked = {};

  let _last = {};

  let last_ranked = {};
  let lastPrimaryDataMap = {};
  let primaryDataMap = {};

  // if a subfactor is present, then return the data for that or those subfactors
  if (subfactor.length > 0 && filter_data.length > 0) {
    let filtered_data = filter_data.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(f.categories.find((f) => f.priority == "primary").subfactor);
    });


    let filtered_last = filter_last_data.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(f.categories.find((f) => f.priority == "primary").subfactor);
    });

    let anchor_priority = structure.categories.find(
      (f) => f.id == anchor
    ).priority;


    if (anchor && anchor_priority != "primary") {
      [_data, ranked, _last, last_ranked, lastPrimaryDataMap, primaryDataMap] =
        get_anchor_data(
          structure,
          anchor,
          filtered_data,
          rule,
          questions,
          filtered_last
        );


      return structure_data(
        _data,
        nav,
        ranked,
        structure,
        anchor,
        _last,
        last_ranked,
        rule
      );
    } else {
      subfactor.map((item, i) => {
        _data[i] = {
          sub: item.sub,
          primary: item.primary,
          data: {},
        };
        ranked[i] = [];

        _last[i] = {
          sub: item.sub,
          primary: item.primary,
          data: {},
        };
        last_ranked[i] = [];

        filtered_data
          .filter(
            (f) =>
              f.categories[0].subfactor == item.sub &&
              f.categories[0].response == item.primary
          )
          .map((r) => {
            let summary = condensed(r, questions);
            let secondary = r.categories[1].response;
            if (!(secondary in _data[i].data)) {
              _data[i].data[secondary] = [];
            }

            let pillar_averages = get_pillar_averages(r, questions);

            ranked[i].push(summary);

            _data[i].data[secondary].push(pillar_averages);
          });

        filtered_last
          .filter(
            (f) =>
              f.categories[0].subfactor == item.sub &&
              f.categories[0].response == item.primary
          )
          .map((r) => {
            let summary = condensed(r, questions);

            let secondary = r.categories[1].response;
            if (!(secondary in _last[i].data)) {
              _last[i].data[secondary] = [];
            }
            let x = [0, 0];
            let y = [0, 0];

            r.questions.map((d) => {
              let resp = check_reverse_score(d, questions);
              if (d.id == 0) {
                x[0] += resp;
                x[1] += 1;
              } else {
                y[0] += resp;
                y[1] += 1;
              }
            });

            last_ranked[i].push(summary);
            let pillar_averages = get_pillar_averages(r, questions);

            _last[i].data[secondary].push(pillar_averages);
          });
      });
    }


    return structure_sub_data(
      _data,
      nav,
      ranked,
      structure,
      _last,
      last_ranked,
      rule
    );

  }

  // if no subfactor is present, then calculate the data for the primary factor
  //start by filtering the data to only the primary factors selected

  let filtered_data = filter_primary(filter_data, primary);
  let filtered_last_data = filter_primary(filter_last_data, primary);


  //bucket data by primary factor or by data anchor
  if (anchor) {
    [_data, ranked, _last, last_ranked] = get_anchor_data(
      structure,
      anchor,
      filtered_data,
      rule,
      questions,
      filtered_last_data,
      showPrimary
    );
  } else {
    primary.map((item, i) => {
      _data[item] = {};

      ranked[item] = [];
      filtered_data
        .filter((f) => f.categories[0].response == item)
        .map((r) => {
          let summary = condensed(r, questions);
          let secondary = r.categories[1].response;
          let sport = r.categories[0].subfactor;
          if (!(secondary in _data[item])) {
            _data[item][secondary] = [];
          }
          let x = [0, 0];
          let y = [0, 0];

          r.questions.map((d) => {
            if (d.id == 0) {
              x[0] += d.response;
              x[1] += 1;
            } else {
              y[0] += d.response;
              y[1] += 1;
            }
          });
          ranked[item].push(summary);

          _data[item][secondary].push([x[0] / x[1], y[0] / y[1]]);
        });
    });
  }



  return structure_data(
    _data,
    nav,
    ranked,
    structure,
    anchor,
    _last,
    last_ranked,
    rule
  );
};

const condensed = (r, questions) => {
  let summary = [];
  r.questions.map((item) => {
    let exists = summary.findIndex(
      (f) => f.id == item.id && f.factor == item.factor
    );

    let resp = check_reverse_score(item, questions);

    if (exists >= 0) {
      let data = summary[exists];

      data.response.push(resp);
      summary[exists] = data;
    } else {
      let data = {
        id: item.id,
        factor: item.factor,
        response: [resp],
      };

      summary.push(data);
    }
  });



  return summary;
};

const checkSubFactors = (id, option, structure) => {
  if (structure.categories.find((f) => f.id == id).subfactors.length > 0) {
    let factor = structure.categories
      .find((f) => f.id == id)
      .subfactors.filter((f) => f.option == option);
    if (factor.length > 0) {
      return true;
    }
  }

  return false;
};

export const build_default_State = (structure) => {
  let chosen = {};
  try {
    structure.categories.map((item) => {
      //start with the item priority and default selected
      chosen[item.id] = { selected: true, priority: item.priority };

      //iterate through each option and default to selected
      item.options.map((x, i) => {
        chosen[item.id][x.id] = {
          selected: true,
        };

        //check to see if subfactors are present, if so then add and select them but not selected by default
        if ("subfactors" in x) {
          //sub signals that a subfactor is selected here
          chosen[item.id][x.id]["sub"] = false;

          //within subfactors, iterate through and add all subfactors, default to not selected
          chosen[item.id][x.id]["subfactors"] = {};
          item.options
            .find((f) => f.id == x.id)
            .subfactors.map((f, idx) => {
              chosen[item.id][x.id]["subfactors"][f.id] = {
                id: f.id,
                selected: false,
              };
            });
        }
      });
    });
  } catch (err) {
    console.log(err);
  }

  return chosen;
};

const filter_array = (data, filters, subfactor, primary) => {
  let filter_data = data
    .filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;
      if (x?.categories) {
        x?.categories
          .filter((d) => d.priority != "primary")
          .map((item, i) => {
            if (item.response !== null) {
              checked = checked
                ? filters[item.id].includes(item.response)
                : false;
            }
          });
        return checked;
      }
    });

  if (subfactor.length > 0) {
    filter_data = filter_data.filter((f) => {
      return subfactor.map((i) => i.sub).includes(f.categories[0].subfactor);
    });
  } else {
    filter_data = filter_data.filter((f) => {
      return primary.includes(f.categories[0].response);
    });
  }

  return filter_data;
};

export const filter_data = (data, nav, structure) => {
  let primary = [];
  let subfactor = [];
  let filters = {};

  //find the primary nav structure
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority == "primary")];
  let prime_id = Object.keys(nav).find((f) => nav[f].priority == "primary");

  Object.keys(prime)
    .filter((f) => f != "selected")
    .map((item, i) => {
      let items = prime[item];
      if (items.selected) {
        primary.push(item);
      }
      let subs = [false];
      if (items.subfactors) {
        if (Object.keys(items.subfactors).length > 0) {
          subs = Object.keys(items.subfactors).map(
            (x) => items.subfactors[x].selected
          );
        }
      }
      if (subs.includes(true)) {
        Object.keys(items.subfactors).map((s) => {
          let sub = items.subfactors[s];

          if (sub.selected) {
            subfactor.push({
              primary: item,
              sub: s,
            });
          }
        });
      }
    });

  Object.keys(nav)
    .filter((f) => f !== prime_id)
    .map((i) => {
      filters[i] = Object.keys(nav[i])
        .filter((f) => f != "selected")
        .filter((f) => nav[i][f].selected)
        .map((x) => x);
    });

  // // Go through the filters, and filter out the corresponding data points
  // if (data.length > 0) {
  //   console.log(data);
  //   let fildata = filter_array(data, filters, subfactor, primary);
  //   console.log(fildata);
  //   return fildata;
  // }

  let filter_data = data.map((f) => {
    return f.responses
      .filter((x) => x.questions.length > 0)
      .filter((x) => {
        let checked = true;
        if (x?.categories) {
          try {
            x.categories
              .filter((d) => d.priority != "primary")
              .map((item, i) => {
                let all_selected = check_all_selected(structure, item, filters);
                 if (
                  !all_selected
                ) {
                  checked = checked
                    ? filters[item.id].includes(item.response)
                    : false;
                }

              });
          } catch (err) {
            checked = false;
          }

          return checked;
        }
      });
  });


  let filter_data_last = data[0]?.last?.filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;
      if (x?.categories) {
        try {
          x.categories
            .filter((d) => d.priority != "primary")
            .map((item, i) => {
              let all_selected = check_all_selected(structure, item, filters);
              if (
                !all_selected
              ) {
                checked = checked
                  ? filters[item.id].includes(item.response)
                  : false;

              }
            });
        } catch (err) {
          checked = false;
        }

        return checked
        }
      });




  if (subfactor.length > 0) {
    filter_data = filter_data.map((d) =>
      d.filter((f) => {
        return subfactor
          .map((i) => i.sub)
          .includes(
            f.categories.find((f) => f.priority == "primary").subfactor
          );
      })
    );

    filter_data_last = filter_data_last?.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(f.categories.find((f) => f.priority == "primary").subfactor);
    });
  } else {

    filter_data = filter_data.map((d) =>
      d.filter((f) => {
        return primary.includes(
          f.categories.find((f) => f.priority == "primary").response
        );
      })
    );

    filter_data_last = filter_data_last?.filter((f) => {
      return primary.includes(
        f.categories.find((f) => f.priority == "primary").response
      );
    });
  }



  return { filter_data, filter_data_last };
};

export const filter_otp_data = (data, selected) => {
  let filter_data = data.map((f) => {
    return f.responses.filter((x) => x.questions.length > 0);
  });

  filter_data = filter_data.filter((d) =>
    d.filter((f) => {
      return (
        f.categories.find((f) => f.priority === "primary").subfactor ===
        selected
      );
    })
  );

  return filter_data;
};

export const get_dates = (data) => {
  return data.map((item) => item.date);
};

export const get_most_recent = (responses) => {
  let tracker = {};
  return {
    date: null,
    responses: responses
      .filter((i) => {
        if (!(i.name_uid in tracker)) {
          tracker[i.name_uid] = i.date;
        }
        return i.date == tracker[i.name_uid];
      })
      .map((i) => i.responses)
      .flat()
      .map((i) => i.response)
      .filter((f) => !Array.isArray(f)),
  };
};


export const get_last_responses = (responses) => {
  let tracker = {};

  return responses
    .filter((i) => {
      if (!(i.name in tracker)) {
        tracker[i.name] = [i.date];
      } else if (tracker[i.name].length === 1) {
        tracker[i.name].push(i.date);
      }

      if (tracker[i.name].length === 2) {
        return i.date == tracker[i.name][1];
      }
      return false;
    })
    .map((i) => i.responses)
    .flat()
    .map((i) => i.response)
    .filter((f) => !Array.isArray(f));
};

export const get_by_id = (responses, id) => {
  const responseGroup = responses.find((i) => i.id === id)?.responses;
  return {
    date: null,
    responses: responseGroup
      ?.flat()
      .map((i) => i.response)
      .filter((f) => !Array.isArray(f)),
  };
};

export const sort_historical_data = (responses, time) => {
  let _responses = [];
  //Must already be sorted
  let start = new Date(new Date(responses[0].date).setDate(0));
  let copied_start = new Date(start.getTime());
  let end = new Date(responses.slice(-1)[0].date);
  let start_month = start.getMonth();
  let current = new Date(
    copied_start.getFullYear(),
    copied_start.getMonth() + 2,
    1
  );

  while (start < end) {
    let filtered_responses = responses.filter((f) => {
      let r_date = new Date(f.date);
      return r_date > start && r_date < current;
    });

    if (filtered_responses.length > 0) {
      _responses.push({
        date: start,
        responses: filtered_responses
          .map((i) => i.responses)
          .flat()
          .map((i) => i.response)
          .filter((f) => !Array.isArray(f)),
      });
    }

    start = new Date(current.getTime());
    current = new Date(current.getFullYear(), current.getMonth() + 2, 0);
  }

  return _responses;
};

export const change_data = (data, sets) => {
  const options = [
    "3c6d7f4a-c860-41c5-99e1-d3ba6171d2f6",
    "b8d47ec9-cef6-4231-802f-2150f55646ab",
    "bbdd7306-8476-4f05-bda9-aa43bda03b3c",
    "fdc74d68-4d1f-4c06-a368-3d31d8404060",
    "5d9bb821-4272-43f5-af27-decf3f9cc46d",
    "fa7784c8-4879-4b8e-b9da-25bbd00259b2",
    "f8cdd260-f48c-4b41-8efd-34bc3d9507c0",
    "a93aa856-9039-4352-8b9a-99c11ba1017b",
    "e0deedb1-d8e9-4144-bd98-09c22772552f",
    "f6d2e5bd-adba-41a5-93a8-20e421d46d95",
  ];

  let _data = [];
  for (let i = 0; i < sets; i++) {
    let dir_0 = Math.random() > 0.5 ? 1 : 1.4;
    let dir_1 = Math.random() > 0.5 ? 1 : 1.4;
    let randomize_0 = dir_0 - Math.random() * 0.2;
    let randomize_1 = dir_1 - Math.random() * 0.2;

    let selected = options[i];

    let copy = JSON.parse(JSON.stringify(data));
    let new_data = copy.responses.map((item, i) => {
      item.response.categories.find((f) => f?.priority == "primary").response =
        selected;
      item.response.questions = item.response.questions.map((q) => {
        if (q.id == 0) {
          let score = Math.floor(q.response * randomize_0);
          q.response = score > 10 ? 10 : score;
        }
        if (q.id == 1) {
          let score = Math.floor(q.response * randomize_0);
          q.response = score > 10 ? 10 : score;
        }

        return q;
      });
      return item;
    });
    let data2 = JSON.parse(JSON.stringify(data));
    data2.responses = new_data;
    _data.push(data2);
  }
};

export const getPrimaryRoleData = (
  data,
  nav,
  structure,
  rule,
  questions,
  anchor
) => {
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority === "primary")];
  let primary = [];
  let subfactor = [];
  let filters = [];

  Object.keys(prime)
    .filter((f) => f !== "selected" || f !== "priority")
    .map((item, i) => {
      let items = prime[item];
      primary.push(item);

      let subs = [false];
      if (items.subfactors) {
        if (Object.keys(items.subfactors).length > 0) {
          subs = Object.keys(items.subfactors).map(
            (x) => items.subfactors[x].selected
          );
        }
      }
      if (subs.includes(true)) {
        Object.keys(items.subfactors).map((s) => {
          let sub = items.subfactors[s];
          if (sub.selected) {
            subfactor.push({
              primary: item,
              sub: s,
            });
          }
        });
      }
    });

  Object.keys(nav)
    .filter((f) => f !== 0)
    .map((i) => {
      filters[i] = Object.keys(nav[i])
        .filter((f) => f !== "selected")
        .filter((f) => nav[i][f].selected)
        .map((x) => x);
    });

  let filter_data = data[0]?.responses
    ?.filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;

      x.categories
        .filter((d) => d.priority !== "primary")
        .map((item, i) => {
          try {
            let all_selected = check_all_selected(structure, item, filters);
            if (
              (item.response !== null && item.response !== "") ||
              !all_selected
            ) {
              checked = checked
                ? filters[item.id].includes(item.response)
                : false;
            }
          } catch (err) {
            checked = false;
          }
        });

      return checked;
    });

  if (subfactor.length > 0) {
    filter_data = filter_data?.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(
          f.categories.find((cat) => cat.priority === "primary").subfactor
        );
    });
  } else {
    filter_data = filter_data?.filter((f) => {
      return primary.includes(
        f.categories.find((cat) => cat.priority === "primary").response
      );
    });
  }
  let anchor_priority = structure.categories.find(
    (f) => f.id === anchor
  ).priority;
  //Begin to summary the question data in to the proper tables
  let response = {};
  filter_data?.map((item, i) => {
    let primary;
    let secondary;
    let subfactor_parent = null;
    secondary = item.categories.find((f) => f.id === anchor).subfactor;
    subfactor_parent = item.categories.find((f) => f.id === anchor).response;

    if (!secondary) {
      return;
    }

    item.questions.map((q, r) => {
      if (!(q.id in response)) {
        response[q.id] = {};
      }
      if (!(q.factor in response[q.id])) {
        response[q.id][q.factor] = {};
      }
      if (!(q.q in response[q.id][q.factor])) {
        response[q.id][q.factor][q.q] = {};
      }
      if (!(secondary in response[q.id][q.factor][q.q])) {
        response[q.id][q.factor][q.q][secondary] = {
          total: 0,
          n: 0,
          responses: [],
          adjusted_total: 0,
          adjusted_responses: [],
          subfactor_parent,
        };
      }
      let resp = check_reverse_score(q, questions);

      response[q.id][q.factor][q.q][secondary].adjusted_total += resp;
      response[q.id][q.factor][q.q][secondary].adjusted_responses.push(resp);
      response[q.id][q.factor][q.q][secondary].total += q.response;
      response[q.id][q.factor][q.q][secondary].n += 1;
      response[q.id][q.factor][q.q][secondary].responses.push(q.response);
      response[q.id][q.factor][q.q][secondary].primary = primary;
    });
  });

  let processed_responses = [];
  Object.keys(response).map((item, i) => {
    let section = response[item];
    Object.keys(section).map((s) => {
      let question = section[s];
      Object.keys(question).map((q) => {
        let role = question[q];
        Object.keys(role).map((r) => {
          let role_data = role[r];
          const primaryKey = role_data.primary;
          let average = role_data.total / role_data.n;
          let stdev =
            Math.floor(getStandardDeviation(role_data.responses) * 10) / 10;
          let label;
          if (subfactor.length > 0 && anchor_priority == "primary") {
            label = label = structure.categories
              .find((f) => f.id == anchor)
              .options.find((f) => f.id == role_data.subfactor_parent)
              .subfactors.find((f) => f.id == r);
          } else {
            label = structure.categories
              .find((f) => f.id == anchor)
              .options.find((f) => f.id == r);
          }

          let distribution = get_buckets(role_data.responses);
          let factors = { dimension: i, factor: s, x: 0, y: 0 };
          // let norm = calculate_norms(data,r,average,factors,q)

          processed_responses.push({
            average,
            label,
            distribution,
            role: r,
            question: q,
            section: s,
            dimension: i,
            stdev,
            n: role_data.n,
            primaryKey,
          });
        });
      });
    });
  });

  processed_responses = processed_responses.filter((f) => f.n > rule);
  return {
    responses: processed_responses,
  };
};

export const calculate_otp_table_data = (
  data,
  structure,
  anchor,
  rule,
  questions,
  selected,
  otpVersion
) => {
  //find the primary nav structure
  remove_NA_data(data);

  let filter_data = data[0]?.responses?.filter((x) => x.questions.length > 0);
  if (otpVersion) {
    filter_data = filter_data?.filter(
      (f) =>
        f.categories.find((f) => f.priority === "primary").subfactor ===
        selected
    );
  }
  //Begin to summary the question data in to the proper tables
  let response = {};
  let primaryResponse = {};
  let respondent_count ={};
  filter_data?.map((item, i) => {
    let secondary;
    let subfactor_parent = null;
    secondary = item.categories.find(
      (f) => f.priority === "secondary"
    ).response;

    subfactor_parent = item.categories.find(
      (f) => f.priority === "primary"
    ).response;

    if(!(secondary in respondent_count)){
      respondent_count[secondary] = 1
    }else{
      respondent_count[secondary]+=1
    }

    item.questions.map((q, r) => {
      if (!(q.id in response)) {
        response[q.id] = {};
      }
      if (!(q.factor in response[q.id])) {
        response[q.id][q.factor] = {};
      }
      if (!(q.q in response[q.id][q.factor])) {
        response[q.id][q.factor][q.q] = {};
      }
      if (!(secondary in response[q.id][q.factor][q.q])) {
        response[q.id][q.factor][q.q][secondary] = {
          total: 0,
          n: 0,
          responses: [],
          adjusted_total: 0,
          adjusted_responses: [],
          primaryGroups: [],
          subfactor_parent,
        };
      }
      let resp = check_reverse_score(q, questions);

      response[q.id][q.factor][q.q][secondary].adjusted_total += resp;
      response[q.id][q.factor][q.q][secondary].adjusted_responses.push(resp);
      response[q.id][q.factor][q.q][secondary].total += q.response;
      response[q.id][q.factor][q.q][secondary].n += 1;
      response[q.id][q.factor][q.q][secondary].responses.push(q.response);
      response[q.id][q.factor][q.q][secondary].primaryGroups.push({
        response: q.response,
        group: subfactor_parent,
      });
    });
  });

  let processed_responses = [];
  Object.keys(response).map((item, i) => {
    let section = response[item];
    Object.keys(section).map((s) => {
      let question = section[s];
      Object.keys(question).map((q) => {
        let role = question[q];
        Object.keys(role).map((r) => {
          let role_data = role[r];
          let average = get_average(role_data.responses)
          let stdev = (getStandardDeviation(role_data.responses) * 10) / 10;
          let label;
          label = otpVersion
            ? null
            : structure.categories
                .find((f) => f.priority === "primary")
                .options.find((f) => f.id === role_data.subfactor_parent)
                .subfactors.find((f) => f.id === selected);

          let distribution = get_buckets(role_data.responses);
          processed_responses.push({
            average,
            label,
            distribution,
            role: r,
            question: q,
            section: s,
            dimension: i,
            stdev,
            n: role_data.n,
            primaryData: role_data.primaryGroups,
          });
        });
      });
    });
  });

  //summarize data in person and performance
  let summary = {};
  Object.keys(response).map((item, i) => {
    summary[item] = {};
    Object.keys(response[item]).map((f) => {
      summary[item][f] = {};
      Object.keys(response[item][f]).map((q) => {
        Object.keys(response[item][f][q]).map((s) => {
          if (!(s in summary[item][f])) {
            summary[item][f][s] = { total: 0, n: 0, responses: [],raw:[] };
          }
          summary[item][f][s].total += response[item][f][q][s].adjusted_total;
          summary[item][f][s].n += response[item][f][q][s].n;
          summary[item][f][s].raw.push(...response[item][f][q][s].adjusted_responses)
          summary[item][f][s].responses.push(
            get_buckets(response[item][f][q][s].adjusted_responses)
          );
        });
      });
    });
  });

  let processed_summary = [];
  Object.keys(summary).map((item, i) => {
    let dimension = summary[item];
    Object.keys(dimension).map((f) => {
      let factor = dimension[f];
      Object.keys(factor).map((s) => {
        let secondary = factor[s];

        let average = get_average(secondary.raw)

        let n =
          secondary.n /
          questions.dimensions[item]?.factors[f]?.questions.length;

        let distribution = sum_distribution(secondary.responses);
        processed_summary.push({
          dimension: item,
          factor: f,
          secondary: s,
          average,
          distribution,
          n: respondent_count[s],
        });
      });
    });
  });

  //summarize data in overall culture
  let overall = {};
  Object.keys(response).map((item, i) => {
    Object.keys(response[item]).map((f) => {
      Object.keys(response[item][f]).map((q) => {
        Object.keys(response[item][f][q]).map((s) => {
          if (!(s in overall)) {
            overall[s] = { total: 0, n: 0, responses: [], n1: 0 };
          }
          overall[s].total += response[item][f][q][s].adjusted_total;
          overall[s].n += respondent_count[s];
          overall[s].n1 = response[item][f][q][s].n;
          overall[s].n2 = respondent_count[s];
          //need to reverse the appropriate responses
          overall[s].responses.push(
            average(response[item][f][q][s].adjusted_responses)
          );
        });
      });
    });
  });

  //filter based on the rule of 3
  processed_summary = processed_summary.filter((f) => f.n > rule);
  processed_responses = processed_responses.filter((f) => f.n > rule);
  const asArray = Object.entries(overall);
  const filtered = asArray.filter(
    ([key, value]) => value.n / value.responses.length > rule
  );
  const filtered_overall = Object.fromEntries(filtered);

  return {
    responses: processed_responses,
    summary: processed_summary,
    overall: filtered_overall,
  };
};
