import React from "react";
import { StyleSheet, Text, View } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100vw",
    padding: 20,
    paddingHorizontal: 40,
    fontSize: 8,
    zIndex: -1,
  },
  line: {
    height: 0.2,
    width: "100%",
    backgroundColor: "grey",
    marginBottom: 10,
  },
});

const Footer = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={styles.line} />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View>
          <Text>Report generated by nuLogic - support@nuwaveco.com</Text>
          <Text>© 2024 nuLogic</Text>
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
        />
      </View>
    </View>
  );
};

export default Footer;
