import {
  ADD_PERSONALITY_PARTICIPANT,
  CLEAR_ALL,
  CLEAR_PERSONALITY_PARTICIPANT,
  DELETE_PERSONALITY_PARTICIPANT,
  EDIT_PERSONALITY_PARTICIPANT,
  FETCH_PERSONALITY_PARTICIPANT,
  GET_PERSONALITY_PARTICIPANTS,
} from "common/constants/actions";

const initialState = {
  personality_participants: [],
  pending: false,
};

export default function personalityParticipantsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERSONALITY_PARTICIPANTS:
      return {
        ...state,
        personality_participants: action.payload,
        pending: false,
      };

    case FETCH_PERSONALITY_PARTICIPANT:
      return { ...state, [action.payload.id]: action.payload, pending: false };

    case EDIT_PERSONALITY_PARTICIPANT:
      const schedule = state.personality_participants.filter(
        (row) => row.id !== action.payload.id,
      );
      schedule.push(action.payload);
      return { ...state, personality_participants: schedule };

    case DELETE_PERSONALITY_PARTICIPANT:
      return {
        ...state,
        personality_participants: state.personality_participants.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_PERSONALITY_PARTICIPANT:
      return {
        ...state,
        personality_participants: [
          ...state.personality_participants,
          action.payload,
        ],
      };

    case CLEAR_PERSONALITY_PARTICIPANT:
      return {
        ...state,
        personality_participants: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        personality_participants: [],
      };

    default:
      return state;
  }
}
