import { useCallback, useEffect, useRef } from "react";

const useAutoHeight = (initialHeight: number) => {
  const ref = useRef<HTMLElement>(null);

  const resize = useCallback(() => {
    if (ref.current) {
      ref.current.style.height = `${initialHeight}px`;
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  }, [initialHeight]);

  useEffect(() => {
    let currentRef = ref.current;
    currentRef?.addEventListener("input", resize);
    resize();

    return () => {
      currentRef?.removeEventListener("input", resize);
    };
  }, [initialHeight, resize]);

  return { ref, resize };
};

export default useAutoHeight;
