import React, { useState } from "react";

import TeamMembers from "../member-directory/components/MemberDirectory";
import Teams from "./HomeTeamTable";

const TeamHome = () => {
  const [selectedTeam, setSelectedTeam] = useState(false);
  return (
    <>
      {selectedTeam ? (
        <TeamMembers
          selectedTeam={selectedTeam}
          setSelectedTeam={setSelectedTeam}
        />
      ) : (
        <Teams setSelectedTeam={setSelectedTeam} />
      )}
    </>
  );
};

export default TeamHome;
