import styled from "styled-components";
import { Popup } from "semantic-ui-react";
import { format } from "date-fns";
import OverviewCard from "./OverviewCard";

function GridRowData({ monthList, itemName, surveySchedule }) {
  return (
    <Row>
      {monthList.map((mon, i) => {
        let selected = false;
        if (surveySchedule) {
          const SportFound = surveySchedule.find(
            ({ name }) => name.trim() === (itemName || "").trim(),
          );

          if (SportFound) {
            const ScheduledFor = format(
              new Date(SportFound?.scheduled_for),
              "MMM-yy",
            );
            selected = ScheduledFor === mon ? true : false;
            const completed = SportFound?.status === 4 ? true : false;
            if (selected) {
              return (
                <Popup
                  content={
                    <OverviewCard
                      sportName={itemName} // item?.value
                      SportFound={SportFound}
                      i={i}
                      completed={completed}
                    />
                  }
                  trigger={
                    <DateCircle
                      completed={!completed}
                      selected={selected}
                      i={i}
                    />
                  }
                  key={i}
                />
              );
            }
          }
        }

        return <DateCircle selected={selected} completed={false} key={i} />;
      })}
    </Row>
  );
}

export default GridRowData;

const DateCircle = styled.div`
  width: calc(100% / 12);
  background-color: ${(props) => (props.selected ? "#E3B01F" : "#ebebeb")};
  border-radius: 10px;
  margin: 5px;
  cursor: pointer;

  background-image: ${(props) =>
    props.completed && props.selected
      ? "linear-gradient(45deg, #818580 25%, #a6a6a6 25%, #a6a6a6 50%, #818580 50%, #818580 75%, #a6a6a6 75%, #a6a6a6 100%)"
      : "none"};
  background-size: 28.28px 28.28px;
  &:hover {
    background-color: ${(props) => (props.selected ? "#E3B01F" : "#808080")};
  }
`;

const Row = styled.div`
  display: flex;
  height: 25px;
`;
