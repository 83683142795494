export const timeZones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];


export const CommonTimezones = [{value: "Africa/Abidjan", label: "Africa/Abidjan", text: "Africa/Abidjan", sort: 999},
  {value: "Africa/Accra", label: "Africa/Accra", text: "Africa/Accra", sort: 999},
  {value: "Africa/Addis_Ababa", label: "Africa/Addis_Ababa", text: "Africa/Addis_Ababa", sort: 999},
  {value: "Africa/Algiers", label: "Africa/Algiers", text: "Africa/Algiers", sort: 999},
  {value: "Africa/Asmara", label: "Africa/Asmara", text: "Africa/Asmara", sort: 999},
  {value: "Africa/Bamako", label: "Africa/Bamako", text: "Africa/Bamako", sort: 999},
  {value: "Africa/Bangui", label: "Africa/Bangui", text: "Africa/Bangui", sort: 999},
  {value: "Africa/Banjul", label: "Africa/Banjul", text: "Africa/Banjul", sort: 999},
  {value: "Africa/Bissau", label: "Africa/Bissau", text: "Africa/Bissau", sort: 999},
  {value: "Africa/Blantyre", label: "Africa/Blantyre", text: "Africa/Blantyre", sort: 999},
  {value: "Africa/Brazzaville", label: "Africa/Brazzaville", text: "Africa/Brazzaville", sort: 999},
  {value: "Africa/Bujumbura", label: "Africa/Bujumbura", text: "Africa/Bujumbura", sort: 999},
  {value: "Africa/Cairo", label: "Africa/Cairo", text: "Africa/Cairo", sort: 999},
  {value: "Africa/Casablanca", label: "Africa/Casablanca", text: "Africa/Casablanca", sort: 999},
  {value: "Africa/Ceuta", label: "Africa/Ceuta", text: "Africa/Ceuta", sort: 999},
  {value: "Africa/Conakry", label: "Africa/Conakry", text: "Africa/Conakry", sort: 999},
  {value: "Africa/Dakar", label: "Africa/Dakar", text: "Africa/Dakar", sort: 999},
  {value: "Africa/Dar_es_Salaam", label: "Africa/Dar_es_Salaam", text: "Africa/Dar_es_Salaam", sort: 999},
  {value: "Africa/Djibouti", label: "Africa/Djibouti", text: "Africa/Djibouti", sort: 999},
  {value: "Africa/Douala", label: "Africa/Douala", text: "Africa/Douala", sort: 999},
  {value: "Africa/El_Aaiun", label: "Africa/El_Aaiun", text: "Africa/El_Aaiun", sort: 999},
  {value: "Africa/Freetown", label: "Africa/Freetown", text: "Africa/Freetown", sort: 999},
  {value: "Africa/Gaborone", label: "Africa/Gaborone", text: "Africa/Gaborone", sort: 999},
  {value: "Africa/Harare", label: "Africa/Harare", text: "Africa/Harare", sort: 999},
  {value: "Africa/Johannesburg", label: "Africa/Johannesburg", text: "Africa/Johannesburg", sort: 999},
  {value: "Africa/Juba", label: "Africa/Juba", text: "Africa/Juba", sort: 999},
  {value: "Africa/Kampala", label: "Africa/Kampala", text: "Africa/Kampala", sort: 999},
  {value: "Africa/Khartoum", label: "Africa/Khartoum", text: "Africa/Khartoum", sort: 999},
  {value: "Africa/Kigali", label: "Africa/Kigali", text: "Africa/Kigali", sort: 999},
  {value: "Africa/Kinshasa", label: "Africa/Kinshasa", text: "Africa/Kinshasa", sort: 999},
  {value: "Africa/Lagos", label: "Africa/Lagos", text: "Africa/Lagos", sort: 999},
  {value: "Africa/Libreville", label: "Africa/Libreville", text: "Africa/Libreville", sort: 999},
  {value: "Africa/Lome", label: "Africa/Lome", text: "Africa/Lome", sort: 999},
  {value: "Africa/Luanda", label: "Africa/Luanda", text: "Africa/Luanda", sort: 999},
  {value: "Africa/Lubumbashi", label: "Africa/Lubumbashi", text: "Africa/Lubumbashi", sort: 999},
  {value: "Africa/Lusaka", label: "Africa/Lusaka", text: "Africa/Lusaka", sort: 999},
  {value: "Africa/Malabo", label: "Africa/Malabo", text: "Africa/Malabo", sort: 999},
  {value: "Africa/Maputo", label: "Africa/Maputo", text: "Africa/Maputo", sort: 999},
  {value: "Africa/Maseru", label: "Africa/Maseru", text: "Africa/Maseru", sort: 999},
  {value: "Africa/Mbabane", label: "Africa/Mbabane", text: "Africa/Mbabane", sort: 999},
  {value: "Africa/Mogadishu", label: "Africa/Mogadishu", text: "Africa/Mogadishu", sort: 999},
  {value: "Africa/Monrovia", label: "Africa/Monrovia", text: "Africa/Monrovia", sort: 999},
  {value: "Africa/Nairobi", label: "Africa/Nairobi", text: "Africa/Nairobi", sort: 999},
  {value: "Africa/Ndjamena", label: "Africa/Ndjamena", text: "Africa/Ndjamena", sort: 999},
  {value: "Africa/Niamey", label: "Africa/Niamey", text: "Africa/Niamey", sort: 999},
  {value: "Africa/Nouakchott", label: "Africa/Nouakchott", text: "Africa/Nouakchott", sort: 999},
  {value: "Africa/Ouagadougou", label: "Africa/Ouagadougou", text: "Africa/Ouagadougou", sort: 999},
  {value: "Africa/Porto-Novo", label: "Africa/Porto-Novo", text: "Africa/Porto-Novo", sort: 999},
  {value: "Africa/Sao_Tome", label: "Africa/Sao_Tome", text: "Africa/Sao_Tome", sort: 999},
  {value: "Africa/Tripoli", label: "Africa/Tripoli", text: "Africa/Tripoli", sort: 999},
  {value: "Africa/Tunis", label: "Africa/Tunis", text: "Africa/Tunis", sort: 999},
  {value: "Africa/Windhoek", label: "Africa/Windhoek", text: "Africa/Windhoek", sort: 999},
  {value: "America/Adak", label: "America/Adak", text: "America/Adak", sort: 999},
  {value: "America/Anchorage", label: "America/Anchorage", text: "America/Anchorage", sort: 999},
  {value: "America/Anguilla", label: "America/Anguilla", text: "America/Anguilla", sort: 999},
  {value: "America/Antigua", label: "America/Antigua", text: "America/Antigua", sort: 999},
  {value: "America/Araguaina", label: "America/Araguaina", text: "America/Araguaina", sort: 999},
  {value: "America/Argentina/Buenos_Aires", label: "America/Argentina/Buenos_Aires", text: "America/Argentina/Buenos_Aires", sort: 999},
  {value: "America/Argentina/Catamarca", label: "America/Argentina/Catamarca", text: "America/Argentina/Catamarca", sort: 999},
  {value: "America/Argentina/Cordoba", label: "America/Argentina/Cordoba", text: "America/Argentina/Cordoba", sort: 999},
  {value: "America/Argentina/Jujuy", label: "America/Argentina/Jujuy", text: "America/Argentina/Jujuy", sort: 999},
  {value: "America/Argentina/La_Rioja", label: "America/Argentina/La_Rioja", text: "America/Argentina/La_Rioja", sort: 999},
  {value: "America/Argentina/Mendoza", label: "America/Argentina/Mendoza", text: "America/Argentina/Mendoza", sort: 999},
  {value: "America/Argentina/Rio_Gallegos", label: "America/Argentina/Rio_Gallegos", text: "America/Argentina/Rio_Gallegos", sort: 999},
  {value: "America/Argentina/Salta", label: "America/Argentina/Salta", text: "America/Argentina/Salta", sort: 999},
  {value: "America/Argentina/San_Juan", label: "America/Argentina/San_Juan", text: "America/Argentina/San_Juan", sort: 999},
  {value: "America/Argentina/San_Luis", label: "America/Argentina/San_Luis", text: "America/Argentina/San_Luis", sort: 999},
  {value: "America/Argentina/Tucuman", label: "America/Argentina/Tucuman", text: "America/Argentina/Tucuman", sort: 999},
  {value: "America/Argentina/Ushuaia", label: "America/Argentina/Ushuaia", text: "America/Argentina/Ushuaia", sort: 999},
  {value: "America/Aruba", label: "America/Aruba", text: "America/Aruba", sort: 999},
  {value: "America/Asuncion", label: "America/Asuncion", text: "America/Asuncion", sort: 999},
  {value: "America/Atikokan", label: "America/Atikokan", text: "America/Atikokan", sort: 999},
  {value: "America/Bahia", label: "America/Bahia", text: "America/Bahia", sort: 999},
  {value: "America/Bahia_Banderas", label: "America/Bahia_Banderas", text: "America/Bahia_Banderas", sort: 999},
  {value: "America/Barbados", label: "America/Barbados", text: "America/Barbados", sort: 999},
  {value: "America/Belem", label: "America/Belem", text: "America/Belem", sort: 999},
  {value: "America/Belize", label: "America/Belize", text: "America/Belize", sort: 999},
  {value: "America/Blanc-Sablon", label: "America/Blanc-Sablon", text: "America/Blanc-Sablon", sort: 999},
  {value: "America/Boa_Vista", label: "America/Boa_Vista", text: "America/Boa_Vista", sort: 999},
  {value: "America/Bogota", label: "America/Bogota", text: "America/Bogota", sort: 999},
  {value: "America/Boise", label: "America/Boise", text: "America/Boise", sort: 999},
  {value: "America/Cambridge_Bay", label: "America/Cambridge_Bay", text: "America/Cambridge_Bay", sort: 999},
  {value: "America/Campo_Grande", label: "America/Campo_Grande", text: "America/Campo_Grande", sort: 999},
  {value: "America/Cancun", label: "America/Cancun", text: "America/Cancun", sort: 999},
  {value: "America/Caracas", label: "America/Caracas", text: "America/Caracas", sort: 999},
  {value: "America/Cayenne", label: "America/Cayenne", text: "America/Cayenne", sort: 999},
  {value: "America/Cayman", label: "America/Cayman", text: "America/Cayman", sort: 999},
  {value: "America/Chicago", label: "America/Chicago", text: "America/Chicago", sort: 999},
  {value: "America/Chihuahua", label: "America/Chihuahua", text: "America/Chihuahua", sort: 999},
  {value: "America/Costa_Rica", label: "America/Costa_Rica", text: "America/Costa_Rica", sort: 999},
  {value: "America/Creston", label: "America/Creston", text: "America/Creston", sort: 999},
  {value: "America/Cuiaba", label: "America/Cuiaba", text: "America/Cuiaba", sort: 999},
  {value: "America/Curacao", label: "America/Curacao", text: "America/Curacao", sort: 999},
  {value: "America/Danmarkshavn", label: "America/Danmarkshavn", text: "America/Danmarkshavn", sort: 999},
  {value: "America/Dawson", label: "America/Dawson", text: "America/Dawson", sort: 999},
  {value: "America/Dawson_Creek", label: "America/Dawson_Creek", text: "America/Dawson_Creek", sort: 999},
  {value: "America/Denver", label: "America/Denver", text: "America/Denver", sort: 999},
  {value: "America/Detroit", label: "America/Detroit", text: "America/Detroit", sort: 999},
  {value: "America/Dominica", label: "America/Dominica", text: "America/Dominica", sort: 999},
  {value: "America/Edmonton", label: "America/Edmonton", text: "America/Edmonton", sort: 999},
  {value: "America/Eirunepe", label: "America/Eirunepe", text: "America/Eirunepe", sort: 999},
  {value: "America/El_Salvador", label: "America/El_Salvador", text: "America/El_Salvador", sort: 999},
  {value: "America/Fort_Nelson", label: "America/Fort_Nelson", text: "America/Fort_Nelson", sort: 999},
  {value: "America/Fortaleza", label: "America/Fortaleza", text: "America/Fortaleza", sort: 999},
  {value: "America/Glace_Bay", label: "America/Glace_Bay", text: "America/Glace_Bay", sort: 999},
  {value: "America/Godthab", label: "America/Godthab", text: "America/Godthab", sort: 999},
  {value: "America/Goose_Bay", label: "America/Goose_Bay", text: "America/Goose_Bay", sort: 999},
  {value: "America/Grand_Turk", label: "America/Grand_Turk", text: "America/Grand_Turk", sort: 999},
  {value: "America/Grenada", label: "America/Grenada", text: "America/Grenada", sort: 999},
  {value: "America/Guadeloupe", label: "America/Guadeloupe", text: "America/Guadeloupe", sort: 999},
  {value: "America/Guatemala", label: "America/Guatemala", text: "America/Guatemala", sort: 999},
  {value: "America/Guayaquil", label: "America/Guayaquil", text: "America/Guayaquil", sort: 999},
  {value: "America/Guyana", label: "America/Guyana", text: "America/Guyana", sort: 999},
  {value: "America/Halifax", label: "America/Halifax", text: "America/Halifax", sort: 0},
  {value: "America/Havana", label: "America/Havana", text: "America/Havana", sort: 999},
  {value: "America/Hermosillo", label: "America/Hermosillo", text: "America/Hermosillo", sort: 999},
  {value: "America/Indiana/Indianapolis", label: "America/Indiana/Indianapolis", text: "America/Indiana/Indianapolis", sort: 999},
  {value: "America/Indiana/Knox", label: "America/Indiana/Knox", text: "America/Indiana/Knox", sort: 999},
  {value: "America/Indiana/Marengo", label: "America/Indiana/Marengo", text: "America/Indiana/Marengo", sort: 999},
  {value: "America/Indiana/Petersburg", label: "America/Indiana/Petersburg", text: "America/Indiana/Petersburg", sort: 999},
  {value: "America/Indiana/Tell_City", label: "America/Indiana/Tell_City", text: "America/Indiana/Tell_City", sort: 999},
  {value: "America/Indiana/Vevay", label: "America/Indiana/Vevay", text: "America/Indiana/Vevay", sort: 999},
  {value: "America/Indiana/Vincennes", label: "America/Indiana/Vincennes", text: "America/Indiana/Vincennes", sort: 999},
  {value: "America/Indiana/Winamac", label: "America/Indiana/Winamac", text: "America/Indiana/Winamac", sort: 999},
  {value: "America/Inuvik", label: "America/Inuvik", text: "America/Inuvik", sort: 999},
  {value: "America/Iqaluit", label: "America/Iqaluit", text: "America/Iqaluit", sort: 999},
  {value: "America/Jamaica", label: "America/Jamaica", text: "America/Jamaica", sort: 999},
  {value: "America/Juneau", label: "America/Juneau", text: "America/Juneau", sort: 999},
  {value: "America/Kentucky/Louisville", label: "America/Kentucky/Louisville", text: "America/Kentucky/Louisville", sort: 999},
  {value: "America/Kentucky/Monticello", label: "America/Kentucky/Monticello", text: "America/Kentucky/Monticello", sort: 999},
  {value: "America/Kralendijk", label: "America/Kralendijk", text: "America/Kralendijk", sort: 999},
  {value: "America/La_Paz", label: "America/La_Paz", text: "America/La_Paz", sort: 999},
  {value: "America/Lima", label: "America/Lima", text: "America/Lima", sort: 999},
  {value: "America/Los_Angeles", label: "America/Los_Angeles", text: "America/Los_Angeles", sort: 999},
  {value: "America/Lower_Princes", label: "America/Lower_Princes", text: "America/Lower_Princes", sort: 999},
  {value: "America/Maceio", label: "America/Maceio", text: "America/Maceio", sort: 999},
  {value: "America/Managua", label: "America/Managua", text: "America/Managua", sort: 999},
  {value: "America/Manaus", label: "America/Manaus", text: "America/Manaus", sort: 999},
  {value: "America/Marigot", label: "America/Marigot", text: "America/Marigot", sort: 999},
  {value: "America/Martinique", label: "America/Martinique", text: "America/Martinique", sort: 999},
  {value: "America/Matamoros", label: "America/Matamoros", text: "America/Matamoros", sort: 999},
  {value: "America/Mazatlan", label: "America/Mazatlan", text: "America/Mazatlan", sort: 999},
  {value: "America/Menominee", label: "America/Menominee", text: "America/Menominee", sort: 999},
  {value: "America/Merida", label: "America/Merida", text: "America/Merida", sort: 999},
  {value: "America/Metlakatla", label: "America/Metlakatla", text: "America/Metlakatla", sort: 999},
  {value: "America/Mexico_City", label: "America/Mexico_City", text: "America/Mexico_City", sort: 999},
  {value: "America/Miquelon", label: "America/Miquelon", text: "America/Miquelon", sort: 999},
  {value: "America/Moncton", label: "America/Moncton", text: "America/Moncton", sort: 999},
  {value: "America/Monterrey", label: "America/Monterrey", text: "America/Monterrey", sort: 999},
  {value: "America/Montevideo", label: "America/Montevideo", text: "America/Montevideo", sort: 999},
  {value: "America/Montserrat", label: "America/Montserrat", text: "America/Montserrat", sort: 999},
  {value: "America/Nassau", label: "America/Nassau", text: "America/Nassau", sort: 999},
  {value: "America/New_York", label: "America/New_York", text: "America/New_York", sort: 999},
  {value: "America/Nipigon", label: "America/Nipigon", text: "America/Nipigon", sort: 999},
  {value: "America/Nome", label: "America/Nome", text: "America/Nome", sort: 999},
  {value: "America/Noronha", label: "America/Noronha", text: "America/Noronha", sort: 999},
  {value: "America/North_Dakota/Beulah", label: "America/North_Dakota/Beulah", text: "America/North_Dakota/Beulah", sort: 999},
  {value: "America/North_Dakota/Center", label: "America/North_Dakota/Center", text: "America/North_Dakota/Center", sort: 999},
  {value: "America/North_Dakota/New_Salem", label: "America/North_Dakota/New_Salem", text: "America/North_Dakota/New_Salem", sort: 999},
  {value: "America/Ojinaga", label: "America/Ojinaga", text: "America/Ojinaga", sort: 999},
  {value: "America/Panama", label: "America/Panama", text: "America/Panama", sort: 999},
  {value: "America/Pangnirtung", label: "America/Pangnirtung", text: "America/Pangnirtung", sort: 999},
  {value: "America/Paramaribo", label: "America/Paramaribo", text: "America/Paramaribo", sort: 999},
  {value: "America/Phoenix", label: "America/Phoenix", text: "America/Phoenix", sort: 999},
  {value: "America/Port-au-Prince", label: "America/Port-au-Prince", text: "America/Port-au-Prince", sort: 999},
  {value: "America/Port_of_Spain", label: "America/Port_of_Spain", text: "America/Port_of_Spain", sort: 999},
  {value: "America/Porto_Velho", label: "America/Porto_Velho", text: "America/Porto_Velho", sort: 999},
  {value: "America/Puerto_Rico", label: "America/Puerto_Rico", text: "America/Puerto_Rico", sort: 999},
  {value: "America/Punta_Arenas", label: "America/Punta_Arenas", text: "America/Punta_Arenas", sort: 999},
  {value: "America/Rainy_River", label: "America/Rainy_River", text: "America/Rainy_River", sort: 999},
  {value: "America/Rankin_Inlet", label: "America/Rankin_Inlet", text: "America/Rankin_Inlet", sort: 999},
  {value: "America/Recife", label: "America/Recife", text: "America/Recife", sort: 999},
  {value: "America/Regina", label: "America/Regina", text: "America/Regina", sort: 999},
  {value: "America/Resolute", label: "America/Resolute", text: "America/Resolute", sort: 999},
  {value: "America/Rio_Branco", label: "America/Rio_Branco", text: "America/Rio_Branco", sort: 999},
  {value: "America/Santarem", label: "America/Santarem", text: "America/Santarem", sort: 999},
  {value: "America/Santiago", label: "America/Santiago", text: "America/Santiago", sort: 999},
  {value: "America/Santo_Domingo", label: "America/Santo_Domingo", text: "America/Santo_Domingo", sort: 999},
  {value: "America/Sao_Paulo", label: "America/Sao_Paulo", text: "America/Sao_Paulo", sort: 999},
  {value: "America/Scoresbysund", label: "America/Scoresbysund", text: "America/Scoresbysund", sort: 999},
  {value: "America/Sitka", label: "America/Sitka", text: "America/Sitka", sort: 999},
  {value: "America/St_Barthelemy", label: "America/St_Barthelemy", text: "America/St_Barthelemy", sort: 999},
  {value: "America/St_Johns", label: "America/St_Johns", text: "America/St_Johns", sort: 999},
  {value: "America/St_Kitts", label: "America/St_Kitts", text: "America/St_Kitts", sort: 999},
  {value: "America/St_Lucia", label: "America/St_Lucia", text: "America/St_Lucia", sort: 999},
  {value: "America/St_Thomas", label: "America/St_Thomas", text: "America/St_Thomas", sort: 999},
  {value: "America/St_Vincent", label: "America/St_Vincent", text: "America/St_Vincent", sort: 999},
  {value: "America/Swift_Current", label: "America/Swift_Current", text: "America/Swift_Current", sort: 999},
  {value: "America/Tegucigalpa", label: "America/Tegucigalpa", text: "America/Tegucigalpa", sort: 999},
  {value: "America/Thule", label: "America/Thule", text: "America/Thule", sort: 999},
  {value: "America/Thunder_Bay", label: "America/Thunder_Bay", text: "America/Thunder_Bay", sort: 999},
  {value: "America/Tijuana", label: "America/Tijuana", text: "America/Tijuana", sort: 999},
  {value: "America/Toronto", label: "America/Toronto", text: "America/Toronto", sort: 999},
  {value: "America/Tortola", label: "America/Tortola", text: "America/Tortola", sort: 999},
  {value: "America/Vancouver", label: "America/Vancouver", text: "America/Vancouver", sort: 999},
  {value: "America/Whitehorse", label: "America/Whitehorse", text: "America/Whitehorse", sort: 999},
  {value: "America/Winnipeg", label: "America/Winnipeg", text: "America/Winnipeg", sort: 999},
  {value: "America/Yakutat", label: "America/Yakutat", text: "America/Yakutat", sort: 999},
  {value: "America/Yellowknife", label: "America/Yellowknife", text: "America/Yellowknife", sort: 999},
  {value: "Antarctica/Casey", label: "Antarctica/Casey", text: "Antarctica/Casey", sort: 999},
  {value: "Antarctica/Davis", label: "Antarctica/Davis", text: "Antarctica/Davis", sort: 999},
  {value: "Antarctica/DumontDUrville", label: "Antarctica/DumontDUrville", text: "Antarctica/DumontDUrville", sort: 999},
  {value: "Antarctica/Macquarie", label: "Antarctica/Macquarie", text: "Antarctica/Macquarie", sort: 999},
  {value: "Antarctica/Mawson", label: "Antarctica/Mawson", text: "Antarctica/Mawson", sort: 999},
  {value: "Antarctica/McMurdo", label: "Antarctica/McMurdo", text: "Antarctica/McMurdo", sort: 999},
  {value: "Antarctica/Palmer", label: "Antarctica/Palmer", text: "Antarctica/Palmer", sort: 999},
  {value: "Antarctica/Rothera", label: "Antarctica/Rothera", text: "Antarctica/Rothera", sort: 999},
  {value: "Antarctica/Syowa", label: "Antarctica/Syowa", text: "Antarctica/Syowa", sort: 999},
  {value: "Antarctica/Troll", label: "Antarctica/Troll", text: "Antarctica/Troll", sort: 999},
  {value: "Antarctica/Vostok", label: "Antarctica/Vostok", text: "Antarctica/Vostok", sort: 999},
  {value: "Arctic/Longyearbyen", label: "Arctic/Longyearbyen", text: "Arctic/Longyearbyen", sort: 999},
  {value: "Asia/Aden", label: "Asia/Aden", text: "Asia/Aden", sort: 999},
  {value: "Asia/Almaty", label: "Asia/Almaty", text: "Asia/Almaty", sort: 999},
  {value: "Asia/Amman", label: "Asia/Amman", text: "Asia/Amman", sort: 999},
  {value: "Asia/Anadyr", label: "Asia/Anadyr", text: "Asia/Anadyr", sort: 999},
  {value: "Asia/Aqtau", label: "Asia/Aqtau", text: "Asia/Aqtau", sort: 999},
  {value: "Asia/Aqtobe", label: "Asia/Aqtobe", text: "Asia/Aqtobe", sort: 999},
  {value: "Asia/Ashgabat", label: "Asia/Ashgabat", text: "Asia/Ashgabat", sort: 999},
  {value: "Asia/Atyrau", label: "Asia/Atyrau", text: "Asia/Atyrau", sort: 999},
  {value: "Asia/Baghdad", label: "Asia/Baghdad", text: "Asia/Baghdad", sort: 999},
  {value: "Asia/Bahrain", label: "Asia/Bahrain", text: "Asia/Bahrain", sort: 999},
  {value: "Asia/Baku", label: "Asia/Baku", text: "Asia/Baku", sort: 999},
  {value: "Asia/Bangkok", label: "Asia/Bangkok", text: "Asia/Bangkok", sort: 999},
  {value: "Asia/Barnaul", label: "Asia/Barnaul", text: "Asia/Barnaul", sort: 999},
  {value: "Asia/Beirut", label: "Asia/Beirut", text: "Asia/Beirut", sort: 999},
  {value: "Asia/Bishkek", label: "Asia/Bishkek", text: "Asia/Bishkek", sort: 999},
  {value: "Asia/Brunei", label: "Asia/Brunei", text: "Asia/Brunei", sort: 999},
  {value: "Asia/Chita", label: "Asia/Chita", text: "Asia/Chita", sort: 999},
  {value: "Asia/Choibalsan", label: "Asia/Choibalsan", text: "Asia/Choibalsan", sort: 999},
  {value: "Asia/Colombo", label: "Asia/Colombo", text: "Asia/Colombo", sort: 999},
  {value: "Asia/Damascus", label: "Asia/Damascus", text: "Asia/Damascus", sort: 999},
  {value: "Asia/Dhaka", label: "Asia/Dhaka", text: "Asia/Dhaka", sort: 999},
  {value: "Asia/Dili", label: "Asia/Dili", text: "Asia/Dili", sort: 999},
  {value: "Asia/Dubai", label: "Asia/Dubai", text: "Asia/Dubai", sort: 999},
  {value: "Asia/Dushanbe", label: "Asia/Dushanbe", text: "Asia/Dushanbe", sort: 999},
  {value: "Asia/Famagusta", label: "Asia/Famagusta", text: "Asia/Famagusta", sort: 999},
  {value: "Asia/Gaza", label: "Asia/Gaza", text: "Asia/Gaza", sort: 999},
  {value: "Asia/Hebron", label: "Asia/Hebron", text: "Asia/Hebron", sort: 999},
  {value: "Asia/Ho_Chi_Minh", label: "Asia/Ho_Chi_Minh", text: "Asia/Ho_Chi_Minh", sort: 999},
  {value: "Asia/Hong_Kong", label: "Asia/Hong_Kong", text: "Asia/Hong_Kong", sort: 999},
  {value: "Asia/Hovd", label: "Asia/Hovd", text: "Asia/Hovd", sort: 999},
  {value: "Asia/Irkutsk", label: "Asia/Irkutsk", text: "Asia/Irkutsk", sort: 999},
  {value: "Asia/Jakarta", label: "Asia/Jakarta", text: "Asia/Jakarta", sort: 999},
  {value: "Asia/Jayapura", label: "Asia/Jayapura", text: "Asia/Jayapura", sort: 999},
  {value: "Asia/Jerusalem", label: "Asia/Jerusalem", text: "Asia/Jerusalem", sort: 999},
  {value: "Asia/Kabul", label: "Asia/Kabul", text: "Asia/Kabul", sort: 999},
  {value: "Asia/Kamchatka", label: "Asia/Kamchatka", text: "Asia/Kamchatka", sort: 999},
  {value: "Asia/Karachi", label: "Asia/Karachi", text: "Asia/Karachi", sort: 999},
  {value: "Asia/Kathmandu", label: "Asia/Kathmandu", text: "Asia/Kathmandu", sort: 999},
  {value: "Asia/Khandyga", label: "Asia/Khandyga", text: "Asia/Khandyga", sort: 999},
  {value: "Asia/Kolkata", label: "Asia/Kolkata", text: "Asia/Kolkata", sort: 999},
  {value: "Asia/Krasnoyarsk", label: "Asia/Krasnoyarsk", text: "Asia/Krasnoyarsk", sort: 999},
  {value: "Asia/Kuala_Lumpur", label: "Asia/Kuala_Lumpur", text: "Asia/Kuala_Lumpur", sort: 999},
  {value: "Asia/Kuching", label: "Asia/Kuching", text: "Asia/Kuching", sort: 999},
  {value: "Asia/Kuwait", label: "Asia/Kuwait", text: "Asia/Kuwait", sort: 999},
  {value: "Asia/Macau", label: "Asia/Macau", text: "Asia/Macau", sort: 999},
  {value: "Asia/Magadan", label: "Asia/Magadan", text: "Asia/Magadan", sort: 999},
  {value: "Asia/Makassar", label: "Asia/Makassar", text: "Asia/Makassar", sort: 999},
  {value: "Asia/Manila", label: "Asia/Manila", text: "Asia/Manila", sort: 999},
  {value: "Asia/Muscat", label: "Asia/Muscat", text: "Asia/Muscat", sort: 999},
  {value: "Asia/Nicosia", label: "Asia/Nicosia", text: "Asia/Nicosia", sort: 999},
  {value: "Asia/Novokuznetsk", label: "Asia/Novokuznetsk", text: "Asia/Novokuznetsk", sort: 999},
  {value: "Asia/Novosibirsk", label: "Asia/Novosibirsk", text: "Asia/Novosibirsk", sort: 999},
  {value: "Asia/Omsk", label: "Asia/Omsk", text: "Asia/Omsk", sort: 999},
  {value: "Asia/Oral", label: "Asia/Oral", text: "Asia/Oral", sort: 999},
  {value: "Asia/Phnom_Penh", label: "Asia/Phnom_Penh", text: "Asia/Phnom_Penh", sort: 999},
  {value: "Asia/Pontianak", label: "Asia/Pontianak", text: "Asia/Pontianak", sort: 999},
  {value: "Asia/Pyongyang", label: "Asia/Pyongyang", text: "Asia/Pyongyang", sort: 999},
  {value: "Asia/Qatar", label: "Asia/Qatar", text: "Asia/Qatar", sort: 999},
  {value: "Asia/Qostanay", label: "Asia/Qostanay", text: "Asia/Qostanay", sort: 999},
  {value: "Asia/Qyzylorda", label: "Asia/Qyzylorda", text: "Asia/Qyzylorda", sort: 999},
  {value: "Asia/Riyadh", label: "Asia/Riyadh", text: "Asia/Riyadh", sort: 999},
  {value: "Asia/Sakhalin", label: "Asia/Sakhalin", text: "Asia/Sakhalin", sort: 999},
  {value: "Asia/Samarkand", label: "Asia/Samarkand", text: "Asia/Samarkand", sort: 999},
  {value: "Asia/Seoul", label: "Asia/Seoul", text: "Asia/Seoul", sort: 999},
  {value: "Asia/Shanghai", label: "Asia/Shanghai", text: "Asia/Shanghai", sort: 999},
  {value: "Asia/Singapore", label: "Asia/Singapore", text: "Asia/Singapore", sort: 999},
  {value: "Asia/Srednekolymsk", label: "Asia/Srednekolymsk", text: "Asia/Srednekolymsk", sort: 999},
  {value: "Asia/Taipei", label: "Asia/Taipei", text: "Asia/Taipei", sort: 999},
  {value: "Asia/Tashkent", label: "Asia/Tashkent", text: "Asia/Tashkent", sort: 999},
  {value: "Asia/Tbilisi", label: "Asia/Tbilisi", text: "Asia/Tbilisi", sort: 999},
  {value: "Asia/Tehran", label: "Asia/Tehran", text: "Asia/Tehran", sort: 999},
  {value: "Asia/Thimphu", label: "Asia/Thimphu", text: "Asia/Thimphu", sort: 999},
  {value: "Asia/Tokyo", label: "Asia/Tokyo", text: "Asia/Tokyo", sort: 999},
  {value: "Asia/Tomsk", label: "Asia/Tomsk", text: "Asia/Tomsk", sort: 999},
  {value: "Asia/Ulaanbaatar", label: "Asia/Ulaanbaatar", text: "Asia/Ulaanbaatar", sort: 999},
  {value: "Asia/Urumqi", label: "Asia/Urumqi", text: "Asia/Urumqi", sort: 999},
  {value: "Asia/Ust-Nera", label: "Asia/Ust-Nera", text: "Asia/Ust-Nera", sort: 999},
  {value: "Asia/Vientiane", label: "Asia/Vientiane", text: "Asia/Vientiane", sort: 999},
  {value: "Asia/Vladivostok", label: "Asia/Vladivostok", text: "Asia/Vladivostok", sort: 999},
  {value: "Asia/Yakutsk", label: "Asia/Yakutsk", text: "Asia/Yakutsk", sort: 999},
  {value: "Asia/Yangon", label: "Asia/Yangon", text: "Asia/Yangon", sort: 999},
  {value: "Asia/Yekaterinburg", label: "Asia/Yekaterinburg", text: "Asia/Yekaterinburg", sort: 999},
  {value: "Asia/Yerevan", label: "Asia/Yerevan", text: "Asia/Yerevan", sort: 999},
  {value: "Atlantic/Azores", label: "Atlantic/Azores", text: "Atlantic/Azores", sort: 999},
  {value: "Atlantic/Bermuda", label: "Atlantic/Bermuda", text: "Atlantic/Bermuda", sort: 999},
  {value: "Atlantic/Canary", label: "Atlantic/Canary", text: "Atlantic/Canary", sort: 999},
  {value: "Atlantic/Cape_Verde", label: "Atlantic/Cape_Verde", text: "Atlantic/Cape_Verde", sort: 999},
  {value: "Atlantic/Faroe", label: "Atlantic/Faroe", text: "Atlantic/Faroe", sort: 999},
  {value: "Atlantic/Madeira", label: "Atlantic/Madeira", text: "Atlantic/Madeira", sort: 999},
  {value: "Atlantic/Reykjavik", label: "Atlantic/Reykjavik", text: "Atlantic/Reykjavik", sort: 999},
  {value: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia", text: "Atlantic/South_Georgia", sort: 999},
  {value: "Atlantic/St_Helena", label: "Atlantic/St_Helena", text: "Atlantic/St_Helena", sort: 999},
  {value: "Atlantic/Stanley", label: "Atlantic/Stanley", text: "Atlantic/Stanley", sort: 999},
  {value: "Australia/Adelaide", label: "Australia/Adelaide", text: "Australia/Adelaide", sort: 999},
  {value: "Australia/Brisbane", label: "Australia/Brisbane", text: "Australia/Brisbane", sort: 999},
  {value: "Australia/Broken_Hill", label: "Australia/Broken_Hill", text: "Australia/Broken_Hill", sort: 999},
  {value: "Australia/Currie", label: "Australia/Currie", text: "Australia/Currie", sort: 999},
  {value: "Australia/Darwin", label: "Australia/Darwin", text: "Australia/Darwin", sort: 999},
  {value: "Australia/Eucla", label: "Australia/Eucla", text: "Australia/Eucla", sort: 999},
  {value: "Australia/Hobart", label: "Australia/Hobart", text: "Australia/Hobart", sort: 999},
  {value: "Australia/Lindeman", label: "Australia/Lindeman", text: "Australia/Lindeman", sort: 999},
  {value: "Australia/Lord_Howe", label: "Australia/Lord_Howe", text: "Australia/Lord_Howe", sort: 999},
  {value: "Australia/Melbourne", label: "Australia/Melbourne", text: "Australia/Melbourne", sort: 999},
  {value: "Australia/Perth", label: "Australia/Perth", text: "Australia/Perth", sort: 999},
  {value: "Australia/Sydney", label: "Australia/Sydney", text: "Australia/Sydney", sort: 999},
  {value: "Canada/Atlantic", label: "Canada/Atlantic", text: "Canada/Atlantic", sort: 1},
  {value: "Canada/Central", label: "Canada/Central", text: "Canada/Central", sort: 1},
  {value: "Canada/Eastern", label: "Canada/Eastern", text: "Canada/Eastern", sort: 1},
  {value: "Canada/Mountain", label: "Canada/Mountain", text: "Canada/Mountain", sort: 1},
  {value: "Canada/Newfoundland", label: "Canada/Newfoundland", text: "Canada/Newfoundland", sort: 1},
  {value: "Canada/Pacific", label: "Canada/Pacific", text: "Canada/Pacific", sort: 1},
  {value: "Europe/Amsterdam", label: "Europe/Amsterdam", text: "Europe/Amsterdam", sort: 999},
  {value: "Europe/Andorra", label: "Europe/Andorra", text: "Europe/Andorra", sort: 999},
  {value: "Europe/Astrakhan", label: "Europe/Astrakhan", text: "Europe/Astrakhan", sort: 999},
  {value: "Europe/Athens", label: "Europe/Athens", text: "Europe/Athens", sort: 999},
  {value: "Europe/Belgrade", label: "Europe/Belgrade", text: "Europe/Belgrade", sort: 999},
  {value: "Europe/Berlin", label: "Europe/Berlin", text: "Europe/Berlin", sort: 999},
  {value: "Europe/Bratislava", label: "Europe/Bratislava", text: "Europe/Bratislava", sort: 999},
  {value: "Europe/Brussels", label: "Europe/Brussels", text: "Europe/Brussels", sort: 999},
  {value: "Europe/Bucharest", label: "Europe/Bucharest", text: "Europe/Bucharest", sort: 999},
  {value: "Europe/Budapest", label: "Europe/Budapest", text: "Europe/Budapest", sort: 999},
  {value: "Europe/Busingen", label: "Europe/Busingen", text: "Europe/Busingen", sort: 999},
  {value: "Europe/Chisinau", label: "Europe/Chisinau", text: "Europe/Chisinau", sort: 999},
  {value: "Europe/Copenhagen", label: "Europe/Copenhagen", text: "Europe/Copenhagen", sort: 999},
  {value: "Europe/Dublin", label: "Europe/Dublin", text: "Europe/Dublin", sort: 999},
  {value: "Europe/Gibraltar", label: "Europe/Gibraltar", text: "Europe/Gibraltar", sort: 999},
  {value: "Europe/Guernsey", label: "Europe/Guernsey", text: "Europe/Guernsey", sort: 999},
  {value: "Europe/Helsinki", label: "Europe/Helsinki", text: "Europe/Helsinki", sort: 999},
  {value: "Europe/Isle_of_Man", label: "Europe/Isle_of_Man", text: "Europe/Isle_of_Man", sort: 999},
  {value: "Europe/Istanbul", label: "Europe/Istanbul", text: "Europe/Istanbul", sort: 999},
  {value: "Europe/Jersey", label: "Europe/Jersey", text: "Europe/Jersey", sort: 999},
  {value: "Europe/Kaliningrad", label: "Europe/Kaliningrad", text: "Europe/Kaliningrad", sort: 999},
  {value: "Europe/Kiev", label: "Europe/Kiev", text: "Europe/Kiev", sort: 999},
  {value: "Europe/Kirov", label: "Europe/Kirov", text: "Europe/Kirov", sort: 999},
  {value: "Europe/Lisbon", label: "Europe/Lisbon", text: "Europe/Lisbon", sort: 999},
  {value: "Europe/Ljubljana", label: "Europe/Ljubljana", text: "Europe/Ljubljana", sort: 999},
  {value: "Europe/London", label: "Europe/London", text: "Europe/London", sort: 999},
  {value: "Europe/Luxembourg", label: "Europe/Luxembourg", text: "Europe/Luxembourg", sort: 999},
  {value: "Europe/Madrid", label: "Europe/Madrid", text: "Europe/Madrid", sort: 999},
  {value: "Europe/Malta", label: "Europe/Malta", text: "Europe/Malta", sort: 999},
  {value: "Europe/Mariehamn", label: "Europe/Mariehamn", text: "Europe/Mariehamn", sort: 999},
  {value: "Europe/Minsk", label: "Europe/Minsk", text: "Europe/Minsk", sort: 999},
  {value: "Europe/Monaco", label: "Europe/Monaco", text: "Europe/Monaco", sort: 999},
  {value: "Europe/Moscow", label: "Europe/Moscow", text: "Europe/Moscow", sort: 999},
  {value: "Europe/Oslo", label: "Europe/Oslo", text: "Europe/Oslo", sort: 999},
  {value: "Europe/Paris", label: "Europe/Paris", text: "Europe/Paris", sort: 999},
  {value: "Europe/Podgorica", label: "Europe/Podgorica", text: "Europe/Podgorica", sort: 999},
  {value: "Europe/Prague", label: "Europe/Prague", text: "Europe/Prague", sort: 999},
  {value: "Europe/Riga", label: "Europe/Riga", text: "Europe/Riga", sort: 999},
  {value: "Europe/Rome", label: "Europe/Rome", text: "Europe/Rome", sort: 999},
  {value: "Europe/Samara", label: "Europe/Samara", text: "Europe/Samara", sort: 999},
  {value: "Europe/San_Marino", label: "Europe/San_Marino", text: "Europe/San_Marino", sort: 999},
  {value: "Europe/Sarajevo", label: "Europe/Sarajevo", text: "Europe/Sarajevo", sort: 999},
  {value: "Europe/Saratov", label: "Europe/Saratov", text: "Europe/Saratov", sort: 999},
  {value: "Europe/Simferopol", label: "Europe/Simferopol", text: "Europe/Simferopol", sort: 999},
  {value: "Europe/Skopje", label: "Europe/Skopje", text: "Europe/Skopje", sort: 999},
  {value: "Europe/Sofia", label: "Europe/Sofia", text: "Europe/Sofia", sort: 999},
  {value: "Europe/Stockholm", label: "Europe/Stockholm", text: "Europe/Stockholm", sort: 999},
  {value: "Europe/Tallinn", label: "Europe/Tallinn", text: "Europe/Tallinn", sort: 999},
  {value: "Europe/Tirane", label: "Europe/Tirane", text: "Europe/Tirane", sort: 999},
  {value: "Europe/Ulyanovsk", label: "Europe/Ulyanovsk", text: "Europe/Ulyanovsk", sort: 999},
  {value: "Europe/Uzhgorod", label: "Europe/Uzhgorod", text: "Europe/Uzhgorod", sort: 999},
  {value: "Europe/Vaduz", label: "Europe/Vaduz", text: "Europe/Vaduz", sort: 999},
  {value: "Europe/Vatican", label: "Europe/Vatican", text: "Europe/Vatican", sort: 999},
  {value: "Europe/Vienna", label: "Europe/Vienna", text: "Europe/Vienna", sort: 999},
  {value: "Europe/Vilnius", label: "Europe/Vilnius", text: "Europe/Vilnius", sort: 999},
  {value: "Europe/Volgograd", label: "Europe/Volgograd", text: "Europe/Volgograd", sort: 999},
  {value: "Europe/Warsaw", label: "Europe/Warsaw", text: "Europe/Warsaw", sort: 999},
  {value: "Europe/Zagreb", label: "Europe/Zagreb", text: "Europe/Zagreb", sort: 999},
  {value: "Europe/Zaporozhye", label: "Europe/Zaporozhye", text: "Europe/Zaporozhye", sort: 999},
  {value: "Europe/Zurich", label: "Europe/Zurich", text: "Europe/Zurich", sort: 999},
  {value: "GMT", label: "GMT",text: "GMT" , sort: 999},
  {value: "Indian/Antananarivo", label: "Indian/Antananarivo",text: "Indian/Antananarivo" , sort: 999},
  {value: "Indian/Chagos", label: "Indian/Chagos", text: "Indian/Chagos", sort: 999},
  {value: "Indian/Christmas", label: "Indian/Christmas", text: "Indian/Christmas", sort: 999},
  {value: "Indian/Cocos", label: "Indian/Cocos", text: "Indian/Cocos", sort: 999},
  {value: "Indian/Comoro", label: "Indian/Comoro", text: "Indian/Comoro", sort: 999},
  {value: "Indian/Kerguelen", label: "Indian/Kerguelen", text: "Indian/Kerguelen", sort: 999},
  {value: "Indian/Mahe", label: "Indian/Mahe", text: "Indian/Mahe", sort: 999},
  {value: "Indian/Maldives", label: "Indian/Maldives", text: "Indian/Maldives", sort: 999},
  {value: "Indian/Mauritius", label: "Indian/Mauritius", text: "Indian/Mauritius", sort: 999},
  {value: "Indian/Mayotte", label: "Indian/Mayotte", text: "Indian/Mayotte", sort: 999},
  {value: "Indian/Reunion", label: "Indian/Reunion", text: "Indian/Reunion", sort: 999},
  {value: "Pacific/Apia", label: "Pacific/Apia", text: "Pacific/Apia", sort: 999},
  {value: "Pacific/Auckland", label: "Pacific/Auckland", text: "Pacific/Auckland", sort: 999},
  {value: "Pacific/Bougainville", label: "Pacific/Bougainville", text: "Pacific/Bougainville", sort: 999},
  {value: "Pacific/Chatham", label: "Pacific/Chatham", text: "Pacific/Chatham", sort: 999},
  {value: "Pacific/Chuuk", label: "Pacific/Chuuk", text: "Pacific/Chuuk", sort: 999},
  {value: "Pacific/Easter", label: "Pacific/Easter", text: "Pacific/Easter", sort: 999},
  {value: "Pacific/Efate", label: "Pacific/Efate", text: "Pacific/Efate", sort: 999},
  {value: "Pacific/Enderbury", label: "Pacific/Enderbury", text: "Pacific/Enderbury", sort: 999},
  {value: "Pacific/Fakaofo", label: "Pacific/Fakaofo", text: "Pacific/Fakaofo", sort: 999},
  {value: "Pacific/Fiji", label: "Pacific/Fiji", text: "Pacific/Fiji", sort: 999},
  {value: "Pacific/Funafuti", label: "Pacific/Funafuti", text: "Pacific/Funafuti", sort: 999},
  {value: "Pacific/Galapagos", label: "Pacific/Galapagos", text: "Pacific/Galapagos", sort: 999},
  {value: "Pacific/Gambier", label: "Pacific/Gambier", text: "Pacific/Gambier", sort: 999},
  {value: "Pacific/Guadalcanal", label: "Pacific/Guadalcanal", text: "Pacific/Guadalcanal", sort: 999},
  {value: "Pacific/Guam", label: "Pacific/Guam", text: "Pacific/Guam", sort: 999},
  {value: "Pacific/Honolulu", label: "Pacific/Honolulu", text: "Pacific/Honolulu", sort: 999},
  {value: "Pacific/Kiritimati", label: "Pacific/Kiritimati", text: "Pacific/Kiritimati", sort: 999},
  {value: "Pacific/Kosrae", label: "Pacific/Kosrae", text: "Pacific/Kosrae", sort: 999},
  {value: "Pacific/Kwajalein", label: "Pacific/Kwajalein", text: "Pacific/Kwajalein", sort: 999},
  {value: "Pacific/Majuro", label: "Pacific/Majuro", text: "Pacific/Majuro", sort: 999},
  {value: "Pacific/Marquesas", label: "Pacific/Marquesas", text: "Pacific/Marquesas", sort: 999},
  {value: "Pacific/Midway", label: "Pacific/Midway", text: "Pacific/Midway", sort: 999},
  {value: "Pacific/Nauru", label: "Pacific/Nauru", text: "Pacific/Nauru", sort: 999},
  {value: "Pacific/Niue", label: "Pacific/Niue", text: "Pacific/Niue", sort: 999},
  {value: "Pacific/Norfolk", label: "Pacific/Norfolk", text: "Pacific/Norfolk", sort: 999},
  {value: "Pacific/Noumea", label: "Pacific/Noumea", text: "Pacific/Noumea", sort: 999},
  {value: "Pacific/Pago_Pago", label: "Pacific/Pago_Pago", text: "Pacific/Pago_Pago", sort: 999},
  {value: "Pacific/Palau", label: "Pacific/Palau", text: "Pacific/Palau", sort: 999},
  {value: "Pacific/Pitcairn", label: "Pacific/Pitcairn", text: "Pacific/Pitcairn", sort: 999},
  {value: "Pacific/Pohnpei", label: "Pacific/Pohnpei", text: "Pacific/Pohnpei", sort: 999},
  {value: "Pacific/Port_Moresby", label: "Pacific/Port_Moresby", text: "Pacific/Port_Moresby", sort: 999},
  {value: "Pacific/Rarotonga", label: "Pacific/Rarotonga", text: "Pacific/Rarotonga", sort: 999},
  {value: "Pacific/Saipan", label: "Pacific/Saipan", text: "Pacific/Saipan", sort: 999},
  {value: "Pacific/Tahiti", label: "Pacific/Tahiti", text: "Pacific/Tahiti", sort: 999},
  {value: "Pacific/Tarawa", label: "Pacific/Tarawa", text: "Pacific/Tarawa", sort: 999},
  {value: "Pacific/Tongatapu", label: "Pacific/Tongatapu", text: "Pacific/Tongatapu", sort: 999},
  {value: "Pacific/Wake", label: "Pacific/Wake", text: "Pacific/Wake", sort: 999},
  {value: "Pacific/Wallis", label: "Pacific/Wallis", text: "Pacific/Wallis", sort: 999},
  {value: "US/Alaska", label: "US/Alaska", text: "US/Alaska", sort: 2},
  {value: "US/Arizona", label: "US/Arizona", text: "US/Arizona", sort: 2},
  {value: "US/Central", label: "US/Central", text: "US/Central", sort: 2},
  {value: "US/Eastern", label: "US/Eastern", text: "US/Eastern", sort: 2},
  {value: "US/Hawaii", label: "US/Hawaii", text: "US/Hawaii", sort: 2},
  {value: "US/Mountain", label: "US/Mountain", text: "US/Mountain", sort: 2},
  {value: "US/Pacific", label: "US/Pacific", text: "US/Pacific", sort: 2},
  {value: "UTC", label: "UTC", text: "UTC", sort: 3}
]

export function SortedTimezone() {
  return  CommonTimezones.sort((a, b) => a.sort - b.sort)
} 
