
const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const get_pillar_averages = (data, questions) => {
  let _data = [];
  questions.dimensions.map(d=>{
    let d_data = []
    d.factors.map(f=>{
      d_data.push(average(data.questions.filter(x=>x.id==d.id-1 && x.factor==f.id-1).map(q=>check_reverse_score(q,questions))))
    })
    
    _data.push(average(d_data))
  })

  return _data
}

const get_heat_data = (structure, anchor, filtered_data, rule, questions,vertical,horizontal,subfactor=[]) => {
  //get the list of the categories options and map through
  let anchor_list = structure.categories.find((f) => f.id === horizontal.id).options;
  let primary_list = structure.categories.find((f) => f.id === vertical.id).options;
   let _data = {};


  if(subfactor.length>0 && vertical.priority=='primary'){
  		subfactor.map((item,i)=>{
  			 _data[item.sub] = {};
  			  let _filtered_data = filtered_data
		      .filter((f) => f.questions.length > 0)
		      .filter(
		        (f) => f.categories.find((c) => c.id==vertical.id).response === item.primary &&
		        	   f.categories.find((c) => c.id==vertical.id).subfactor === item.sub
		      )
		      .filter((f) => f.questions.length > 0);

		       if (_filtered_data.length > rule) {
			      _filtered_data.map((r) => {
			        let summary = condensed(r, questions);
			        try {
			          let secondary = r.categories.find(
			              (f) => f.id === horizontal.id
			            ).response;

			          if (!(secondary in _data[item.sub])) {
			            _data[item.sub][secondary] = [];
			          }
			          let x = [0, 0];
			          let y = [0, 0];
			          if (r.questions.length > 0) {
			          }
			          r.questions.map((d) => {
			            let resp = check_reverse_score(d, questions);

			            if (d.id === 0) {
			              x[0] += resp;
			              x[1] += 1;
			            } else {
			              y[0] += resp;
			              y[1] += 1;
			            }
			          });
			         
                let pillar_averages = get_pillar_averages(r, questions);
			          _data[item.sub][secondary].push(pillar_averages);
			        } catch (err) {
			          console.log(err);
			        }
			      });
			    }
  		})


  		    let outcome  = Object.keys(_data).filter(f=>Object.keys(_data[f]).length>0).map(item=>{
  		    let parent = subfactor.find(f=>f.sub==item).primary
		    let title = structure.categories.find(f=>f.priority=='primary').options.find(f=>f.id==parent).subfactors.find(f=>f.id==item).name
		    let role_data = Object.keys(_data[item]).filter(f=>_data[item][f]).map(k=>{
		      return {id:k,score:getScore(_data[item][k],rule)}
		    })

		    role_data = role_data.filter(f=>f.score!=0)
		    return {title,role_data,id:item,parent}
		  })

  		return outcome
  }else{

  	  primary_list.map((item, i) => {
        _data[item.id] = {};

	    let _filtered_data = filtered_data
	      .filter((f) => f.questions.length > 0)
	      .filter(
	        (f) => f.categories.find((c) => c.id==vertical.id)?.response === item.id
	      )
	      .filter((f) => f.questions.length > 0);

	    if (_filtered_data.length > rule) {
	      _filtered_data.map((r) => {
	        let summary = condensed(r, questions);
	        try {
	          let secondary = r.categories.find(
	              (f) => f.id === horizontal.id
	            ).response;

	          if (!(secondary in _data[item.id])) {
	            _data[item.id][secondary] = [];
	          }
	          let x = [0, 0];
	          let y = [0, 0];
	          if (r.questions.length > 0) {
	          }
	          r.questions.map((d) => {
	            let resp = check_reverse_score(d, questions);

	            if (d.id === 0) {
	              x[0] += resp;
	              x[1] += 1;
	            } else {
	              y[0] += resp;
	              y[1] += 1;
	            }
	          });

            let pillar_averages = get_pillar_averages(r, questions);
	         
	          _data[item.id][secondary].push(pillar_averages);
	        } catch (err) {
	          console.log(err);
	        }
	      });
	    }
	  });



	   let outcome  = Object.keys(_data).filter(f=>Object.keys(_data[f]).length>0).map(item=>{
	    let title = primary_list.find(f=>f.id==item).name
	    let role_data = Object.keys(_data[item]).filter(f=>_data[item][f]).map(k=>{
	      return {id:k,score:getScore(_data[item][k],rule)}
	    })
		let parent = vertical.id
	    role_data = role_data.filter(f=>f.score!=0)
	    return {title,role_data,id:item,parent}
	  })


	  return outcome

  }


 
}

const get_heat_data_Q = (structure, anchor, filtered_data, rule, questions,vertical,horizontal,subfactor=[]) => {
  //get the list of the categories options and map through
  let primary_list = structure.categories.find((f) => f.id === vertical.id).options;
  let parent_list = structure.categories.find((f) => f.id === vertical.id)

  let _data = {};

  if(subfactor.length>0 && vertical.priority=='primary'){
  	subfactor.map((item,i)=>{
  			 _data[item.sub] = {};
  			  let _filtered_data = filtered_data
		      .filter((f) => f.questions.length > 0)
		      .filter(
		        (f) => f.categories.find((c) => c.id==vertical.id).response === item.primary &&
		        	   f.categories.find((c) => c.id==vertical.id).subfactor === item.sub
		      )
		      .filter((f) => f.questions.length > 0);

		       if (_filtered_data.length > rule) {

			    	horizontal.factors.map(((o,i)=>{
			    		let idx = i
			    		let r = _filtered_data
			    					.map(q=>q.questions)
			    					.map(q=>q.filter(f=>((f.id==horizontal.id-1) && (f.factor==idx))))
			    					.map(q=>(q.map(l=>{
			    						 return check_reverse_score(l, questions);
			    						})))
			    		_data[item.sub][idx] = average(r.flat())
    
			    	}))
			    }
  		})

  		 let outcome  = Object.keys(_data).filter(f=>Object.keys(_data[f]).length>0).map(item=>{
		   let parent = subfactor.find(f=>f.sub==item).primary
		    let title = structure.categories.find(f=>f.priority=='primary').options.find(f=>f.id==parent).subfactors.find(f=>f.id==item).name
		    let role_data = Object.keys(_data[item]).filter(f=>_data[item][f]).map(k=>{
		      return {id:k,score:_data[item][k]}
		    })
		    return {title,role_data,id:item,parent:parent_list.id,subOption:parent}
		  })


  		return outcome



  }else{
  		  primary_list.map((item, i) => {
		    _data[item.id] = {};

		    let _filtered_data = filtered_data
		      .filter((f) => f.questions.length > 0)
		      .filter(
		        (f) => f.categories.find((c) => c.id==vertical.id)?.response === item.id
		      )
		      .filter((f) => f.questions.length > 0);

		    if (_filtered_data.length > rule) {

		    	horizontal.factors.map(((o,i)=>{
		    		let idx = i

		    		let r = _filtered_data
		    					.map(q=>q.questions)
		    					.map(q=>q.filter(f=>((f.id==horizontal.id-1) && (f.factor==idx))))
		    					.map(q=>(q.map(l=>{
		    						 return check_reverse_score(l, questions);
		    						})))
            
		    		_data[item.id][idx] = average(r.flat())

		    	}))

		    }
		  });


		  let outcome  = Object.keys(_data).filter(f=>Object.keys(_data[f]).length>0).map(item=>{
		    let title = primary_list.find(f=>f.id==item).name
		    let role_data = Object.keys(_data[item]).filter(f=>_data[item][f]).map(k=>{
		      return {id:k,score:_data[item][k]}
		    })
		    return {title,role_data,id:item,parent:parent_list.id}
		  })

		  return outcome
  }


}

const check_all_selected = (structure, item, filters) => {
	let filter_length = filters[item.id]?.length;
	let structure_length = structure.categories.find((f) => f.id == item.id)
	  ?.options.length;
	return filter_length == structure_length;
  };

export const calculate_heat_data = (
  data,
  nav,
  structure,
  anchor,
  rule,
  questions,
  horizontal,
  vertical
) => {
  let primary = [];
  let subfactor = [];
  let filters = {};

  //find the primary nav structure
  let prime = nav[Object.keys(nav).find((f) => nav[f].priority == "primary")];

  Object.keys(prime)
    .filter((f) => f != "selected" || f != "priority")
    .map((item, i) => {
      let items = prime[item];

      if (items.selected) {
        primary.push(item);
      }
      let subs = [false];
      if (items.subfactors) {
        if (Object.keys(items.subfactors).length > 0) {
          subs = Object.keys(items.subfactors).map(
            (x) => items.subfactors[x].selected
          );
        }
      }
      if (subs.includes(true)) {
        Object.keys(items.subfactors).map((s) => {
          let sub = items.subfactors[s];
          if (sub.selected) {
            subfactor.push({
              primary: item,
              sub: s,
            });
          }
        });
      }
    });

  Object.keys(nav)
    .filter((f) => f != 0)
    .map((i) => {
      filters[i] = Object.keys(nav[i])
        .filter((f) => f != "selected")
        .filter((f) => nav[i][f].selected)
        .map((x) => x);
    });

//   let filter_data = data[0]?.responses?.filter((x) => {
//     let checked = true;
//     try {
//       x.categories
//         .filter((d) => d.priority != "primary")
//         .map((item, i) => {
//         	if(item.response!==null && item.response!=''){
// 							checked = checked ? filters[item.id].includes(item.response) : false;
//         	}
          
//         });
//     } catch (err) {
//       checked = false;
//     }

//     return checked;
//   });

  let filter_data = data[0]?.responses?.filter((x) => {
        let checked = true;
        if (x?.categories) {
          try {
            x.categories
              .filter((d) => d.priority != "primary")
              .map((item, i) => {
                let all_selected = check_all_selected(structure, item, filters);
                if (
                  (item.response !== null && item.response !== "") &&
                  !all_selected
                ) {
                  checked = checked
                    ? filters[item.id].includes(item.response)
                    : false;
                }
              });
          } catch (err) {
            checked = false;
          }

          return checked;
        }
      });





  // if a subfactor is present, then return the data for that or those subfactors
  if (subfactor.length > 0 && filter_data.length > 0) {
    let filtered_data = filter_data.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(f.categories.find((f) => f.priority == "primary").subfactor);
    });

    	if(horizontal.priority == 'questions'){
  	   return get_heat_data_Q(
        structure,
        anchor,
        filtered_data,
        rule,
        questions,
        vertical,
        horizontal,
        subfactor
      ); 
  	}

     return get_heat_data(
        structure,
        anchor,
        filtered_data,
        rule,
        questions,
         vertical,
        horizontal,
        subfactor
      );

  }

  // if no subfactor is present, then calculate the data for the primary factor
  //start by filtering the data to only the primary factors selected
  let filtered_data = filter_data.filter((f) => {
    return primary.includes(
      f.categories.find((f) => f.priority == "primary").response
    );
  });


  	if(horizontal.priority == 'questions'){
  	   return get_heat_data_Q(
        structure,
        anchor,
        filtered_data,
        rule,
        questions,
        vertical,
        horizontal
      ); 
  	}

     return get_heat_data(
        structure,
        anchor,
        filtered_data,
        rule,
        questions,
        vertical,
        horizontal
      );

};


const condensed = (r, questions) => {

  let summary = [];
  r.questions.map((item) => {
    let exists = summary.findIndex(
      (f) => f.id == item.id && f.factor == item.factor
    );
    let resp = check_reverse_score(item, questions);
    if (exists >= 0) {
      let data = summary[exists];

      data.response.push(resp);
      summary[exists] = data;
    } else {
      let data = {
        id: item.id,
        factor: item.factor,
        response: [resp],
      };
      summary.push(data);
    }
  });

  return summary;
};

const check_reverse_score = (resp, questions) => {

  let reverse =
    questions.dimensions[resp.id].factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const getScore = (arr,rule) =>{

		if(arr.length<=rule){
			return 0
		}

  return average(arr.flat(2))
}

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += !isNaN(item)? item : 0;
    n += !isNaN(item)?1:0;
  });
  return total / n;
};