import {
  ADD_EMPLOYEE_RECORD_LIST,
  CLEAR_ALL,
  CLEAR_EMPLOYEE_RECORD_LIST,
  DELETE_EMPLOYEE_RECORD_LIST,
  FETCH_EMPLOYEE_RECORD_LIST,
  GET_EMPLOYEE_RECORD_LIST,
  UPDATE_EMPLOYEE_RECORD_LIST,
} from "common/constants/actions";

const initialState = {
  employee_record_list: [],
  pending: false,
  created: false,
};

export default function EmployeeCategories(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_RECORD_LIST:
      return {
        ...state,
        employee_record_list: action.payload,
        pending: false,
        created: false,
      };

    case FETCH_EMPLOYEE_RECORD_LIST:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        created: false,
      };

    case UPDATE_EMPLOYEE_RECORD_LIST:
      if (
        state.employee_record_list.filter(
          (an) => an.id === action.payload.id,
        )[0]
      ) {
        state = {
          ...state,
          employee_record_list: state.employee_record_list.filter(
            (rw) => rw.id !== action.payload.id,
          ),
        };
      }
      return {
        ...state,
        created: false,
        employee_record_list: [...state.employee_record_list, action.payload],
      };

    case DELETE_EMPLOYEE_RECORD_LIST:
      // console.log(action.payload)
      return {
        ...state,
        employee_record_list: state.employee_record_list.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_EMPLOYEE_RECORD_LIST:
      // const newState = state.employee_record
      // newState.push(action.payload)
      return {
        ...state,
        created: action.payload,
        employee_record_list: [...state.employee_record_list, action.payload],
      };

    case CLEAR_EMPLOYEE_RECORD_LIST:
      return {
        ...state,
        employee_record_list: [],
        created: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        employee_record_list: [],
        created: false,
      };

    default:
      return state;
  }
}
