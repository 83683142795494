import React from "react";

export function TeamIcon(fill: string) {
  return (
    <svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7171 10.3764L10.7169 10.3762C10.5416 10.1595 10.5745 9.84114 10.792 9.66537M10.7171 10.3764L11.4276 10.451C12.0498 9.94744 12.8364 9.66987 13.6422 9.66987C15.5721 9.66987 17.1394 11.2212 17.1394 13.1246V14.2869C17.1394 14.317 17.1128 14.3489 17.0713 14.3489H12.6139C12.335 14.3489 12.1085 14.5753 12.1085 14.8542C12.1085 15.1333 12.335 15.3595 12.6139 15.3595H17.0712C17.6653 15.3595 18.1499 14.8793 18.1499 14.2869V13.1246C18.1499 10.6608 16.1265 8.6592 13.6422 8.6592C12.6059 8.6592 11.5939 9.01648 10.792 9.66537M10.7171 10.3764C10.8929 10.5931 11.2109 10.6266 11.4276 10.4511L10.7171 10.3764ZM10.792 9.66537L10.8863 9.78203M10.792 9.66537C10.792 9.66539 10.792 9.66541 10.7919 9.66543L10.8863 9.78203M10.8863 9.78203C10.7334 9.90557 10.7102 10.1294 10.8336 10.2819L10.8863 9.78203Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M10.9367 5.75981C10.9367 7.24012 12.1515 8.44191 13.6422 8.44191C15.1329 8.44191 16.3481 7.24033 16.3481 5.75981C16.3481 4.27931 15.1331 3.07754 13.6422 3.07754C12.1514 3.07754 10.9367 4.27951 10.9367 5.75981ZM11.9473 5.75981C11.9473 4.84003 12.706 4.08821 13.6422 4.08821C14.5786 4.08821 15.3374 4.84006 15.3374 5.75981C15.3374 6.67955 14.5788 7.43124 13.6422 7.43124C12.706 7.43124 11.9473 6.67957 11.9473 5.75981Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M2.16195 16.7849H11.8073C12.5299 16.7849 13.1193 16.2014 13.1193 15.4814V13.8467C13.1193 10.4954 10.3662 7.77175 6.98463 7.77175C3.60301 7.77175 0.85 10.4954 0.85 13.8467V15.4814C0.85 16.2014 1.4394 16.7849 2.16195 16.7849ZM1.86067 13.8469C1.86067 11.0562 4.15778 8.78259 6.98463 8.78259C9.81148 8.78259 12.1086 11.0562 12.1084 13.8469V15.4816C12.1084 15.6402 11.9758 15.7744 11.8071 15.7744H2.16195C1.99328 15.7744 1.86067 15.6402 1.86067 15.4816V13.8469Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M3.42715 4.253C3.42715 6.1312 4.96903 7.65635 6.86121 7.65635C8.75354 7.65635 10.2954 6.13138 10.2954 4.25317C10.2954 2.37515 8.75372 0.85 6.86121 0.85C4.96887 0.85 3.42715 2.37497 3.42715 4.253ZM4.43782 4.25317C4.43782 2.93582 5.52343 1.86067 6.86121 1.86067C8.19916 1.86067 9.28477 2.93583 9.28477 4.25317C9.28477 5.5707 8.19916 6.64586 6.86121 6.64586C5.52344 6.64586 4.43782 5.57071 4.43782 4.25317Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
    </svg>
  );
}
export default TeamIcon;
