export function validationReducer(tasks, action) {
    switch (action.type) {
      case 'add': {
        let setTasks = new Set(tasks);
        setTasks.add(action.payload)
        const taskList = Array.from(setTasks);
        return taskList;
      }
    //   case 'changed': {
    //     return tasks.map(t => {
    //       if (t === action) {
    //         return action;
    //       } else {
    //         return t;
    //       }
    //     });
    //   }
      case 'delete': {
        return tasks.filter(t => t !== action.payload);
      }
      default: {
        throw Error('Unknown action: ' + action.type);
      }
    }
  }
  

 export const initialTasks = [];