import styled from "styled-components";

export const PageContent = styled.div`
  font-family: "Poppins";
  font-style: normal;
  padding-left: 35px;
  justify-content: space-between;
`;

export const PageSection = styled.div`
  padding-left: 35px;
  padding-bottom: 20px;
`;

export const Title = styled.div`
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  color: #414141;
  padding-bottom: 35px;
`;

export const Subtitle = styled.div`
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  align-self: center;
  color: #414141;
`;
