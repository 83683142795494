import React from "react";

function HeartIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.332 21L10.882 19.7052C5.73203 15.1243 2.33203 12.103 2.33203 8.3951C2.33203 5.37384 4.75203 3 7.83203 3C9.57203 3 11.242 3.79455 12.332 5.05014C13.422 3.79455 15.092 3 16.832 3C19.912 3 22.332 5.37384 22.332 8.3951C22.332 12.103 18.932 15.1243 13.782 19.715L12.332 21Z"
        stroke="#2D70E2"
      />
    </svg>
  );
}

export default HeartIcon;
