import {
  ADD_DEBRIEF_TOKEN,
  CLEAR_ALL,
  CLEAR_DEBRIEF_TOKEN,
  DELETE_DEBRIEF_TOKEN,
  EDIT_DEBRIEF_TOKEN,
  FETCH_DEBRIEF_TOKEN,
  GET_DEBRIEF_TOKENS,
} from "common/constants/actions";

const initialState = {
  debrief_token: [],
  pending: false,
  response: null,
  created: [],
};

export default function DebriefToken(state = initialState, action) {
  switch (action.type) {
    case GET_DEBRIEF_TOKENS:
      return {
        ...state,
        debrief_token: action.payload,
        pending: false,
      };

    case FETCH_DEBRIEF_TOKEN:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case EDIT_DEBRIEF_TOKEN:
      if (state.debrief_token.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          debrief_token: state.debrief_token.filter(
            (rw) => rw.id !== action.payload.id,
          ),
        };
      }
      return {
        ...state,
        debrief_token: [...state.debrief_token, action.payload],
      };

    case DELETE_DEBRIEF_TOKEN:
      return {
        ...state,
        debrief_token: state.debrief_token.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_DEBRIEF_TOKEN:
      return {
        ...state,
        created: action.payload,
        debrief_token: [...state.debrief_token, action.payload],
        response: action.payload.response,
      };

    case CLEAR_DEBRIEF_TOKEN:
      return {
        ...state,
        debrief_token: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_token: [],
      };

    default:
      return state;
  }
}
