import { all, put, takeLatest } from "redux-saga/effects";

import {
  ALL_ERRORS,
  GET_EMOTION_DEFINITIONS,
  LOAD_EMOTION_DEFINITIONS,
} from "common/constants/actions";

// import * as api from "common/constants/api";
import eq_data from "common/data/emotion_definitions.json";

function* getEmotionDefinitions(action) {
  try {
    // const json = yield call(api.GET_EMOTION_DEFINITIONS, action.payload);
    // yield put({ type: GET_EMOTION_DEFINITIONS, payload: json.data });

    yield put({ type: GET_EMOTION_DEFINITIONS, payload: eq_data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadEmotionDefinitions() {
  yield takeLatest(LOAD_EMOTION_DEFINITIONS, getEmotionDefinitions);
}

export default function* index() {
  yield all([loadEmotionDefinitions()]);
}
