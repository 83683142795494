import {
  ADD_FILE_UPLOAD,
  CLEAR_ALL,
  CLEAR_CREATED_FILE_UPLOAD,
  CLEAR_FILE_UPLOAD,
  DELETE_FILE_UPLOAD,
  EDIT_FILE_UPLOAD,
  FETCH_FILE_UPLOAD,
  GET_FILE_UPLOADS,
  START_FILE_UPLOAD,
} from "common/constants/actions";

const initialState = {
  file_uploads: [],
  created: [],
  pending: false,
};

export default function FileUpload(state = initialState, action) {
  switch (action.type) {
    case START_FILE_UPLOAD:
      return {
        ...state,
        pending: true,
      };
    case GET_FILE_UPLOADS:
      return {
        ...state,
        file_uploads: action.payload,
        pending: false,
      };

    case FETCH_FILE_UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case CLEAR_CREATED_FILE_UPLOAD:
      return {
        ...state,
        created: [],
        pending: false,
      };

    case EDIT_FILE_UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case DELETE_FILE_UPLOAD:
      return {
        ...state,
        file_uploads: state.file_uploads.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_FILE_UPLOAD:
      return {
        ...state,
        created: action.payload,
        file_uploads: [...state.file_uploads, action.payload],
      };

    case CLEAR_FILE_UPLOAD:
      return {
        ...state,
        file_uploads: [],
        created: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        file_uploads: [],
        created: [],
        pending: false,
      };

    default:
      return state;
  }
}
