import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";

import { rootSaga } from "sagas/index";

// import LogRocket from 'logrocket';

// declare global {
//   interface Window {
//       __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
//   }
// }
// to reduce Excessive use of memory and CPU
// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
// process.env.NODE_ENV
const initialiseSagaMiddleware = createSagaMiddleware();
const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionSanitizer: (action) => {
          const message = action.payload && action.payload.message;
          return action.type.endsWith("FAILURE")
            ? {
                ...action,
                payload: !!message
                  ? `(DEV) Error message: ${message}`
                  : undefined,
              }
            : action;
        },
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(initialiseSagaMiddleware));

const store = createStore(rootReducer, enhancer);

initialiseSagaMiddleware.run(rootSaga);

export default store;
