import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ALL_ERRORS,
  LOGIN_GOOGLE_AUTH,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  REGISTER_GOOGLE_AUTH,
  REGISTER_GOOGLE_AUTH_TOKEN,
  SG_LOGIN_GOOGLE_AUTH,
  SG_REGISTER_GOOGLE_AUTH,
  SG_REGISTER_GOOGLE_AUTH_TOKEN,
} from "common/constants/actions";

import * as api from "common/constants/api";

// LOGIN USER
function* loginUser(action) {
  yield put({ type: LOGIN_PENDING });
  try {
    const login = yield call(api.GOOGLE_AUTH, action.payload);
    yield put({ type: LOGIN_GOOGLE_AUTH, payload: login.data });
    yield put({ type: LOGIN_SUCCESS, payload: login.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteLoginUser() {
  yield takeLatest(SG_LOGIN_GOOGLE_AUTH, loginUser);
}

function* registerUser(action) {
  yield put({ type: LOGIN_PENDING });
  try {
    const json = yield call(api.REGISTER_GOOGLE_AUTH, action.payload);
    yield put({ type: REGISTER_GOOGLE_AUTH, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteRegisterUser() {
  yield takeLatest(SG_REGISTER_GOOGLE_AUTH, registerUser);
}

function* registerUserToken(action) {
  yield put({ type: LOGIN_PENDING });
  try {
    const json = yield call(api.REGISTER_GOOGLE_AUTH_TOKEN, action.payload);
    yield put({ type: REGISTER_GOOGLE_AUTH_TOKEN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteRegisterUserToken() {
  yield takeLatest(SG_REGISTER_GOOGLE_AUTH_TOKEN, registerUserToken);
}

export default function* index() {
  yield all([
    websiteLoginUser(),
    websiteRegisterUser(),
    websiteRegisterUserToken(),
  ]);
}
