import styled from "styled-components";
import React from "react";
import { get_historical_pillar } from "../../../data_navigation/get_historical_pillar";
import { shallowEqual, useSelector } from "react-redux";
import Chart from "react-apexcharts";
import ChartLoading from "common/components/analytics/ChartLoading";
import LegendIcon from "common/components/icons/LegendIcon";

const Historical = ({ data, nav, structure, categories, rule, anchor }) => {
  const [loading, setLoading] = React.useState(false);

  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual,
  );

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const raw = useSelector((state) => state.audit?.raw_data, shallowEqual);
  const dates = useSelector((state) => state.audit?.dates, shallowEqual);

  const [series, setSeries] = React.useState(data3.series);
  const [options, setOptions] = React.useState(data3.options);

  React.useEffect(() => {
    setLoading(true);
    let { averages, org_averages } = get_historical_pillar(
      filter_data,
      selected,
      nav,
      categories,
      anchor,
      rule,
      structure,
      raw,
      dates,
    );

    let _series = [
      { name: "Pillar Trends", data: averages },
      { name: "Organization Trends", data: org_averages },
    ];
    let _options = options;
    _options.xaxis.categories = dates.map((i) => i.getTime());
    _options.xaxis.type = "datetime";

    setSeries(_series);
    setOptions(_options);

    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [filter_data, raw, selected]);

  return (
    <Container>
      <div
        style={{
          width: "100%",
          padding: 30,
          marginBottom: -65,
          height: "100%",
        }}
      >
        <Title>
          <LegendIcon />
          Long term pillar trends
        </Title>
        <Description>
          Historical pillar scores with organization trends
        </Description>
        {loading ? (
          <ChartLoading />
        ) : (
          <Chart options={options} series={series} type="line" height={350} />
        )}
      </div>
    </Container>
  );
};

export default Historical;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 20px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-left: 25px;

  color: #c0c0c0;
`;

const data3 = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6],
    },
    {
      name: "Category Norms",
      data: [8.6, 8.0, 7.3, 8.5],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    colors: ["#5167DD", "#D7DCE6"],
    fill: {
      type: ["solid", "pattern"],
      opacity: 1,
      pattern: {
        style: [
          "verticalLines",
          "slantedLines",
          "verticalLines",
          "horizontalLines",
        ], // string or array of strings
      },
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },

    dataLabels: {
      enabled: true,
      style: {
        colors: ["white", "black"],
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Coaches",
        "Technical Leaders",
        "Sport Scientists",
        "Athletes",
      ],
    },
    yaxis: {
      title: {
        text: "Score",
        style: {
          fontWeight: 400,
        },
      },
      min: 0,
      max: 10,
      decimalsInFloat: 0,
    },
  },
};
