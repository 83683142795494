import { all, call, put, takeLatest } from "redux-saga/effects";

import {
  ALL_ERRORS,
  FETCH_CULTURE_DATA,
  GET_CULTURE_DATA,
  GET_CULTURE_DATA_ORG,
  GET_CULTURE_DATA_TEAMS,
  GET_CULTURE_ORG_REPORT,
  SG_FETCH_CULTURE_DATA,
  SG_GET_CULTURE_DATA,
  SG_GET_CULTURE_DATA_ORG,
  SG_GET_CULTURE_DATA_TEAMS,
  SG_GET_CULTURE_ORG_REPORT,
  SG_UPDATE_CULTURE_COMMENTS,
  UPDATE_CULTURE_COMMENTS,
} from "common/constants/actions";

import * as api from "common/constants/api";

function* getCultureData(action) {
  try {
    const json = yield call(api.GET_CULTURE_REPORTS, action.payload);
    yield put({ type: GET_CULTURE_DATA, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadCultureData() {
  yield takeLatest(SG_GET_CULTURE_DATA, getCultureData);
}

function* fetchCultureData(action) {
  try {
    const json = yield call(api.FETCH_CULTURE_REPORT, action.payload);
    yield put({ type: FETCH_CULTURE_DATA, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* cultureDataFetch() {
  yield takeLatest(SG_FETCH_CULTURE_DATA, fetchCultureData);
}

function* updateCultureComments(action) {
  try {
    const json = yield call(api.UPDATE_CULTURE_COMMENTS, action.payload);
    yield put({ type: UPDATE_CULTURE_COMMENTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* cultureCommentUpdate() {
  yield takeLatest(SG_UPDATE_CULTURE_COMMENTS, updateCultureComments);
}

function* getCultureTeamData(action) {
  try {
    const json = yield call(api.GET_CULTURE_REPORTS, action.payload);
    yield put({ type: GET_CULTURE_DATA_TEAMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadCultureTeamData() {
  yield takeLatest(SG_GET_CULTURE_DATA_TEAMS, getCultureTeamData);
}

function* getCultureOrgData(action) {
  try {
    const json = yield call(api.GET_CULTURE_REPORTS, action.payload);
    yield put({ type: GET_CULTURE_DATA_ORG, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadCultureOrgData() {
  yield takeLatest(SG_GET_CULTURE_DATA_ORG, getCultureOrgData);
}

function* getCultureOrgReport(action) {
  try {
    const json = yield call(api.GET_CULTURE_ORG_REPORTS, action.payload);
    yield put({ type: GET_CULTURE_ORG_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadCultureOrgReport() {
  yield takeLatest(SG_GET_CULTURE_ORG_REPORT, getCultureOrgReport);
}
export default function* index() {
  yield all([
    loadCultureData(),
    cultureDataFetch(),
    cultureCommentUpdate(),
    loadCultureTeamData(),
    loadCultureOrgData(),
    loadCultureOrgReport(),
  ]);
}
