import {
  CLEAR_ALL,
  CLEAR_SIGNUP_TOKEN,
  DELETE_SIGNUP_TOKEN,
  GET_SIGNUP_TOKENS,
} from "common/constants/actions";

const initialState = {
  signup_tokens: [],

  pending: false,
};

export default function signupTokenReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIGNUP_TOKENS:
      return {
        ...state,
        signup_tokens: action.payload,
        pending: false,
      };

    case DELETE_SIGNUP_TOKEN:
      return {
        ...state,
        payments: state.signup_tokens.filter(
          (row) => row.id !== action.payload,
        ),
      };

    case CLEAR_SIGNUP_TOKEN:
      return {
        ...state,
        signup_tokens: [],
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        signup_tokens: [],
        pending: false,
      };
    default:
      return state;
  }
}
