import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import {
  SG_ADD_EMPLOYEE_RECORD,
  SG_UPDATE_EMPLOYEE_RECORD,
} from "common/constants/actions";

export function AddEmpRecRow({ employee, valueName, employee_record_value }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function handleSubmit() {
    if (value === "") {
      setErrorMsg("You must enter a value");
      return;
    }
    dispatch({
      type: SG_ADD_EMPLOYEE_RECORD,
      payload: {
        employee,
        employee_record_value,
        value,
      },
    });
    setValue("");
  }

  function UpdateValue(e) {
    setValue(e);
    setErrorMsg(false);
  }

  return (
    <FormContainer>
      <FormContents>
        <RowData
          value={value}
          placeholder={valueName}
          onChange={(e) => UpdateValue(e.target.value)}
        />
        <AddButton onClick={() => handleSubmit()}>
          <AddButtonText>Add New</AddButtonText>
        </AddButton>
        {errorMsg && <ErrorLabel>{errorMsg}</ErrorLabel>}
      </FormContents>
    </FormContainer>
  );
}

export function UpdateRecRow({ id, valueName, orgValue }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setValue(orgValue);
  }, [orgValue]);

  function handleSubmit() {
    if (value === "") {
      setErrorMsg("You must enter a value");
      return;
    }
    dispatch({
      type: SG_UPDATE_EMPLOYEE_RECORD,
      payload: {
        id,
        value,
      },
    });
  }

  function UpdateValue(e) {
    setValue(e);
    setErrorMsg(false);
  }

  return (
    <FormContainer>
      <FormContents>
        <RowData
          value={value}
          placeholder={valueName}
          onChange={(e) => UpdateValue(e.target.value)}
        />
        <AddButton onClick={() => handleSubmit()}>
          <AddButtonText>Update</AddButtonText>
        </AddButton>
        {errorMsg && <ErrorLabel>{errorMsg}</ErrorLabel>}
      </FormContents>
    </FormContainer>
  );
}

export default AddEmpRecRow;

const ErrorMessage = styled.p`
  //   font-size: 12px;
  // font-family: "Barlow", sans-serif;
  color: #ff0000;
`;
const FormContainer = styled.div`
  display: flex;
  padding-top: 30px;
  justify-content: flex-end;
`;

const ErrorLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: red;
`;

const AddButtonText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;

const AddButton = styled.div`
  width: 100px;
  height: 44px;
  cursor: pointer;

  background: #476dfa;
  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const RowData = styled.input`
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 300px;
  height: 48px;
  margin-right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;
  text-transform: capitalize;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 15px;
`;

const FormContents = styled.div`
  flex-direction: row;
  display: flex;
`;
