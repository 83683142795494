import styled from "styled-components";
import React from "react";
import LegendIcon from "common/components/icons/LegendIcon";
import ArrowUp from "assets/images/UI/up.svg";
import ArrowDown from "assets/images/UI/down.svg";
import { shallowEqual, useSelector } from "react-redux";

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;

  return response;
};

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item != null ? item : 0;
    n += item != null ? 1 : 0;
  });

  if (!total) {
    return 0;
  }

  return total / n;
};

const Summary = ({
  data,
  last_data,
  structure,
  filter_data,
  standards,
  selectedQ,
}) => {
  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const get_last_average = () => {
    //Filter questions based on the selected pillar first
    let questions = last_data
      .map((item) =>
        item.questions.filter(
          (f) => f.id == selected.dimension && f.factor == selected.factor,
        ),
      )
      .flat()
      .map((item) => check_reverse_score(item, structure));
    return average(questions);
  };

  const get_current_average = () => {
    let questions = filter_data[0]
      .map((item) =>
        item.questions.filter(
          (f) => f.id == selected.dimension && f.factor == selected.factor,
        ),
      )
      .flat()
      .map((item) => check_reverse_score(item, structure));
    return average(questions).toFixed(1);
  };

  const getFeedbackContext = (s) => {
    let _data = [];
    if (s.level) {
      filter_data[0]
        .filter((f) => "feedback" in f)
        .filter((f) => f.feedback.length > 0)
        .map((item) => {
          item.feedback
            .filter(
              (f) => f.factor == s.factor && f.id == s.dimension && f.feedback,
            )
            .map((f) => {
              _data.push({ feedback: f, categories: item.categories });
            });
        });
    }

    return _data;
  };

  const getStandard = () => {
    let stand = standards?.response?.pillars.find(
      (f) => f.id == selected.dimension && f.factor == selected.factor,
    )?.average;
    if (stand) {
      return stand;
    }

    return 0;
  };

  const [last_average, setLast] = React.useState(get_last_average());
  const [average_survey, setAverage] = React.useState(get_current_average());
  const [feedback, setFeedback] = React.useState(getFeedbackContext(selected));
  const [standard_diff, setStandardDiff] = React.useState(getStandard());

  React.useEffect(() => {
    setLast(get_last_average());
    setAverage(get_current_average());
  }, [data, last_data, selected]);

  React.useEffect(() => {
    if (selected) {
      setFeedback(getFeedbackContext(selected));
    }
  }, [selected]);

  React.useEffect(() => {
    if (standards) {
      setStandardDiff(getStandard());
    }
  }, [standards, selected]);

  return (
    <Container>
      <Title>
        <LegendIcon /> Pillar Summary
      </Title>
      <Row>
        <Quad>
          <Score>{average_survey}</Score>
          <Sub>Total Score</Sub>
        </Quad>
        <Quad>
          {last_average != 0 ? (
            <Score
              color={average_survey > last_average ? "#66D686" : "#E66579"}
            >
              <Icon src={average_survey > last_average ? ArrowUp : ArrowDown} />
              {(Math.abs(average_survey - last_average) * 10).toFixed(1)}%
            </Score>
          ) : (
            <Score color={"lightgrey"}>--</Score>
          )}
          <Sub>Since last assessment</Sub>
        </Quad>

        <Quad>
          {standard_diff ? (
            <Score
              color={average_survey > standard_diff ? "#66D686" : "#E66579"}
            >
              <Icon
                src={average_survey > standard_diff ? ArrowUp : ArrowDown}
              />
              {(Math.abs(average_survey / standard_diff - 1) * 100).toFixed(1)}%
            </Score>
          ) : (
            <Score color={"lightgrey"}>--</Score>
          )}
          <Sub>{average_survey > standard_diff ? "Above" : "Below"} norms</Sub>
        </Quad>
        <Quad>
          <Score>{feedback.length}</Score>
          <Sub># of feedback comments</Sub>
        </Quad>
      </Row>
    </Container>
  );
};

export default Summary;

const Container = styled.div`
  border-radius: 10px;
  padding: 25px;
  width: 100%;
  height: 175px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
`;

const Quad = styled.div`
  width: calc(25% - 10px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Score = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;

  color: ${(props) => (props.color ? props.color : "#414141")};
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  /* identical to box height */

  color: #7e7e7e;
`;

const Icon = styled.img`
  width: 25px;
  margin-right: 5px;
`;
