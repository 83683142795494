import React from "react";
import styled, { keyframes } from "styled-components";
import Blank from "assets/images/empty/user.svg";

const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`;

const Edit = styled.div`
  position: absolute;
  bottom: 0px;
  right: -20px;
  color: #4a4a4a;
  font-size: 30px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
`;

const Container = styled.div`
  position: relative;
  width: 80px;
  cursor: pointer;

  &:hover ${Edit} {
    color: #007cff;
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
`;

const Image = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100px;
  z-index: -1;
`;

const GreyCircle = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 100px;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;

const Avatar = (props) => {
  /* 	if(props.loading){
		return <Container><GreyCircle/></Container>
	}
 */
  return (
    <Container onClick={() => props.onClick()}>
      <Image src={props.picture ? props.picture : Blank} />
    </Container>
  );
};

export default Avatar;
