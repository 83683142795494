import React from "react";

function IdeaIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9692 5.34982C13.953 3.41673 10.6841 3.41673 8.66795 5.34982C6.67778 7.25797 6.86844 10.1881 8.89571 12.1318C9.4691 12.6816 9.72538 13.4698 9.84814 14.176C9.97188 14.8878 9.97187 15.5932 9.97186 16.0411V16.05C9.97186 16.1963 10.0087 16.276 10.0397 16.3207C10.0727 16.3682 10.1201 16.4054 10.1818 16.435C10.2453 16.4654 10.3144 16.4829 10.3717 16.4921C10.3992 16.4965 10.4211 16.4985 10.4343 16.4994C10.4398 16.4998 10.4436 16.4999 10.4454 16.5H14.1917C14.1936 16.4999 14.1974 16.4998 14.2028 16.4994C14.216 16.4985 14.2379 16.4965 14.2655 16.4921C14.3227 16.4829 14.3919 16.4654 14.4554 16.435C14.517 16.4054 14.5644 16.3682 14.5974 16.3207C14.6285 16.276 14.6653 16.1963 14.6653 16.05V16.0411C14.6653 15.5932 14.6653 14.8878 14.789 14.176C14.9118 13.4698 15.168 12.6816 15.7414 12.1318C17.8403 10.1194 17.9633 7.26176 15.9692 5.34982ZM8.0042 4.71343C10.387 2.42886 14.2502 2.42886 16.633 4.71343C19.0378 7.01915 18.8249 10.4482 16.4052 12.7682C16.0399 13.1184 15.8268 13.6802 15.7149 14.324C15.6048 14.9574 15.604 15.5974 15.604 16.05C15.604 16.3537 15.5234 16.6115 15.3785 16.8199C15.2355 17.0256 15.0482 17.1571 14.8752 17.24C14.704 17.3221 14.5385 17.3608 14.4198 17.3798C14.3593 17.3895 14.3079 17.3945 14.2697 17.3971C14.2506 17.3984 14.2345 17.3991 14.2221 17.3995L14.2062 17.3999L14.2003 17.4L14.198 17.4L14.1969 17.4C14.1967 17.4 14.1959 17.4 14.1959 16.95V17.4H10.4412V16.95C10.4412 17.4 10.4414 17.4 10.4412 17.4L10.4402 17.4L10.4392 17.4L10.4368 17.4L10.4309 17.3999L10.415 17.3995C10.4026 17.3991 10.3866 17.3984 10.3674 17.3971C10.3293 17.3945 10.2779 17.3895 10.2174 17.3798C10.0986 17.3608 9.93314 17.3221 9.76197 17.24C9.58895 17.1571 9.40166 17.0256 9.25868 16.8199C9.11373 16.6115 9.03318 16.3537 9.03318 16.05C9.03318 15.5974 9.03234 14.9574 8.92223 14.324C8.81032 13.6802 8.59726 13.1184 8.23196 12.7682C5.8924 10.5251 5.59542 7.02294 8.0042 4.71343Z"
        fill="#2D70E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.98532 20.55C9.98532 20.3015 10.1955 20.1 10.4547 20.1H14.2094C14.4686 20.1 14.6787 20.3015 14.6787 20.55C14.6787 20.7985 14.4686 21 14.2094 21H10.4547C10.1955 21 9.98532 20.7985 9.98532 20.55Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export default IdeaIcon;
