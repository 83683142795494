import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ALL_ERRORS,
  FETCH_TEAM_TYPE,
  GET_TEAM_TYPES,
  SG_FETCH_TEAM_TYPE,
  SG_GET_TEAM_TYPES,
} from "common/constants/actions";

function* loadteamTypes(action) {
  try {
    const json = yield call(api.GET_TEAM_TYPES, action.payload);
    yield put({ type: GET_TEAM_TYPES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* teamTypeLoad() {
  yield takeLatest(SG_GET_TEAM_TYPES, loadteamTypes);
}

function* fetchteamTypes(action) {
  try {
    const json = yield call(api.FETCH_TEAM_TYPE, action.payload);
    yield put({ type: FETCH_TEAM_TYPE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, message: e });
  }
}

export function* teamTypeFetch() {
  yield takeLatest(SG_FETCH_TEAM_TYPE, fetchteamTypes);
}

export default function* index() {
  yield all([teamTypeLoad(), teamTypeFetch()]);
}
