import React from "react";

function TrendDown(fill: string | undefined) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6C15.2761 6 15.5 6.22386 15.5 6.5V15H7C6.72386 15 6.5 14.7761 6.5 14.5C6.5 14.2239 6.72386 14 7 14H14.5V6.5C14.5 6.22386 14.7239 6 15 6Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L15.3536 14.1464C15.5488 14.3417 15.5488 14.6583 15.3536 14.8536C15.1583 15.0488 14.8417 15.0488 14.6464 14.8536L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z"
        fill={fill}
      />
    </svg>
  );
}

export default TrendDown;
