import React from "react";

function ChatBubble(fill: string | undefined) {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H19C19.2761 0.5 19.5 0.723858 19.5 1V14C19.5 14.2761 19.2761 14.5 19 14.5H11.1514L5.27735 18.416C5.12392 18.5183 4.92665 18.5278 4.76407 18.4408C4.60149 18.3538 4.5 18.1844 4.5 18V14.5H1C0.723858 14.5 0.5 14.2761 0.5 14V1ZM1.5 1.5V13.5H5C5.27614 13.5 5.5 13.7239 5.5 14V17.0657L10.7226 13.584C10.8048 13.5292 10.9013 13.5 11 13.5H18.5V1.5H1.5Z"
        fill={fill}
      />
    </svg>
  );
}

export default ChatBubble;
