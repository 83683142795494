import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Progress } from "semantic-ui-react";
import { build_default_State } from "../../../common/calculations/analytics";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  SET_NAVIGATION,
  SET_SELECTED_FILTERS,
  SET_SELECTED_QUESTION,
  SET_SUB_NAV,
} from "common/constants/actions";

const configure = (structure) => {
  let ids = 0;
  let names = [{ title: "Overall DEI", level: 0, id: ids }];
  ids += 1;
  if (structure?.dimensions) {
    structure?.dimensions.map((item, i) => {
      names.push({ title: item.title, level: 1, id: ids, dimension: i });
      ids += 1;
      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
  }

  return names;
};

const LinkedRow = ({ data, color }) => {
  const dispatch = useDispatch();

  const [structure, setStructure] = useState(null);
  const [filteredData1, setFilteredData1] = useState([]);

  const questions = useSelector(
    (state) => state.audit?.question_structure,
    shallowEqual,
  );

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual,
  );

  useEffect(() => {
    setFilteredData1(
      data.ranking
        .filter((f) => f.id === 0)
        .sort((a, b) => b.response - a.response),
    );
  }, [data]);

  useEffect(() => {
    if (questions) {
      if (Object.keys(questions).length > 0) {
        // setQ(questions);
        setStructure(configure(questions));
      }
    }
  }, [questions]);

  const navigate = (select) => {
    if (data) {
      let selected = structure.find(
        (f) => f.dimension === select.id && f.factor === select.factor,
      );

      dispatch({
        type: SET_SELECTED_QUESTION,
        payload: selected,
      });
      dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });

      onSelect({
        id: data.navigation.id,
        option: data.navigation.option,
        subfactor: data.navigation.subfactor,
        name: { name: data.title.name },
      });
    }
  };

  const selected = useSelector((state) => state.audit?.filters, shallowEqual);

  const get_last = (item) => {
    //console.log(item, data);
    let last;
    if (!item.last && data.last_ranking) {
      last = data.last_ranking.find(
        (f) => f.id == item.id && f.factor == item.factor,
      )?.response;
    } else {
      last = item.last;
    }

    if (last) {
      let diff = item.response - last;
      return (
        <Diff color={diff > 0 ? "green" : "red"}>
          {"(" +
            (item.response - last > 0 ? "+" : "") +
            (item.response - last).toFixed(1) * 10 +
            "%)"}
        </Diff>
      );
    }

    return "";
  };

  const onSelect = (s) => {
    let check = selected.filter(
      (f) =>
        f.id === s.id && f.subfactor === s.subfactor && f.option === s.option,
    );
    let _selected;
    if (check.length > 0) {
      _selected = selected;
    } else {
      _selected = [...selected, s];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);
    // console.log(_selected, new_selected);
    new_selected.map((item, i) => {
      if (_selected[item.id].selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s !== "selected" && s !== "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }
      if (item.subfactor !== null) {
        _selected[item.id][item.option].subfactors[item.subfactor].selected =
          true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  return (
    <div>
      {filteredData1.map((item, i) => {
        return (
          <Row key={i}>
            <Linked onClick={() => navigate(item)}>
              {/*<Num>{i + 1 + ". "}</Num>*/}
              {questions?.dimensions[item?.id]?.factors[item?.factor]?.title}
            </Linked>
            <StyledProgressBar
              value={Math.floor(item?.response * 10) / 10}
              total="10"
              inverted={false}
              color={color} // accepts only ["red","orange","yellow","olive","green","teal","blue","violet","purple","pink","brown","grey","black"].
              size="small"
              style={{
                width: "calc(100% - 140px)",
                margin: 0,
              }}
            />
            <Score>
              {Math.floor(item?.response * 10) / 10}
              {get_last(item)}
            </Score>
          </Row>
        );
      })}
    </div>
  );
};

export default LinkedRow;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: #66d686 !important;
    border-radius: 15px !important;
  }
  border-radius: 15px !important;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2.5px 0px 2.5px 2.5px;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Linked = styled.div`
  font-weight: 300;
  width: 150px;
  cursor: pointer;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

const Score = styled.div`
  width: 40px;
  margin-left: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;

  color: #7e7e7e;
`;

const Diff = styled.span`
  font-size: 10px;
  margin-left: 5px;
  color: ${(props) => props.color};
`;
