import styled from "styled-components";
import React from "react";
import { Dimmer, Icon, Loader } from "semantic-ui-react";
import { shallowEqual, useSelector } from "react-redux";
import ArrowUp from "assets/images/UI/up.svg";
import ArrowDown from "assets/images/UI/down.svg";
import Chart from "react-apexcharts";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getMonth = (month) => {
  let offset = month.getTimezoneOffset();
  let new_date = new Date(
    month.getTime() + offset * 60 * 1000 + 24 * 60 * 60 * 1000,
  ).getMonth();

  return new_date;
};

const QuestionInsights = ({
  open,
  setClose,
  filter_data,
  filter_last_data,
  anchor,
  questions,
}) => {
  const [series, setSeries] = React.useState(data2.series);
  const [options, setOptions] = React.useState(data2.options);
  const [selectCat, setCat] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const dates = useSelector((state) => state.audit?.dates, shallowEqual);

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual,
  );

  const get_question_total = (data) => {
    if (data.length > 0) {
      return average(
        data.map(
          (item) =>
            item.questions.find(
              (f) =>
                f.q == open &&
                f.id == selected.dimension &&
                f.factor == selected.factor,
            ).response,
        ),
      );
    }
    return null;
  };

  const get_last_question_total = () => {
    if (filter_last_data.length == 0) {
      return 0;
    }
    return average(
      filter_last_data?.map(
        (item) =>
          item.questions.find(
            (f) =>
              f.q == open &&
              f.id == selected.dimension &&
              f.factor == selected.factor,
          ).response,
      ),
    );
  };

  const get_cat_average = (cat, data) => {
    return get_question_total(
      data.filter(
        (f) => f.categories.find((f) => f.id == anchor)?.response == cat.id,
      ),
    );
  };

  const get_average_by_anchor = () => {
    //Establish list of ID's from the anchor
    let anchor_categories = categories.categories.find(
      (f) => f.id == anchor,
    ).options;

    //Loop through each category and get the average for that category
    return anchor_categories.map((item) => {
      let last_average =
        filter_last_data.length > 0
          ? get_cat_average(item, filter_last_data)
          : 0;
      let averaged = get_cat_average(item, filter_data[0]);
      return {
        average: averaged ? averaged.toFixed(1) : 0,
        title: item.name,
        last_average,
        id: item.id,
      };
    });
  };

  const get_longitudinal_data = () => {
    setLoading(true);
    let data = filter_data.slice(1).map((item) => get_question_total(item));
    let _options = options;
    _options.xaxis.categories = dates.map((item) => monthNames[getMonth(item)]);
    setSeries([{ data }]);
    setOptions(_options);
    setTimeout(() => setLoading(false), 300);
  };

  const get_longitudinal_data_by_cat = (cat) => {
    setLoading(true);
    let data = filter_data
      .slice(1)
      .map((item) =>
        get_question_total(
          item.filter(
            (f) => f.categories.find((d) => d.id == anchor)?.response == cat,
          ),
        ),
      );
    let _options = options;
    _options.xaxis.categories = dates
      .map((item) => monthNames[getMonth(item)])
      .filter((f, i) => data[i]);
    setSeries([{ data: data.filter((f) => f != null) }]);
    setOptions(_options);
    setTimeout(() => setLoading(false), 300);
  };

  React.useEffect(() => {
    if (open !== false) {
      get_longitudinal_data();
    }
  }, [open]);

  React.useEffect(() => {
    if (selectCat) {
      get_longitudinal_data_by_cat(selectCat);
    }
  }, [selectCat]);

  const closeCat = () => {
    setCat(null);
    get_longitudinal_data();
  };

  const getSelection = () => {
    return (
      <Box onClick={() => closeCat()}>
        {
          categories.categories
            .find((f) => f.id == anchor)
            .options.find((f) => f.id == selectCat).name
        }
        <Icon
          name={"close"}
          style={{ fontSize: 10, marginLeft: 5, marginTop: -12 }}
        />
      </Box>
    );
  };

  const get_score_diff = (a, b) => {
    if (b) {
      let _diff = (a - b) * 10;
      return (
        <span
          style={{ color: _diff > 0 ? "green" : "red", marginLeft: 5 }}
        >{`(${_diff > 0 ? "+" : ""} ${_diff.toFixed(1)}%)`}</span>
      );
    }
  };

  const getContent = () => {
    if (open !== false) {
      let last_average = get_last_question_total();
      let averaged = Number(get_question_total(filter_data[0]));
      let diff = last_average
        ? ((averaged - last_average) * 10).toFixed(1)
        : null;
      return (
        <>
          <Question>
            {
              questions.dimensions[selected.dimension].factors[selected.factor]
                .questions[open].q
            }
          </Question>
          <Summary>
            <Half>
              <Score>{get_question_total(filter_data[0]).toFixed(1)}</Score>
              <Sub>Total Score</Sub>
            </Half>
            <Half>
              {last_average != 0 ? (
                <Score color={averaged > last_average ? "#66D686" : "#E66579"}>
                  <IconIMG
                    src={averaged > last_average ? ArrowUp : ArrowDown}
                  />
                  {diff}%
                </Score>
              ) : (
                <Score color={"lightgrey"}>--</Score>
              )}
              <Sub>Since last assessment</Sub>
            </Half>
          </Summary>

          <AnchorScores>
            <SectionTitle>Question scores by anchor factor</SectionTitle>
            {get_average_by_anchor()
              .filter((f) => f.average != 0)
              .sort((a, b) => a.average - b.average)
              .map((item) => {
                return (
                  <Row>
                    <Title onClick={() => setCat(item.id)}>{item.title}</Title>
                    <Bar2 width={item.average * 4.09} color={"#C0C0C0"}>
                      <Score2>
                        {item.average}{" "}
                        {get_score_diff(item.average, item.last_average)}
                      </Score2>
                    </Bar2>
                  </Row>
                );
              })}
          </AnchorScores>

          <SectionTitle>
            Longitudinal Scores {selectCat ? getSelection() : ""}
          </SectionTitle>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          ) : (
            <Chart options={options} series={series} type="line" height={400} />
          )}
        </>
      );
    }
  };

  return (
    <Container open={open !== false}>
      <Icon
        name="close"
        onClick={() => setClose()}
        style={{ position: "absolute", right: 10, top: 30, cursor: "pointer" }}
      />
      {getContent()}
    </Container>
  );
};

export default QuestionInsights;

const Container = styled.div`
  position: absolute;
  width: 600px;
  right: ${(props) => (props.open ? "-20px" : "-110%")};
  top: -30px;
  background-color: white;
  height: 100%;
  transition: all 0.5s;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid grey;
  padding: 30px;
`;

const Question = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #414141;
`;

const Summary = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 30px;
`;

const Half = styled.div`
  width: calc(50% - 10px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Score = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;

  color: ${(props) => (props.color ? props.color : "#414141")};
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  /* identical to box height */

  color: #7e7e7e;
`;

const IconIMG = styled.img`
  width: 25px;
  margin-right: 5px;
`;

const Bar2 = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: ${(props) => props.color};
  position: relative;
  border-radius: 10px;
`;

const Score2 = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  color: #7e7e7e;
  width: 100px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  height: 25px;
  align-items: center;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
  width: 200px;
  cursor: pointer;
`;

const AnchorScores = styled.div`
  margin-top: 50px;
`;

const SectionTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  margin-top: 50px;
  color: #414141;
  margin-bottom: 10px;
  align-items: center;
`;

const Box = styled.div`
  border-radius: 5px;
  background-color: grey;
  padding: 0px 15px;
  font-size: 8px;
  margin-left: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item != null ? item : 0;
    n += item != null ? 1 : 0;
  });

  if (!total) {
    return 0;
  }

  return total / n;
};

const data2 = {
  series: [
    {
      name: "Scores",
      data: [0, 0, 0, 0, 1, 2, 8, 3, 2, 0, 1],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    colors: ["#66D686", "#FFC600", "#FF0000", "#02AE5B"],
    fill: {
      opacity: 1,
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    yaxis: {
      title: {
        text: "Score frequency",
        style: {
          fontWeight: 400,
        },
      },
      decimalsInFloat: 1,
      min: 0,
      max: 10,
    },
  },
};
