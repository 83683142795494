import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ADD_CHAT_RECOMMENDATION,
  ALL_ERRORS,
  CHAT_RECOMMENDATION_ERROR,
  DELETE_CHAT_RECOMMENDATION,
  EDIT_CHAT_RECOMMENDATION,
  FETCH_CHAT_RECOMMENDATION,
  GET_CHAT_RECOMMENDATION,
  SG_ADD_CHAT_RECOMMENDATION,
  SG_DELETE_CHAT_RECOMMENDATION,
  SG_EDIT_CHAT_RECOMMENDATION,
  SG_FETCH_CHAT_RECOMMENDATION,
  SG_GET_CHAT_RECOMMENDATION,
} from "common/constants/actions";

function* loadmethods(action) {
  try {
    const json = yield call(api.GET_CHAT_RECOMMENDATION, action.payload);
    yield put({ type: GET_CHAT_RECOMMENDATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_CHAT_RECOMMENDATION, loadmethods);
}

function* fetchmethods(action) {
  try {
    const json = yield call(api.FETCH_CHAT_RECOMMENDATION, action.payload);
    yield put({ type: FETCH_CHAT_RECOMMENDATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_CHAT_RECOMMENDATION, fetchmethods);
}

function* addmethods(action) {
  try {
    const json = yield call(api.ADD_CHAT_RECOMMENDATION, action.payload);
    yield put({ type: ADD_CHAT_RECOMMENDATION, payload: json.data });
  } catch (e) {
    yield put({ type: CHAT_RECOMMENDATION_ERROR, payload: e.response });
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_CHAT_RECOMMENDATION, addmethods);
}

function* editmethods(action) {
  try {
    const json = yield call(api.EDIT_CHAT_RECOMMENDATION, action.payload);
    yield put({ type: EDIT_CHAT_RECOMMENDATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodEdit() {
  yield takeLatest(SG_EDIT_CHAT_RECOMMENDATION, editmethods);
}

function* deletemethods(action) {
  try {
    const json = yield call(api.DELETE_CHAT_RECOMMENDATION, action.payload);
    yield put({ type: DELETE_CHAT_RECOMMENDATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_CHAT_RECOMMENDATION, deletemethods);
}

export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodAdd(),
    methodEdit(),
    methodDelete(),
  ]);
}
