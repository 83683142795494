import React from "react";
import styled from "styled-components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Button, Modal, Segment, Table } from "semantic-ui-react";

import format from "date-fns/format";

import {
  SG_EMAIL_DELIVERY_STATUS,
  SG_FETCH_EMAIL_DELIVERY_STATUS,
} from "common/constants/actions";

const EmailDeliveryStatus = (props) => {
  const dispatch = useDispatch();

  const [responses, setResponses] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [secondOpen, setSecondOpen] = React.useState(false);

  const [messageId, setMessageId] = React.useState(false);
  const [deliveryInfo, setDeliveryInfo] = React.useState([]);
  const [emailId, setEmailId] = React.useState(false);

  const { get_email_status, get_message_status, get_employees } = useSelector(
    (state) => ({
      get_email_status: state.emaildeliverystatus.email_status,
      get_message_status: state.emaildeliverystatus.message_status,
      get_employees: state.employees.employees,
    }),
    shallowEqual,
  );

  React.useEffect(() => {
    // email
    if (open) {
      setResponses([]);
      if (props?.email) {
        dispatch({
          type: SG_EMAIL_DELIVERY_STATUS,
          payload: `?email=${props?.email}`,
        });
        setEmailId(props?.email);
        return;
      } else if (props?.employee_id) {
        let email_id = get_employees.filter(
          (em) => em.id === props.employee_id,
        )[0]?.email;
        if (email_id) {
          dispatch({
            type: SG_EMAIL_DELIVERY_STATUS,
            payload: `?email=${email_id}`,
          });
          setEmailId(email_id);
        }

        return;
      }
    }
  }, [props, dispatch, get_employees, open]);

  function SelectedMessage(message_id) {
    setSecondOpen(true);
    setDeliveryInfo([]);
    dispatch({
      type: SG_FETCH_EMAIL_DELIVERY_STATUS,
      payload: `?message-id=${message_id}`,
    });
    setMessageId(message_id);
  }

  React.useEffect(() => {
    if (get_email_status?.response?.Messages) {
      setResponses(get_email_status?.response?.Messages);
    } else {
      setResponses([]);
    }
  }, [get_email_status]);

  React.useEffect(() => {
    if (get_message_status?.response) {
      setDeliveryInfo(get_message_status?.response);
    } else {
      setDeliveryInfo([]);
    }
  }, [get_message_status]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<DeliveryInfoLabel>Recent Outreach</DeliveryInfoLabel>}
    >
      <Modal.Header>Recent emails sent to {emailId}</Modal.Header>

      <Segment>
        {responses.length > 0 ? (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                <Table.HeaderCell>Delivery Details</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {responses &&
                responses.map((p, i) => {
                  return (
                    <Table.Row key={i} active={messageId === p.MessageID}>
                      <Table.Cell>
                        {format(
                          new Date(p?.ReceivedAt.toLocaleString()),
                          "EEEE, MMM do h:mm a",
                        )}
                      </Table.Cell>
                      <Table.Cell>{p?.Subject}</Table.Cell>
                      <Table.Cell>
                        <DeliveryInfoLabel
                          onClick={() => SelectedMessage(p.MessageID)}
                        >
                          Delivery Details
                        </DeliveryInfoLabel>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        ) : (
          <div>No emails have been recently sent</div>
        )}
      </Segment>

      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
      <Modal
        onClose={() => setSecondOpen(false)}
        open={secondOpen}
        size="small"
      >
        <Modal.Header>Delivery Details</Modal.Header>
        <Segment>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deliveryInfo &&
                deliveryInfo.map((p, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {format(
                          new Date(p?.ReceivedAt.toLocaleString()),
                          "EEEE, MMM do h:mm a",
                        )}
                      </Table.Cell>
                      <Table.Cell>{p?.Type}</Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Segment>
        <Modal.Actions>
          <Button
            icon="check"
            content="Close"
            onClick={() => setSecondOpen(false)}
          />
        </Modal.Actions>
      </Modal>
    </Modal>
  );
};

export default EmailDeliveryStatus;

const DeliveryInfoLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 20px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #476dfa;
  cursor: pointer;
`;
