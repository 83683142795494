import {
  CREATE_PDF_TEMPLATE,
  FETCH_PDF_TEMPLATE,
  GET_PDF_TEMPLATES,
} from "common/constants/actions";

const initialState = {
  pdfConfig: [],
  createdNew: false,
  pending: false,
};

export default function PDFGenerator(state = initialState, action) {
  switch (action.type) {
    case FETCH_PDF_TEMPLATE:
      return {
        ...state,
        pdfConfig: action.payload,
      };

    case GET_PDF_TEMPLATES:
      return {
        ...state,
        pdfConfig: action.payload,
      };

    case CREATE_PDF_TEMPLATE:
      return {
        ...state,
        createdNew: action.payload,
        pdfConfig: [action.payload],
      };

    default:
      return state;
  }
}
