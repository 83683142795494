import {
  CLEAR_ALL,
  GET_PERSONALITY,
  GET_TRAIT_DATA,
} from "common/constants/actions";

const initialState = {
  personality: [],
  trait_data: [],
  pending: false,
};

export default function personalityReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERSONALITY:
      return {
        ...state,
        personality: action.payload,
        pending: false,
      };
    case GET_TRAIT_DATA:
      return {
        ...state,
        trait_data: action.payload,
        pending: false,
      };
    case CLEAR_ALL:
      return {
        ...state,
        personality: [],
        pending: false,
      };
    default:
      return state;
  }
}
