import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  COMPLETE_ANALYTICS,
  SET_SUB_NAV,
  SET_TRACKED_STATE,
} from "common/constants/actions";

import Languages from "common/utils/language-utils/index";

import { useLanguageSetting } from "common/hooks/useLanguageSetting";

const SubNav = ({ WhiteLabel, comments, questionStructure, ai }) => {
  const dispatch = useDispatch();
  const language_hook = new Languages(useLanguageSetting());
  const [sub, setSub] = useState(1);

  const sub_state = useSelector((state) => state.audit?.sub, shallowEqual);
  const checklist = useSelector(
    (state) => state.checklist?.analytics,
    shallowEqual,
  );
  const get_employee = useSelector((state) => state.employees, shallowEqual);

  useEffect(() => {
    setSub(sub_state);
  }, [sub_state]);

  const select = (val) => {
    dispatch({
      type: SET_SUB_NAV,
      payload: val,
    });

    if (!checklist.steps[1].tracked.includes(val)) {
      dispatch({
        type: SET_TRACKED_STATE,
        id: 1,
        payload: val,
      });

      if (
        !checklist.steps[1].completed &&
        checklist.steps[1].tracked.length == 3
      ) {
        dispatch({
          type: COMPLETE_ANALYTICS,
          payload: 1,
        });
      }
    }
  };

  const getAccess = () => {
    if (get_employee.userEmp.read_feedback) {
      return true;
    }
    if (get_employee.userEmp.account_type_text == "Admin") {
      return true;
    }

    return false;
  };

  return (
    <Container>
      <NavItem
        icon="home"
        title={language_hook.fetch_words("Overview")}
        selected={sub == 1}
        setSelected={() => select(1)}
        highlight={WhiteLabel.highlight}
      />
      <NavItem
        icon="chart bar"
        title={language_hook.fetch_words("Dashboard")}
        selected={sub == 2}
        setSelected={() => select(2)}
        highlight={WhiteLabel.highlight}
      />
      <NavItem
        icon="table"
        title={language_hook.fetch_words("Tables")}
        selected={sub == 3}
        setSelected={() => select(3)}
        highlight={WhiteLabel.highlight}
      />

      {Number(WhiteLabel?.overview) !== 1 ? (
        <>
          <NavItem
            icon="table"
            title="Insights"
            selected={sub == 6}
            setSelected={() => select(6)}
            highlight={WhiteLabel.highlight}
          />
          {/* <NavItem
					icon="table"
					title="Pillars"
					selected={sub == 5}
					setSelected={() => select(5)}
					highlight={WhiteLabel.highlight}
				/> */}
        </>
      ) : (
        ""
      )}
      {comments && getAccess() ? (
        <NavItem
          icon="chat"
          title={language_hook.fetch_words("Feedback")}
          selected={sub == 4}
          setSelected={() => select(4)}
          highlight={WhiteLabel.highlight}
        />
      ) : (
        ""
      )}

      {/* {ai && Number(WhiteLabel?.overview) !== 1?<NavItem
				icon="table"
				title="AI"
				selected={sub == 7}
				setSelected={() => select(7)}
				highlight={WhiteLabel.highlight}
			/>:""} */}
    </Container>
  );
};

export default SubNav;

const NavItem = (props) => {
  return (
    <NavContainer
      selected={props.selected}
      onClick={() => props.setSelected()}
      color={props.highlight}
      id={"nav_" + props.title}
      className={props.className}
    >
      <Title>{props.title}</Title>
    </NavContainer>
  );
};

const Container = styled.div`
  font-family: "Inter", sans-serif;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  padding-left: 58px;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 20px 0px 5px 0px;
  // color:{props=>props.selected?'#ab710a':'#9b9b9b'};
  color: #9e9fa1;
  border-bottom: ${(props) => (props.selected ? "2px solid #476DFA" : "")};
  margin-bottom: -1px;

  cursor: pointer;
`;

const Title = styled.div`
  font-size: 12px;
`;

const IconContainer = styled.div`
  font-size: 20px;
`;
