import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Select } from "semantic-ui-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { SET_DATA_ACNHOR } from "common/constants/actions";

const Anchor = (props) => {
  const dispatch = useDispatch();
  const get_survey_structure = useSelector(
    (state) => state.surveystructure.survey_structure,
    shallowEqual,
  );

  const get_employee_permission = useSelector(
    (state) => state.employee_permission,
    shallowEqual,
  );

  const build_state = (is_french) => {
    let _state = get_survey_structure[0]?.categories
      .filter((f) => f.priority.toLowerCase() !== "primary")
      .map((i) => {
        return {
          text: is_french ? (i.fr_name ? i.fr_name : i.name) : i.name,
          value: i.id,
        };
      });
    if (!props.pdf && _state) {
      _state.unshift({
        value: get_survey_structure[0].categories.find(
          (f) => f.priority.toLowerCase() === "primary",
        ).id,
        text: is_french ? "Aperçu" : "Overview",
      });
    }

    // _state.push({
    //     value: -1,
    //     text: "BIPOC",
    //   });

    return _state;
  };

  const [selected, setSelected] = useState(
    props.selectedAnchor ||
      get_survey_structure?.[0]?.categories.find(
        (f) => f.priority === "primary",
      ).id,
  );

  const [anchor_list, set_anchor_list] = useState();

  useEffect(() => {
    if (!selected && get_survey_structure?.[0]) {
      setSelected(
        get_survey_structure?.[0]?.categories.find(
          (f) => f.priority === "primary",
        ).id,
      );
    }
  }, [selected, get_survey_structure]);

  useEffect(() => {
    if (get_employee_permission) {
      if (
        get_employee_permission.employee_permission?.[0]?.details?.[0]?.name_id
      ) {
        setSelected(
          get_employee_permission.employee_permission?.[0].details[0].name_id,
        );
      }
    }
  }, [get_employee_permission]);

  useEffect(() => {
    if (props.pdf && anchor_list) {
      const selectedValue = anchor_list.find(
        (anchor) => anchor.text === "Roles",
      );
      setSelected(
        (selectedValue && selectedValue?.value) || anchor_list?.[0]?.value,
      );
    }
  }, [anchor_list]);

  useEffect(() => {
    if (!anchor_list) {
      set_anchor_list(build_state(props.is_french));
    }
  }, [props]);

  useEffect(() => {
    dispatch({
      type: SET_DATA_ACNHOR,
      payload: selected || props.selectedAnchor,
    });
  }, [dispatch, selected, props.selectedAnchor]);

  return (
    <div className="anchors">
      <StyledSelect
        placeholder="Select Survey Type"
        options={anchor_list || []}
        // defaultValue={anchor_list[0].value}
        value={selected}
        id="anchors"
        onChange={(e, t) => setSelected(t.value)}
      />
    </div>
  );
};

export default Anchor;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;

const StyledSelect = styled(Select)`
  font-family: "Inter", sans-serif !important;
  border-radius: 20px !important;
  font-size: 10px !important;
  color: #7e7e7e;

  .item {
    font-size: 10px !important;
    color: #7e7e7e;
    font-family: "Inter", sans-serif !important;
  }
`;
