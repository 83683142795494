export const StarterQuestions = [
  {
    ques: "For understanding the general trends and themes in your data.",
    title:"Generate themes",
    prompt:"What are the top 3 themes that you see in the data?"
  },
  { ques: "Pull out real examples from your data to help connect certain themes.",
  prompt:"Generate 3 themes from the data and pull example quotes from the data to support each theme.",
  title:"Pull examples" },
  { ques: "To identify areas of highest risk within your organization.",
  prompt:"Identiy the top 3 risks that you see in the data. Support these risks with examples.",
title:"Assess Risks" },
  { ques: "Build suggestions for you to act on based on the data.",
  prompt:"Based on this feedback, generate 3 suggestions for the organization to act on.",
  title:"Build suggestions" },
  // {ques: "Suggest 3 activities we could undertake as an organization in order to address one aspect of the feedback given."},
  // {ques: "What are the top 3 strengths of this organization's culture based on the feedback?"}
];