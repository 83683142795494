export const ErrorMessages =[
    {   id: 1, 
        name: 'username password not matching',
        toastMessage: 'Incorrect Password',
        userMessage: 'The email and password combination is not correct, please try again with correct password'
    },
    {   id: 2, 
        name: 'Authentication credentials were not provided.',
        toastMessage: 'Please Login',
        userMessage: 'Please login again'
    },
    {   id: 3, 
        name: 'Invalid token.',
        toastMessage: 'Please Login',
        userMessage: 'Please login again'
    },
    {   id: 4, 
        name: 'non_field_errors',
        toastMessage: 'Wrong Email',
        userMessage: 'Please use the correct email, we were unable to find the login with this email id'
    },
    {   id: 5, 
        name: 'This user already exists, please try using forget password',
        toastMessage: 'This user already exists',
        userMessage: 'This user already exists, please try using forget password with this email id'
    },
    {   id: 6, 
        name: 'Your login has been disbaled',
        toastMessage: 'Please contact support or team admin, your login is disabled',
        userMessage: 'Please contact support or team admin, your login is disabled'
    }
    // Your login has been disbaled
    // /"This user already exists, please try using forget password"/ 
]