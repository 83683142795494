const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
const threshold = 5;

const get__cat_scores = (
  opt,
  data,
  item,
  questions,
  checked,
  company_norms
) => {
  let blob = [];

  let filtered = data.filtered_data[0]
    .filter(
      (f) => f.categories.find((f) => f.id == item.id)?.subfactor == opt.id
    )
    .map((i) => i.questions);

  // let filtered_last = last
  //   .filter(
  //     (f) => f.categories.find((f) => f.id == item.id)?.subfactor == opt.id
  //   )
  //   .map((i) => i.questions);
  if (filtered.length > 0) {
    let scores = get_scores(filtered, questions);
    // let last_scores = get_scores(filtered_last, questions);
    let norm_total = average_1_layer(company_norms);
    if (checked) {
      scores.map((s) => {
        if (s.value != 0) {
          let norm = company_norms.find(
            (f) => f.d == s.d && f.factor == s.factor
          )?.value;
          let thresh = get_scores_below_threshold(filtered, questions, s);
          // let last_score = last_scores.find(
          //   (f) => f.d == s.d && f.factor == s.factor
          // )?.value;
          let diff = (s.value / norm - 1) * 100;
          blob.push({
            ...s,
            category1: item.id,
            option1: opt,
            diff,
            norm,
            thresh,
            n: filtered.length,
            // change: s.value - last_score,
          });
        }
      });
    } else {
      let total = average_1_layer(scores);
      // let last_total = average_1_layer(last_scores);
      let thresh = get_scores_below_threshold(filtered, questions);
      let diff = (total / norm_total - 1) * 100;
      // let change = last_total ? total - last_total : "N/A";
      blob.push({
        category1: item.id,
        option1: opt,
        diff,
        factor: null,
        score: total,
        thresh,
        n: filtered.length
        // change,
      });
    }
  }
  return blob;
};


function get_outcome_scores(outcomes,questions){
  return questions.map(q=>{
    let filtered = outcomes.map((item)=>item?.responses?.filter((f)=>f.q == q.id).map(r=>r.response)).flat()

    return {score:average(filtered),n:outcomes.length,name:q.name,id:q.id}
  })
}

function calculate_outcome_data(data,questions,categories,demographic){
  let company_data = data.filtered_data[0]?.map((item) => item.employee_outcomes)
  let company_norms = get_outcome_scores(company_data,questions)

  let data_blob = [];

  categories.categories.map((item)=>{
    if (item?.demographic && !demographic) {
      return;
    }
    item.options.map((opt)=>{

      let filtered = data.filtered_data[0]
            .filter(
              (f) =>
                f.categories.find((f) => f.id == item.id)?.response == opt.id
            )
            .map((item) => item.employee_outcomes);

      if (filtered.length > 2) {

          let cat_scores = get_outcome_scores(filtered,questions)

          cat_scores = cat_scores.map((score)=>{
            let diff = (score.score/company_norms.find(f=>f.id == score.id).score - 1) * 100
            score['category1'] = item.id
            score['option1'] = opt
            score['factor'] = null
            score['diff'] = diff
            score['effect']  = diff * filtered.length
            
            return score
          })

          data_blob.push(...cat_scores)
      }

            })
  })


return data_blob.filter(f=>f.diff!=-100).sort((a, b) => a.effect - b.effect);
}

export const Search = (
  data,
  categories,
  questions,
  checked,
  demographic,
  layer = 1,
  outcomes,
  outcome_questions
) => {

  let last = data.filter_data_last;
  let company_data = data.filtered_data[0]?.map((item) => item.questions);
  let company_norms = get_scores(company_data, questions);
  //Iterate through each category
 
  if(outcomes){
    return calculate_outcome_data(data,outcome_questions,categories,demographic)
  }

  let data_blob = [];
  categories.categories.map((item) => {
    //Get the average data set based on every category and look at the overall group average
    if (item?.demographic && !demographic) {
      return;
    }
    if (layer == 1) {
      item.options.map((opt) => {
        //check if subfactors are present
        if (opt?.subfactors?.length > 0) {
          opt.subfactors.map((sub) => {
            data_blob.push(
              ...get__cat_scores(
                sub,
                data,
                item,
                questions,
                checked,
                company_norms
                // last
              )
            );
          });
        } else {
          let filtered = data.filtered_data[0]
            .filter(
              (f) =>
                f.categories.find((f) => f.id == item.id)?.response == opt.id
            )
            .map((item) => item.questions);
          // let filtered_last = last
          //   .filter(
          //     (f) =>
          //       f.categories.find((f) => f.id == item.id)?.response == opt.id
          //   )
          //   .map((item) => item.questions);
          if (filtered.length > 2) {
            let scores = get_scores(filtered, questions);
            // let last_scores = get_scores(filtered_last, questions);
            let norm_total = average_1_layer(company_norms);
            if (checked) {
              scores.map((s) => {
                if (s.value != 0) {
                  let norm = company_norms.find(
                    (f) => f.d == s.d && f.factor == s.factor
                  )?.value;
                  let thresh = get_scores_below_threshold(
                    filtered,
                    questions,
                    s
                  );
                  // let last_score = last_scores.find(
                  //   (f) => f.d == s.d && f.factor == s.factor
                  // )?.value;
                  let diff = (s.value / norm - 1) * 100;
                  let effect = diff * filtered.length
                  // let change = last_score ? s.value - last_score : null;
                  data_blob.push({
                    ...s,
                    category1: item.id,
                    option1: opt,
                    diff,
                    norm,
                    thresh,
                    n: filtered.length,
                    effect
                    // change,
                  });
                }
              });
            } else {
              let total = average_1_layer(scores);
              // let last_total = average_1_layer(last_scores);
              let thresh = get_scores_below_threshold(filtered, questions);
              let diff = (total / norm_total - 1) * 100;
              let effect = diff * filtered.length
              // let change = last_total ? total - last_total : null;
              data_blob.push({
                category1: item.id,
                option1: opt,
                diff,
                factor: null,
                score: total,
                thresh,
                n: filtered.length,
                effect
                // change,
              });
            }
          }
        }
      });
    } else {
      //Get the 2nd category analysis variable
      categories.categories
        .filter((f) => f.id != item.id)
        .map((cat2) => {
          if (cat2?.demographic && !demographic) {
            return;
          }

          item.options.map((opt) => {
            cat2.options.map((opt2) => {
              //Get the average scores from each category option
              let filtered = data.filtered_data[0]
                .filter(
                  (f) =>
                    f.categories.find((f) => f.id == item.id)?.response ==
                      opt.id &&
                    f.categories.find((f) => f.id == cat2.id)?.response ==
                      opt2.id
                )
                .map((item) => item.questions);

              // let filtered_last = last
              //   .filter(
              //     (f) =>
              //       f.categories.find((f) => f.id == item.id)?.response ==
              //         opt.id &&
              //       f.categories.find((f) => f.id == cat2.id)?.response ==
              //         opt2.id
              //   )
              //   .map((item) => item.questions);
              if (filtered.length > 2 && !check_blob(opt, opt2, data_blob)) {
                let scores = get_scores(filtered, questions);

                // let last_scores = get_scores(filtered_last, questions);
                let norm_total = average(company_norms.map((s) => s.value));
                if (checked) {
                  scores.map((s) => {
                    if (s.value != 0) {
                      let norm = company_norms.find(
                        (f) => f.d == s.d && f.factor == s.factor
                      )?.value;
                      // let last_score = last_scores.find(
                      //   (f) => f.d == s.d && f.factor == s.factor
                      // )?.value;
                      let thresh = get_scores_below_threshold(
                        filtered,
                        questions,
                        s
                      );
                      let diff = (s.value / norm - 1) * 100;
                      let effect = diff * filtered.length
                      data_blob.push({
                        ...s,
                        category1: item.id,
                        option1: opt,
                        category2: cat2.id,
                        option2: opt2,
                        diff,
                        norm,
                        score: s.value,
                        thresh,
                        n: filtered.length,
                        effect
                        // change: s.value - last_score,
                      });
                    }
                  });
                } else {
                  let total = average(scores.map((s) => s.value));
                  // let last_total = average(last_scores.map((s) => s.value));
                  let diff = (total / norm_total - 1) * 100;
                  let effect = diff * filtered.length
                  let thresh = get_scores_below_threshold(filtered, questions);
                  data_blob.push({
                    category1: item.id,
                    option1: opt,
                    category2: cat2.id,
                    option2: opt2,
                    diff,
                    factor: null,
                    score: total,
                    thresh,
                    n: filtered.length,
                    effect
                    // change: total - last_total,
                  });
                }
              }
            });
          });
        });
    }
  });
  //Look for significant differences in data
  return data_blob.sort((a, b) => a.effect - b.effect);
};

const check_blob = (o1, o2, data) => {
  return (
    data.filter(
      (f) =>
        (f.option1.id == o1.id || f.option2.id == o1.id) &&
        (f.option1.id == o2.id || f.option2.id == o2.id)
    ).length > 0
  );
};

const get_scores = (responses, questions) => {
  let total = [];
  questions.dimensions.map((d) => {
    d.factors.map((f) => {
      let resps = responses
        .map((r) => r.filter((x) => x.id == d.id - 1 && x.factor == f.id - 1))
        .map((r) => r.map((q) => check_reverse_score(q, questions)))
        .flat();

      let value = average(resps);
      total.push({ value, d: d.id - 1, factor: f.id - 1, n: resps.length });
    });
  });

  return total;
};

const average_1_layer = (array) => {
  let dimensions = [];
  array.map((item) => {
    if (!dimensions.includes(item.d)) {
      dimensions.push(item.d);
    }
  });

  let averages = dimensions.map((d) => {
    let total = 0;
    let n = 0;
    array
      .filter((f) => f.d == d)
      .map((item) => {
        total += item.value !== null ? item.value * item.n : 0;
        n += item.value != null ? item.n : 0;
      });

    if (!total) {
      return 0;
    }
    return total / n;
  });

  return average(averages);
};

const average_1_layer2 = (array) => {
  let dimensions = [];
  array.map((item) => {
    if (!dimensions.includes(item.d)) {
      dimensions.push(item.d);
    }
  });

  let averages = dimensions.map((d) => {
    let total = 0;
    let n = 0;
    array
      .filter((f) => f.d == d)
      .map((item) => {
        total += item.value ? item.value * item.n : 0;
        n += item.value ? item.n : 0;
      });

    if (!total) {
      return 0;
    }
    return total / n;
  });

  return averages;
};

const get_scores_below_threshold = (responses, questions, s = null) => {
  if (s) {
    responses = responses.map((i) =>
      i.filter((f) => f.id == s.d && f.factor == s.factor)
    );
  }
  return responses
    .map((i) => average(get_scores([i], questions).map((s) => s.value)))
    .filter((f) => f < threshold).length;
};

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item != null ? item : 0;
    n += item != null ? 1 : 0;
  });

  if (!total) {
    return 0;
  }
  return total / n;
};

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id].factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const get_unique_averages = (resp) =>{

  return resp.map((item)=>{
    let total = 0
    let n = 0
    item.forEach((i)=>{
      total+=i.response
      n+=1
    })
    return total/n
  })

}


function standardDeviation(data) {
  // Calculate the mean
  let mean = data.reduce((a, b) => a + b, 0) / data.length;

  // Calculate the sum of squared differences from the mean
  let sumOfSquaredDifferences = data.reduce((sum, value) => {
      return sum + Math.pow(value - mean, 2);
  }, 0);

  // Calculate variance
  let variance = sumOfSquaredDifferences / data.length;

  // Return standard deviation (square root of variance)
  return Math.sqrt(variance);
}

function pooledStandardDeviation(samples) {
  // Calculate sum of squared deviations and total sample size
  let sumOfSquaredDeviations = 0;
  let totalSampleSize = 0;

  samples.forEach(sample => {
      let sampleSize = sample.length;
      let sampleMean = sample.reduce((a, b) => a + b, 0) / sampleSize;

      sumOfSquaredDeviations += sample.reduce((sum, value) => {
          return sum + Math.pow(value - sampleMean, 2);
      }, 0);

      totalSampleSize += sampleSize;
  });

  // Calculate pooled variance
  let pooledVariance = sumOfSquaredDeviations / (totalSampleSize - samples.length);

  // Return pooled standard deviation
  return Math.sqrt(pooledVariance);
}
