import React from "react";

function AddIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 5.77909 0.179086 5.6 0.4 5.6H11.6C11.8209 5.6 12 5.77909 12 6C12 6.22091 11.8209 6.4 11.6 6.4H0.4C0.179086 6.4 0 6.22091 0 6Z"
        fill="#2D70E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C6.22091 0 6.4 0.179086 6.4 0.4V11.6C6.4 11.8209 6.22091 12 6 12C5.77909 12 5.6 11.8209 5.6 11.6V0.4C5.6 0.179086 5.77909 0 6 0Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export default AddIcon;
