import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/TeamManagement";
import Update from "./user-update/components";

import InActive from "areas/team-management/components/InactiveUsers";
// audit employess
import Team from "./components/CurrentTeamPreference";
import NewTeamMember from "./components/New";
import EditTeamMember from "./components/Edit";
import EditMember from "./components/EditAudit";
import Audit from "./member-directory/components/MemberDirectory";
import UserUpload from "./user-upload/components/UserUpload";

const Menulist = [
  {
    url: "/core/members/list",
    path: "list",
    name: "list",
    element: <Audit />,
    title: "Member List",
    subtitle: "Member List",
    visible: true,
  },
  {
    url: "/core/members/new-member",
    path: "new-member",
    name: "new member",
    element: <NewTeamMember />,
    title: "New Member",
    subtitle: "Add new Member",
    visible: true,
  },
  {
    url: "/core/members/inactive",
    path: "inactive",
    name: "inactive",
    element: <InActive />,
    title: "InActive Members",
    subtitle: "Inactive Members",
    visible: true,
  },
  {
    url: "/core/members/edit/:id",
    path: "edit/:id",
    name: "edit",
    element: <EditMember />,
    title: "Edit Member",
    subtitle: "edit Member",
    visible: false,
  },
  {
    url: "/core/members/team",
    path: "team",
    name: "team",
    element: <Team />,
    title: "Team",
    subtitle: "View All teams",
    visible: true,
  },
  {
    url: "/core/members/team/member/:id",
    path: "team/member/:id",
    name: "team member",
    element: <EditTeamMember />,
    title: "Team",
    subtitle: "View All teams",
    visible: false,
  },
  {
    url: "/core/members/team/new",
    path: "team/new",
    name: "team/new",
    element: <NewTeamMember />,
    title: "New Team",
    subtitle: "Add new team",
    visible: true,
  },
  {
    url: "/core/members/upload",
    path: "upload",
    name: "upload",
    element: <UserUpload />,
    title: "Upload Members",
    subtitle: "Upload members using CSV file",
    visible: true,
  },
  {
    url: "/core/members/update",
    path: "update",
    name: "update",
    element: <Update />,
    title: "Update Members",
    subtitle: "Update members using CSV file",
    visible: true,
  },
];

function MembersRouter() {
  return (
    <div style={{ marginTop: "50px" }}>
      <Routes>
        <Route path="/" element={<Home Menulist={Menulist} />} />
        {Menulist.map((m, i) => {
          return <Route path={m.path} element={m.element} key={i} />;
        })}
      </Routes>
    </div>
  );
}

export default MembersRouter;
