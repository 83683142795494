import styled from "styled-components";
import React, { useEffect, useState } from "react";
import IndividualModal from "./IndividualModal/index";
import { shallowEqual, useSelector } from "react-redux";

const get_average = (data) => {
  let total = 0;
  let n = 0;

  data.map((i) => {
    total += i.average * i.n;
    n += i.n;
  });

  if (n == 0) {
    return "";
  }

  return total / n;
};

const average_responses = (resp) => {
  let total = 0;
  let n = 0;
  resp.map((i) => {
    total += i.response;
    n += 1;
  });

  if (n == 0) {
    return "";
  }

  return total / n;
};

const Individual = ({
  question,
  data,
  q,
  questions,
  is_french,
  language_hook,
}) => {
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setRefresh(!refresh);
  }, [q]);

  return (
    <Table
      question={question}
      info={data}
      refresh={refresh}
      questions={questions}
      is_french={is_french}
      language_hook={language_hook}
    />
  );
};

export default Individual;

const Container = styled.div`
  font-family: "Red Hat Display", sans-serif;
  padding: 40px 80px;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
`;

const get_question_list = (q, questions) => {
  return questions.dimensions[q.dimension].factors[q.factor].questions;
};

const structured = (data, q) => {
  let responses = data.filter(
    (f) => f.dimension == q.dimension && f.section == q.factor,
  );

  return responses;
};

const Table = ({
  question,
  info,
  refresh,
  questions,
  is_french,
  language_hook,
  WhiteLabel,
}) => {
  const [q, setQ] = useState(get_question_list(question, questions));
  const [data, setData] = useState(structured(info, question));

  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const filter_last_data = useSelector(
    (state) => state.audit?.filtered_last_data,
    shallowEqual,
  );

  const get_last_data = (q) => {
    return filter_last_data
      .map((item) =>
        item.questions.filter(
          (f) =>
            f.id == selected.dimension &&
            f.factor == selected.factor &&
            f.q == q,
        ),
      )
      .flat();
  };

  useEffect(() => {
    setData(structured(info, question));
    setQ(get_question_list(question, questions));
  }, [info, refresh]);

  return (
    <TableContainer>
      <Scale color={"181, 181, 181"}>
        <TH width={52.7} />
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <Num>{i}</Num>
        ))}
        <Num2 />
      </Scale>
      {q.map((item, i) => (
        <TableRow
          item={item}
          idx={i}
          data={data.filter((f) => Number(f.question == i))}
          color={"rgba(181, 181, 181,1)"}
          last={get_last_data(i)}
          anchor={anchor}
          last_data={filter_last_data}
          selected={selected}
          is_french={is_french}
          language_hook={language_hook}
          WhiteLabel={WhiteLabel}
        />
      ))}
    </TableContainer>
  );
};

function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
  );
}

const get_stdev = (data) => {
  let dataSet = [];
  data.map((item) => {
    item.distribution.map((x, i) => {
      for (let l = 0; l < x; l++) {
        dataSet.push(i);
      }
    });
  });
  return getStandardDeviation(dataSet);
};

const getWidth = (average, stdev) => {
  if (average - stdev < 0) {
    return average + stdev;
  } else if (average + stdev > 10) {
    return 10 - average + stdev;
  }

  return stdev * 2;
};

const getOffset = (average, stdev) => {
  if (average - stdev < 0) {
    return 0;
  }

  return average - stdev;
};

const TableRow = ({
  item,
  data,
  color,
  idx,
  last,
  anchor,
  last_data,
  selected,
  is_french,
  language_hook,
  WhiteLabel,
}) => {
  const sortedData = data.sort((a, b) => a.average - b.average);
  const [open, setOpen] = React.useState(false);
  const stdev = get_stdev(data);

  const average = get_average(data);
  const last_average = average_responses(last);
  const diff = ((average - last_average) * 10).toFixed(1);

  const get_score_diff = (c) => {
    let last_specific_average = average_responses(
      last_data
        .filter(
          (f) =>
            f.categories.find((q) => q.id == anchor).response == c.label.id,
        )
        .map((item) =>
          item.questions.filter(
            (f) =>
              f.id == selected.dimension &&
              f.factor == selected.factor &&
              f.q == idx,
          ),
        )
        .flat(),
    );

    if (last_specific_average) {
      let _diff = ((c.average.toFixed(1) - last_specific_average) * 10).toFixed(
        1,
      );
      return (
        <span
          style={{ color: _diff > 0 ? "green" : "red", marginLeft: 5 }}
        >{`(${_diff > 0 ? "+" : ""} ${_diff}%)`}</span>
      );
    }
    return "";
  };

  if (data.length == 0) {
    return "";
  }

  return (
    <>
      <TR onClick={() => setOpen(!open)} highlight={item.reverse}>
        <TD
          width={40}
          borderRight={true}
          bold={item == "Average"}
          noBottom={open}
          top={idx === 0}
        >
          {is_french ? item.fr : item.q}
        </TD>
        <TD width={15} center={true} bold={item == "Average"} top={idx === 0}>
          {average.toFixed(1)}{" "}
          {last_average ? (
            <span
              style={{ color: diff > 0 ? "green" : "red", marginLeft: 5 }}
            >{`(${diff > 0 ? "+" : ""} ${diff}%)`}</span>
          ) : (
            ""
          )}
        </TD>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <TD width={4.09} dash={true} noBottom={i == 10} top={idx === 0}></TD>
        ))}

        <BarArea>
          <div
            style={{
              width: "100%",
              position: "relative",
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <STDBAR
              width={getWidth(average, stdev)}
              left={getOffset(average, stdev)}
            />
            <Bar left={average} color={color} show={stdev == 0} />
          </div>
        </BarArea>
      </TR>

      <Details open={open}>
        <TD width={40} borderRight={true}></TD>
        <TD width={15} center={true}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            {sortedData.map((x, i) => {
              return (
                <div>
                  <IndividualModal
                    title={is_french ? x.label.fr : x.label?.name}
                    question={is_french ? item.fr : item.q}
                    data={x}
                    i={i}
                    idx={idx}
                    color={x.label?.color}
                    is_french={is_french}
                    language_hook={language_hook}
                    WhiteLabel={WhiteLabel}
                    trigger={
                      <LabelContainer>
                        {is_french ? x.label.fr : x.label?.name}
                      </LabelContainer>
                    }
                  />
                </div>
              );
            })}
          </div>
        </TD>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <TD width={4.09} dash={true} noBottom={i == 10}></TD>
        ))}

        <BarContainer>
          {sortedData.map((c, i) => {
            return (
              <div
                style={{ height: 25, display: "flex", alignItems: "center" }}
              >
                <Bar2 width={c.average * 4.09} color={"#C0C0C0"}>
                  <Score>
                    {c.average.toFixed(1)} {get_score_diff(c)}
                  </Score>
                </Bar2>
              </div>
            );
          })}
        </BarContainer>
      </Details>
    </>
  );
};

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const LabelContainer = styled.div`
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 12px;
  overflow: hidden;
  margin-bottom: 5px;

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const Scale = styled.div`
  margin-bottom: 5px;
  display: flex;
`;

const TR = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  background-color: ${(props) => (props.highlight ? "#F5F5F5" : "white")};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  }
`;

const BarContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(55% - 2px);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px 0px;
`;

const BarArea = styled.div`
  width: 42%;
  position: absolute;
  left: 54.5%;
  height: 100%;
  align-items: center;
  display: flex;
`;

const Bar = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  position: absolute;
  left: ${(props) => "calc(" + props.left * 10 + "% - 6px)"};
  top: calc(50% - 6px);
  background-color: ${(props) => (props.show ? "#E66579" : "white")};
`;

const STDBAR = styled.div`
  height: 12px;
  width: ${(props) => props.width * 10 + "%"};
  background-color: #e66579;
  opacity: 1;
  left: ${(props) => "calc(" + props.left * 10 + "%)"};
  position: absolute;
  border-radius: 10px;
`;

const Bar2 = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: ${(props) => props.color};
  position: relative;
  border-radius: 10px;
`;

const Num = styled.div`
  width: 4.09%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #7e7e7e;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Num2 = styled.div`
  width: 2%;

  position: relative;
  display: flex;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  flex-direction: row;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.dash ? "0px " : "10px 5px")};
  border-right: ${(props) =>
    props.borderRight ? "1px solid #DFDFDF" : "none"};
  font-weight: ${(props) => (props.bold ? "bold" : 400)};
  border-left: ${(props) => (props.dash ? "1px dashed #DFDFDF " : "none")};
  border-bottom: ${(props) => (!props.noBottom ? "1px solid #DFDFDF" : "none")};
  border-top: ${(props) => (props.top ? "1px solid #DFDFDF" : "none")};
  box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const Details = styled.div`
  width: 100%;
  max-height: ${(props) => (props.open ? "1000px" : 0)};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const Score = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  color: #7e7e7e;
  width: 100px;
`;
