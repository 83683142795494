import { all, call, put, takeLatest } from "redux-saga/effects";
import * as api from "common/constants/api";

import {
  ADD_ORGANIZATION,
  ALL_ERRORS,
  DELETE_ORGANIZATION,
  EDIT_ORGANIZATION,
  EDIT_ORGANIZATION_LOGO,
  FETCH_ORGANIZATION,
  GET_ORGANIZATIONS,
  LOAD_ORGANIZATIONS,
  PULL_ORGANIZATION,
  REMOVE_ORGANIZATION,
  SAVE_ORGANIZATION,
  SG_VALIDATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_LOGO,
  VALIDATE_ORGANIZATION,
} from "common/constants/actions";

function* loadorganizations(action) {
  //   yield put({ type: GET_organizationS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ORGANIZATIONS, action.payload);
    yield put({ type: GET_ORGANIZATIONS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationLoad() {
  yield takeLatest(LOAD_ORGANIZATIONS, loadorganizations);
}

function* fetchorganizations(action) {
  try {
    const json = yield call(api.FETCH_ORGANIZATION, action.payload);
    yield put({ type: FETCH_ORGANIZATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationFetch() {
  yield takeLatest(PULL_ORGANIZATION, fetchorganizations);
}

function* updateorganizations(action) {
  try {
    const json = yield call(api.EDIT_ORGANIZATION, action.payload);
    yield put({ type: EDIT_ORGANIZATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationUpdate() {
  yield takeLatest(UPDATE_ORGANIZATION, updateorganizations);
}

function* updateorganizationlogo(action) {
  try {
    const json = yield call(api.ADD_ORGANIZATION_LOGO, action.payload);
    yield put({ type: EDIT_ORGANIZATION_LOGO, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationlogoUpdate() {
  yield takeLatest(UPDATE_ORGANIZATION_LOGO, updateorganizationlogo);
}

function* addorganizations(action) {
  try {
    const json = yield call(api.ADD_ORGANIZATION, action.payload);
    yield put({ type: ADD_ORGANIZATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationAdd() {
  yield takeLatest(SAVE_ORGANIZATION, addorganizations);
}

function* deleteorganizations(action) {
  try {
    const json = yield call(api.DELETE_ORGANIZATION, action.payload);
    yield put({ type: DELETE_ORGANIZATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationDelete() {
  yield takeLatest(REMOVE_ORGANIZATION, deleteorganizations);
}

function* validateorganizations(action) {
  try {
    const json = yield call(api.VALIDATE_ORGANIZATION, action.payload);
    yield put({ type: VALIDATE_ORGANIZATION, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationValidate() {
  yield takeLatest(SG_VALIDATE_ORGANIZATION, validateorganizations);
}

export default function* index() {
  yield all([
    organizationLoad(),
    organizationFetch(),
    organizationUpdate(),
    organizationlogoUpdate(),
    organizationAdd(),
    organizationDelete(),
    organizationValidate(),
  ]);
}
