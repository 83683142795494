import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Pagination, Progress } from "semantic-ui-react";
import TeamIcon from "assets/images/nav/team.svg";
import { shallowEqual, useSelector } from "react-redux";

var SentimentAnalysis = require("sentiment");

const Context = ({ data, categories, structure }) => {
  var sentiment = new SentimentAnalysis();

  const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual,
  );

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const getFeedbackContext = (s) => {
    let _data = [];
    if (s.level) {
      filter_data[0]
        .filter((f) => "feedback" in f)
        .filter((f) => f.feedback.length > 0)
        .map((item) => {
          item.feedback
            .filter(
              (f) => f.factor == s.factor && f.id == s.dimension && f.feedback,
            )
            .map((f) => {
              _data.push({ feedback: f, categories: item.categories });
            });
        });
    }

    return _data;
  };

  const [page, setPage] = useState(1);
  const [feedback, setFeedback] = useState(getFeedbackContext(selected));

  const get_risk = (score, resp) => {
    let sent = sentiment.analyze(resp);
    let sent_score =
      sent.calculation.length > 0
        ? sent.score / sent.calculation.length
        : sent.score;
    return Math.floor(50 - score * 5 + (sent_score * -1 + 5) * 5);
  };

  useEffect(() => {
    if (selected) {
      setFeedback(getFeedbackContext(selected));
    }
  }, [selected]);

  const getRisk = (risk) => {
    if (risk > 70) {
      return (
        <>
          <Sentiment>High - {risk}%</Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#E5657A"} />
        </>
      );
    }
    if (risk > 50) {
      return (
        <>
          <Sentiment>Moderate - {risk}%</Sentiment>
          <StyledProgressBar percent={risk} size="tiny" color={"#F3AB3C"} />
        </>
      );
    }
    return (
      <>
        <Sentiment>Low - {risk}%</Sentiment>
        <StyledProgressBar percent={risk} size="tiny" color={"#66D686"} />
      </>
    );
  };

  return (
    <Container>
      <div
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
        }}
      ></div>

      <div style={{ width: "100%", marginTop: 20 }}>
        <Header>
          <TH width={50}>Feedback</TH>
          <TH width={20}>
            {categories?.categories.find((f) => f.priority == "primary").name}
          </TH>

          <TH width={30}>Risk</TH>
        </Header>

        {feedback
          .map((item) => {
            let _data = item;
            _data.feedback.risk = get_risk(
              item.feedback.response,
              item.feedback.feedback,
            );
            return _data;
          })
          .sort((a, b) => b.feedback.risk - a.feedback.risk)
          .slice((page - 1) * 10, page * 10)
          .map((item) => {
            let primary = item.categories.find(
              (f) => f.priority == "primary",
            ).response;

            if (
              (structure.dimensions.length == 1 && item.feedback.id != 1) ||
              structure.dimensions.length > 1
            ) {
              return (
                <TR>
                  <TD width={50}>
                    <Question>{item.feedback.question}</Question>
                    <Text>{item.feedback.feedback}</Text>
                  </TD>

                  <TD width={20}>
                    <Team>
                      <Image src={TeamIcon} />
                      {
                        categories.categories
                          .find((f) => f.priority == "primary")
                          .options.find((f) => f.id == primary).name
                      }
                    </Team>
                  </TD>

                  <TD width={30}>{getRisk(item.feedback.risk)}</TD>
                </TR>
              );
            }
          })}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 5,
        }}
      >
        <Pagination
          activePage={page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          totalPages={Math.ceil(feedback.length / 10)}
          onPageChange={(e, { activePage }) => setPage(activePage)}
          size="mini"
        />
      </div>
    </Container>
  );
};

export default Context;

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const Title = styled.div`
  margin-top: 20px;

  margin-bottom: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #414141;
`;

const Question = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
  margin-bottom: 5px;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Sentiment = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #7e7e7e;
`;

const Header = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  padding: 14px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
`;

const TR = styled.div`
  width: 100%;
  padding: 18px 27px;
  font-family: "Poppins";
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 5px;
  align-items: center;
`;

const TD = styled.div`
  word-wrap: break-word;
  width: ${(props) => props.width + "%"};
  padding-right: 10px;
`;

const Image = styled.img`
  margin-right: 10px;
`;

const Team = styled.div`
  display: flex;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #7e7e7e;
  align-items: center;
`;

const Pillar = styled.div`
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  padding: 5px;
  background-color: #615bf0;
  margin: 0px 5px;
  color: white;
  border-radius: 100px;
`;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color + " !important"};
    border-radius: 15px !important;
  }
  border-radius: 15px !important;
`;
