

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item;
    n += 1;
  });
  return Math.floor(total / n * 10)/10;
};


const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const check_reverse_score = (resp, questions) => {

	let reverse =
		questions.dimensions[resp.id].factors[resp.factor].questions[resp.q]
			.reverse;
	let response = reverse
		? rev_order[Math.floor(resp.response)]
		: resp.response;
	return response;
};



export const get_historical_pillar = (
  data,
  selected,
  nav,
  structure,
  anchor,
  rule,
  questions,
  raw) =>{

  //Loop through the history object and filter out the appropriate data
  let new_filtered = data.filter((i,idx)=>idx!=0).map((i) => {
			return i.map((x) => {
				return {
					...x,
					questions: x.questions.filter(
						(f) =>
							f.id == selected.dimension &&
							f.factor == selected.factor
					),
				};
			});
		})

  let new_raw = raw.filter((i,idx)=>idx!=0).map((i) => {
			return i.responses.map((x) => {
				return {
					...x,
					questions: x.questions.filter(
						(f) =>
							f.id == selected.dimension &&
							f.factor == selected.factor
					),
				};
			});
		})


  //loop through each date, and get the average value needed for the chart
 let averages = new_filtered.map(val=>get_average_value(val,questions))
 let org_averages = new_raw.map(val=>get_average_value(val,questions))


return {averages,org_averages}



}



const get_average_value = (data,questions) =>{
	const reducer_reverse = (a, b) => {
		let resp = check_reverse_score(b, questions);
		return a + resp;
	};


	return average(data.map((item)=>
		item.questions.reduce(reducer_reverse, 0) /
					item.questions.length
	))



}