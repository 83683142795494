import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import SurveyVersions from "common/components/VersionControl";
import { Loader } from "semantic-ui-react";

const Correlations = ({ data, questions, loading }) => {
  const get_max = (info) => {
    return Math.max(...info?.map((item) => item.correlation));
  };

  const get_min = (info) => {
    return Math.min(...info?.map((item) => item.correlation));
  };

  const [max, setMax] = useState(get_max(data));
  const [min, setMin] = useState(get_min(data));

  useEffect(() => {
    setMax(get_max(data));
    setMin(get_min(data));
  }, [data]);

  const get_title = (item) => {
    if (questions) {
      if (questions.questions?.dimensions) {
        return questions.questions.dimensions
          .find((d, i) => i == item.dimension)
          .factors.find((factor) => factor.id - 1 === item.factor)?.title;
      }

      return questions.questions.factors.find((d, i) => i == item.factor)
        ?.title;
    }
  };

  const addElipses = (string) => {
    if (string?.length > 45) {
      return string?.slice(0, 45) + "...";
    }
    return string;
  };

  return (
    <Card>
      <CardTitle>
        <div>Correlation with nuLogic factors</div>

        <SurveyVersions />
      </CardTitle>

      <Text>
        The table below shows the correlations between this outcome factor and
        each nuLogic score.
      </Text>
      <Container>
        {data
          ?.sort((a, b) => b.correlation - a.correlation)
          .map((item, index) => {
            let score = item.correlation;
            let width = score > 0 ? (score / max) * 100 : (score / min) * 100;
            let offset = score > 0 ? 0 : -width;
            return (
              <Row>
                <Title>{addElipses(get_title(item))}</Title>
                <Block>
                  <Score score={score} width={width} offset={offset}>
                    <Number side={score > 0}>{score.toFixed(2)}</Number>
                  </Score>
                </Block>
              </Row>
            );
          })}
        {data.length > 0 && (
          <Line>
            <LineTitle>0</LineTitle>
          </Line>
        )}

        {data.length == 0 && !loading && (
          <Empty>
            There are no correlations with this survey version to report.
          </Empty>
        )}
        {loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Loader inline active>
              Loading
            </Loader>
          </div>
        )}
      </Container>
    </Card>
  );
};

export default Correlations;

const Card = styled.div`
  width: 100%;
  font-family: Raleway;
  margin-top: 30px;
  padding-bottom: 80px;
  padding-right: 40px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  color: #2a3039;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  position: relative;

  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

const Title = styled.div`
  width: 175px;
  color: #666d79;
  height: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const Empty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const Block = styled.div`
  width: calc(100% - 175px);
  position: relative;
`;

const growWidth = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${(props) => props.width / 2}%;
    }
`;

// For positive scores
const growRight = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${(props) => props.width / 2}%;
    }
`;

// For negative scores
const growLeft = keyframes`
    0% {
        width: 0%;
        left: 50%;
    }
    100% {
        width: ${(props) => props.width / 2}%;
        left: calc(50% - ${(props) => props.width / 2}% + ${(props) => props.offset / 2}%);
    }
`;

const Score = styled.div`
  width: ${(props) => props.width / 2}%;
  position: absolute;
  left: calc(50% + ${(props) => props.offset / 2}%);
  height: 15px;
  background-color: ${(props) => (props.score > 0 ? "#27CDA7" : "#F15062")};
  opacity: 0.5;
  border-radius: ${(props) =>
    props.score > 0 ? "0px 8px 8px 0px" : "8px 0px 0px 8px"};
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${(props) => (props.score > 0 ? growRight : growLeft)} 0.5s
    forwards ease;
  top: 0px;
`;

const Line = styled.div`
  height: 102%;
  width: 2px;
  background-color: #dfdfdf;
  position: absolute;
  left: calc(175px + (100% - 175px) / 2 - 1px);
  top: -5px;
`;

const LineTitle = styled.div`
  position: absolute;
  left: -100px;
  display: flex;
  justify-content: center;
  top: -20px;
  width: 200px;
  font-size: 10px;
  font-weight: bold;
`;

const Number = styled.div`
  position: absolute;
  left: ${(props) => (props.side ? "" : "-40px")};
  right: ${(props) => (props.side ? "-40px" : "")};
  font-size: 12px;
`;

const Text = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 60px;
`;
