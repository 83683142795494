import React from "react";

function TrendUp(fill: string | undefined) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15.2761 9 15.5 8.77614 15.5 8.5V2.38419e-07H7C6.72386 2.38419e-07 6.5 0.223858 6.5 0.5C6.5 0.776143 6.72386 1 7 1H14.5V8.5C14.5 8.77614 14.7239 9 15 9Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 14.8536C0.841709 15.0488 1.15829 15.0488 1.35355 14.8536L15.3536 0.853554C15.5488 0.658291 15.5488 0.341709 15.3536 0.146446C15.1583 -0.0488155 14.8417 -0.0488155 14.6464 0.146446L0.646447 14.1464C0.451184 14.3417 0.451184 14.6583 0.646447 14.8536Z"
        fill={fill}
      />
    </svg>
  );
}

export default TrendUp;
