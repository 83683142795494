import {
  ADD_ANSWER,
  ADD_CULTURE_ANSWER,
  ADD_MOOD_ANSWER,
  CLEAR_ALL,
  CLEAR_ANSWER,
  FETCH_CULTURE_ANSWER,
  POST_ANSWER,
  SET_ANSWERS,
} from "common/constants/actions";

const initialState = {
  pending: false,
  all_answers: [], // for submitting optional
  // 0 inactive 1 active (part of this survey token) 2 inprocess 3 completed
  culture_survey_status: 0,
  mood_survey_status: 0,
  leadeship_survey_status: 0,
  //
  culture_answers: [],
  culture_max_ques: 0,
  culture_high_ans: 0,
  culture_cur_ques: 0,
  //
  mood_answers: [],
  mood_max_ques: 0,
  mood_high_ans: 0,
  mood_max_ans_nos: 0,
  //
  leadeship_answers: [],
  leadeship_max_ques: 0,
  leadeship_high_ans: 0,
  leadeship_cur_ques: 0,
  //
  personality_answers: [],
  personality_max_ques: 0,
  personality_high_ans: 0,
  personality_cur_ques: 0,

  personality_survey_status: 0,
  // 1 culture 2 mood, 3 leadership 4 personality
  curr_direction: 1,
  all_survey_types: [],
  curr_survey_type: 3,
  direction: 0,
  //
  survey_compliance: "",
  user_token: "",
  isBrowser: [],
  isMobile: [],
  browser: [],
  deviceType: [],
  osVersion: [],
  osName: [],
  deviceDetected: [],
};
// function to check if array are same
function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}
let ansList = [];
let _currentRegQues = 0;
let ans = [];
export default function Answers(state = initialState, action) {
  switch (action.type) {
    case SET_ANSWERS:
      // culture_high_ans
      let culture_high_ans = state.culture_high_ans;
      if (action.payload.culture_high_ans > state.culture_high_ans) {
        culture_high_ans = action.payload.culture_high_ans;
      }
      // mood_high_ans
      let mood_high_ans = state.mood_high_ans;
      if (action.payload.mood_high_ans > state.mood_high_ans) {
        mood_high_ans = action.payload.mood_high_ans;
      }
      // leadeship_high_ans
      let leadeship_high_ans = state.leadeship_high_ans;
      if (action.payload.leadeship_high_ans > state.leadeship_high_ans) {
        leadeship_high_ans = action.payload.leadeship_high_ans;
      }
      // personality_high_ans
      return {
        ...state,
        ...action.payload,
        culture_high_ans,
        mood_high_ans,
        leadeship_high_ans,
      };
    case FETCH_CULTURE_ANSWER:
      let selected_answer = {};
      if (
        state.culture_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        selected_answer = {
          culture_answers: state.culture_answers.filter(
            (ans) => ans.id === action.payload.id,
          )[0],
        };
      }
      return {
        ...state,
        [action.payload.id]: selected_answer,
        pending: false,
        culture_cur_ques: action.payload.id,
      };
    case ADD_CULTURE_ANSWER:
      if (
        state.culture_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        state = {
          ...state,
          culture_answers: state.culture_answers.filter(
            (ans) => ans.id !== action.payload.id,
          ),
        };
      }
      ansList = [];
      _currentRegQues = action.payload.id;

      ans = [...state.culture_answers, action.payload];
      ans.map((p) => ansList.push(p.id));
      if (parseInt(action.payload.id + 1) <= state.culture_max_ques) {
        _currentRegQues = action.payload.id + 1;
      }
      let _culture_high_ans = state.culture_high_ans;
      if (action.payload.culture_high_ans > state.culture_high_ans) {
        _culture_high_ans = action.payload.culture_high_ans;
      }

      return {
        ...state,
        culture_answers: [...state.culture_answers, action.payload],
        culture_high_ans: _culture_high_ans,
        culture_cur_ques: _currentRegQues,
      };

    case ADD_MOOD_ANSWER:
      if (state.mood_answers.filter((an) => an.id === action.payload.id)[0]) {
        state = {
          ...state,
          mood_answers: state.mood_answers.filter(
            (ans) => ans.id !== action.payload.id,
          ),
        };
      }
      return {
        ...state,
        mood_answers: [...state.mood_answers, action.payload],
      };

    case ADD_ANSWER:
      if (
        state.culture_answers.filter((an) => an.id === action.payload.id)[0]
      ) {
        state = {
          ...state,
          culture_answers: state.culture_answers.filter(
            (ans) => ans.id !== action.payload.id,
          ),
        };
      }
      ansList = [];
      _currentRegQues = action.payload.id;

      ans = [...state.culture_answers, action.payload];
      ans.map((p) => ansList.push(p.id));
      if (parseInt(state.maxQuestionNo) >= parseInt(action.payload.id + 1)) {
        _currentRegQues = action.payload.id + 1;
        // console.log(_currentRegQues,  parseInt(action.payload.id + 1))
      }

      return {
        ...state,
        culture_answers: [...state.culture_answers, action.payload],
        answerRegNos: ansList.sort(),
        maxAnsNo: Math.max(...ansList),
        // preview: _preview,
        currentRegQues: _currentRegQues,
      };
    case POST_ANSWER:
      return {
        ...state,
        all_answers: [
          ...state.culture_answers,
          ...state.mood_answers,
          ...state.leadeship_answers,
          ...state.personality_answers,
          action.payload,
        ],
      };
    case CLEAR_ANSWER:
      return {
        ...state,
        answers: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        answers: [],
      };
    default:
      return state;
  }
}
