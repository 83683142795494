import React from "react";
import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import format from "date-fns/format";

import coverImage from "../assets/logo.png";

const Intro = (props) => {
  let imgCover;
  let coverTitle;
  let subtitle;
  let reportType;
  let templateType = 1;
  if (props.config?.[0].data) {
    const { coverSubtitle, coverImage, coverHeading, type, introTemplate } =
      props.config?.[0].data;
    templateType = (introTemplate && introTemplate) || 1;
    imgCover = coverImage && coverImage !== "DEFAULT" ? coverImage : null;
    subtitle = (coverSubtitle && coverSubtitle) || "PDF summary report";
    coverTitle = (coverHeading && coverHeading) || "Sport Culture Index";
    reportType = (type && type) || "Culture Audit";
  }

  const styles = StyleSheet.create({
    page: {
      position: "relative",
      height: "100vh",
      backgroundColor: "#FCD21D",
    },
    textarea: {
      position: "absolute",
      top: "10%",
      left: 80,
      color: "#353740",
    },
    title: {
      fontWeight: 700,
      fontSize: 30,
      width: 350,
      lineHeight: 1.2,
    },
    subTitle: {
      fontSize: 16,
      marginTop: 20,
      width: 350,
      lineHeight: 1.2,
    },
    bottom: {
      width: "100vw",
      height: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      position: "absolute",
      bottom: 0,
    },
    bar: {
      width: 7,
      borderRadius: 2.5,
    },
    sub1: {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 20,
      marginBottom: 200,
    },
    img: {
      width: 50,
      marginLeft: -10,
      marginBottom: 75,
    },
  });

  const bars = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28,
  ];
  const colors = ["#24445B", "#3A657B", "#30566C"];

  const formatReportDate = (dateTime) => {
    const dateParts = dateTime.match(/(\d+)/g);
    const newDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

    return format(newDate, "MMMM do, yyyy");
  };

  return (
    <View style={styles.page}>
      {/* <Svg height="700" width="400" >
            <Polygon
              points="0,0 150,0 400,150 400,625 250,700 0,550 0,0"
              fill="#063D5C"
            />
          </Svg>
*/}
      <View style={styles.textarea}>
        <Image src={coverImage} style={styles.img} />
        <Text style={styles.title}>nuLogic Report</Text>
        <Text style={styles.sub1}>Overview and Key Findings </Text>
        <Text style={styles.subTitle}>
          Generated by nuLogic for Company on March 4th, 2022
        </Text>
      </View>

      <View style={styles.bottom}>
        {bars.map((item) => (
          <View
            style={[
              styles.bar,
              {
                height: 50 + (100 * item) / 28 + Math.random() * 100,
                backgroundColor:
                  colors[Math.floor(Math.random() * colors.length)],
              },
            ]}
          />
        ))}
      </View>
    </View>
  );
};

Intro.propTypes = {
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string,
  coverSubtitle: PropTypes.string,
  metadata: PropTypes.object,
};

export default Intro;
