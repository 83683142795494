export const SURVEY_TYPE = {
    AUDIT: 3,
    EDI: 4,
    OTP: 5,
};

export const SURVEY_QUESTION_TYPE = {
    AUDIT: 6,
    EDI: 7,
    OTP: 8,
};

export const SURVEY_QUESTION_STATUS = {
    NEW: 1,
    LIVE: 2,
    ARCHIVED: 3,
};

export const SURVEY_INFO = {
    audit : {
        type: 3,
        name: "Audit",
        question_type: 6,
        default_question_sort_order: 6,
    },
    edi : {
        type: 4,
        name: "EDI",
        question_type: 7,
        default_question_sort_order: 7,
    },
    otp : {
        type: 5,
        name: "OTP",
        question_type: 8,
        default_question_sort_order: 8,
    }
}