import {
  ADD_EMPLOYEE_RECORD_VALUE,
  CLEAR_ALL,
  CLEAR_EMPLOYEE_RECORD_VALUE,
  DELETE_EMPLOYEE_RECORD_VALUE,
  FETCH_EMPLOYEE_RECORD_VALUE,
  GET_EMPLOYEE_RECORD_VALUE,
  UPDATE_EMPLOYEE_RECORD_VALUE,
} from "common/constants/actions";

const initialState = {
  employee_record_value: [],
  pending: false,
  created: false,
};

export default function EmployeeCategories(state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEE_RECORD_VALUE:
      return {
        ...state,
        employee_record_value: action.payload,
        pending: false,
        created: false,
      };

    case FETCH_EMPLOYEE_RECORD_VALUE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        created: false,
      };

    case UPDATE_EMPLOYEE_RECORD_VALUE:
      // if (state.employee_record_value.filter((an) => an.id === action.payload.id)[0]) {
      //     state = {
      //         ...state,
      //         employee_record_value: state.employee_record_value.filter(
      //             (rw) => rw.id !== action.payload.id
      //         ),
      //     };
      // }
      return {
        ...state,
        created: false,
        employee_record_value: [...state.employee_record_value, action.payload],
      };

    case DELETE_EMPLOYEE_RECORD_VALUE:
      // console.log(action.payload)
      return {
        ...state,
        employee_record_value: state.employee_record_value.filter(
          (row) => row.id !== action.payload.id,
        ),
      };

    case ADD_EMPLOYEE_RECORD_VALUE:
      // const newState = state.employee_record
      // newState.push(action.payload)
      return {
        ...state,
        created: action.payload,
        employee_record_value: [...state.employee_record_value, action.payload],
      };

    case CLEAR_EMPLOYEE_RECORD_VALUE:
      return {
        ...state,
        employee_record_value: [],
        created: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        employee_record_value: [],
        created: false,
      };

    default:
      return state;
  }
}
