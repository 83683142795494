import {
  ButtonTrashIcon,
  PlusIcon,
  SaveIcon,
  XIcon,
} from "common/components/icons";
import styled from "styled-components";
import PropTypes from "prop-types";

// Shared StyledButton component with props for dynamic customization
const StyledButton = ({
  label = "Button",
  color = "gray",
  icon,
  width = "auto",
  onClick,
}) => {
  return (
    <StyledButtonStyles variant={color} onClick={onClick} width={width}>
      {icon && <ButtonIcon>{icon}</ButtonIcon>}
      <ButtonText>{label}</ButtonText>
    </StyledButtonStyles>
  );
};

StyledButton.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(["blue", "gray", "orange", "green", "red"]),
  icon: PropTypes.element,
  width: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export { StyledButton };

// Specialized Buttons using the generic StyledButton
const StyledSaveButton = ({
  label = "Save",
  color = "blue",
  width = "100px",
  onClick,
}) => (
  <StyledButton
    label={label}
    color={color}
    icon={<SaveIcon />}
    width={width}
    onClick={onClick}
  />
);

const StyledCancelButton = ({
  label = "Cancel",
  color = "gray",
  width = "100px",
  onClick,
}) => (
  <StyledButton
    label={label}
    color={color}
    icon={<XIcon />}
    width={width}
    onClick={onClick}
  />
);

const StyledArchiveButton = ({
  label = "Archive",
  color = "orange",
  width = "100px",
  onClick,
}) => (
  <StyledButton
    label={label}
    color={color}
    icon={<ButtonTrashIcon />}
    width={width}
    onClick={onClick}
  />
);

const StyledReactivateButton = ({
  label = "Re-Activate",
  color = "red",
  width = "120px",
  onClick,
}) => (
  <StyledButton label={label} color={color} width={width} onClick={onClick} />
);

const StyledAddButton = ({
  label = "Add",
  color = "green",
  width = "120px",
  onClick,
}) => (
  <StyledButton
    label={label}
    color={color}
    icon={<PlusIcon />}
    width={width}
    onClick={onClick}
  />
);

export {
  StyledSaveButton,
  StyledCancelButton,
  StyledArchiveButton,
  StyledReactivateButton,
  StyledAddButton,
};

// Simplified Styled Components

const colorMap = {
  blue: "#476dfa",
  gray: "#bbbbbb",
  orange: "#f3aa3d",
  green: "#28a745",
  red: "#e5657a",
  default: "#bbbbbb",
};

const hoverColorMap = {
  blue: "#6b8aff",
  gray: "#dddddd",
  orange: "#f6bd65",
  green: "#4ed46b",
  red: "#f28a9b",
  default: "#dddddd",
};

const StyledButtonStyles = styled.div`
  padding: 3px 20px;
  height: 30px;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: ${(props) => props.width || "auto"};

  background: ${(props) => colorMap[props.variant] || colorMap.default};

  &:hover {
    background-color: ${(props) =>
      hoverColorMap[props.variant] || hoverColorMap.default};
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

const ButtonText = styled.label`
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  cursor: pointer;
`;
