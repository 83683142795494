import React from "react";
import styled from "styled-components";

const Custom = styled.input`
  border-radius: 5px;
  height: 45px;
  outline: none;
  border: ${(props) =>
    props.error ? "1px solid #ff2d55" : "1px solid #9b9b9b"};
  font-family: "Barlow", sans-serif;
  margin-top: 3px;
  padding-left: 15px;
`;
const Container = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-right: 30px;
  width: ${(props) => (props.half ? "calc(50% - 15px)" : "100%")};
`;

const Error = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #ff2d55;
`;

const Input = React.forwardRef((props, ref) => {
  return (
    <Container half={props.half}>
      {props.placeholder}
      <Custom
        error={props.error}
        type={props.type}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e.target.value)}
        defaultValue={props.defaultValue}
        forwardedRef={ref}
      />
      <Error>{props.error}</Error>
    </Container>
  );
});

export default Input;
