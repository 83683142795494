import React from "react";

function FilterIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99975 9.26137H7.49975C7.49975 9.14363 7.4582 9.02966 7.38241 8.93955L6.99975 9.26137ZM1.41042 2.61548L1.79308 2.29366L1.41042 2.61548ZM16.6457 2.61548L16.2631 2.29366L16.6457 2.61548ZM10.9998 9.32874L10.6171 9.00691C10.5413 9.09702 10.4998 9.21099 10.4998 9.32874H10.9998ZM10.9998 14L11.2998 14.4C11.4257 14.3056 11.4998 14.1574 11.4998 14H10.9998ZM6.99975 17H6.49975C6.49975 17.1894 6.60675 17.3625 6.77615 17.4472C6.94554 17.5319 7.14824 17.5136 7.29975 17.4L6.99975 17ZM7.38241 8.93955L1.79308 2.29366L1.02776 2.93731L6.61709 9.5832L7.38241 8.93955ZM1.79308 2.29366C1.51955 1.96841 1.75077 1.47183 2.17574 1.47183V0.471832C0.900811 0.471832 0.207153 1.96158 1.02776 2.93731L1.79308 2.29366ZM2.17574 1.47183H15.8804V0.471832H2.17574V1.47183ZM15.8804 1.47183C16.3054 1.47183 16.5366 1.96841 16.2631 2.29366L17.0284 2.93731C17.849 1.96158 17.1553 0.471832 15.8804 0.471832V1.47183ZM16.2631 2.29366L10.6171 9.00691L11.3824 9.65056L17.0284 2.93731L16.2631 2.29366ZM10.4998 9.32874V14H11.4998V9.32874H10.4998ZM10.6998 13.6L6.69975 16.6L7.29975 17.4L11.2998 14.4L10.6998 13.6ZM7.49975 17V9.26137H6.49975V17H7.49975Z"
        fill="#666D79"
      />
    </svg>
  );
}

export default FilterIcon;
