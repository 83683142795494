import {
  ADD_SURVEY_STANDARD,
  CLEAR_ALL,
  CLEAR_SURVEY_STANDARD,
  DELETE_SURVEY_STANDARD,
  FETCH_SURVEY_STANDARD,
  GET_SURVEY_STANDARD,
  SURVEY_STANDARD_PENDING,
  UPDATE_SURVEY_STANDARD,
} from "common/constants/actions";

const initialState = {
  survey_standards: [],
  pending: false,
};

export default function SurveyStandard(state = initialState, action) {
  switch (action.type) {
    case SURVEY_STANDARD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SURVEY_STANDARD:
      return {
        ...state,
        survey_standards: action.payload,
        pending: false,
      };

    case FETCH_SURVEY_STANDARD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case UPDATE_SURVEY_STANDARD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
      };

    case DELETE_SURVEY_STANDARD:
      return {
        ...state,
        survey_standards: state.survey_standards.filter(
          (row) => row.id !== action.payload.id,
        ),
        pending: false,
      };

    case ADD_SURVEY_STANDARD:
      return {
        ...state,
        survey_standards: [...state.survey_standards, action.payload],
        pending: false,
      };

    case CLEAR_SURVEY_STANDARD:
      return {
        ...state,
        survey_standards: [],
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        survey_standards: [],
        pending: false,
      };

    default:
      return state;
  }
}
