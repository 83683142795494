import React from "react";

function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.14272 6.14272C6.333 5.95243 6.64152 5.95243 6.83181 6.14272L17.8573 17.1682C18.0476 17.3585 18.0476 17.667 17.8573 17.8573C17.667 18.0476 17.3585 18.0476 17.1682 17.8573L6.14272 6.83181C5.95243 6.64152 5.95243 6.333 6.14272 6.14272Z"
            fill="#2A3039"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.8573 6.14272C18.0476 6.333 18.0476 6.64152 17.8573 6.83181L6.83181 17.8573C6.64152 18.0476 6.333 18.0476 6.14272 17.8573C5.95243 17.667 5.95243 17.3585 6.14272 17.1682L17.1682 6.14272C17.3585 5.95243 17.667 5.95243 17.8573 6.14272Z"
            fill="#2A3039"
          />
        </g>
      </g>
    </svg>
  );
}

export default CloseIcon;
