import React, { useEffect, useState } from "react";
import Template from "../pages/Template";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Button, Icon, Loader } from "semantic-ui-react";
import styled from "styled-components";

const PDFInstance = (props) => {
  const {
    setPdfGenerating,
    overview,
    surveyStructure,
    surveyQuestions,
    responseData,
    overviewReport,
  } = props;

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentBlob, setDocumentBlob] = useState();
  const [documentUrl, setDocumentUrl] = useState();

  /**** Helper Functions ****/
  const generatePDF = async () => {
    // This hides the loading spinner which displays *BEFORE* the pdf offially starts generating.
    setPdfGenerating(false);
    setLoading(true);
    const pdfDoc = await pdf(
      <Template
        surveyStructure={surveyStructure[0]}
        surveyQuestions={surveyQuestions[0]}
        ediResponses={responseData}
        overviewReport={overviewReport}
      />,
    ).toBlob();

    setDocumentBlob(pdfDoc);
    setDocumentUrl(window.URL.createObjectURL(pdfDoc));
    setLoading(false);
  };

  /**** useEffect Hooks ****/
  useEffect(() => {
    generatePDF();
  }, []);

  useEffect(() => {
    if (documentBlob && !saved && !loading) {
      setSaved(true);
      // TODO: Update filename to be proper/dynamic
      saveAs(documentBlob, `test.pdf`);
    }
  }, [documentBlob, loading]);

  /****** Render Functions *****/
  if (loading) {
    return (
      <div
        style={{
          marginLeft: overviewReport ? "0px" : "-65px",
          marginTop: overviewReport ? "0px" : "-35px",
        }}
      >
        <Loader inline active size="small" />
      </div>
    );
  }

  if (documentUrl && !props.overviewReport) {
    return (
      <div
        style={{
          height: "25px",
          width: "110px",
          cursor: "pointer",
          marginLeft: "-65px",
          marginTop: "-35px",
        }}
      >
        <a href={documentUrl} download={`test.pdf`}>
          <Icon
            name="file pdf"
            size="large"
            style={{
              color: "rgb(156,35,21)",
            }}
          />
        </a>
      </div>
    );
  }

  if (documentUrl && props.overviewReport) {
    return (
      <div
        style={{
          height: "25px",
          width: "110px",
          cursor: "pointer",
        }}
      >
        <a href={documentUrl} download={`test.pdf`}>
          <PDFDownloadWrapper>
            <Button>Download Again</Button>
          </PDFDownloadWrapper>
        </a>
      </div>
    );
  }

  return null;
};

const PDFDownloadWrapper = styled.div`
  width: 150px;
  left: 0;
  top: -50px;
`;

export default PDFInstance;
