import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./login/index";

function AmbassadorPages() {
  /* All <Route path> and <Link to> values in this
     component will automatically be "mounted" at the
     /blog URL prefix since the <Blog> element is only
     ever rendered when the URL matches /blog/*
  */
  return (
    <div>
      {/* <nav>
        <Link to="create-blog">My Profile</Link>
      </nav> */}

      <Routes>
        <Route path="/" element={<Home />} />
        {/* To do map the routed within this folder on this page */}
        {/* <Route path="edit/:id" element={<CreateBlog />} />
        <Route path="create-blog" element={<CreateBlog />} /> */}
      </Routes>
    </div>
  );
}

export default AmbassadorPages;
