import React from "react";

function StarIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6695 0.5C10.8598 0.5 11.0337 0.608049 11.1179 0.778717L13.9099 6.43601L20.1531 7.3432C20.3415 7.37056 20.4979 7.50248 20.5568 7.68349C20.6156 7.8645 20.5665 8.0632 20.4302 8.19604L15.9126 12.5996L16.9791 18.8176C17.0112 19.0052 16.9341 19.1948 16.7802 19.3066C16.6262 19.4185 16.4221 19.4332 16.2536 19.3447L10.6695 16.409L5.08545 19.3447C4.91699 19.4332 4.71286 19.4185 4.55888 19.3066C4.40491 19.1948 4.3278 19.0052 4.35997 18.8176L5.42644 12.5996L0.908821 8.19604C0.772535 8.0632 0.723486 7.8645 0.782299 7.68349C0.841111 7.50248 0.997585 7.37056 1.18593 7.3432L7.42912 6.43601L10.2212 0.778717C10.3054 0.608049 10.4792 0.5 10.6695 0.5ZM10.6695 2.12978L8.20952 7.11429C8.13669 7.26187 7.9959 7.36415 7.83305 7.38781L2.33231 8.18712L6.31268 12.067C6.43052 12.1819 6.4843 12.3474 6.45648 12.5096L5.51684 17.9881L10.4369 15.4015C10.5825 15.3249 10.7565 15.3249 10.9022 15.4015L15.8222 17.9881L14.8826 12.5096C14.8548 12.3474 14.9085 12.1819 15.0264 12.067L19.0067 8.18712L13.506 7.38781C13.3431 7.36415 13.2024 7.26187 13.1295 7.11429L10.6695 2.12978Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export default StarIcon;
